import nimoReport from '@client/common/services/report';

/**
 * @param {string} trigger switch-用户切换高分辨率时触发 watch-用户高分辨率下观看满5分钟触发
 */
export var reportShowLoginguideVideoplayer = function reportShowLoginguideVideoplayer(trigger) {
  nimoReport.report(nimoReport._formatReportObj('sys/show/loginguide/videoplayer', '直播间播放器登录引导展示', {
    trigger_: trigger
  }));
};

/**
 * @param {string} trigger switch-用户切换高分辨率时触发 watch-用户高分辨率下观看满5分钟触发
 */
export var reportClickDownloadLoginguide = function reportClickDownloadLoginguide(trigger) {
  nimoReport.report(nimoReport._formatReportObj('usr/click/download/loginguide', '直播间播放器登录引导-点击下载', {
    trigger_: trigger
  }));
};

/**
 * @param {string} trigger switch-用户切换高分辨率时触发 watch-用户高分辨率下观看满5分钟触发
 */
export var reportClickLoginLoginguide = function reportClickLoginLoginguide(trigger) {
  nimoReport.report(nimoReport._formatReportObj('usr/click/login/loginguide', '直播间播放器登录引导-点击登录', {
    trigger_: trigger
  }));
};