var _globalThis$__NIMO_AP;
import globalThis from '@nimo-fed/util/globalThis';
import { MetricReport, getTimestampInSecond } from '@nimo-fed/metric-report';
import ua from '@client/common/utils/ua';
import { getCountry as _getCountry, getMid } from '../utils/fn';
import { getUnsafeAuth } from './getUnsafeAuth';
var metricReport = new MetricReport({
  getUid: function getUid() {
    return getUnsafeAuth().udbUserId || '0';
  },
  getDeviceId: function getDeviceId() {
    return getMid();
  },
  getCountry: function getCountry() {
    return _getCountry();
  },
  getGuid: function getGuid() {
    return getUnsafeAuth().guid;
  },
  host: DOMAINS.metricdomain,
  isDebug: DOMAINS.isDebug,
  isMobile: (globalThis === null || globalThis === void 0 ? void 0 : (_globalThis$__NIMO_AP = globalThis.__NIMO_APP_DATA__) === null || _globalThis$__NIMO_AP === void 0 ? void 0 : _globalThis$__NIMO_AP.platform) === 'mobile' || (ua === null || ua === void 0 ? void 0 : ua.isWap),
  // NOTE: 在 layout.ejs 模板定义 window?.__NIMO_APP_DATA__?.platform，未定义则使用 ua 判断
  metricVerSuffix: DOMAINS.metric_ver_suffix
});
var r = metricReport.report.bind(metricReport);
export default metricReport;
export { r, getTimestampInSecond };