import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var UserId = function UserId() {
  this.lUid = 0;
  this.sGuid = "";
  this.sToken = "";
  this.sUA = "";
  this.sCookie = "";
  this.sLang = "";
  this.sUDBVer = "";
  this.iRegOrigin = 0;
  this.sCountry = "";
  this.sRegion = "";
  this.sOpSeq = "";
  this.iNetType = 127;
  this.lDevInstallTime = 0;
};
UserId.prototype._clone = function () {
  return new Nimo.UserId();
};
UserId.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
UserId.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
UserId.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUid);
  os.writeString(1, this.sGuid);
  os.writeString(2, this.sToken);
  os.writeString(3, this.sUA);
  os.writeString(4, this.sCookie);
  os.writeString(5, this.sLang);
  os.writeString(6, this.sUDBVer);
  os.writeInt32(7, this.iRegOrigin);
  os.writeString(8, this.sCountry);
  os.writeString(9, this.sRegion);
  os.writeString(10, this.sOpSeq);
  os.writeInt32(11, this.iNetType);
  os.writeInt64(12, this.lDevInstallTime);
};
UserId.prototype.readFrom = function (is) {
  this.lUid = is.readInt64(0, false, this.lUid);
  this.sGuid = is.readString(1, false, this.sGuid);
  this.sToken = is.readString(2, false, this.sToken);
  this.sUA = is.readString(3, false, this.sUA);
  this.sCookie = is.readString(4, false, this.sCookie);
  this.sLang = is.readString(5, false, this.sLang);
  this.sUDBVer = is.readString(6, false, this.sUDBVer);
  this.iRegOrigin = is.readInt32(7, false, this.iRegOrigin);
  this.sCountry = is.readString(8, false, this.sCountry);
  this.sRegion = is.readString(9, false, this.sRegion);
  this.sOpSeq = is.readString(10, false, this.sOpSeq);
  this.iNetType = is.readInt32(11, false, this.iNetType);
  this.lDevInstallTime = is.readInt64(12, false, this.lDevInstallTime);
};
Nimo.UserId = UserId;
export default UserId;