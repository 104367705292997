import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import fanGroupModel from '@client/common/models/follow/fanGroup';
import { updateFanGroupInfo } from './actions';
export function updateFanGroupInfoAsync() {
  return function (dispatch, getState) {
    var _ref = getState().home.room || {},
      _ref$roomInfo = _ref.roomInfo,
      _ref$roomInfo2 = _ref$roomInfo === void 0 ? {} : _ref$roomInfo,
      auId = _ref$roomInfo2.auId,
      _ref$userInfo = _ref.userInfo,
      _ref$userInfo2 = _ref$userInfo === void 0 ? {} : _ref$userInfo,
      uid = _ref$userInfo2.uid;
    if (auId) {
      return fanGroupModel.getFanGroupRelationAndTicket({
        anchorId: auId,
        fanId: uid || 0
      }).then(function (_ref2) {
        var ticketGiftId = _ref2.ticketGiftId,
          fanGroupInfo = _ref2.badgeInfoView;
        dispatch(updateFanGroupInfo(_objectSpread(_objectSpread({
          ticketGiftId: ticketGiftId
        }, fanGroupInfo), {}, {
          leftBadgeIcon: fanGroupInfo.leftBadgeIconV2,
          // 手动将leftBadgeIconV2赋值给leftBadgeIcon，防止有的地方直接用了旧的字段
          relation: fanGroupInfo.badgeStatus // 手动增加一下旧字段名
        })));
      }).catch(function () {});
    }
    return Promise.resolve();
  };
}