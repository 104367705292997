import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var ItemTallyUserInfo = function ItemTallyUserInfo() {
  this.lUid = 0;
  this.sNick = "";
  this.sAvatarUrl = "";
  this.lScore = 0;
  this.iMysteror = 0;
};
ItemTallyUserInfo.prototype._clone = function () {
  return new Nimo.ItemTallyUserInfo();
};
ItemTallyUserInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ItemTallyUserInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ItemTallyUserInfo.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUid);
  os.writeString(1, this.sNick);
  os.writeString(2, this.sAvatarUrl);
  os.writeInt64(3, this.lScore);
  os.writeInt32(4, this.iMysteror);
};
ItemTallyUserInfo.prototype.readFrom = function (is) {
  this.lUid = is.readInt64(0, false, this.lUid);
  this.sNick = is.readString(1, false, this.sNick);
  this.sAvatarUrl = is.readString(2, false, this.sAvatarUrl);
  this.lScore = is.readInt64(3, false, this.lScore);
  this.iMysteror = is.readInt32(4, false, this.iMysteror);
};
Nimo.ItemTallyUserInfo = ItemTallyUserInfo;
export default ItemTallyUserInfo;