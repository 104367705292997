module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="bc1 n-as-rel bsc3 n-as-w' +
((__t = ( typeof isCollapse !== 'undefined' && isCollapse ? '50' : '220' )) == null ? '' : __t) +
'px">\n  <div class="n-as-fixed n-as-h100 bc1 n-as-w' +
((__t = ( typeof isCollapse !== 'undefined' && isCollapse ? '50' : '220' )) == null ? '' : __t) +
'px">\n    <div class="n-sk n-sk-t16 n-sk-min-w20px n-as-mrgh n-as-mrgv-lg"></div>\n    <div class="n-sk n-sk-t16 n-sk-min-w20px n-as-mrgh n-as-mrgv-lg"></div>\n    <div class="n-sk n-sk-t16 n-sk-min-w20px n-as-mrgh n-as-mrgv-lg"></div>\n    <div class="n-sk n-sk-t16 n-sk-min-w20px n-as-mrgh n-as-mrgv-lg"></div>\n  </div>\n</div>\n';

}
return __p
}