import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var UserEventRsp = function UserEventRsp() {
  this.iCode = 0;
  this.iUserHeartBeatInterval = 20;
};
UserEventRsp.prototype._clone = function () {
  return new Nimo.UserEventRsp();
};
UserEventRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
UserEventRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
UserEventRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeInt32(1, this.iUserHeartBeatInterval);
};
UserEventRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.iUserHeartBeatInterval = is.readInt32(1, false, this.iUserHeartBeatInterval);
};
Nimo.UserEventRsp = UserEventRsp;
export default UserEventRsp;