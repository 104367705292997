import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BaseParam';
var FollowParam = function FollowParam() {
  this.baseParam = new NimoBuss.BaseParam();
  this.anchorId = 0;
  this.roomId = 0;
  this.followSource = "";
};
FollowParam.prototype._clone = function () {
  return new NimoBuss.FollowParam();
};
FollowParam.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FollowParam.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FollowParam.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeInt64(1, this.anchorId);
  os.writeInt64(2, this.roomId);
  os.writeString(3, this.followSource);
};
FollowParam.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.anchorId = is.readInt64(1, false, this.anchorId);
  this.roomId = is.readInt64(2, false, this.roomId);
  this.followSource = is.readString(3, false, this.followSource);
};
NimoBuss.FollowParam = FollowParam;
export default FollowParam;