import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './ExtendRoomMsgBannerNoticeItem';
var ExtendRoomMsgBannerNotice = function ExtendRoomMsgBannerNotice() {
  this.vNotices = new Taf.Vector(new Nimo.ExtendRoomMsgBannerNoticeItem());
};
ExtendRoomMsgBannerNotice.prototype._clone = function () {
  return new Nimo.ExtendRoomMsgBannerNotice();
};
ExtendRoomMsgBannerNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ExtendRoomMsgBannerNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ExtendRoomMsgBannerNotice.prototype.writeTo = function (os) {
  os.writeVector(0, this.vNotices);
};
ExtendRoomMsgBannerNotice.prototype.readFrom = function (is) {
  this.vNotices = is.readVector(0, false, this.vNotices);
};
Nimo.ExtendRoomMsgBannerNotice = ExtendRoomMsgBannerNotice;
export default ExtendRoomMsgBannerNotice;