import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var GetPushStateReq = function GetPushStateReq() {
  this.user = new Nimo.UserId();
  this.lRoomId = 0;
};
GetPushStateReq.prototype._clone = function () {
  return new Nimo.GetPushStateReq();
};
GetPushStateReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetPushStateReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetPushStateReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
  os.writeInt64(1, this.lRoomId);
};
GetPushStateReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
};
Nimo.GetPushStateReq = GetPushStateReq;
export default GetPushStateReq;