import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var GetLiveRoomInfoReq = function GetLiveRoomInfoReq() {
  this.tUser = new Nimo.UserId();
  this.lPid = 0;
  this.mUserData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.sPassword = "";
};
GetLiveRoomInfoReq.prototype._clone = function () {
  return new Nimo.GetLiveRoomInfoReq();
};
GetLiveRoomInfoReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetLiveRoomInfoReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetLiveRoomInfoReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeInt64(1, this.lPid);
  os.writeMap(2, this.mUserData);
  os.writeString(3, this.sPassword);
};
GetLiveRoomInfoReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.lPid = is.readInt64(1, false, this.lPid);
  this.mUserData = is.readMap(2, false, this.mUserData);
  this.sPassword = is.readString(3, false, this.sPassword);
};
Nimo.GetLiveRoomInfoReq = GetLiveRoomInfoReq;
export default GetLiveRoomInfoReq;