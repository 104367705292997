import { markNavigateForWebpush, shouldReportPermission, rememberReportPermission } from '@client/common/lib/firebase/analytics';
import supportWebPush from '@client/common/lib/firebase/supportWebPush';
export default function report(nimoReport) {
  if (!supportWebPush()) return;
  markNavigateForWebpush();
  shouldReportPermission().then(function (shouldIReport) {
    if (shouldIReport) {
      var reportObj = nimoReport.formatReportObj('webpush_statue', 'webpush_状态', {
        statue_: {
          default: 'ask',
          granted: 'allow',
          denied: 'forbid'
        }[Notification.permission]
      });
      nimoReport.report(reportObj);
      rememberReportPermission();
    }
  });
}