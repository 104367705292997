import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
var _excluded = ["onUpdatePenaltyInfo"];
import { useCallback, useEffect, useRef, useState } from 'react';
import auditPunishSocketModel, { EUriAuditPunishMuteNotice, EUriAuditPunishMaskNotice, getPunishTopic } from '@client/common/models/socket/auditPunishSocketModel';
import { updatePenaltyInfo } from '@desktop/src/common/models/home/room/actions';
import { MessengerSingleton } from '@client/common/services/messenger';
import { useDispatch } from 'react-redux';
var messenger = MessengerSingleton.getInstance();
auditPunishSocketModel.messenger = messenger;
export var usePenaltyInfo = function usePenaltyInfo(_ref) {
  var player = _ref.player,
    punishInfo = _ref.punishInfo,
    anchorId = _ref.anchorId,
    roomId = _ref.roomId,
    muted = _ref.muted;
  var _useState = useState(null),
    _useState2 = _slicedToArray(_useState, 2),
    penaltyInfo = _useState2[0],
    setPenaltyInfo = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    penaltyMask = _useState4[0],
    setPenaltyMask = _useState4[1];
  var _useState5 = useState(false),
    _useState6 = _slicedToArray(_useState5, 2),
    penaltyMute = _useState6[0],
    setPenaltyMute = _useState6[1];

  // 更新全局状态
  var dispatch = useDispatch();
  useEffect(function () {
    var curPenaltyInfo = {
      mask: penaltyMask,
      mute: penaltyMute
    };
    setPenaltyInfo(curPenaltyInfo);
    dispatch(updatePenaltyInfo(curPenaltyInfo));
    // console.log('======updatePenaltyInfo', curPenaltyInfo, from)
  }, [penaltyMask, penaltyMute, dispatch]);
  var playerIns = player === null || player === void 0 ? void 0 : player.player;

  // 初始化
  useEffect(function () {
    if (playerIns && punishInfo) {
      var iMaskSeconds = punishInfo.iMaskSeconds,
        iMuteSeconds = punishInfo.iMuteSeconds,
        dScale = punishInfo.dScale;
      // console.log('====初始化punishInfo', punishInfo, from)
      // 遮罩
      if (iMaskSeconds > 0) {
        handleSetMask({
          scale: dScale,
          maskSeconds: iMaskSeconds
        });
      } else {
        handleCancelMask();
      }
      // 静音
      if (iMuteSeconds > 0) {
        if (!muted) {
          handleSetMute({
            mute: 1,
            muteSeconds: iMuteSeconds
          });
        }
      } else {
        handleCancelMute();
      }
    }
  }, [playerIns, punishInfo, handleSetMask, handleSetMute, handleCancelMask, handleCancelMute, muted]);

  // 小窗模式需要额外进广播组
  useEffect(function () {
    if (roomId) {
      var punishTopic = getPunishTopic(roomId);
      messenger === null || messenger === void 0 ? void 0 : messenger.subscribeTopic([punishTopic]);
      return function () {
        messenger === null || messenger === void 0 ? void 0 : messenger.unsubscribeTopic([punishTopic]);
      };
    }
    return function () {};
  }, [roomId]);

  // 监听广播
  useEffect(function () {
    if (player) {
      // console.log('====punish 监听广播', from, anchorId)
      auditPunishSocketModel.on(EUriAuditPunishMaskNotice, handleMaskNotice);
      if (!muted) {
        auditPunishSocketModel.on(EUriAuditPunishMuteNotice, handleMuteNotice);
      }
      return function () {
        // console.log('====punish 解绑广播', from, anchorId)
        auditPunishSocketModel.off(EUriAuditPunishMaskNotice, handleMaskNotice);
        if (!muted) {
          auditPunishSocketModel.off(EUriAuditPunishMuteNotice, handleMuteNotice);
        }
      };
    }
    return function () {};
  }, [handleMaskNotice, handleMuteNotice, player, muted]);

  // 取消遮罩
  var maskTimeout = useRef(null); // 遮罩计时器

  var handleCancelMask = useCallback(function () {
    // console.log('===取消遮罩', from)
    playerIns.setBackDropFilter(false);
    maskTimeout.current = null;
    player.setState('exitPIP', false); // 取消隐藏画中画
    setPenaltyMask(false);
  }, [player, playerIns]);

  // 显示遮罩
  var handleSetMask = useCallback(function (data) {
    // console.log('===显示遮罩', from)
    var scale = data.scale,
      maskSeconds = data.maskSeconds;
    setPenaltyMask(true);
    playerIns.setBackDropFilter(true, scale, player.subContainerId);
    maskTimeout.current = setTimeout(function () {
      handleCancelMask();
    }, maskSeconds * 1000);
    player.setState('exitPIP', true); // 隐藏画中画
  }, [player, playerIns, handleCancelMask]);

  // 遮罩广播回调
  var handleMaskNotice = useCallback(function (data) {
    if (data && playerIns && (data === null || data === void 0 ? void 0 : data.anchorId) === anchorId) {
      // 清除计时器
      if (maskTimeout.current) {
        clearTimeout(maskTimeout.current);
        maskTimeout.current = null;
      }
      // 处理遮罩
      if (data.maskSeconds > 0) {
        handleSetMask(data);
      } else {
        handleCancelMask();
      }
    }
  }, [playerIns, handleCancelMask, handleSetMask, anchorId]);

  // 取消静音
  var muteTimeout = useRef(null); // 静音计时器

  var handleCancelMute = useCallback(function () {
    // console.log('===取消静音', from)
    playerIns.setMuted(false);
    muteTimeout.current = null;
    // 隐藏声音控件
    setPenaltyMute(false);
  }, [playerIns]);

  // 静音
  var handleSetMute = useCallback(function (data) {
    // console.log('===静音', from)
    var muteSeconds = data.muteSeconds;
    // 先改变全局状态，不显示声音控件。不然，声音控件里会监听静音状态，误以为是用户操作，存到localstorage。
    setPenaltyMute(true);
    playerIns.setMuted(true); // 静音
    // console.log('======setMuted true')
    muteTimeout.current = setTimeout(function () {
      handleCancelMute();
    }, muteSeconds * 1000);
  }, [playerIns, handleCancelMute]);

  // 静音广播回调
  var handleMuteNotice = useCallback(function (data) {
    if (data && playerIns && (data === null || data === void 0 ? void 0 : data.anchorId) === anchorId) {
      // 清除计时器
      if (muteTimeout.current) {
        clearTimeout(muteTimeout.current);
        muteTimeout.current = null;
      }
      // 处理静音
      if (data.mute === 1) {
        handleSetMute(data);
      } else {
        handleCancelMute();
      }
    }
  }, [playerIns, handleCancelMute, handleSetMute, anchorId]);
  return penaltyInfo;
};
export var UsePenaltyInfoCom = function UsePenaltyInfoCom(_ref2) {
  var onUpdatePenaltyInfo = _ref2.onUpdatePenaltyInfo,
    props = _objectWithoutProperties(_ref2, _excluded);
  var penaltyInfo = usePenaltyInfo(props);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  var onUpdatePenaltyInfoCb = useCallback(onUpdatePenaltyInfo, []);
  useEffect(function () {
    if (onUpdatePenaltyInfo) {
      onUpdatePenaltyInfoCb(penaltyInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [penaltyInfo, onUpdatePenaltyInfoCb]);
  return '';
};