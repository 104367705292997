import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var WS_RoomAttendeeChange = function WS_RoomAttendeeChange() {
  this.lRoomId = 0;
  this.iAttendee = 0;
  this.bIfAttendeeChange = true;
  this.iFans = 0;
  this.bIfFansChange = true;
};
WS_RoomAttendeeChange.prototype._clone = function () {
  return new Nimo.WS_RoomAttendeeChange();
};
WS_RoomAttendeeChange.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
WS_RoomAttendeeChange.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
WS_RoomAttendeeChange.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
  os.writeInt32(1, this.iAttendee);
  os.writeBoolean(2, this.bIfAttendeeChange);
  os.writeInt32(3, this.iFans);
  os.writeBoolean(4, this.bIfFansChange);
};
WS_RoomAttendeeChange.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
  this.iAttendee = is.readInt32(1, false, this.iAttendee);
  this.bIfAttendeeChange = is.readBoolean(2, false, this.bIfAttendeeChange);
  this.iFans = is.readInt32(3, false, this.iFans);
  this.bIfFansChange = is.readBoolean(4, false, this.bIfFansChange);
};
Nimo.WS_RoomAttendeeChange = WS_RoomAttendeeChange;
export default WS_RoomAttendeeChange;