import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './VisitHistoryInfo';
var VisitHistoryListView = function VisitHistoryListView() {
  this.visitHistoryInfoList = new Taf.Vector(new NimoBuss.VisitHistoryInfo());
};
VisitHistoryListView.prototype._clone = function () {
  return new NimoBuss.VisitHistoryListView();
};
VisitHistoryListView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
VisitHistoryListView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
VisitHistoryListView.prototype.writeTo = function (os) {
  os.writeVector(0, this.visitHistoryInfoList);
};
VisitHistoryListView.prototype.readFrom = function (is) {
  this.visitHistoryInfoList = is.readVector(0, false, this.visitHistoryInfoList);
};
NimoBuss.VisitHistoryListView = VisitHistoryListView;
export default VisitHistoryListView;