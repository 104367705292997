import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './MeetingThemeInfo';
var MeetingRoomInfo = function MeetingRoomInfo() {
  this.iAllMicStatus = 0;
  this.iAllSeatStatus = 0;
  this.tTheme = new Nimo.MeetingThemeInfo();
  this.iEntertainStatus = 0;
};
MeetingRoomInfo.prototype._clone = function () {
  return new Nimo.MeetingRoomInfo();
};
MeetingRoomInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MeetingRoomInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MeetingRoomInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iAllMicStatus);
  os.writeInt32(1, this.iAllSeatStatus);
  os.writeStruct(2, this.tTheme);
  os.writeInt32(3, this.iEntertainStatus);
};
MeetingRoomInfo.prototype.readFrom = function (is) {
  this.iAllMicStatus = is.readInt32(0, false, this.iAllMicStatus);
  this.iAllSeatStatus = is.readInt32(1, false, this.iAllSeatStatus);
  this.tTheme = is.readStruct(2, false, this.tTheme);
  this.iEntertainStatus = is.readInt32(3, false, this.iEntertainStatus);
};
Nimo.MeetingRoomInfo = MeetingRoomInfo;
export default MeetingRoomInfo;