import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var EDecorationAppType = {
  kDecorationAppTypeCamp: 0,
  kDecorationAppTypeRoomMgr: 10000,
  kDecorationAppTypeGlobalRich: 11000,
  kDecorationAppTypeAreaRich: 12000,
  kDecorationAppTypePKKing: 13000,
  kDecorationAppTypePKStar: 14000,
  kDecorationAppTypeFirstCharge: 15000,
  kDecorationAppTypeFans: 16000,
  kDecorationAppTypeLuckyKoiKing: 17000,
  kDecorationAppTypeLuckyKoiTop: 18000,
  kDecorationAppTypePKGuardian: 19000,
  kDecorationAppTypeSuperNewUser: 20000,
  kDecorationAppTypeSuperNewAnchor: 21000,
  kDecorationAppTypeLuckyGiftWinner: 22000,
  kDecorationAppTypePKMessageConnect: 22330,
  kDecorationAppTypeLuckyGiftGod: 23000,
  kDecorationAppTypeHalloween: 24000,
  kDecorationAppTypeAseanCup: 25000,
  kDecorationAppTypeNewYearDaily: 25001,
  kDecorationAppType201912RevenueRich: 25002,
  kDecorationAppType201912RevenueAnchor: 25003,
  kDecorationAppType201911RevenueRich: 25004,
  kDecorationAppType201911RevenueAnchor: 25005,
  kDecorationAppType202001RevenueRich: 25006,
  kDecorationAppType202002RevenueRich: 25007,
  kDecorationAppType202003RevenueRich: 25008,
  kDecorationAppType202004RevenueRich: 25009,
  kDecorationAppType202005RevenueRich: 25010,
  kDecorationAppType202006RevenueRich: 25011,
  kDecorationAppType202007RevenueRich: 25012,
  kDecorationAppType202008RevenueRich: 25013,
  kDecorationAppType202009RevenueRich: 25014,
  kDecorationAppType202010RevenueRich: 25015,
  kDecorationAppTypeSubscribeVIP: 25016,
  kDecorationAppTypeRacingCar: 25017,
  kDecorationAppTypeNobleVIP: 25018,
  kDecorationAppTypeNobleVIPWarm: 25019,
  kDecorationAppTypeYomeRich: 25020,
  kDecorationAppTypeLuckyBox: 25021,
  kDecorationAppType7DiamondBack: 25022,
  kDecorationAppType300kRecharge: 25023,
  kDecorationAppTypeFanFestival: 25024,
  kDecorationAppTypeBigR: 25025,
  kDecorationAppType202009IDFamily: 25026,
  kDecorationAppType202011RevenueRich: 25027,
  kDecorationAppType202012GameAnchor: 25028,
  kDecorationAppType202012YearNationalBR: 25029,
  kDecorationAppType202012YearNationalTH: 25030,
  kDecorationAppType202012YearNationalVN: 25031,
  kDecorationAppType202012YearNationalTR: 25032,
  kDecorationAppType202012YearNationalES: 25033,
  kDecorationAppType202012YearNationalID: 25034,
  kDecorationAppType202012YearNationalME: 25035,
  kDecorationAppType202012YearNationalIN: 25036,
  kDecorationAppType202012YearNationalOT: 25037,
  kDecorationAppTypeTRConsumeActivity: 25038,
  kDecorationAppType202012Christmas: 25039,
  kDecorationAppType202101RevenueRich: 25040,
  kDecorationAppTypePKMVP: 25041,
  kDecorationAppType202102RevenueRich: 25042,
  kDecorationAppType2021WomenDay: 25043,
  kDecorationAppTypeYachtGift: 25044,
  kDecorationAppTypeNewUserConsume: 25045,
  kDecorationAppType202103RevenueRich: 25046,
  kDecorationAppType202104BigRGiftHigh: 25047,
  kDecorationAppType202104BigRGiftLow: 25048,
  kDecorationAppTypeRichFamily: 25050,
  kDecorationAppTypeNimoOfficialStaff: 25051,
  kDecorationAppType202104Family: 25052,
  kDecorationAppType202105BigRGift: 25053,
  kDecorationAppType202105Ramadan: 25054,
  kDecorationAppType202105HungryCrazy: 25055,
  kDecorationAppType202106BigRGift: 25056,
  kDecorationAppType202106RevenueRich: 25057,
  kDecorationAppType202107BigRGift: 25058,
  kDecorationAppType202107RevenueRich: 25059,
  kDecorationAppType202108BigRGift: 25060,
  kDecorationAppTypeNimoCoinDealer: 25061,
  kDecorationAppType202108RevenueRich: 25062,
  kDecorationAppType202109BigRGift: 25063,
  kDecorationAppType202109RevenueRich: 25064,
  kDecorationAppTypeUserLevel: 25065,
  kDecorationAppTypeSVipLevel: 25066,
  kDecorationAppTypeRoomLevel: 25067,
  kDecorationAppTypeGuardian: 25073,
  kDecorationAppTypeChatBubbleGuardian: 25074,
  kDecorationAppTypeInfoCardBackgroundGuardian: 25075,
  kDecorationAppTypeChatBubbleMysteryMan: 25100,
  kDecorationAppTypeNimoWeekStar: 35065,
  kDecorationAppTypeGuildRank: 512345,
  kDecorationAppTypeMiddleEastTeam: 200000,
  kDecorationAppTypePaidPotential: 200001,
  kDecorationAppTypeNewUserFlag: 200002,
  kDecorationAppTypeNewUserFlag4VoiceRoom: 200003,
  kDecorationAppTypeHunttingUserFlag: 200004,
  kDecorationAppTypeSendPatFollowingBubble: 200005,
  kDecorationAppTypeSendPatNotFollowBubble: 200006
};
Nimo.EDecorationAppType = EDecorationAppType;
export default EDecorationAppType;