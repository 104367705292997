import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var StreamerEffect = function StreamerEffect() {
  this.iLevel = 0;
  this.iDurationMS = 0;
  this.sUrl = "";
  this.iMin = 0;
  this.iMax = 0;
  this.iTerminalType = 0;
  this.iRecordId = 0;
  this.iShowLevel = 0;
  this.iComboPattern = 0;
  this.iComboCurrent = 0;
  this.iComboPre = 0;
};
StreamerEffect.prototype._clone = function () {
  return new Nimo.StreamerEffect();
};
StreamerEffect.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
StreamerEffect.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
StreamerEffect.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iLevel);
  os.writeInt32(1, this.iDurationMS);
  os.writeString(2, this.sUrl);
  os.writeInt32(3, this.iMin);
  os.writeInt32(4, this.iMax);
  os.writeInt32(5, this.iTerminalType);
  os.writeInt32(6, this.iRecordId);
  os.writeInt32(7, this.iShowLevel);
  os.writeInt32(8, this.iComboPattern);
  os.writeInt32(9, this.iComboCurrent);
  os.writeInt32(10, this.iComboPre);
};
StreamerEffect.prototype.readFrom = function (is) {
  this.iLevel = is.readInt32(0, false, this.iLevel);
  this.iDurationMS = is.readInt32(1, false, this.iDurationMS);
  this.sUrl = is.readString(2, false, this.sUrl);
  this.iMin = is.readInt32(3, false, this.iMin);
  this.iMax = is.readInt32(4, false, this.iMax);
  this.iTerminalType = is.readInt32(5, false, this.iTerminalType);
  this.iRecordId = is.readInt32(6, false, this.iRecordId);
  this.iShowLevel = is.readInt32(7, false, this.iShowLevel);
  this.iComboPattern = is.readInt32(8, false, this.iComboPattern);
  this.iComboCurrent = is.readInt32(9, false, this.iComboCurrent);
  this.iComboPre = is.readInt32(10, false, this.iComboPre);
};
Nimo.StreamerEffect = StreamerEffect;
export default StreamerEffect;