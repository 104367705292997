import domains from '@client/common/services/domains';
import { getMid } from '@client/common/utils/fn';
import HYSDK from './getHYSDK';
import { getInstanceCache, getInstance } from '@client/common/lib/nm-socket/signalingInstance';
function getPlayerIns(options) {
  var _ref = options || {},
    _ref$deviceId = _ref.deviceId,
    deviceId = _ref$deviceId === void 0 ? getMid() : _ref$deviceId;
  var player = null;

  // eslint-disable-next-line no-undef
  if (HYSDK) {
    HYSDK.setAppId(domains.hyplayer_appid);
    var signal = getInstanceCache();
    if (signal) {
      HYSDK.setSignalSdk(signal);
    } else {
      getInstance({
        // 不确定短链接的可不可以
        noConnect: 1
      }).then(function (instance) {
        HYSDK.setSignalSdk(instance);
      });
    }
    HYSDK.setProperties({
      deviceId: deviceId
    });
    player = HYSDK.createPlayer({});
  }
  return player;
}
export default getPlayerIns;