import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './RichText';
var MarqueeNotice = function MarqueeNotice() {
  this.sPreIcon = "";
  this.tRichText = new Nimo.RichText();
};
MarqueeNotice.prototype._clone = function () {
  return new Nimo.MarqueeNotice();
};
MarqueeNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MarqueeNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MarqueeNotice.prototype.writeTo = function (os) {
  os.writeString(0, this.sPreIcon);
  os.writeStruct(1, this.tRichText);
};
MarqueeNotice.prototype.readFrom = function (is) {
  this.sPreIcon = is.readString(0, false, this.sPreIcon);
  this.tRichText = is.readStruct(1, false, this.tRichText);
};
Nimo.MarqueeNotice = MarqueeNotice;
export default MarqueeNotice;