module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="n-as-w100" style="min-width: 1060px;">\n  ' +
((__t = ( require('ejs-loader!./components/Banner/skeleton.ejs')() )) == null ? '' : __t) +
'\n</div>\n';

}
return __p
}