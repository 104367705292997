import "core-js/modules/es6.array.sort.js";
import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var TeamRoomView = function TeamRoomView() {
  this.roomId = 0;
  this.title = "";
  this.alise = "";
  this.liveStreamStatus = 0;
  this.sort = 0;
};
TeamRoomView.prototype._clone = function () {
  return new NimoBuss.TeamRoomView();
};
TeamRoomView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
TeamRoomView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
TeamRoomView.prototype.writeTo = function (os) {
  os.writeInt64(0, this.roomId);
  os.writeString(1, this.title);
  os.writeString(2, this.alise);
  os.writeInt32(3, this.liveStreamStatus);
  os.writeInt32(4, this.sort);
};
TeamRoomView.prototype.readFrom = function (is) {
  this.roomId = is.readInt64(0, false, this.roomId);
  this.title = is.readString(1, false, this.title);
  this.alise = is.readString(2, false, this.alise);
  this.liveStreamStatus = is.readInt32(3, false, this.liveStreamStatus);
  this.sort = is.readInt32(4, false, this.sort);
};
NimoBuss.TeamRoomView = TeamRoomView;
export default TeamRoomView;