import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './EventRoomView';
import './RoomScreenshotsView';
import './TeamRoomView';
import './StarLiveRoomView';
import './CoverLabelView';
import './AnchorLabelView';
import Nimo from '../nimo';
import '../Nimo/RoomLineInfo';
import './GameAbbr';
import './InterceptReasonView';
import './ActivityLabelView';
import '../Nimo/UserTitleData';
import './PkLiveRoomView';
import './NormalMultiRoomView';
import '../Nimo/MeetingInfo';
import './PkSummaryView';
import './EntertainmentLable';
import './VoiceEntertainmentLabelLevel2';
import './TeamPKSummaryView';
var LiveRoomView = function LiveRoomView() {
  this.DEFAULT_ROOM_NAME = "xxx's Live Channel";
  this.DEFAULT_ROOM_GAME = "unknow";
  this.DEFAULT_LIVE_ANNOUNCEMENT = "Hi! New streamer here. Hope you enjoy my livestreams and feel free to tap that follow button!";
  this.id = 0;
  this.roomTheme = "";
  this.alise = "";
  this.roomType = 0;
  this.roomTypeName = "";
  this.roomSort = 0;
  this.businessType = 0;
  this.anchorName = "";
  this.anchorAvatarUrl = "";
  this.anchorAnnouncement = "";
  this.anchorCountryCode = "";
  this.eventId = 0;
  this.eventRoomListView = new Taf.Vector(new NimoBuss.EventRoomView());
  this.headImg = "";
  this.backgroundImg = "";
  this.anchorId = 0;
  this.userId = 0;
  this.viewerNum = 0;
  this.fanCount = 0;
  this.isFollow = true;
  this.roomScreenshots = new Taf.Vector(new NimoBuss.RoomScreenshotsView());
  this.roomNumbering = "";
  this.onlineStatus = 0;
  this.liveStreamStatus = 0;
  this.lcid = 0;
  this.lcidText = "";
  this.createdTime = 0;
  this.updatedTime = 0;
  this.teamRoomViews = new Taf.Vector(new NimoBuss.TeamRoomView());
  this.teamId = 0;
  this.isLottery = 0;
  this.microPKStatus = 0;
  this.starLiveRoomView = new NimoBuss.StarLiveRoomView();
  this.endLiveTime = 0;
  this.showScreenshots = new Taf.Vector(new NimoBuss.RoomScreenshotsView());
  this.aiRecommend = "";
  this.coverLabels = new Taf.Vector(new NimoBuss.CoverLabelView());
  this.anchorLabels = new Taf.Vector(new NimoBuss.AnchorLabelView());
  this.isGuessing = 0;
  this.roomLineInfo = new Nimo.RoomLineInfo();
  this.avatarBoxUrl = "";
  this.anchorScreenshots = new Taf.Vector(new NimoBuss.RoomScreenshotsView());
  this.mStreamPkg = "";
  this.superscriptType = 0;
  this.superscriptText = "";
  this.gameAbbrList = new Taf.Vector(new NimoBuss.GameAbbr());
  this.viewerNumRef = 0;
  this.fps = 0;
  this.bitRate = 0;
  this.sharpness = 0;
  this.viewerScore = 0;
  this.platform = "";
  this.version = "";
  this.templateType = 0;
  this.liveId = 0;
  this.interceptReasonView = new NimoBuss.InterceptReasonView();
  this.voiceScreenshots = new Taf.Vector(new NimoBuss.RoomScreenshotsView());
  this.activityLabelView = new NimoBuss.ActivityLabelView();
  this.isPlayback = 0;
  this.dynamicAvatarBoxUrl = "";
  this.startLiveTime = 0;
  this.userTitleData = new Nimo.UserTitleData();
  this.highLightStatus = 0;
  this.pkLiveRoomView = new NimoBuss.PkLiveRoomView();
  this.normalMultiRoomList = new Taf.Vector(new NimoBuss.NormalMultiRoomView());
  this.strategy = "";
  this.source = "";
  this.subRoomSort = 0;
  this.nextLiveNoticeTimestamp = 0;
  this.nextLiveNoticeEndTime = 0;
  this.nextLiveNoticeDesc = "";
  this.authenticatedAnchorV2 = 0;
  this.meetingInfo = new Nimo.MeetingInfo();
  this.iScene = 0;
  this.recommendReasonId = 0;
  this.loc = 0;
  this.recommendedReason = "";
  this.recommendNameCode = 0;
  this.actionType = 0;
  this.isLiveHouse = 0;
  this.liveType = 0;
  this.voiceLabel = "";
  this.blockCurrentUser = 0;
  this.pkSummaryView = new NimoBuss.PkSummaryView();
  this.entertainmentLabelList = new Taf.Vector(new NimoBuss.EntertainmentLable());
  this.dataAnalysisActivityLabelViews = new Taf.Vector(new NimoBuss.ActivityLabelView());
  this.anchorLocationAnnounce = "";
  this.anchorAgeAnnounce = "";
  this.anchorSex = 0;
  this.bio = "";
  this.hasNewPost = 0;
  this.voiceLabelList = new Taf.Vector(new NimoBuss.VoiceEntertainmentLabelLevel2());
  this.guildId = 0;
  this.teamPKSummaryView = new NimoBuss.TeamPKSummaryView();
  this.autoPlayWhiteListStatus = 0;
};
LiveRoomView.prototype._clone = function () {
  return new NimoBuss.LiveRoomView();
};
LiveRoomView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LiveRoomView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LiveRoomView.prototype.writeTo = function (os) {
  os.writeString(0, this.DEFAULT_ROOM_NAME);
  os.writeString(1, this.DEFAULT_ROOM_GAME);
  os.writeString(2, this.DEFAULT_LIVE_ANNOUNCEMENT);
  os.writeInt64(3, this.id);
  os.writeString(4, this.roomTheme);
  os.writeString(5, this.alise);
  os.writeInt64(6, this.roomType);
  os.writeString(7, this.roomTypeName);
  os.writeInt32(8, this.roomSort);
  os.writeInt32(9, this.businessType);
  os.writeString(10, this.anchorName);
  os.writeString(11, this.anchorAvatarUrl);
  os.writeString(12, this.anchorAnnouncement);
  os.writeString(13, this.anchorCountryCode);
  os.writeInt64(14, this.eventId);
  os.writeVector(15, this.eventRoomListView);
  os.writeString(16, this.headImg);
  os.writeString(17, this.backgroundImg);
  os.writeInt64(18, this.anchorId);
  os.writeInt64(19, this.userId);
  os.writeInt64(20, this.viewerNum);
  os.writeInt64(21, this.fanCount);
  os.writeBoolean(22, this.isFollow);
  os.writeVector(23, this.roomScreenshots);
  os.writeString(24, this.roomNumbering);
  os.writeInt32(25, this.onlineStatus);
  os.writeInt32(26, this.liveStreamStatus);
  os.writeInt32(27, this.lcid);
  os.writeString(28, this.lcidText);
  os.writeInt64(29, this.createdTime);
  os.writeInt64(30, this.updatedTime);
  os.writeVector(31, this.teamRoomViews);
  os.writeInt32(32, this.teamId);
  os.writeInt32(33, this.isLottery);
  os.writeInt32(34, this.microPKStatus);
  os.writeStruct(35, this.starLiveRoomView);
  os.writeInt64(36, this.endLiveTime);
  os.writeVector(37, this.showScreenshots);
  os.writeString(38, this.aiRecommend);
  os.writeVector(39, this.coverLabels);
  os.writeVector(40, this.anchorLabels);
  os.writeInt32(41, this.isGuessing);
  os.writeStruct(42, this.roomLineInfo);
  os.writeString(43, this.avatarBoxUrl);
  os.writeVector(44, this.anchorScreenshots);
  os.writeString(45, this.mStreamPkg);
  os.writeInt32(46, this.superscriptType);
  os.writeString(47, this.superscriptText);
  os.writeVector(48, this.gameAbbrList);
  os.writeInt64(49, this.viewerNumRef);
  os.writeInt32(50, this.fps);
  os.writeInt64(51, this.bitRate);
  os.writeInt32(52, this.sharpness);
  os.writeInt64(53, this.viewerScore);
  os.writeString(54, this.platform);
  os.writeString(55, this.version);
  os.writeInt32(56, this.templateType);
  os.writeInt64(57, this.liveId);
  os.writeStruct(59, this.interceptReasonView);
  os.writeVector(60, this.voiceScreenshots);
  os.writeStruct(61, this.activityLabelView);
  os.writeInt32(62, this.isPlayback);
  os.writeString(63, this.dynamicAvatarBoxUrl);
  os.writeInt32(64, this.startLiveTime);
  os.writeStruct(65, this.userTitleData);
  os.writeInt32(66, this.highLightStatus);
  os.writeStruct(67, this.pkLiveRoomView);
  os.writeVector(68, this.normalMultiRoomList);
  os.writeString(69, this.strategy);
  os.writeString(70, this.source);
  os.writeInt32(71, this.subRoomSort);
  os.writeInt64(72, this.nextLiveNoticeTimestamp);
  os.writeInt64(73, this.nextLiveNoticeEndTime);
  os.writeString(74, this.nextLiveNoticeDesc);
  os.writeInt32(77, this.authenticatedAnchorV2);
  os.writeStruct(78, this.meetingInfo);
  os.writeInt32(79, this.iScene);
  os.writeInt32(80, this.recommendReasonId);
  os.writeInt32(81, this.loc);
  os.writeString(82, this.recommendedReason);
  os.writeInt32(83, this.recommendNameCode);
  os.writeInt32(84, this.actionType);
  os.writeInt32(85, this.isLiveHouse);
  os.writeInt32(86, this.liveType);
  os.writeString(87, this.voiceLabel);
  os.writeInt32(88, this.blockCurrentUser);
  os.writeStruct(89, this.pkSummaryView);
  os.writeVector(90, this.entertainmentLabelList);
  os.writeVector(91, this.dataAnalysisActivityLabelViews);
  os.writeString(92, this.anchorLocationAnnounce);
  os.writeString(93, this.anchorAgeAnnounce);
  os.writeInt32(94, this.anchorSex);
  os.writeString(95, this.bio);
  os.writeInt32(96, this.hasNewPost);
  os.writeVector(97, this.voiceLabelList);
  os.writeInt64(98, this.guildId);
  os.writeStruct(100, this.teamPKSummaryView);
  os.writeInt32(101, this.autoPlayWhiteListStatus);
};
LiveRoomView.prototype.readFrom = function (is) {
  this.DEFAULT_ROOM_NAME = is.readString(0, false, this.DEFAULT_ROOM_NAME);
  this.DEFAULT_ROOM_GAME = is.readString(1, false, this.DEFAULT_ROOM_GAME);
  this.DEFAULT_LIVE_ANNOUNCEMENT = is.readString(2, false, this.DEFAULT_LIVE_ANNOUNCEMENT);
  this.id = is.readInt64(3, false, this.id);
  this.roomTheme = is.readString(4, false, this.roomTheme);
  this.alise = is.readString(5, false, this.alise);
  this.roomType = is.readInt64(6, false, this.roomType);
  this.roomTypeName = is.readString(7, false, this.roomTypeName);
  this.roomSort = is.readInt32(8, false, this.roomSort);
  this.businessType = is.readInt32(9, false, this.businessType);
  this.anchorName = is.readString(10, false, this.anchorName);
  this.anchorAvatarUrl = is.readString(11, false, this.anchorAvatarUrl);
  this.anchorAnnouncement = is.readString(12, false, this.anchorAnnouncement);
  this.anchorCountryCode = is.readString(13, false, this.anchorCountryCode);
  this.eventId = is.readInt64(14, false, this.eventId);
  this.eventRoomListView = is.readVector(15, false, this.eventRoomListView);
  this.headImg = is.readString(16, false, this.headImg);
  this.backgroundImg = is.readString(17, false, this.backgroundImg);
  this.anchorId = is.readInt64(18, false, this.anchorId);
  this.userId = is.readInt64(19, false, this.userId);
  this.viewerNum = is.readInt64(20, false, this.viewerNum);
  this.fanCount = is.readInt64(21, false, this.fanCount);
  this.isFollow = is.readBoolean(22, false, this.isFollow);
  this.roomScreenshots = is.readVector(23, false, this.roomScreenshots);
  this.roomNumbering = is.readString(24, false, this.roomNumbering);
  this.onlineStatus = is.readInt32(25, false, this.onlineStatus);
  this.liveStreamStatus = is.readInt32(26, false, this.liveStreamStatus);
  this.lcid = is.readInt32(27, false, this.lcid);
  this.lcidText = is.readString(28, false, this.lcidText);
  this.createdTime = is.readInt64(29, false, this.createdTime);
  this.updatedTime = is.readInt64(30, false, this.updatedTime);
  this.teamRoomViews = is.readVector(31, false, this.teamRoomViews);
  this.teamId = is.readInt32(32, false, this.teamId);
  this.isLottery = is.readInt32(33, false, this.isLottery);
  this.microPKStatus = is.readInt32(34, false, this.microPKStatus);
  this.starLiveRoomView = is.readStruct(35, false, this.starLiveRoomView);
  this.endLiveTime = is.readInt64(36, false, this.endLiveTime);
  this.showScreenshots = is.readVector(37, false, this.showScreenshots);
  this.aiRecommend = is.readString(38, false, this.aiRecommend);
  this.coverLabels = is.readVector(39, false, this.coverLabels);
  this.anchorLabels = is.readVector(40, false, this.anchorLabels);
  this.isGuessing = is.readInt32(41, false, this.isGuessing);
  this.roomLineInfo = is.readStruct(42, false, this.roomLineInfo);
  this.avatarBoxUrl = is.readString(43, false, this.avatarBoxUrl);
  this.anchorScreenshots = is.readVector(44, false, this.anchorScreenshots);
  this.mStreamPkg = is.readString(45, false, this.mStreamPkg);
  this.superscriptType = is.readInt32(46, false, this.superscriptType);
  this.superscriptText = is.readString(47, false, this.superscriptText);
  this.gameAbbrList = is.readVector(48, false, this.gameAbbrList);
  this.viewerNumRef = is.readInt64(49, false, this.viewerNumRef);
  this.fps = is.readInt32(50, false, this.fps);
  this.bitRate = is.readInt64(51, false, this.bitRate);
  this.sharpness = is.readInt32(52, false, this.sharpness);
  this.viewerScore = is.readInt64(53, false, this.viewerScore);
  this.platform = is.readString(54, false, this.platform);
  this.version = is.readString(55, false, this.version);
  this.templateType = is.readInt32(56, false, this.templateType);
  this.liveId = is.readInt64(57, false, this.liveId);
  this.interceptReasonView = is.readStruct(59, false, this.interceptReasonView);
  this.voiceScreenshots = is.readVector(60, false, this.voiceScreenshots);
  this.activityLabelView = is.readStruct(61, false, this.activityLabelView);
  this.isPlayback = is.readInt32(62, false, this.isPlayback);
  this.dynamicAvatarBoxUrl = is.readString(63, false, this.dynamicAvatarBoxUrl);
  this.startLiveTime = is.readInt32(64, false, this.startLiveTime);
  this.userTitleData = is.readStruct(65, false, this.userTitleData);
  this.highLightStatus = is.readInt32(66, false, this.highLightStatus);
  this.pkLiveRoomView = is.readStruct(67, false, this.pkLiveRoomView);
  this.normalMultiRoomList = is.readVector(68, false, this.normalMultiRoomList);
  this.strategy = is.readString(69, false, this.strategy);
  this.source = is.readString(70, false, this.source);
  this.subRoomSort = is.readInt32(71, false, this.subRoomSort);
  this.nextLiveNoticeTimestamp = is.readInt64(72, false, this.nextLiveNoticeTimestamp);
  this.nextLiveNoticeEndTime = is.readInt64(73, false, this.nextLiveNoticeEndTime);
  this.nextLiveNoticeDesc = is.readString(74, false, this.nextLiveNoticeDesc);
  this.authenticatedAnchorV2 = is.readInt32(77, false, this.authenticatedAnchorV2);
  this.meetingInfo = is.readStruct(78, false, this.meetingInfo);
  this.iScene = is.readInt32(79, false, this.iScene);
  this.recommendReasonId = is.readInt32(80, false, this.recommendReasonId);
  this.loc = is.readInt32(81, false, this.loc);
  this.recommendedReason = is.readString(82, false, this.recommendedReason);
  this.recommendNameCode = is.readInt32(83, false, this.recommendNameCode);
  this.actionType = is.readInt32(84, false, this.actionType);
  this.isLiveHouse = is.readInt32(85, false, this.isLiveHouse);
  this.liveType = is.readInt32(86, false, this.liveType);
  this.voiceLabel = is.readString(87, false, this.voiceLabel);
  this.blockCurrentUser = is.readInt32(88, false, this.blockCurrentUser);
  this.pkSummaryView = is.readStruct(89, false, this.pkSummaryView);
  this.entertainmentLabelList = is.readVector(90, false, this.entertainmentLabelList);
  this.dataAnalysisActivityLabelViews = is.readVector(91, false, this.dataAnalysisActivityLabelViews);
  this.anchorLocationAnnounce = is.readString(92, false, this.anchorLocationAnnounce);
  this.anchorAgeAnnounce = is.readString(93, false, this.anchorAgeAnnounce);
  this.anchorSex = is.readInt32(94, false, this.anchorSex);
  this.bio = is.readString(95, false, this.bio);
  this.hasNewPost = is.readInt32(96, false, this.hasNewPost);
  this.voiceLabelList = is.readVector(97, false, this.voiceLabelList);
  this.guildId = is.readInt64(98, false, this.guildId);
  this.teamPKSummaryView = is.readStruct(100, false, this.teamPKSummaryView);
  this.autoPlayWhiteListStatus = is.readInt32(101, false, this.autoPlayWhiteListStatus);
};
NimoBuss.LiveRoomView = LiveRoomView;
export default LiveRoomView;