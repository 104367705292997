import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './PayChatOption';
import './PayChatRichTextItem';
import './UidNickName';
var PayChatRichTextNotice = function PayChatRichTextNotice() {
  this.lAnchorId = 0;
  this.lUid = 0;
  this.sNickName = "";
  this.sAvatarUrl = "";
  this.tOption = new Nimo.PayChatOption();
  this.iShowType = 0;
  this.lSendTime = 0;
  this.tRichTextItem = new Nimo.PayChatRichTextItem();
  this.mExtraData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.vAtSomeone = new Taf.Vector(new Nimo.UidNickName());
  this.iRuleType = 0;
  this.iSource = 0;
};
PayChatRichTextNotice.prototype._clone = function () {
  return new Nimo.PayChatRichTextNotice();
};
PayChatRichTextNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
PayChatRichTextNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
PayChatRichTextNotice.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lAnchorId);
  os.writeInt64(1, this.lUid);
  os.writeString(2, this.sNickName);
  os.writeString(3, this.sAvatarUrl);
  os.writeStruct(5, this.tOption);
  os.writeInt32(6, this.iShowType);
  os.writeInt64(7, this.lSendTime);
  os.writeStruct(8, this.tRichTextItem);
  os.writeMap(9, this.mExtraData);
  os.writeVector(10, this.vAtSomeone);
  os.writeInt32(11, this.iRuleType);
  os.writeInt32(12, this.iSource);
};
PayChatRichTextNotice.prototype.readFrom = function (is) {
  this.lAnchorId = is.readInt64(0, false, this.lAnchorId);
  this.lUid = is.readInt64(1, false, this.lUid);
  this.sNickName = is.readString(2, false, this.sNickName);
  this.sAvatarUrl = is.readString(3, false, this.sAvatarUrl);
  this.tOption = is.readStruct(5, false, this.tOption);
  this.iShowType = is.readInt32(6, false, this.iShowType);
  this.lSendTime = is.readInt64(7, false, this.lSendTime);
  this.tRichTextItem = is.readStruct(8, false, this.tRichTextItem);
  this.mExtraData = is.readMap(9, false, this.mExtraData);
  this.vAtSomeone = is.readVector(10, false, this.vAtSomeone);
  this.iRuleType = is.readInt32(11, false, this.iRuleType);
  this.iSource = is.readInt32(12, false, this.iSource);
};
Nimo.PayChatRichTextNotice = PayChatRichTextNotice;
export default PayChatRichTextNotice;