import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
/* eslint-disable prefer-destructuring */
import { cookie, session } from '../utils/storage';
import { getLang, parseURL, roomEnterURL } from '@client/common/utils/fn';
// import removeUrlParams from '@client/common/utils/removeUrlParams.js';
import nimoReport from '@client/common/services/report';
import addUrlParams from '@client/common/utils/addUrlParams';
import ua from '@client/common/utils/ua';
var commonShareBaseUrl = 'https://nimotv.onelink.me/B6cW';
var ROOM_DOMAIN = process.env.platform === 'desktop' ? DOMAINS.maindomain : DOMAINS.wapdomain;

/**
 * _from或af_sub1参数含有此值时，代表来自分享
 * ejs代码需同步修改，文件目录：frontend/common/layouts/setAdType/content.js
 */
export var FROM_COMMON_SHARE_MAP = {
  PICK_ME: 'pickme',
  CLIP: 'clip',
  // 视频剪辑的分享
  OTHERS: 'others',
  SELF: 'selfpromotion',
  // 主播在主播端分享自己 web端Dashboard还有移动端入口
  ROOM: 'default',
  // 观众在直播间分享
  LIVESHOW_ROOM: 'liveshow_default',
  // 观众在秀场直播间分享
  h5activity: 'h5activity',
  // (用于文章系统生成的各种活动),
  promotioncampaign: 'promotioncampaign',
  // (用于运营要求的社媒合作分享)
  referal: 'referal',
  // 用于用户拉新获得奖励的功能设计
  competition: 'competition',
  // 赛事分享抽奖
  autoshare: 'autoshare',
  // 自动分享
  restream: 'restream',
  // 转推自动分享
  referal_fans_202012: 'referal_fans_202012',
  // 2020年12月，年度粉丝节活动
  sharecombo: 'sharecombo',
  // 直播间公屏分享,
  OTHER: 'other',
  editstreaminfo: 'editstreaminfo',
  // 主播在仪表盘修改直播间信息成功以后，弹出的手动分享引导弹出
  CLIP_CREATE: 'createclip',
  // 剪辑创建
  ESPORT: 'esport',
  // 赛事直播间 wap 导流
  SCHEDULE_POSTER: 'schedule_poster',
  // 直播预告编辑页面海报分享
  REPLAY: 'replay',
  // 视频回放分享
  referal_superstar: 'referal_superstar',
  // 粉丝节活动
  FANTASK: 'fantask',
  // 粉丝日常任务
  INVITATION_NEWUSERS: 'invitation_newusers',
  MATCH_SHARE_BOOST: 'match_share_boost',
  // 赛事助力
  OPERATION_VIDEO: 'operation_video',
  // 运营上传/运营打点/运营实时剪辑
  USER_UPLOAD_VIDEO: 'user_upload_video',
  // 用户上传
  OTHER_VIDEO: 'other_video',
  // 授权上传/广告视频
  CRAWLER_VIDEO: 'crawler_video',
  // 有趣视频
  OPS_ARTICLE: 'ops_article',
  // 右上角分享
  LIVESHOW_HIGHLIGHT: 'liveshow_highlight_poster',
  // 端外打开视频结算页海报/高光海报
  post_button: 'post_button'
};
var DOC_TYPE_MAP = {
  1: FROM_COMMON_SHARE_MAP.REPLAY,
  // 直播录像
  2: FROM_COMMON_SHARE_MAP.OPERATION_VIDEO,
  // 运营打点
  3: FROM_COMMON_SHARE_MAP.CLIP,
  // 主播剪辑
  4: FROM_COMMON_SHARE_MAP.CLIP,
  // 用户剪辑
  5: FROM_COMMON_SHARE_MAP.OPERATION_VIDEO,
  // 运营上传-游戏专区
  6: FROM_COMMON_SHARE_MAP.USER_UPLOAD_VIDEO,
  // 用户上传
  7: FROM_COMMON_SHARE_MAP.OTHER_VIDEO,
  // 授权上传
  8: FROM_COMMON_SHARE_MAP.OPERATION_VIDEO,
  // 运营实时剪辑
  10: FROM_COMMON_SHARE_MAP.OTHER_VIDEO,
  // 广告视频
  11: FROM_COMMON_SHARE_MAP.CRAWLER_VIDEO,
  // 有趣视频
  12: FROM_COMMON_SHARE_MAP.OPERATION_VIDEO // 运营后台上传视频
};

/**
 * 通过doctype值获取分享from值
 * @param {number} docType
 * @returns
 */
export var getShareFromByDocType = function getShareFromByDocType(docType) {
  return DOC_TYPE_MAP[docType] || FROM_COMMON_SHARE_MAP.OTHERS;
};

// appflyer sub3 是活动id的来源
export var FROM_ACT = [FROM_COMMON_SHARE_MAP.h5activity, FROM_COMMON_SHARE_MAP.promotioncampaign];
export var getShareUid = function getShareUid() {
  return cookie.get('userid') || '0';
};
export var postProcessShareUrl = function postProcessShareUrl(_ref) {
  var _parseURL;
  var roomId = _ref.roomId,
    url = _ref.url,
    platformId = _ref.platformId,
    _ref$from = _ref.from,
    from = _ref$from === void 0 ? FROM_COMMON_SHARE_MAP.OTHERS : _ref$from,
    userId = _ref.userId,
    adset = _ref.adset;
  var params = ((_parseURL = parseURL(url)) === null || _parseURL === void 0 ? void 0 : _parseURL.params) || {};
  if (!(params !== null && params !== void 0 && params._from)) {
    params._from = from;
  }
  if (!(params !== null && params !== void 0 && params._channel)) {
    params._channel = roomId || '0';
  }
  if (!(params !== null && params !== void 0 && params._shareuid)) {
    params._shareuid = userId || getShareUid();
  }
  if (!(params !== null && params !== void 0 && params._source) && platformId !== undefined) {
    params._source = platformId;
  }
  if (!(params !== null && params !== void 0 && params._adset) && !!adset) {
    params._adset = adset;
  }
  return addUrlParams(url, params);
};
export function isFromCommonShare(from) {
  return Object.values(FROM_COMMON_SHARE_MAP).indexOf(from) !== -1;
}
export var isFromCommonShareNow = function isFromCommonShareNow() {
  var af_sub1 = parseURL().params.af_sub1;
  var from = parseURL().params._from;
  return isFromCommonShare(af_sub1 || from);
};
export var getCommonShareParams = function getCommonShareParams() {
  var raw = session.get('nm-common-share');
  var params;
  if (!raw) return null;
  try {
    params = JSON.parse(raw);
  } catch (_) {
    //
  }
  if (!params) return null;
  var _params = params,
    af_sub1 = _params.af_sub1,
    af_sub2 = _params.af_sub2,
    af_sub3 = _params.af_sub3,
    af_sub4 = _params.af_sub4,
    _from = _params._from;
  if (!isFromCommonShare(af_sub1 || _from)) {
    return null;
  }
  var _params2 = params,
    _channel = _params2._channel,
    _shareuid = _params2._shareuid,
    _source = _params2._source,
    _activityid = _params2._activityid;
  _channel = Number(af_sub2 || _channel) || 0; // 房间ID
  _shareuid = Number(af_sub3 || _shareuid) || 0; // 分享者userId
  _source = Number(af_sub4 || _source) || -1; // 社媒渠道 https://www.tapd.cn/47540949/markdown_wikis/show/#1147540949001005547
  _activityid = String(af_sub3 || _activityid || 0);
  return {
    _from: _from,
    _channel: _channel,
    _shareuid: _shareuid,
    _source: _source,
    _activityid: _activityid
  };
};

// 清除分享相关url参数，session
export var clearCommonShareParams = function clearCommonShareParams() {
  session.remove('nm-common-share');

  // if (isFromCommonShareNow()) {
  //   try {
  //     window.history.replaceState(
  //       '',
  //       '',
  //       removeUrlParams(['_from', '_channel', '_shareuid', '_source'])
  //     );
  //   } catch (e) {
  //     //
  //   }
  // }
};

export var reportLoginFromShare = function reportLoginFromShare(_ref2) {
  var isRegister = _ref2.isRegister,
    isLogined = _ref2.isLogined,
    _from = _ref2._from,
    _channel = _ref2._channel,
    _shareuid = _ref2._shareuid,
    _source = _ref2._source,
    _activityid = _ref2._activityid;
  nimoReport.formatAndSend('usr/click/login/from_share', '通过分享链接进入并完成登录', {
    shareuser_: _shareuid,
    alreadylogin_: isLogined ? 1 : 0,
    from_: _from,
    roomid_: _channel,
    newuser_: isRegister ? 1 : 0,
    platform_: _source,
    activityid_: _activityid
  });
};
export var reportRegisterFromShare = function reportRegisterFromShare(_ref3) {
  var _from = _ref3._from,
    _channel = _ref3._channel,
    _shareuid = _ref3._shareuid,
    _source = _ref3._source,
    _activityid = _ref3._activityid;
  nimoReport.formatAndSend('usr/click/register/from_share', '通过分享链接中进入Nimo且完成注册的Nimo新用户', {
    shareuser_: _shareuid,
    from_: _from,
    roomid_: _channel,
    platform_: _source,
    activityid_: _activityid
  });
};
var reported = false;
export var reportAlreadyLoginFromShare = function reportAlreadyLoginFromShare() {
  if (reported) return;
  // 避免经过跳转重复的上报，比如登录/注册
  var refererUrl = document.referrer && parseURL(document.referrer);
  if (refererUrl && refererUrl.host === (process.env.platform === 'desktop' ? DOMAINS.maindomain : DOMAINS.wapdomain)) {
    return;
  }
  var params = getCommonShareParams();
  if (!params) return;
  reported = true;
  clearCommonShareParams();
  reportLoginFromShare(_objectSpread({
    isLogined: true,
    isRegister: false
  }, params));
};
export var reportLoginSuccessFromShare = function reportLoginSuccessFromShare() {
  var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref4$isRegister = _ref4.isRegister,
    isRegister = _ref4$isRegister === void 0 ? false : _ref4$isRegister;
  var params = getCommonShareParams();
  if (params) {
    clearCommonShareParams();
    reportLoginFromShare(_objectSpread({
      isRegister: isRegister,
      isLogined: false
    }, params));
    if (isRegister) {
      reportRegisterFromShare(params);
    }
  }
};

/**
 *
 * * @param alias
 * * @param roomId
 * * @param anchorId
 * * @param from 对应FROM_COMMON_SHARE_MAP里面的值
 * * @param shareuid
 * * @param source 分享渠道id：Twitter、Facebook等对应的id
 * * @param fallbackUrl 若使用电脑打开onelink，或者使用手机打开onelink时没安装Nimo APP，则会跳转到此fallbackUrl
 * * @param deepLink 使用手机打开onelink并跳转到Nimo APP时，则会在Nimo APP内调起此deepLink
 * * @param adset 细分功能来源
 * * @param fallback 如果没有安装 app，设置为 false，则调起应用商店，默认值为1，三个端都跳转 fallbackUrl；值为2则只在web端跳转fallbackUrl，移动端跳转应用商店
 * * @returns {string}
 */
export var getCommonShareUrl = function getCommonShareUrl(_ref5) {
  var alias = _ref5.alias,
    roomId = _ref5.roomId,
    anchorId = _ref5.anchorId,
    _ref5$from = _ref5.from,
    from = _ref5$from === void 0 ? FROM_COMMON_SHARE_MAP.OTHERS : _ref5$from,
    shareuid = _ref5.shareuid,
    source = _ref5.source,
    fallbackUrl = _ref5.fallbackUrl,
    deepLink = _ref5.deepLink,
    adset = _ref5.adset,
    _ref5$fallback = _ref5.fallback,
    fallback = _ref5$fallback === void 0 ? 1 : _ref5$fallback,
    mobileFallback = _ref5.mobileFallback,
    type = _ref5.type,
    _ref5$forceDeeplink = _ref5.forceDeeplink,
    forceDeeplink = _ref5$forceDeeplink === void 0 ? true : _ref5$forceDeeplink,
    _ref5$webDpParams = _ref5.webDpParams,
    webDpParams = _ref5$webDpParams === void 0 ? {} : _ref5$webDpParams,
    pid = _ref5.pid,
    af_sub2 = _ref5.af_sub2,
    _ref5$af_sub = _ref5.af_sub5,
    af_sub5 = _ref5$af_sub === void 0 ? '' : _ref5$af_sub;
  var encodedDeepLink = '';
  if (deepLink) {
    encodedDeepLink = deepLink;
  } else {
    encodedDeepLink = "nimotv://huya.nimo.com/living_room?anchor_id=".concat(anchorId, "&room_id=").concat(roomId);
    if (from) {
      encodedDeepLink += "&from=".concat(from);
    }
  }
  encodedDeepLink = encodeURIComponent(encodedDeepLink);
  var commonShareParams = {
    pid: pid || 'nimo_wap',
    c: type || 'common_share',
    af_dp: encodedDeepLink,
    af_adset: adset || from,
    af_sub1: from,
    af_sub2: af_sub2 || roomId || '0',
    af_sub3: shareuid || '0',
    is_retargeting: 'true',
    af_sub5: af_sub5
  };
  if (forceDeeplink) {
    commonShareParams.af_force_deeplink = true;
  }
  if (fallback) {
    var _fallbackUrl = '';
    if (fallbackUrl) {
      _fallbackUrl = fallbackUrl;
    } else {
      _fallbackUrl = "".concat(location.protocol, "//").concat(ROOM_DOMAIN).concat(roomEnterURL({
        alias: alias,
        roomId: roomId
      }));
    }
    var _encodeFallbackUrl = encodeURIComponent(_fallbackUrl);
    if (fallback !== 2) {
      commonShareParams.af_ios_url = _encodeFallbackUrl;
      commonShareParams.af_android_url = _encodeFallbackUrl;
    }
    commonShareParams.af_web_dp = encodeURIComponent(addUrlParams(_fallbackUrl, _objectSpread({
      _lang: getLang()
    }, webDpParams))); // 社媒爬虫会根据这个链接去爬取预览内容，加上_lang使得内容展示为当前用户语言
  }

  if (mobileFallback) {
    commonShareParams.af_ios_url = encodeURIComponent(mobileFallback);
    commonShareParams.af_android_url = encodeURIComponent(mobileFallback);
  }

  // 不是在APP内才带上分享渠道，因为在APP内会由APP端拼接af_sub4参数
  if (!ua.isInNimoApp && source !== undefined) {
    var _source = source || '-1';
    commonShareParams.af_sub4 = _source;
    commonShareParams.af_ad = _source;
  }
  return addUrlParams(commonShareBaseUrl, commonShareParams);
};
export var addPlatformId = function addPlatformId(_ref6) {
  var link = _ref6.link,
    platformId = _ref6.platformId;
  return link && addUrlParams(link, {
    af_sub4: platformId,
    af_ad: platformId
  });
};
export var getVideoPageDp = function getVideoPageDp() {
  var _ref7 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    resourceId = _ref7.resourceId;
  if (!resourceId) {
    return '';
  }
  return "nimotv://huya.nimo.com/video_page?resourceId=".concat(resourceId);
};

/**
 * 用于生成社媒话题，要求单个话题之间不能有空格。
 * 比如：#NimoTV #elgato #freefire
 * @param words
 * @returns {string}
 */
export var getShareTopic = function getShareTopic(words) {
  if (typeof words !== 'string') {
    return '';
  }
  var result = words.replace(/\s+/g, '');
  if (result) {
    result = "#".concat(result, " ");
  }
  return result;
};

/**
文档地址：https://www.tapd.cn/47540949/markdown_wikis/show/#1147540949001005547
share-id 分享渠道枚举值:
1 WhatsApp
2 Facebook
3 instagram
4 Snapchat
5 messenger
6 Discord
7 twitter
8 复制链接
9 更多
10 line
11 youtube
12 twitch
 */
export var PLATFORM_COMMON_SHARE_ID = {
  WHATSAPP: 1,
  FACEBOOK: 2,
  INSTAGRAM: 3,
  SNAPCHAT: 4,
  MESSENGER: 5,
  DISCORD: 6,
  TWITTER: 7,
  COPYLINK: 8,
  MORE: 9,
  LINE: 10,
  YOUTUBE: 11,
  TWITCH: 12
};