import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
/** 解析URL */
var parseURL = function parseURL(url) {
  var _url$match;
  var _url = url || location.href;
  var diss = _url.match(/^(?:(\w+):\/{2,3})?(?:(\w+):?(\w+)?@)?([^:/?#]+)(?::(\d+))?(\/[^?#]+)?(?:\?([^#]+))?(?:#(\w+))?/);
  var host = diss[4];
  var query;
  query = _url.match(/((\?))((?!#).)*/g);
  query = query ? query[0] : '';
  var pathname = diss[6] || '';
  var dirname = (_url$match = _url.match(/[^?#]*\//)) === null || _url$match === void 0 ? void 0 : _url$match[0];
  var relative = (_url.match(/tps?:\/\/[^/]+(.+)/) || ['', ''])[1];
  var path = relative.match(/([^?#]*\/)?/)[0];
  var obj = {
    href: _url,
    protocol: diss[1],
    username: diss[2],
    password: diss[3],
    host: host,
    port: diss[5],
    query: query,
    params: function () {
      var rst = {};
      var seg = query.replace(/^\?/, '').split('&');
      var len = seg.length;
      var i = 0;
      for (; i < len; i += 1) {
        if (!seg[i]) {
          continue;
        }
        var _seg$i$split = seg[i].split('='),
          _seg$i$split2 = _slicedToArray(_seg$i$split, 2),
          a = _seg$i$split2[0],
          b = _seg$i$split2[1];
        rst[a] = b;
      }
      return rst;
    }(),
    file: (pathname.match(/\/([^/?#]+)$/i) || ['', ''])[1],
    hash: diss[8] ? diss[8].replace('#', '') : '',
    dirname: dirname,
    path: path,
    pathname: pathname,
    relative: relative,
    segments: path.replace(/^\/|\/$/g, '').split('/'),
    pathArray: pathname.replace(/^\/|\/$/g, '').split('/')
  };
  return obj;
};
export default parseURL;