import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var AnchorPanelInfo = function AnchorPanelInfo() {
  this.lRoomId = 0;
  this.lUid = 0;
  this.iAttendee = 0;
  this.iGemsAdd = 0;
  this.iSubsAdd = 0;
  this.iFansAdd = 0;
  this.iLiveTime = 0;
  this.iStreamState = 0;
  this.iVipsAdd = 0;
};
AnchorPanelInfo.prototype._clone = function () {
  return new Nimo.AnchorPanelInfo();
};
AnchorPanelInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorPanelInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorPanelInfo.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
  os.writeInt64(1, this.lUid);
  os.writeInt32(2, this.iAttendee);
  os.writeInt32(3, this.iGemsAdd);
  os.writeInt32(4, this.iSubsAdd);
  os.writeInt32(5, this.iFansAdd);
  os.writeInt32(6, this.iLiveTime);
  os.writeInt32(7, this.iStreamState);
  os.writeInt32(8, this.iVipsAdd);
};
AnchorPanelInfo.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
  this.lUid = is.readInt64(1, false, this.lUid);
  this.iAttendee = is.readInt32(2, false, this.iAttendee);
  this.iGemsAdd = is.readInt32(3, false, this.iGemsAdd);
  this.iSubsAdd = is.readInt32(4, false, this.iSubsAdd);
  this.iFansAdd = is.readInt32(5, false, this.iFansAdd);
  this.iLiveTime = is.readInt32(6, false, this.iLiveTime);
  this.iStreamState = is.readInt32(7, false, this.iStreamState);
  this.iVipsAdd = is.readInt32(8, false, this.iVipsAdd);
};
Nimo.AnchorPanelInfo = AnchorPanelInfo;
export default AnchorPanelInfo;