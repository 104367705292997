import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var ChatBubbleInfo = function ChatBubbleInfo() {
  this.iAppId = 0;
  this.sColor = "";
  this.sIconUrl = "";
  this.sResource = "";
  this.mNameLang = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.sResourceTwo = "";
  this.sResourceTwoGame = "";
  this.mExtraData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.iUpperLimitDay = 0;
  this.sColorNew = "";
};
ChatBubbleInfo.prototype._clone = function () {
  return new Nimo.ChatBubbleInfo();
};
ChatBubbleInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ChatBubbleInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ChatBubbleInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iAppId);
  os.writeString(1, this.sColor);
  os.writeString(2, this.sIconUrl);
  os.writeString(3, this.sResource);
  os.writeMap(4, this.mNameLang);
  os.writeString(5, this.sResourceTwo);
  os.writeString(6, this.sResourceTwoGame);
  os.writeMap(7, this.mExtraData);
  os.writeInt32(8, this.iUpperLimitDay);
  os.writeString(9, this.sColorNew);
};
ChatBubbleInfo.prototype.readFrom = function (is) {
  this.iAppId = is.readInt32(0, false, this.iAppId);
  this.sColor = is.readString(1, false, this.sColor);
  this.sIconUrl = is.readString(2, false, this.sIconUrl);
  this.sResource = is.readString(3, false, this.sResource);
  this.mNameLang = is.readMap(4, false, this.mNameLang);
  this.sResourceTwo = is.readString(5, false, this.sResourceTwo);
  this.sResourceTwoGame = is.readString(6, false, this.sResourceTwoGame);
  this.mExtraData = is.readMap(7, false, this.mExtraData);
  this.iUpperLimitDay = is.readInt32(8, false, this.iUpperLimitDay);
  this.sColorNew = is.readString(9, false, this.sColorNew);
};
Nimo.ChatBubbleInfo = ChatBubbleInfo;
export default ChatBubbleInfo;