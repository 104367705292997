import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BaseParam';
var FollowStatusReq = function FollowStatusReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.anchorIds = new Taf.Vector(new Taf.INT64());
};
FollowStatusReq.prototype._clone = function () {
  return new NimoBuss.FollowStatusReq();
};
FollowStatusReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FollowStatusReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FollowStatusReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeVector(1, this.anchorIds);
};
FollowStatusReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.anchorIds = is.readVector(1, false, this.anchorIds);
};
NimoBuss.FollowStatusReq = FollowStatusReq;
export default FollowStatusReq;