import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var BulletFormat = function BulletFormat() {
  this.iFontColor = -1;
  this.iFontSize = 4;
  this.iTextSpeed = 0;
  this.iTransitionType = 1;
  this.iPopupStyle = 0;
  this.iFontShadowColor = -1;
};
BulletFormat.prototype._clone = function () {
  return new Nimo.BulletFormat();
};
BulletFormat.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
BulletFormat.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
BulletFormat.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iFontColor);
  os.writeInt32(1, this.iFontSize);
  os.writeInt32(2, this.iTextSpeed);
  os.writeInt32(3, this.iTransitionType);
  os.writeInt32(4, this.iPopupStyle);
  os.writeInt32(5, this.iFontShadowColor);
};
BulletFormat.prototype.readFrom = function (is) {
  this.iFontColor = is.readInt32(0, false, this.iFontColor);
  this.iFontSize = is.readInt32(1, false, this.iFontSize);
  this.iTextSpeed = is.readInt32(2, false, this.iTextSpeed);
  this.iTransitionType = is.readInt32(3, false, this.iTransitionType);
  this.iPopupStyle = is.readInt32(4, false, this.iPopupStyle);
  this.iFontShadowColor = is.readInt32(5, false, this.iFontShadowColor);
};
Nimo.BulletFormat = BulletFormat;
export default BulletFormat;