import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var UserHeartBeatReq = function UserHeartBeatReq() {
  this.tUser = new Nimo.UserId();
  this.lPid = 0;
  this.lRoomId = 0;
  this.bWatchVideo = false;
};
UserHeartBeatReq.prototype._clone = function () {
  return new Nimo.UserHeartBeatReq();
};
UserHeartBeatReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
UserHeartBeatReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
UserHeartBeatReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeInt64(1, this.lPid);
  os.writeInt64(2, this.lRoomId);
  os.writeBoolean(3, this.bWatchVideo);
};
UserHeartBeatReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.lPid = is.readInt64(1, false, this.lPid);
  this.lRoomId = is.readInt64(2, false, this.lRoomId);
  this.bWatchVideo = is.readBoolean(3, false, this.bWatchVideo);
};
Nimo.UserHeartBeatReq = UserHeartBeatReq;
export default UserHeartBeatReq;