import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var AnchorTaskConfig = function AnchorTaskConfig() {
  this.iID = 0;
  this.iType = 0;
  this.iClass = 0;
  this.sName = "";
  this.sDesc = "";
  this.iEnableLevel = 0;
  this.mExt = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.lReserve = 0;
  this.iExp = 0;
  this.iEnableEndLevel = 0;
  this.lEnableBeginTime = 0;
  this.lEnableEndTime = 0;
  this.sIcon = "";
  this.sIconComplete = "";
  this.sIconBackground = "";
  this.sIconBackgroundComplete = "";
};
AnchorTaskConfig.prototype._clone = function () {
  return new Nimo.AnchorTaskConfig();
};
AnchorTaskConfig.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorTaskConfig.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorTaskConfig.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iID);
  os.writeInt32(1, this.iType);
  os.writeInt32(2, this.iClass);
  os.writeString(3, this.sName);
  os.writeString(4, this.sDesc);
  os.writeInt32(5, this.iEnableLevel);
  os.writeMap(6, this.mExt);
  os.writeInt64(7, this.lReserve);
  os.writeInt32(8, this.iExp);
  os.writeInt32(9, this.iEnableEndLevel);
  os.writeInt64(10, this.lEnableBeginTime);
  os.writeInt64(11, this.lEnableEndTime);
  os.writeString(12, this.sIcon);
  os.writeString(13, this.sIconComplete);
  os.writeString(14, this.sIconBackground);
  os.writeString(15, this.sIconBackgroundComplete);
};
AnchorTaskConfig.prototype.readFrom = function (is) {
  this.iID = is.readInt32(0, false, this.iID);
  this.iType = is.readInt32(1, false, this.iType);
  this.iClass = is.readInt32(2, false, this.iClass);
  this.sName = is.readString(3, false, this.sName);
  this.sDesc = is.readString(4, false, this.sDesc);
  this.iEnableLevel = is.readInt32(5, false, this.iEnableLevel);
  this.mExt = is.readMap(6, false, this.mExt);
  this.lReserve = is.readInt64(7, false, this.lReserve);
  this.iExp = is.readInt32(8, false, this.iExp);
  this.iEnableEndLevel = is.readInt32(9, false, this.iEnableEndLevel);
  this.lEnableBeginTime = is.readInt64(10, false, this.lEnableBeginTime);
  this.lEnableEndTime = is.readInt64(11, false, this.lEnableEndTime);
  this.sIcon = is.readString(12, false, this.sIcon);
  this.sIconComplete = is.readString(13, false, this.sIconComplete);
  this.sIconBackground = is.readString(14, false, this.sIconBackground);
  this.sIconBackgroundComplete = is.readString(15, false, this.sIconBackgroundComplete);
};
Nimo.AnchorTaskConfig = AnchorTaskConfig;
export default AnchorTaskConfig;