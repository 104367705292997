import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var UserEventReq = function UserEventReq() {
  this.tUser = new Nimo.UserId();
  this.eOp = 0;
  this.lPid = 0;
  this.lRoomId = 0;
  this.bWatchVideo = false;
  this.sTraceSource = "";
  this.sTraceData = "";
};
UserEventReq.prototype._clone = function () {
  return new Nimo.UserEventReq();
};
UserEventReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
UserEventReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
UserEventReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeInt32(1, this.eOp);
  os.writeInt64(2, this.lPid);
  os.writeInt64(3, this.lRoomId);
  os.writeBoolean(4, this.bWatchVideo);
  os.writeString(5, this.sTraceSource);
  os.writeString(6, this.sTraceData);
};
UserEventReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.eOp = is.readInt32(1, false, this.eOp);
  this.lPid = is.readInt64(2, false, this.lPid);
  this.lRoomId = is.readInt64(3, false, this.lRoomId);
  this.bWatchVideo = is.readBoolean(4, false, this.bWatchVideo);
  this.sTraceSource = is.readString(5, false, this.sTraceSource);
  this.sTraceData = is.readString(6, false, this.sTraceData);
};
Nimo.UserEventReq = UserEventReq;
export default UserEventReq;