import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var EntertainmentLable = function EntertainmentLable() {
  this.id = 0;
  this.lableName = "";
  this.weight = 0;
  this.type = 0;
  this.icon = "";
};
EntertainmentLable.prototype._clone = function () {
  return new NimoBuss.EntertainmentLable();
};
EntertainmentLable.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
EntertainmentLable.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
EntertainmentLable.prototype.writeTo = function (os) {
  os.writeInt32(0, this.id);
  os.writeString(1, this.lableName);
  os.writeInt32(2, this.weight);
  os.writeInt32(3, this.type);
  os.writeString(4, this.icon);
};
EntertainmentLable.prototype.readFrom = function (is) {
  this.id = is.readInt32(0, false, this.id);
  this.lableName = is.readString(1, false, this.lableName);
  this.weight = is.readInt32(2, false, this.weight);
  this.type = is.readInt32(3, false, this.type);
  this.icon = is.readString(4, false, this.icon);
};
NimoBuss.EntertainmentLable = EntertainmentLable;
export default EntertainmentLable;