import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var TransDownUserFollow = function TransDownUserFollow() {
  this.fansId = 0;
  this.fansName = "";
  this.fansAvatarUrl = "";
  this.fansAvatarBoxUrl = "";
  this.badgeName = "";
  this.badgeIcon = "";
  this.leftBadgeIcon = "";
  this.level = 0;
  this.anchorId = 0;
  this.leftBadgeIconV2 = "";
};
TransDownUserFollow.prototype._clone = function () {
  return new NimoBuss.TransDownUserFollow();
};
TransDownUserFollow.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
TransDownUserFollow.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
TransDownUserFollow.prototype.writeTo = function (os) {
  os.writeInt64(0, this.fansId);
  os.writeString(1, this.fansName);
  os.writeString(2, this.fansAvatarUrl);
  os.writeString(3, this.fansAvatarBoxUrl);
  os.writeString(4, this.badgeName);
  os.writeString(5, this.badgeIcon);
  os.writeString(6, this.leftBadgeIcon);
  os.writeInt32(7, this.level);
  os.writeInt64(8, this.anchorId);
  os.writeString(9, this.leftBadgeIconV2);
};
TransDownUserFollow.prototype.readFrom = function (is) {
  this.fansId = is.readInt64(0, false, this.fansId);
  this.fansName = is.readString(1, false, this.fansName);
  this.fansAvatarUrl = is.readString(2, false, this.fansAvatarUrl);
  this.fansAvatarBoxUrl = is.readString(3, false, this.fansAvatarBoxUrl);
  this.badgeName = is.readString(4, false, this.badgeName);
  this.badgeIcon = is.readString(5, false, this.badgeIcon);
  this.leftBadgeIcon = is.readString(6, false, this.leftBadgeIcon);
  this.level = is.readInt32(7, false, this.level);
  this.anchorId = is.readInt64(8, false, this.anchorId);
  this.leftBadgeIconV2 = is.readString(9, false, this.leftBadgeIconV2);
};
NimoBuss.TransDownUserFollow = TransDownUserFollow;
export default TransDownUserFollow;