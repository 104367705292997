import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import ua from '@client/common/utils/ua';
import { getGuid } from '@nimo-fed/x';
import { cookie } from '@client/common/utils/storage';
var data = {
  udbUserId: '',
  guid: ''
};
if (ua !== null && ua !== void 0 && ua.isInNimoApp) {
  var auth = require('@client/common/services/auth').default;
  auth.getUserInfo().then(function (userInfo) {
    var udbUserId = userInfo.udbUserId,
      guid = userInfo.guid;
    if (udbUserId && udbUserId !== '-1' && udbUserId !== -1) {
      data.udbUserId = String(udbUserId);
    }
    if (guid) {
      data.guid = guid;
    }
  });
}
export var getUnsafeAuth = function getUnsafeAuth() {
  if (!(ua !== null && ua !== void 0 && ua.isInNimoApp)) {
    data.guid = getGuid();
    data.udbUserId = cookie.get('udbUserId') || '';
  }
  return _objectSpread({}, data);
};