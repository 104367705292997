import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var NormalMultiRoomView = function NormalMultiRoomView() {
  this.roomId = 0;
  this.title = "";
  this.alise = "";
  this.liveStreamStatus = 0;
  this.appImg = "";
  this.webImg = "";
  this.anchorId = 0;
  this.sendLink = "";
};
NormalMultiRoomView.prototype._clone = function () {
  return new NimoBuss.NormalMultiRoomView();
};
NormalMultiRoomView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
NormalMultiRoomView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
NormalMultiRoomView.prototype.writeTo = function (os) {
  os.writeInt64(0, this.roomId);
  os.writeString(1, this.title);
  os.writeString(2, this.alise);
  os.writeInt32(3, this.liveStreamStatus);
  os.writeString(4, this.appImg);
  os.writeString(5, this.webImg);
  os.writeInt64(6, this.anchorId);
  os.writeString(7, this.sendLink);
};
NormalMultiRoomView.prototype.readFrom = function (is) {
  this.roomId = is.readInt64(0, false, this.roomId);
  this.title = is.readString(1, false, this.title);
  this.alise = is.readString(2, false, this.alise);
  this.liveStreamStatus = is.readInt32(3, false, this.liveStreamStatus);
  this.appImg = is.readString(4, false, this.appImg);
  this.webImg = is.readString(5, false, this.webImg);
  this.anchorId = is.readInt64(6, false, this.anchorId);
  this.sendLink = is.readString(7, false, this.sendLink);
};
NimoBuss.NormalMultiRoomView = NormalMultiRoomView;
export default NormalMultiRoomView;