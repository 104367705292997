import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var TeamView = function TeamView() {
  this.teamId = 0;
  this.teamName = "";
  this.teamLogo = "";
  this.score = 0;
};
TeamView.prototype._clone = function () {
  return new NimoBuss.TeamView();
};
TeamView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
TeamView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
TeamView.prototype.writeTo = function (os) {
  os.writeInt32(0, this.teamId);
  os.writeString(1, this.teamName);
  os.writeString(2, this.teamLogo);
  os.writeInt32(3, this.score);
};
TeamView.prototype.readFrom = function (is) {
  this.teamId = is.readInt32(0, false, this.teamId);
  this.teamName = is.readString(1, false, this.teamName);
  this.teamLogo = is.readString(2, false, this.teamLogo);
  this.score = is.readInt32(3, false, this.score);
};
NimoBuss.TeamView = TeamView;
export default TeamView;