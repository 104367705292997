import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var MessageBussInfo = function MessageBussInfo() {
  this.iIsCamp = 0;
  this.iIsPickMe = 0;
  this.iIs1Diamond = 0;
  this.iIsPayChat = 0;
  this.iIsInterGift = 0;
  this.iIsVoiceEmoji = 0;
  this.iIsGamePlay = 0;
  this.iIsFanActivity = 0;
  this.iIsSayHello = 0;
  this.iIsSendKiss = 0;
  this.iIsSendKissRsp = 0;
  this.iIsGuardianPayChat = 0;
  this.iIsSendPatRemain = 0;
  this.iIsMysteryMan = 0;
  this.iIsAutoSayHi = 0;
};
MessageBussInfo.prototype._clone = function () {
  return new Nimo.MessageBussInfo();
};
MessageBussInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MessageBussInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MessageBussInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iIsCamp);
  os.writeInt32(1, this.iIsPickMe);
  os.writeInt32(2, this.iIs1Diamond);
  os.writeInt32(3, this.iIsPayChat);
  os.writeInt32(4, this.iIsInterGift);
  os.writeInt32(5, this.iIsVoiceEmoji);
  os.writeInt32(6, this.iIsGamePlay);
  os.writeInt32(7, this.iIsFanActivity);
  os.writeInt32(8, this.iIsSayHello);
  os.writeInt32(9, this.iIsSendKiss);
  os.writeInt32(10, this.iIsSendKissRsp);
  os.writeInt32(11, this.iIsGuardianPayChat);
  os.writeInt32(12, this.iIsSendPatRemain);
  os.writeInt32(13, this.iIsMysteryMan);
  os.writeInt32(14, this.iIsAutoSayHi);
};
MessageBussInfo.prototype.readFrom = function (is) {
  this.iIsCamp = is.readInt32(0, false, this.iIsCamp);
  this.iIsPickMe = is.readInt32(1, false, this.iIsPickMe);
  this.iIs1Diamond = is.readInt32(2, false, this.iIs1Diamond);
  this.iIsPayChat = is.readInt32(3, false, this.iIsPayChat);
  this.iIsInterGift = is.readInt32(4, false, this.iIsInterGift);
  this.iIsVoiceEmoji = is.readInt32(5, false, this.iIsVoiceEmoji);
  this.iIsGamePlay = is.readInt32(6, false, this.iIsGamePlay);
  this.iIsFanActivity = is.readInt32(7, false, this.iIsFanActivity);
  this.iIsSayHello = is.readInt32(8, false, this.iIsSayHello);
  this.iIsSendKiss = is.readInt32(9, false, this.iIsSendKiss);
  this.iIsSendKissRsp = is.readInt32(10, false, this.iIsSendKissRsp);
  this.iIsGuardianPayChat = is.readInt32(11, false, this.iIsGuardianPayChat);
  this.iIsSendPatRemain = is.readInt32(12, false, this.iIsSendPatRemain);
  this.iIsMysteryMan = is.readInt32(13, false, this.iIsMysteryMan);
  this.iIsAutoSayHi = is.readInt32(14, false, this.iIsAutoSayHi);
};
Nimo.MessageBussInfo = MessageBussInfo;
export default MessageBussInfo;