import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './DecorationInfo';
var GetDecorationInfoListRsp = function GetDecorationInfoListRsp() {
  this.iCode = 0;
  this.vDecorationInfo = new Taf.Vector(new Nimo.DecorationInfo());
};
GetDecorationInfoListRsp.prototype._clone = function () {
  return new Nimo.GetDecorationInfoListRsp();
};
GetDecorationInfoListRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetDecorationInfoListRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetDecorationInfoListRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeVector(1, this.vDecorationInfo);
};
GetDecorationInfoListRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.vDecorationInfo = is.readVector(1, false, this.vDecorationInfo);
};
Nimo.GetDecorationInfoListRsp = GetDecorationInfoListRsp;
export default GetDecorationInfoListRsp;