import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './FansEmoticonView';
var TransDownFanUpgradeClubPacketRspV2 = function TransDownFanUpgradeClubPacketRspV2() {
  this.fanId = 0;
  this.anchorId = 0;
  this.badgeName = "";
  this.badgeIcon = "";
  this.oldLevel = 0;
  this.nowLevel = 0;
  this.nowGetBarrageType = 0;
  this.nowGetBarrageColor = 0;
  this.nextGetBarrageType = 0;
  this.nextGetBarrageColor = 0;
  this.nextLevel = 0;
  this.nowGetFansEmoticonLevel = 0;
  this.nextGetFansEmoticonLevel = 0;
  this.nowGetFansEmoticons = new Taf.Vector(new NimoBuss.FansEmoticonView());
  this.nextGetFansEmoticons = new Taf.Vector(new NimoBuss.FansEmoticonView());
  this.leftBadgeIcon = "";
  this.leftBadgeIconV2 = "";
  this.notPop = 0;
};
TransDownFanUpgradeClubPacketRspV2.prototype._clone = function () {
  return new NimoBuss.TransDownFanUpgradeClubPacketRspV2();
};
TransDownFanUpgradeClubPacketRspV2.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
TransDownFanUpgradeClubPacketRspV2.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
TransDownFanUpgradeClubPacketRspV2.prototype.writeTo = function (os) {
  os.writeInt64(0, this.fanId);
  os.writeInt64(1, this.anchorId);
  os.writeString(2, this.badgeName);
  os.writeString(3, this.badgeIcon);
  os.writeInt32(4, this.oldLevel);
  os.writeInt32(5, this.nowLevel);
  os.writeInt32(6, this.nowGetBarrageType);
  os.writeInt32(7, this.nowGetBarrageColor);
  os.writeInt32(8, this.nextGetBarrageType);
  os.writeInt32(9, this.nextGetBarrageColor);
  os.writeInt32(10, this.nextLevel);
  os.writeInt32(11, this.nowGetFansEmoticonLevel);
  os.writeInt32(12, this.nextGetFansEmoticonLevel);
  os.writeVector(13, this.nowGetFansEmoticons);
  os.writeVector(14, this.nextGetFansEmoticons);
  os.writeString(15, this.leftBadgeIcon);
  os.writeString(16, this.leftBadgeIconV2);
  os.writeInt32(17, this.notPop);
};
TransDownFanUpgradeClubPacketRspV2.prototype.readFrom = function (is) {
  this.fanId = is.readInt64(0, false, this.fanId);
  this.anchorId = is.readInt64(1, false, this.anchorId);
  this.badgeName = is.readString(2, false, this.badgeName);
  this.badgeIcon = is.readString(3, false, this.badgeIcon);
  this.oldLevel = is.readInt32(4, false, this.oldLevel);
  this.nowLevel = is.readInt32(5, false, this.nowLevel);
  this.nowGetBarrageType = is.readInt32(6, false, this.nowGetBarrageType);
  this.nowGetBarrageColor = is.readInt32(7, false, this.nowGetBarrageColor);
  this.nextGetBarrageType = is.readInt32(8, false, this.nextGetBarrageType);
  this.nextGetBarrageColor = is.readInt32(9, false, this.nextGetBarrageColor);
  this.nextLevel = is.readInt32(10, false, this.nextLevel);
  this.nowGetFansEmoticonLevel = is.readInt32(11, false, this.nowGetFansEmoticonLevel);
  this.nextGetFansEmoticonLevel = is.readInt32(12, false, this.nextGetFansEmoticonLevel);
  this.nowGetFansEmoticons = is.readVector(13, false, this.nowGetFansEmoticons);
  this.nextGetFansEmoticons = is.readVector(14, false, this.nextGetFansEmoticons);
  this.leftBadgeIcon = is.readString(15, false, this.leftBadgeIcon);
  this.leftBadgeIconV2 = is.readString(16, false, this.leftBadgeIconV2);
  this.notPop = is.readInt32(17, false, this.notPop);
};
NimoBuss.TransDownFanUpgradeClubPacketRspV2 = TransDownFanUpgradeClubPacketRspV2;
export default TransDownFanUpgradeClubPacketRspV2;