import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var GetAnchorFollowInfoRsp = function GetAnchorFollowInfoRsp() {
  this.totalFollowedNum = 0;
  this.followedNumIn7Day = 0;
};
GetAnchorFollowInfoRsp.prototype._clone = function () {
  return new NimoBuss.GetAnchorFollowInfoRsp();
};
GetAnchorFollowInfoRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetAnchorFollowInfoRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetAnchorFollowInfoRsp.prototype.writeTo = function (os) {
  os.writeInt64(0, this.totalFollowedNum);
  os.writeInt64(1, this.followedNumIn7Day);
};
GetAnchorFollowInfoRsp.prototype.readFrom = function (is) {
  this.totalFollowedNum = is.readInt64(0, false, this.totalFollowedNum);
  this.followedNumIn7Day = is.readInt64(1, false, this.followedNumIn7Day);
};
NimoBuss.GetAnchorFollowInfoRsp = GetAnchorFollowInfoRsp;
export default GetAnchorFollowInfoRsp;