import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var MatchDataConfig = function MatchDataConfig() {
  this.componentType = 0;
  this.mobileUrl = "";
  this.pcUrl = "";
};
MatchDataConfig.prototype._clone = function () {
  return new NimoBuss.MatchDataConfig();
};
MatchDataConfig.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MatchDataConfig.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MatchDataConfig.prototype.writeTo = function (os) {
  os.writeInt32(0, this.componentType);
  os.writeString(1, this.mobileUrl);
  os.writeString(2, this.pcUrl);
};
MatchDataConfig.prototype.readFrom = function (is) {
  this.componentType = is.readInt32(0, false, this.componentType);
  this.mobileUrl = is.readString(1, false, this.mobileUrl);
  this.pcUrl = is.readString(2, false, this.pcUrl);
};
NimoBuss.MatchDataConfig = MatchDataConfig;
export default MatchDataConfig;