import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var GiftPayType = {
  PAY_TYPE_R_DIAMOND: 900,
  PAY_TYPE_GOLD: 1002,
  PAY_TYPE_SILVER: 1003,
  PAY_TYPE_FREE: 1018,
  PAY_TYPE_BEAN: 3008,
  PAY_TYPE_F: 1073
};
Nimo.GiftPayType = GiftPayType;
export default GiftPayType;