import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var AnimationContent = function AnimationContent() {
  this.sUrl = "";
  this.sMP4Url = "";
  this.iMP4Width = 0;
  this.iMP4Height = 0;
  this.sMP4ConfigResource = "";
  this.vMP4Placeholder = new Taf.Vector(new Taf.STRING());
};
AnimationContent.prototype._clone = function () {
  return new Nimo.AnimationContent();
};
AnimationContent.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnimationContent.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnimationContent.prototype.writeTo = function (os) {
  os.writeString(0, this.sUrl);
  os.writeString(1, this.sMP4Url);
  os.writeInt32(2, this.iMP4Width);
  os.writeInt32(3, this.iMP4Height);
  os.writeString(4, this.sMP4ConfigResource);
  os.writeVector(5, this.vMP4Placeholder);
};
AnimationContent.prototype.readFrom = function (is) {
  this.sUrl = is.readString(0, false, this.sUrl);
  this.sMP4Url = is.readString(1, false, this.sMP4Url);
  this.iMP4Width = is.readInt32(2, false, this.iMP4Width);
  this.iMP4Height = is.readInt32(3, false, this.iMP4Height);
  this.sMP4ConfigResource = is.readString(4, false, this.sMP4ConfigResource);
  this.vMP4Placeholder = is.readVector(5, false, this.vMP4Placeholder);
};
Nimo.AnimationContent = AnimationContent;
export default AnimationContent;