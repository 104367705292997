import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './LiveInfo';
var NonPublicLiveBeginNotice = function NonPublicLiveBeginNotice() {
  this.tLiveInfo = new Nimo.LiveInfo();
};
NonPublicLiveBeginNotice.prototype._clone = function () {
  return new Nimo.NonPublicLiveBeginNotice();
};
NonPublicLiveBeginNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
NonPublicLiveBeginNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
NonPublicLiveBeginNotice.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tLiveInfo);
};
NonPublicLiveBeginNotice.prototype.readFrom = function (is) {
  this.tLiveInfo = is.readStruct(0, false, this.tLiveInfo);
};
Nimo.NonPublicLiveBeginNotice = NonPublicLiveBeginNotice;
export default NonPublicLiveBeginNotice;