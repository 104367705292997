import Auth from '@desktop/src/common/services/auth';
import { cookie } from '@client/common/utils/storage';
import { eventEmitter, EVENTS } from '@client/common/utils/events';
import fanModel, { fanWupModel } from '@client/common/models/follow/fan';
import nimoReport, { getRoomActivityState } from '@desktop/src/common/services/report';
import anchorModel from '@client/common/models/follow/anchor';
var userInfo = Auth.getUserInfo();
export var ajaxBaseInfo = {
  version: cookie.get('version'),
  bizToken: cookie.get('bizToken'),
  udbUserId: cookie.get('udbUserId')
};
var _getUserInfo = function _getUserInfo() {
  if (!userInfo.udbUserId) {
    userInfo = Auth.getUserInfo();
  }
  return userInfo;
};
function getFollowFetchParams(roomInfo) {
  var roomBaseInfo = roomInfo || window.G_roomBaseInfo;
  var _getUserInfo2 = _getUserInfo(),
    udbUserId = _getUserInfo2.udbUserId,
    bizToken = _getUserInfo2.bizToken,
    version = _getUserInfo2.version;
  return {
    bizToken: bizToken,
    udbUserId: udbUserId,
    version: version,
    anchorID: roomBaseInfo === null || roomBaseInfo === void 0 ? void 0 : roomBaseInfo.auId,
    // 主播id
    fanID: udbUserId || '',
    // 用户id
    roomID: roomBaseInfo === null || roomBaseInfo === void 0 ? void 0 : roomBaseInfo.roomId,
    udbDeviceType: 'WEB',
    requestSource: 'WEB'
  };
}
export var SUBSCRIBE_TYPE = {
  SUBSCRIBE: 'subscribe',
  UNSUBSCRIBE: 'unSubscribe'
};
export var SUBSCRIBE_ERROR_CODE_MAP = {
  LOGIN_EXPIRE: 501,
  OPER_TOO_FREQUENT: 16003,
  REPEAT_OPER: 16004,
  BEYOND_LIMIT: 16020
};
export * from './SUBSCRIBE_FROM_MAP';
var subscribe = function subscribe(from, roomInfo) {
  var data = getFollowFetchParams(roomInfo);
  var anchorID = data.anchorID,
    fanID = data.fanID;
  var emitEvent = function emitEvent(relationship) {
    eventEmitter.emit(EVENTS.FOLLOW, {
      anchorID: anchorID,
      fanID: fanID,
      sta: 1,
      type: 'subscribe',
      relationship: relationship // relationship === 1 用户关注了主播，2 互相关注，0 未关注主播
    });
  };

  return fanModel.followAnchorV2(data).then(function (rsp) {
    emitEvent(rsp === null || rsp === void 0 ? void 0 : rsp.relationship);
    if (from && typeof from === 'string') {
      nimoReport.report({
        eid: 'follow_success',
        eid_desc: '关注_成功',
        prop: JSON.stringify({
          from: from,
          style_: getRoomActivityState()
        })
      });
    }
  }, function (err) {
    var _ref = err || {},
      code = _ref.code;
    // 重复操作，例如不同浏览器同时打开同一个未关注的直播间，其中一个点击关注，另一个再点击关注直接就切换UI状态
    if (code !== SUBSCRIBE_ERROR_CODE_MAP.REPEAT_OPER) {
      throw err;
    } else {
      emitEvent();
    }
  });
};
var unsubscribe = function unsubscribe(roomInfo) {
  var data = getFollowFetchParams(roomInfo);
  var anchorID = data.anchorID,
    fanID = data.fanID;
  return fanWupModel.unFollowAnchor(data).catch(function (err) {
    var _ref2 = err || {},
      code = _ref2.code;
    if (code !== SUBSCRIBE_ERROR_CODE_MAP.REPEAT_OPER) {
      throw err;
    }
  }).then(function () {
    eventEmitter.emit(EVENTS.UNFOLLOW, {
      anchorID: anchorID,
      fanID: fanID,
      sta: 0,
      type: 'unsubscribe'
    });
  });
};
var followStatusAndCount = function followStatusAndCount(roomInfo) {
  return anchorModel.fetchFollowStatusAndCount(getFollowFetchParams(roomInfo));
};
var getFanCount = function getFanCount(params) {
  return anchorModel.fetchFanCount(params);
};
export default {
  subscribe: subscribe,
  unsubscribe: unsubscribe,
  followStatusAndCount: followStatusAndCount,
  getFanCount: getFanCount
};