import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var GetTeamListReq = function GetTeamListReq() {
  this.eventId = 0;
  this.groupShow = 0;
  this.countryCode = "";
  this.lcid = 0;
  this.language = "";
};
GetTeamListReq.prototype._clone = function () {
  return new NimoBuss.GetTeamListReq();
};
GetTeamListReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetTeamListReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetTeamListReq.prototype.writeTo = function (os) {
  os.writeInt64(0, this.eventId);
  os.writeInt32(1, this.groupShow);
  os.writeString(2, this.countryCode);
  os.writeInt32(3, this.lcid);
  os.writeString(4, this.language);
};
GetTeamListReq.prototype.readFrom = function (is) {
  this.eventId = is.readInt64(0, false, this.eventId);
  this.groupShow = is.readInt32(1, false, this.groupShow);
  this.countryCode = is.readString(2, false, this.countryCode);
  this.lcid = is.readInt32(3, false, this.lcid);
  this.language = is.readString(4, false, this.language);
};
NimoBuss.GetTeamListReq = GetTeamListReq;
export default GetTeamListReq;