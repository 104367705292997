var getParentOrigin = function getParentOrigin() {
  if (location.ancestorOrigins && location.ancestorOrigins.length > 0) {
    return location.ancestorOrigins[0];
  } else {
    var _document = document,
      referrer = _document.referrer;
    if (referrer) {
      var result = /^\w+:\/\/[^/?#]+/.exec(referrer);
      if (result) return result[0];
    }
  }
  return '';
};
export default getParentOrigin;