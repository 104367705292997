import "core-js/modules/es6.array.sort.js";
import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var CoverLabelView = function CoverLabelView() {
  this.id = 0;
  this.colorType = 0;
  this.labelType = "";
  this.sort = 0;
  this.title = "";
  this.titleLang = "";
  this.number = 0;
  this.timestamp = 0;
};
CoverLabelView.prototype._clone = function () {
  return new NimoBuss.CoverLabelView();
};
CoverLabelView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
CoverLabelView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
CoverLabelView.prototype.writeTo = function (os) {
  os.writeInt32(0, this.id);
  os.writeInt32(1, this.colorType);
  os.writeString(2, this.labelType);
  os.writeInt32(3, this.sort);
  os.writeString(4, this.title);
  os.writeString(5, this.titleLang);
  os.writeInt32(6, this.number);
  os.writeInt64(7, this.timestamp);
};
CoverLabelView.prototype.readFrom = function (is) {
  this.id = is.readInt32(0, false, this.id);
  this.colorType = is.readInt32(1, false, this.colorType);
  this.labelType = is.readString(2, false, this.labelType);
  this.sort = is.readInt32(3, false, this.sort);
  this.title = is.readString(4, false, this.title);
  this.titleLang = is.readString(5, false, this.titleLang);
  this.number = is.readInt32(6, false, this.number);
  this.timestamp = is.readInt64(7, false, this.timestamp);
};
NimoBuss.CoverLabelView = CoverLabelView;
export default CoverLabelView;