import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './LiveRoomView';
var LiveRoomListView = function LiveRoomListView() {
  this.liveRoomViewList = new Taf.Vector(new NimoBuss.LiveRoomView());
};
LiveRoomListView.prototype._clone = function () {
  return new NimoBuss.LiveRoomListView();
};
LiveRoomListView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LiveRoomListView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LiveRoomListView.prototype.writeTo = function (os) {
  os.writeVector(0, this.liveRoomViewList);
};
LiveRoomListView.prototype.readFrom = function (is) {
  this.liveRoomViewList = is.readVector(0, false, this.liveRoomViewList);
};
NimoBuss.LiveRoomListView = LiveRoomListView;
export default LiveRoomListView;