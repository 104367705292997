import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './SenderInfo';
import './ContentFormat';
import './BulletFormat';
import './DecorationInfo';
import './UidNickName';
import './NickNameFormat';
import './MessageBussInfo';
import './AvatarBox';
import './ChatBubbleInfo';
var MessageNotice = function MessageNotice() {
  this.tUserInfo = new Nimo.SenderInfo();
  this.lRoomId = 0;
  this.sContent = "";
  this.iShowMode = 0;
  this.tFormat = new Nimo.ContentFormat();
  this.tBulletFormat = new Nimo.BulletFormat();
  this.iTermType = 0;
  this.vDecorationPrefix = new Taf.Vector(new Nimo.DecorationInfo());
  this.vDecorationSuffix = new Taf.Vector(new Nimo.DecorationInfo());
  this.vAtSomeone = new Taf.Vector(new Nimo.UidNickName());
  this.lPid = 0;
  this.sAvatar = "";
  this.lCombo = 0;
  this.bFindSWord = false;
  this.bIsVIP = false;
  this.bIsCamp = false;
  this.tNickNameFormat = new Nimo.NickNameFormat();
  this.iRoyalLevel = 0;
  this.bIsPickMe = false;
  this.iExtraStyle = 0;
  this.tMessageBussInfo = new Nimo.MessageBussInfo();
  this.mExtraData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.tAvatarBox = new Nimo.AvatarBox();
  this.tChatBubbleInfo = new Nimo.ChatBubbleInfo();
  this.vDecorationAnchor = new Taf.Vector(new Nimo.DecorationInfo());
  this.sTranslatedContent = "";
  this.iTranslatedLang = 0;
};
MessageNotice.prototype._clone = function () {
  return new Nimo.MessageNotice();
};
MessageNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MessageNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MessageNotice.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUserInfo);
  os.writeInt64(1, this.lRoomId);
  os.writeString(2, this.sContent);
  os.writeInt32(3, this.iShowMode);
  os.writeStruct(4, this.tFormat);
  os.writeStruct(5, this.tBulletFormat);
  os.writeInt32(6, this.iTermType);
  os.writeVector(7, this.vDecorationPrefix);
  os.writeVector(8, this.vDecorationSuffix);
  os.writeVector(9, this.vAtSomeone);
  os.writeInt64(10, this.lPid);
  os.writeString(11, this.sAvatar);
  os.writeInt64(12, this.lCombo);
  os.writeBoolean(13, this.bFindSWord);
  os.writeBoolean(14, this.bIsVIP);
  os.writeBoolean(15, this.bIsCamp);
  os.writeStruct(16, this.tNickNameFormat);
  os.writeInt32(17, this.iRoyalLevel);
  os.writeBoolean(18, this.bIsPickMe);
  os.writeInt32(19, this.iExtraStyle);
  os.writeStruct(20, this.tMessageBussInfo);
  os.writeMap(21, this.mExtraData);
  os.writeStruct(22, this.tAvatarBox);
  os.writeStruct(23, this.tChatBubbleInfo);
  os.writeVector(24, this.vDecorationAnchor);
  os.writeString(25, this.sTranslatedContent);
  os.writeInt32(26, this.iTranslatedLang);
};
MessageNotice.prototype.readFrom = function (is) {
  this.tUserInfo = is.readStruct(0, false, this.tUserInfo);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.sContent = is.readString(2, false, this.sContent);
  this.iShowMode = is.readInt32(3, false, this.iShowMode);
  this.tFormat = is.readStruct(4, false, this.tFormat);
  this.tBulletFormat = is.readStruct(5, false, this.tBulletFormat);
  this.iTermType = is.readInt32(6, false, this.iTermType);
  this.vDecorationPrefix = is.readVector(7, false, this.vDecorationPrefix);
  this.vDecorationSuffix = is.readVector(8, false, this.vDecorationSuffix);
  this.vAtSomeone = is.readVector(9, false, this.vAtSomeone);
  this.lPid = is.readInt64(10, false, this.lPid);
  this.sAvatar = is.readString(11, false, this.sAvatar);
  this.lCombo = is.readInt64(12, false, this.lCombo);
  this.bFindSWord = is.readBoolean(13, false, this.bFindSWord);
  this.bIsVIP = is.readBoolean(14, false, this.bIsVIP);
  this.bIsCamp = is.readBoolean(15, false, this.bIsCamp);
  this.tNickNameFormat = is.readStruct(16, false, this.tNickNameFormat);
  this.iRoyalLevel = is.readInt32(17, false, this.iRoyalLevel);
  this.bIsPickMe = is.readBoolean(18, false, this.bIsPickMe);
  this.iExtraStyle = is.readInt32(19, false, this.iExtraStyle);
  this.tMessageBussInfo = is.readStruct(20, false, this.tMessageBussInfo);
  this.mExtraData = is.readMap(21, false, this.mExtraData);
  this.tAvatarBox = is.readStruct(22, false, this.tAvatarBox);
  this.tChatBubbleInfo = is.readStruct(23, false, this.tChatBubbleInfo);
  this.vDecorationAnchor = is.readVector(24, false, this.vDecorationAnchor);
  this.sTranslatedContent = is.readString(25, false, this.sTranslatedContent);
  this.iTranslatedLang = is.readInt32(26, false, this.iTranslatedLang);
};
Nimo.MessageNotice = MessageNotice;
export default MessageNotice;