export var broadcastKey = 'broadcast';
export var personalKey = 'personal';
export var clanKey = 'clan';

/**
 * side 纯配置文件
 * 除了个人中心还有其他地方会饮用该配置获取个人中心目录层级结构，不能放任何个人中心的配置
 * 具体业务配置放到 side.business.config
 *
 * 配置说明
 * key 唯一标识
 * lang 多语言 key
 * children 目前个人中心 side 只有两级目录，一级目录下有 children，children 为二级目录集合
 * report 点击的数据埋点结构体
 * icon 二级目录 icon
 * prompt 是否参与红点提示计算
 */
var sides = [{
  key: personalKey,
  lang: 'side.title1',
  children: [{
    key: 'myInfo',
    lang: 'side.info',
    report: '{"eid":"me_myinfo_click","eid_desc":"个人中心_我的信息_点击"}',
    icon: 'ic-info'
  }, {
    key: 'mySubscribe',
    lang: 'side.sub',
    report: '{"eid":"me_myfllow_click","eid_desc":"个人中心_我的关注_点击"}',
    icon: 'ic-follow'
  }, {
    key: 'consumeList',
    lang: 'side.diamond_bill_text',
    report: '{"eid":"my_diamonddetail_click","eid_desc":"个人_点击钻石明细_点击"}',
    icon: 'ic-diamond'
  }, {
    key: 'giftRecord',
    lang: 'side.giftRecordDetail',
    icon: 'ic-lottery'
  }, {
    key: 'royaldiamondDetail',
    lang: 'side.rdmaindDetail',
    icon: 'ic-rdiamond'
  }, {
    key: 'coinDetail',
    lang: 'side.coin_bill_text',
    report: '{"eid":"me_coindetail_click","eid_desc":"个人中心_金币账户明细_点击"}',
    icon: 'ic-coin'
  }, {
    key: 'goldbeanDetail',
    lang: 'side.goldBeanDetail',
    icon: 'ic-gold-bean-detail'
  }, {
    key: 'guessRecord',
    lang: 'side.guessRecord',
    report: '{"eid":"interactbet_record_click","eid_desc":"个人中心_竞猜记录_点击"}',
    icon: 'ic-guess-record'
  },
  // 无人使用，下掉入口
  // {
  //   key: 'article',
  //   lang: 'article.title',
  //   report: '',
  //   icon: 'ic-article'
  // },
  {
    key: 'subscribedStreamer',
    lang: 'side.mySubsctiption',
    report: '',
    icon: 'ic-my-subsctiption'
  }, {
    key: 'myAchievements',
    lang: 'side.myAchievements',
    report: '{"eid":"usr/click/giftmedalwall/me","eid_desc":"用户/点击/成就勋章墙/个人中心"}',
    icon: 'ic-my-achievements'
  }, {
    key: 'myFamily',
    lang: 'side.myFamily',
    report: '{"eid":"usr/click/mytab/familycenter-entrance","eid_desc":"我的页_点击家族中心入口"}',
    icon: 'ic-my-family'
  }, {
    key: 'video',
    lang: 'side.myVideo',
    report: '{"eid":"me_myvideo_click","eid_desc":"个人中心_我的视频_点击"}',
    icon: 'ic-video'
  }]
}, {
  key: broadcastKey,
  lang: 'header.goopen',
  children: [{
    key: 'streamManager',
    lang: 'side.dashboard',
    icon: 'ic-dashboard'
  }, {
    key: 'anchorLevel',
    lang: 'side.anchorLevel',
    report: '{"eid":"mylevel_enter_click","eid_desc":"我的等级_入口_点击"}',
    icon: 'ic-anchorlevel'
  }, {
    key: 'liveRecord',
    lang: 'side.record',
    report: '{"eid":"me_liverecord_click","eid_desc":"个人中心_直播记录_点击"}',
    icon: 'ic-liverecord'
  }, {
    key: 'subscriptionsManager',
    lang: 'side.subscriptionsManager',
    report: '',
    icon: 'ic-subscriptions-manager'
  }, {
    key: 'fansClub',
    lang: 'side.myFan',
    report: '',
    icon: 'ic-fans-club'
  }, {
    key: 'clanIntroduction',
    lang: 'side.clanIntroduction',
    icon: 'ic-clan-intro'
  }, {
    key: 'streamUrl',
    lang: 'side.streamUrl',
    icon: 'ic-stream-url'
  }, {
    key: 'streamDownload',
    lang: 'side.streamDownload',
    icon: 'ic-download'
  }, {
    key: 'propSaleSharing',
    lang: 'side.sales_records',
    iconSvg: 'game-center'
  }]
}, {
  key: clanKey,
  lang: 'side.title3',
  children: [{
    key: 'clanDurationTask',
    lang: 'side.newAnchorData',
    icon: 'ic-clan-duration-task'
  },
  // 新增试播主播
  {
    key: 'addNewAnchorBroadcast',
    lang: 'side.addNewAcnhor',
    icon: 'ic-clan-duration-task'
  }, {
    key: 'clanOperate',
    lang: 'side.clanOperate',
    icon: 'ic-clan-operate'
  }, {
    key: 'clanContract',
    lang: 'side.clanContract',
    icon: 'ic-clan-contract'
  }, {
    key: 'clanAuthority',
    lang: 'side.authority',
    icon: 'icon-authority'
  }, {
    key: 'clanInformation',
    lang: 'side.clanInformation',
    report: '{"eid":"usr/click/clan3_infos","eid_desc":"用户_点击_公会资料页","prop":{"result_":1}}',
    icon: 'icon-clan-info'
  }, {
    key: 'clanTraffic',
    lang: 'side.popularityManagement',
    icon: 'ic-clan-traffic'
  }, {
    key: 'clanInvoiceUpload',
    lang: 'side.uploadInvoice',
    icon: 'ic-invoice-upload'
  }, {
    key: 'clanInvoiceManagement',
    lang: 'side.historyRecored',
    icon: 'ic-invoice-management'
  }]
}];
export default sides;