import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var CdnPushInfo = function CdnPushInfo() {
  this.iCdn = 0;
  this.sUrlBase = "";
  this.vUrlBases = new Taf.Vector(new Taf.STRING());
  this.sCode = "";
  this.vQuicsUrlBases = new Taf.Vector(new Taf.STRING());
  this.sQuicSNI = "";
  this.bWebIpPushUrl = false;
  this.sSrtUrl = "";
};
CdnPushInfo.prototype._clone = function () {
  return new Nimo.CdnPushInfo();
};
CdnPushInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
CdnPushInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
CdnPushInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCdn);
  os.writeString(1, this.sUrlBase);
  os.writeVector(2, this.vUrlBases);
  os.writeString(3, this.sCode);
  os.writeVector(4, this.vQuicsUrlBases);
  os.writeString(5, this.sQuicSNI);
  os.writeBoolean(6, this.bWebIpPushUrl);
  os.writeString(7, this.sSrtUrl);
};
CdnPushInfo.prototype.readFrom = function (is) {
  this.iCdn = is.readInt32(0, false, this.iCdn);
  this.sUrlBase = is.readString(1, false, this.sUrlBase);
  this.vUrlBases = is.readVector(2, false, this.vUrlBases);
  this.sCode = is.readString(3, false, this.sCode);
  this.vQuicsUrlBases = is.readVector(4, false, this.vQuicsUrlBases);
  this.sQuicSNI = is.readString(5, false, this.sQuicSNI);
  this.bWebIpPushUrl = is.readBoolean(6, false, this.bWebIpPushUrl);
  this.sSrtUrl = is.readString(7, false, this.sSrtUrl);
};
Nimo.CdnPushInfo = CdnPushInfo;
export default CdnPushInfo;