import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import Modal from './Modal';
import confirm from './confirm';
import showModal from './showModal';
import './style/index';
Modal.info = function (props) {
  var config = _objectSpread({
    type: 'info',
    iconType: 'info-circle',
    okCancel: false
  }, props);
  return confirm(config);
};
Modal.success = function (props) {
  var config = _objectSpread({
    type: 'success',
    iconType: 'check-circle',
    okCancel: false
  }, props);
  return confirm(config);
};
Modal.error = function (props) {
  var config = _objectSpread({
    type: 'error',
    iconType: 'cross-circle',
    okCancel: false
  }, props);
  return confirm(config);
};
Modal.warn = function (props) {
  var config = _objectSpread({
    type: 'warning',
    iconType: 'exclamation-circle',
    okCancel: false
  }, props);
  return confirm(config);
};
Modal.warning = Modal.warn;
Modal.confirm = function (props) {
  var config = _objectSpread({
    type: 'confirm',
    okCancel: true
  }, props);
  return confirm(config);
};
Modal.show = showModal;
export default Modal;