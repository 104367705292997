/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable global-require */
/* eslint-disable react/no-danger */
import lazy from '@client/common/components/functional/lazyLoadComp';

/**
 * 明星主播介绍
 */
export var DetailBroadcast = lazy(function () {
  return import( /* webpackChunkName: 'star-intro' */'./DetailBoard');
}, {
  loading: false,
  async: true
});