import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './PropsItem';
import './SpecPropItem';
var GetPropsListByTypeRsp = function GetPropsListByTypeRsp() {
  this.iCode = 0;
  this.sLang = "";
  this.sClientType = "";
  this.iPropsListType = 0;
  this.vPropsItemList = new Taf.Vector(new Nimo.PropsItem());
  this.mSpecPropsItemList = new Taf.Map(new Taf.INT32(), new Taf.Vector(new Nimo.SpecPropItem()));
};
GetPropsListByTypeRsp.prototype._clone = function () {
  return new Nimo.GetPropsListByTypeRsp();
};
GetPropsListByTypeRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetPropsListByTypeRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetPropsListByTypeRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sLang);
  os.writeString(2, this.sClientType);
  os.writeInt32(3, this.iPropsListType);
  os.writeVector(4, this.vPropsItemList);
  os.writeMap(5, this.mSpecPropsItemList);
};
GetPropsListByTypeRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sLang = is.readString(1, false, this.sLang);
  this.sClientType = is.readString(2, false, this.sClientType);
  this.iPropsListType = is.readInt32(3, false, this.iPropsListType);
  this.vPropsItemList = is.readVector(4, false, this.vPropsItemList);
  this.mSpecPropsItemList = is.readMap(5, false, this.mSpecPropsItemList);
};
Nimo.GetPropsListByTypeRsp = GetPropsListByTypeRsp;
export default GetPropsListByTypeRsp;