import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './ItemTallyRecord';
import './RichText';
var ItemTallyInfoNotify = function ItemTallyInfoNotify() {
  this.lUid = 0;
  this.lRoomId = 0;
  this.iNotifyType = 0;
  this.tRecord = new Nimo.ItemTallyRecord();
  this.tAnchorSystemMessage = new Nimo.RichText();
  this.tAudienceSystemMessage = new Nimo.RichText();
  this.iAnchorSysMessageJump = 0;
  this.iAudiencesysMessageJump = 0;
  this.sBackground = "";
  this.mTitle = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.mDescription = new Taf.Map(new Taf.STRING(), new Taf.STRING());
};
ItemTallyInfoNotify.prototype._clone = function () {
  return new Nimo.ItemTallyInfoNotify();
};
ItemTallyInfoNotify.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ItemTallyInfoNotify.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ItemTallyInfoNotify.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUid);
  os.writeInt64(1, this.lRoomId);
  os.writeInt32(2, this.iNotifyType);
  os.writeStruct(3, this.tRecord);
  os.writeStruct(4, this.tAnchorSystemMessage);
  os.writeStruct(5, this.tAudienceSystemMessage);
  os.writeInt32(6, this.iAnchorSysMessageJump);
  os.writeInt32(7, this.iAudiencesysMessageJump);
  os.writeString(8, this.sBackground);
  os.writeMap(9, this.mTitle);
  os.writeMap(10, this.mDescription);
};
ItemTallyInfoNotify.prototype.readFrom = function (is) {
  this.lUid = is.readInt64(0, false, this.lUid);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.iNotifyType = is.readInt32(2, false, this.iNotifyType);
  this.tRecord = is.readStruct(3, false, this.tRecord);
  this.tAnchorSystemMessage = is.readStruct(4, false, this.tAnchorSystemMessage);
  this.tAudienceSystemMessage = is.readStruct(5, false, this.tAudienceSystemMessage);
  this.iAnchorSysMessageJump = is.readInt32(6, false, this.iAnchorSysMessageJump);
  this.iAudiencesysMessageJump = is.readInt32(7, false, this.iAudiencesysMessageJump);
  this.sBackground = is.readString(8, false, this.sBackground);
  this.mTitle = is.readMap(9, false, this.mTitle);
  this.mDescription = is.readMap(10, false, this.mDescription);
};
Nimo.ItemTallyInfoNotify = ItemTallyInfoNotify;
export default ItemTallyInfoNotify;