import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { eventEmitter, EVENTS } from '@client/common/utils/events';
import EPayChatSourceType from '@client/jce/Nimo/EPayChatSourceType';
export var offTextForbid = function offTextForbid(handler) {
  eventEmitter.off(EVENTS.TEXT_FORBID, handler);
};
export var onTextForbid = function onTextForbid(handler) {
  eventEmitter.on(EVENTS.TEXT_FORBID, handler);
};
export var emitTextForbid = function emitTextForbid(forbidMsg) {
  return eventEmitter.emit(EVENTS.TEXT_FORBID, forbidMsg);
};
export var isForbid = function isForbid(forbidMsg, msg) {
  if (!msg) return false;
  if (msg.__msgtype__ === 1400) {
    // MessageNotice
    if (msg.tUserInfo.lUid === forbidMsg.lForbidUid) {
      return true;
    }
  } else if (msg.__msgtype__ === 1419) {
    // EUriPayChatNotice
    // PayChatNotice
    if (msg.lUid === forbidMsg.lForbidUid) {
      return true;
    }
  } else if (msg.__msgtype__ === 1428) {
    // EUriPayChatRichTextNewNotice
    if (msg.lUid === forbidMsg.lForbidUid && msg.iSource === EPayChatSourceType.kPAYCHAT_NORMAL) {
      return true;
    }
  }
  return false;
};
export var setTextForbidImmutable = function setTextForbidImmutable(forbidMsg, msgList) {
  var changed = false;
  if (!Array.isArray(msgList)) return msgList;
  var newMsgList = msgList.map(function (item) {
    if (isForbid(forbidMsg, item)) {
      changed = true;
      return _objectSpread(_objectSpread({}, item), {}, {
        __banned__: true
      });
    }
    return item;
  });
  return changed ? newMsgList : msgList;
};
export var setTextForbidMutable = function setTextForbidMutable(forBidMsg, msgList) {
  if (!Array.isArray(msgList)) return;
  msgList.forEach(function (item) {
    if (isForbid(forBidMsg, item)) {
      // eslint-disable-next-line no-param-reassign
      item.__banned__ = true;
    }
  });
};