import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var PayChatOption = function PayChatOption() {
  this.iType = 0;
  this.iCount = 0;
  this.iStyle = 0;
  this.sTitleBgColor = "";
  this.sContentBgColor = "";
  this.sTextColor = "";
  this.iLengthLimit = 0;
  this.iDurationS = 0;
  this.iIsShow = 0;
  this.iIsGuardian = 0;
  this.iGuardianLevel = 0;
  this.sTitleBgColorDark = "";
  this.sContentBgColorDark = "";
  this.sTextColorDark = "";
  this.sPreviewColor = "";
  this.sHorizontalTitleBgColor = "";
  this.sHorizontalTitleBgColorDark = "";
  this.sHorizontalContentBgColor = "";
  this.sHorizontalContentBgColorDark = "";
  this.sFloatBackGroundColor = "";
  this.sFloatFrameColor = "";
  this.sGuardianIconUrl = "";
  this.sCornerPreviewIconUrl = "";
  this.sCornerBackgroundUrl = "";
  this.sCornerIconUrl = "";
};
PayChatOption.prototype._clone = function () {
  return new Nimo.PayChatOption();
};
PayChatOption.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
PayChatOption.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
PayChatOption.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iType);
  os.writeInt32(1, this.iCount);
  os.writeInt32(2, this.iStyle);
  os.writeString(3, this.sTitleBgColor);
  os.writeString(4, this.sContentBgColor);
  os.writeString(5, this.sTextColor);
  os.writeInt32(6, this.iLengthLimit);
  os.writeInt32(7, this.iDurationS);
  os.writeInt32(8, this.iIsShow);
  os.writeInt32(9, this.iIsGuardian);
  os.writeInt32(10, this.iGuardianLevel);
  os.writeString(11, this.sTitleBgColorDark);
  os.writeString(12, this.sContentBgColorDark);
  os.writeString(13, this.sTextColorDark);
  os.writeString(14, this.sPreviewColor);
  os.writeString(15, this.sHorizontalTitleBgColor);
  os.writeString(16, this.sHorizontalTitleBgColorDark);
  os.writeString(17, this.sHorizontalContentBgColor);
  os.writeString(18, this.sHorizontalContentBgColorDark);
  os.writeString(19, this.sFloatBackGroundColor);
  os.writeString(20, this.sFloatFrameColor);
  os.writeString(21, this.sGuardianIconUrl);
  os.writeString(22, this.sCornerPreviewIconUrl);
  os.writeString(23, this.sCornerBackgroundUrl);
  os.writeString(24, this.sCornerIconUrl);
};
PayChatOption.prototype.readFrom = function (is) {
  this.iType = is.readInt32(0, false, this.iType);
  this.iCount = is.readInt32(1, false, this.iCount);
  this.iStyle = is.readInt32(2, false, this.iStyle);
  this.sTitleBgColor = is.readString(3, false, this.sTitleBgColor);
  this.sContentBgColor = is.readString(4, false, this.sContentBgColor);
  this.sTextColor = is.readString(5, false, this.sTextColor);
  this.iLengthLimit = is.readInt32(6, false, this.iLengthLimit);
  this.iDurationS = is.readInt32(7, false, this.iDurationS);
  this.iIsShow = is.readInt32(8, false, this.iIsShow);
  this.iIsGuardian = is.readInt32(9, false, this.iIsGuardian);
  this.iGuardianLevel = is.readInt32(10, false, this.iGuardianLevel);
  this.sTitleBgColorDark = is.readString(11, false, this.sTitleBgColorDark);
  this.sContentBgColorDark = is.readString(12, false, this.sContentBgColorDark);
  this.sTextColorDark = is.readString(13, false, this.sTextColorDark);
  this.sPreviewColor = is.readString(14, false, this.sPreviewColor);
  this.sHorizontalTitleBgColor = is.readString(15, false, this.sHorizontalTitleBgColor);
  this.sHorizontalTitleBgColorDark = is.readString(16, false, this.sHorizontalTitleBgColorDark);
  this.sHorizontalContentBgColor = is.readString(17, false, this.sHorizontalContentBgColor);
  this.sHorizontalContentBgColorDark = is.readString(18, false, this.sHorizontalContentBgColorDark);
  this.sFloatBackGroundColor = is.readString(19, false, this.sFloatBackGroundColor);
  this.sFloatFrameColor = is.readString(20, false, this.sFloatFrameColor);
  this.sGuardianIconUrl = is.readString(21, false, this.sGuardianIconUrl);
  this.sCornerPreviewIconUrl = is.readString(22, false, this.sCornerPreviewIconUrl);
  this.sCornerBackgroundUrl = is.readString(23, false, this.sCornerBackgroundUrl);
  this.sCornerIconUrl = is.readString(24, false, this.sCornerIconUrl);
};
Nimo.PayChatOption = PayChatOption;
export default PayChatOption;