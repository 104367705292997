import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var EventIntroConfig = function EventIntroConfig() {
  this.componentType = 0;
  this.eventPic = "";
  this.eventName = "";
  this.componentTitle = "";
  this.eventDigest = "";
  this.isDefault = 0;
};
EventIntroConfig.prototype._clone = function () {
  return new NimoBuss.EventIntroConfig();
};
EventIntroConfig.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
EventIntroConfig.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
EventIntroConfig.prototype.writeTo = function (os) {
  os.writeInt32(0, this.componentType);
  os.writeString(1, this.eventPic);
  os.writeString(2, this.eventName);
  os.writeString(3, this.componentTitle);
  os.writeString(4, this.eventDigest);
  os.writeInt32(5, this.isDefault);
};
EventIntroConfig.prototype.readFrom = function (is) {
  this.componentType = is.readInt32(0, false, this.componentType);
  this.eventPic = is.readString(1, false, this.eventPic);
  this.eventName = is.readString(2, false, this.eventName);
  this.componentTitle = is.readString(3, false, this.componentTitle);
  this.eventDigest = is.readString(4, false, this.eventDigest);
  this.isDefault = is.readInt32(5, false, this.isDefault);
};
NimoBuss.EventIntroConfig = EventIntroConfig;
export default EventIntroConfig;