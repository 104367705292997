import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var GetHuyaSdkTokenRsp = function GetHuyaSdkTokenRsp() {
  this.sHuyaToken = "";
  this.iHuyaTokenTime = 0;
  this.iCode = 0;
  this.sMessage = "";
};
GetHuyaSdkTokenRsp.prototype._clone = function () {
  return new Nimo.GetHuyaSdkTokenRsp();
};
GetHuyaSdkTokenRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetHuyaSdkTokenRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetHuyaSdkTokenRsp.prototype.writeTo = function (os) {
  os.writeString(0, this.sHuyaToken);
  os.writeInt32(1, this.iHuyaTokenTime);
  os.writeInt32(2, this.iCode);
  os.writeString(3, this.sMessage);
};
GetHuyaSdkTokenRsp.prototype.readFrom = function (is) {
  this.sHuyaToken = is.readString(0, false, this.sHuyaToken);
  this.iHuyaTokenTime = is.readInt32(1, false, this.iHuyaTokenTime);
  this.iCode = is.readInt32(2, false, this.iCode);
  this.sMessage = is.readString(3, false, this.sMessage);
};
Nimo.GetHuyaSdkTokenRsp = GetHuyaSdkTokenRsp;
export default GetHuyaSdkTokenRsp;