// 映射关系详见：https://docs.google.com/spreadsheets/d/1rzDsR404QbL1fhJyLNcqO_SL-18iJkbc1LbDDufvUPY/edit#gid=1975616580
export var SUBSCRIBE_FROM_MAP = {
  COMMON: 'common',
  TIPS_1: 'tips1',
  TIPS_2: 'tips2',
  FULLSCREEN: 'fullscreen',
  BULLET_1: 'bullet1',
  BULLET_2: 'bullet2',
  NO_LIVE: 'nolive',
  MATCH_NO_LIVE: 'matchnolive',
  FOLLOWERONLY_POPUP: 'followeronly_popup'
};