import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var TeamIntroductionView = function TeamIntroductionView() {
  this.id = 0;
  this.name = "";
  this.logo = "";
  this.badge = "";
  this.description = "";
};
TeamIntroductionView.prototype._clone = function () {
  return new NimoBuss.TeamIntroductionView();
};
TeamIntroductionView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
TeamIntroductionView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
TeamIntroductionView.prototype.writeTo = function (os) {
  os.writeInt32(0, this.id);
  os.writeString(1, this.name);
  os.writeString(2, this.logo);
  os.writeString(3, this.badge);
  os.writeString(4, this.description);
};
TeamIntroductionView.prototype.readFrom = function (is) {
  this.id = is.readInt32(0, false, this.id);
  this.name = is.readString(1, false, this.name);
  this.logo = is.readString(2, false, this.logo);
  this.badge = is.readString(3, false, this.badge);
  this.description = is.readString(4, false, this.description);
};
NimoBuss.TeamIntroductionView = TeamIntroductionView;
export default TeamIntroductionView;