import template from "lodash/template";

// node 环境下执行的
export default function () {
  var templateStr = require("./template.html");
  var complied = template(templateStr);
  var html = complied({
    DOMAINS: {
      maindomain: DOMAINS.maindomain
    },
    year: new Date().getFullYear()
  });
  return html;
}