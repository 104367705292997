import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var Team = function Team() {
  this.id = 0;
  this.name = "";
  this.logo = "";
  this.badge = "";
  this.score = 0;
  this.createTime = 0;
  this.updateTime = 0;
  this.introduction = "";
  this.introductionLang = "";
};
Team.prototype._clone = function () {
  return new NimoBuss.Team();
};
Team.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
Team.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
Team.prototype.writeTo = function (os) {
  os.writeInt32(0, this.id);
  os.writeString(1, this.name);
  os.writeString(2, this.logo);
  os.writeString(3, this.badge);
  os.writeInt32(4, this.score);
  os.writeInt64(5, this.createTime);
  os.writeInt64(6, this.updateTime);
  os.writeString(7, this.introduction);
  os.writeString(8, this.introductionLang);
};
Team.prototype.readFrom = function (is) {
  this.id = is.readInt32(0, false, this.id);
  this.name = is.readString(1, false, this.name);
  this.logo = is.readString(2, false, this.logo);
  this.badge = is.readString(3, false, this.badge);
  this.score = is.readInt32(4, false, this.score);
  this.createTime = is.readInt64(5, false, this.createTime);
  this.updateTime = is.readInt64(6, false, this.updateTime);
  this.introduction = is.readString(7, false, this.introduction);
  this.introductionLang = is.readString(8, false, this.introductionLang);
};
NimoBuss.Team = Team;
export default Team;