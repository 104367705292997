import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './CdnLineInfo';
import './CodeLine';
import './CodeLine2';
var RoomLineInfo = function RoomLineInfo() {
  this.lRoomId = 0;
  this.mLineInfo = new Taf.Map(new Taf.INT32(), new Taf.Vector(new Nimo.CdnLineInfo()));
  this.iRecommendCode = 0;
  this.vCodeLines = new Taf.Vector(new Nimo.CodeLine());
  this.vCodeLines2 = new Taf.Vector(new Nimo.CodeLine2());
  this.bIfLiveInter = true;
  this.iBusinessType = 0;
  this.iWidth = 0;
  this.iHeight = 0;
  this.lUid = 0;
  this.iTemplateType = 0;
  this.iMaskSeconds = 0;
  this.dScale = 0;
  this.iMuteSeconds = 0;
};
RoomLineInfo.prototype._clone = function () {
  return new Nimo.RoomLineInfo();
};
RoomLineInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
RoomLineInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
RoomLineInfo.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
  os.writeMap(1, this.mLineInfo);
  os.writeInt32(2, this.iRecommendCode);
  os.writeVector(3, this.vCodeLines);
  os.writeVector(4, this.vCodeLines2);
  os.writeBoolean(5, this.bIfLiveInter);
  os.writeInt32(6, this.iBusinessType);
  os.writeInt32(7, this.iWidth);
  os.writeInt32(8, this.iHeight);
  os.writeInt64(9, this.lUid);
  os.writeInt32(10, this.iTemplateType);
  os.writeInt32(11, this.iMaskSeconds);
  os.writeDouble(12, this.dScale);
  os.writeInt32(13, this.iMuteSeconds);
};
RoomLineInfo.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
  this.mLineInfo = is.readMap(1, false, this.mLineInfo);
  this.iRecommendCode = is.readInt32(2, false, this.iRecommendCode);
  this.vCodeLines = is.readVector(3, false, this.vCodeLines);
  this.vCodeLines2 = is.readVector(4, false, this.vCodeLines2);
  this.bIfLiveInter = is.readBoolean(5, false, this.bIfLiveInter);
  this.iBusinessType = is.readInt32(6, false, this.iBusinessType);
  this.iWidth = is.readInt32(7, false, this.iWidth);
  this.iHeight = is.readInt32(8, false, this.iHeight);
  this.lUid = is.readInt64(9, false, this.lUid);
  this.iTemplateType = is.readInt32(10, false, this.iTemplateType);
  this.iMaskSeconds = is.readInt32(11, false, this.iMaskSeconds);
  this.dScale = is.readDouble(12, false, this.dScale);
  this.iMuteSeconds = is.readInt32(13, false, this.iMuteSeconds);
};
Nimo.RoomLineInfo = RoomLineInfo;
export default RoomLineInfo;