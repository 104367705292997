import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["type"];
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { SET_MEETING_INFO, SET_CONTENT_COUNTRY_LIST, SET_CONTENT_COUNTRY, SET_ROYAL_LEVEL_CONFIG, SET_POPOUT_STATE, SET_SELF_ROOM_LINK, UPDATE_LANGPKG } from './actions';
var initialState = {
  cCountry: '',
  popout: {},
  langPkg: {}
};

// 通用合并state的action type
var commonMergeTypes = [SET_MEETING_INFO, SET_CONTENT_COUNTRY_LIST, SET_CONTENT_COUNTRY, SET_ROYAL_LEVEL_CONFIG, SET_SELF_ROOM_LINK];
function commonReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type,
    payload = _objectWithoutProperties(action, _excluded);
  if (commonMergeTypes.indexOf(type) > -1) {
    return _objectSpread(_objectSpread({}, state), payload);
  }
  switch (type) {
    case SET_POPOUT_STATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          popout: _objectSpread(_objectSpread({}, state.popout), {}, _defineProperty({}, payload.key, payload.value))
        });
      }
    case UPDATE_LANGPKG:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          langPkg: _objectSpread(_objectSpread({}, state.langPkg), {}, _defineProperty({}, payload.key, payload.value))
        });
      }
    default:
      return state;
  }
}
export default commonReducer;