module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="n-as-w100 n-fx1 main-container">\n  <div id="head-bg-wrap">\n    <div class="n-as-mrgb-lg n-as-rel">\n      <div class="n-as-abs n-as-tl n-as-w100 bc1 n-as-op80" style="height: 100%"></div>\n      <div class="n-as-conf n-as-padt-lg n-fx-ss n-fx-col n-as-rel">\n        <div class="n-fx1 n-as-mrgh-md n-as-padt-md">\n          <div class="n-as-w10 n-sk  n-as-mrgb" style="height:46px;width:300px"></div>\n          <div class="n-as-w50  n-sk  n-as-mrgb"  style="height:60px;width:680px"></div>\n          <div class="n-as-w10 n-sk  n-as-mrgb" style="width:74px;height:18px;margin-bottom:80px"></div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div id="container" class="n-as-conf n-as-mrgb-lg">\n    <ul class="n-fx-grid-300">\n      ';
 for(var i = 0; i < 24; i++) { ;
__p += '\n        <li class="n-as-mrgb-md">\n          ' +
((__t = ( require('ejs-loader!@desktop/src/common/components/RoomCard/skeleton.ejs')() )) == null ? '' : __t) +
'\n        </li>\n      ';
 } ;
__p += '\n    </ul>\n  </div>\n</div>\n';

}
return __p
}