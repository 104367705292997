import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { setUserIdWithAlias } from './wup';
import readFromObject from '@client/common/utils/readFromObject';

/**
 * @param {import('@client/jce/NimoBuss/BaseParam').default} baseParam
 * @param {*} overridedInfo
 */
export function setBaseParam(baseParam) {
  var overridedInfo = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  readFromObject(baseParam, _objectSpread({
    requestSource: 'WEB',
    deviceType: 7,
    apiVersionCode: 'v1'
  }, overridedInfo));
  setUserIdWithAlias(baseParam.userId, overridedInfo === null || overridedInfo === void 0 ? void 0 : overridedInfo.userId);
}