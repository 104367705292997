export function getSentry() {
  return new Promise(function (resolve) {
    if (window.Sentry) {
      resolve(window.Sentry);
    } else {
      var script = document.getElementById('nimo-sentry-script');
      if (script) {
        var onload = function onload() {
          script.removeEventListener('load', onload);
          script.removeEventListener('error', onload);
          if (window.Sentry) {
            resolve(window.Sentry);
          } else {
            resolve();
          }
        };
        script.addEventListener('load', onload);
        script.addEventListener('error', onload);
      } else {
        resolve();
      }
    }
  });
}