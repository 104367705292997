import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var GiftChannel = function GiftChannel() {
  this.iChannelType = 0;
  this.vChannelData = new Taf.Vector(new Taf.STRING());
};
GiftChannel.prototype._clone = function () {
  return new Nimo.GiftChannel();
};
GiftChannel.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GiftChannel.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GiftChannel.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iChannelType);
  os.writeVector(1, this.vChannelData);
};
GiftChannel.prototype.readFrom = function (is) {
  this.iChannelType = is.readInt32(0, false, this.iChannelType);
  this.vChannelData = is.readVector(1, false, this.vChannelData);
};
Nimo.GiftChannel = GiftChannel;
export default GiftChannel;