import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var LotterySwitchView = function LotterySwitchView() {
  this.activityId = 0;
  this.status = 0;
  this.directUrl = "";
  this.orderBatchId = "";
};
LotterySwitchView.prototype._clone = function () {
  return new NimoBuss.LotterySwitchView();
};
LotterySwitchView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LotterySwitchView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LotterySwitchView.prototype.writeTo = function (os) {
  os.writeInt32(0, this.activityId);
  os.writeInt32(1, this.status);
  os.writeString(2, this.directUrl);
  os.writeString(3, this.orderBatchId);
};
LotterySwitchView.prototype.readFrom = function (is) {
  this.activityId = is.readInt32(0, false, this.activityId);
  this.status = is.readInt32(1, false, this.status);
  this.directUrl = is.readString(2, false, this.directUrl);
  this.orderBatchId = is.readString(3, false, this.orderBatchId);
};
NimoBuss.LotterySwitchView = LotterySwitchView;
export default LotterySwitchView;