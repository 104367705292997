import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var CdnLineInfo = function CdnLineInfo() {
  this.iCdnType = 0;
  this.iBitRate = 0;
  this.sFlvUrl = "";
  this.sHlsUrl = "";
  this.sRtmpUrl = "";
  this.iNameCode = 0;
};
CdnLineInfo.prototype._clone = function () {
  return new Nimo.CdnLineInfo();
};
CdnLineInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
CdnLineInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
CdnLineInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCdnType);
  os.writeInt32(1, this.iBitRate);
  os.writeString(2, this.sFlvUrl);
  os.writeString(3, this.sHlsUrl);
  os.writeString(4, this.sRtmpUrl);
  os.writeInt32(5, this.iNameCode);
};
CdnLineInfo.prototype.readFrom = function (is) {
  this.iCdnType = is.readInt32(0, false, this.iCdnType);
  this.iBitRate = is.readInt32(1, false, this.iBitRate);
  this.sFlvUrl = is.readString(2, false, this.sFlvUrl);
  this.sHlsUrl = is.readString(3, false, this.sHlsUrl);
  this.sRtmpUrl = is.readString(4, false, this.sRtmpUrl);
  this.iNameCode = is.readInt32(5, false, this.iNameCode);
};
Nimo.CdnLineInfo = CdnLineInfo;
export default CdnLineInfo;