import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var VisitHistoryInfoParam = function VisitHistoryInfoParam() {
  this.udbUserId = 0;
  this.language = "";
  this.startVisit = 0;
  this.endVisit = 0;
  this.sUA = "";
  this.countryCode = "";
};
VisitHistoryInfoParam.prototype._clone = function () {
  return new NimoBuss.VisitHistoryInfoParam();
};
VisitHistoryInfoParam.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
VisitHistoryInfoParam.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
VisitHistoryInfoParam.prototype.writeTo = function (os) {
  os.writeInt64(0, this.udbUserId);
  os.writeString(1, this.language);
  os.writeInt32(2, this.startVisit);
  os.writeInt32(3, this.endVisit);
  os.writeString(4, this.sUA);
  os.writeString(5, this.countryCode);
};
VisitHistoryInfoParam.prototype.readFrom = function (is) {
  this.udbUserId = is.readInt64(0, false, this.udbUserId);
  this.language = is.readString(1, false, this.language);
  this.startVisit = is.readInt32(2, false, this.startVisit);
  this.endVisit = is.readInt32(3, false, this.endVisit);
  this.sUA = is.readString(4, false, this.sUA);
  this.countryCode = is.readString(5, false, this.countryCode);
};
NimoBuss.VisitHistoryInfoParam = VisitHistoryInfoParam;
export default VisitHistoryInfoParam;