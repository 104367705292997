import { atom, useAtom, useAtomValue } from 'jotai';
import { getMessageSettings, getDefaultSettings, setMessageSettings } from './settings';
import { useMemo } from 'react';
import { selectAtom } from 'jotai/utils';
import { useSelector } from 'react-redux';
var atomsByUid = {};

/** @typedef {ReturnType<typeof getDefaultSettings>} StreamerChatSetting  */

var getAtomByUid = function getAtomByUid(uid, isStreamerSelf) {
  var key = uid && isStreamerSelf ? uid : 'default';
  if (!atomsByUid[key]) {
    var streamerState = uid && isStreamerSelf && getMessageSettings(uid);
    /** @type {StreamerChatSetting} */
    var initalState = streamerState || getDefaultSettings();
    var baseAtom = atom(initalState);
    baseAtom.debugLabel = "baseAtom";
    var finalAtom = streamerState ? atom(function (get) {
      return get(baseAtom);
    }, function (get, set, update) {
      var nextValue = typeof update === 'function' ? update(get(baseAtom)) : update;
      set(baseAtom, nextValue);
      setMessageSettings(uid, nextValue);
    }) : baseAtom;
    atomsByUid[key] = finalAtom;
  }
  /** @type {import('jotai').PrimitiveAtom<StreamerChatSetting>} */
  var ret = atomsByUid[key];
  return ret;
};
var getIsInSelfRoom = function getIsInSelfRoom(state) {
  var _ref = (state.home || state).room || {},
    roomInfo = _ref.roomInfo,
    userInfo = _ref.userInfo;
  var isInSelfRoom = false;
  var uid = 0;
  if (roomInfo && userInfo && roomInfo.auId) {
    isInSelfRoom = userInfo.uid === roomInfo.auId;
    uid = roomInfo.auId;
  }
  return {
    isInSelfRoom: isInSelfRoom,
    uid: uid
  };
};
export var useIsInSelfRoom = function useIsInSelfRoom() {
  return useSelector(getIsInSelfRoom);
};

/**
 * @param {string | number} uid
 * @param {boolean} isStreamerSelf
 */
export var useStreamerChatSettings = function useStreamerChatSettings() {
  var _useIsInSelfRoom = useIsInSelfRoom(),
    isInSelfRoom = _useIsInSelfRoom.isInSelfRoom,
    uid = _useIsInSelfRoom.uid;
  var streamerChatSettingAtom = getAtomByUid(uid, isInSelfRoom);
  return useAtom(streamerChatSettingAtom);
};

/**
 * @param {keyof StreamerChatSetting} key
 */
export var useStreamerChatSettingsSelector = function useStreamerChatSettingsSelector(key) {
  var _useIsInSelfRoom2 = useIsInSelfRoom(),
    isInSelfRoom = _useIsInSelfRoom2.isInSelfRoom,
    uid = _useIsInSelfRoom2.uid;
  var streamerChatSettingAtom = getAtomByUid(uid, isInSelfRoom);
  var selected = useMemo(function () {
    return selectAtom(streamerChatSettingAtom, function (state) {
      return state[key];
    });
  }, [key, streamerChatSettingAtom]);
  return useAtomValue(selected);
};