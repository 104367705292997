import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var EventModel = function EventModel() {
  this.id = 0;
  this.title = "";
  this.logo = "";
  this.cover = "";
  this.gameId = 0;
  this.lcid = 0;
  this.status = 0;
  this.countryCode = "";
  this.createTime = "";
  this.updateTime = "";
  this.name = "";
  this.titleLang = "";
  this.introduction = "";
  this.introductionLang = "";
  this.advanceStyle = 0;
  this.advanceImg = "";
  this.openAdvance = 0;
  this.eventAbbreviation = "";
};
EventModel.prototype._clone = function () {
  return new NimoBuss.EventModel();
};
EventModel.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
EventModel.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
EventModel.prototype.writeTo = function (os) {
  os.writeInt64(0, this.id);
  os.writeString(1, this.title);
  os.writeString(2, this.logo);
  os.writeString(3, this.cover);
  os.writeInt32(4, this.gameId);
  os.writeInt32(5, this.lcid);
  os.writeInt32(6, this.status);
  os.writeString(7, this.countryCode);
  os.writeString(8, this.createTime);
  os.writeString(9, this.updateTime);
  os.writeString(10, this.name);
  os.writeString(11, this.titleLang);
  os.writeString(12, this.introduction);
  os.writeString(13, this.introductionLang);
  os.writeInt32(14, this.advanceStyle);
  os.writeString(15, this.advanceImg);
  os.writeInt32(16, this.openAdvance);
  os.writeString(17, this.eventAbbreviation);
};
EventModel.prototype.readFrom = function (is) {
  this.id = is.readInt64(0, false, this.id);
  this.title = is.readString(1, false, this.title);
  this.logo = is.readString(2, false, this.logo);
  this.cover = is.readString(3, false, this.cover);
  this.gameId = is.readInt32(4, false, this.gameId);
  this.lcid = is.readInt32(5, false, this.lcid);
  this.status = is.readInt32(6, false, this.status);
  this.countryCode = is.readString(7, false, this.countryCode);
  this.createTime = is.readString(8, false, this.createTime);
  this.updateTime = is.readString(9, false, this.updateTime);
  this.name = is.readString(10, false, this.name);
  this.titleLang = is.readString(11, false, this.titleLang);
  this.introduction = is.readString(12, false, this.introduction);
  this.introductionLang = is.readString(13, false, this.introductionLang);
  this.advanceStyle = is.readInt32(14, false, this.advanceStyle);
  this.advanceImg = is.readString(15, false, this.advanceImg);
  this.openAdvance = is.readInt32(16, false, this.openAdvance);
  this.eventAbbreviation = is.readString(17, false, this.eventAbbreviation);
};
NimoBuss.EventModel = EventModel;
export default EventModel;