import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './CdnUrlInfo';
var CodeLineInfo2 = function CodeLineInfo2() {
  this.iCdnType = 0;
  this.vCdnUrls = new Taf.Vector(new Nimo.CdnUrlInfo());
  this.iCdnTypeHY = 0;
};
CodeLineInfo2.prototype._clone = function () {
  return new Nimo.CodeLineInfo2();
};
CodeLineInfo2.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
CodeLineInfo2.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
CodeLineInfo2.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCdnType);
  os.writeVector(1, this.vCdnUrls);
  os.writeInt32(2, this.iCdnTypeHY);
};
CodeLineInfo2.prototype.readFrom = function (is) {
  this.iCdnType = is.readInt32(0, false, this.iCdnType);
  this.vCdnUrls = is.readVector(1, false, this.vCdnUrls);
  this.iCdnTypeHY = is.readInt32(2, false, this.iCdnTypeHY);
};
Nimo.CodeLineInfo2 = CodeLineInfo2;
export default CodeLineInfo2;