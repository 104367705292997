import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BaseParam';
var MatchModuleReq = function MatchModuleReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.eventId = 0;
  this.roomId = 0;
  this.requestType = "";
  this.lcid = 0;
  this.language = "";
  this.countryCode = "";
};
MatchModuleReq.prototype._clone = function () {
  return new NimoBuss.MatchModuleReq();
};
MatchModuleReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MatchModuleReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MatchModuleReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeInt64(1, this.eventId);
  os.writeInt64(2, this.roomId);
  os.writeString(3, this.requestType);
  os.writeInt32(4, this.lcid);
  os.writeString(5, this.language);
  os.writeString(6, this.countryCode);
};
MatchModuleReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.eventId = is.readInt64(1, false, this.eventId);
  this.roomId = is.readInt64(2, false, this.roomId);
  this.requestType = is.readString(3, false, this.requestType);
  this.lcid = is.readInt32(4, false, this.lcid);
  this.language = is.readString(5, false, this.language);
  this.countryCode = is.readString(6, false, this.countryCode);
};
NimoBuss.MatchModuleReq = MatchModuleReq;
export default MatchModuleReq;