import webpushStore from '@client/common/lib/firebase/webpushStore';
import { isSameDay } from '@client/common/utils/localTime';
var NAVIGATE = 't_nav';
var PERMISSION = 't_per';
var lastNaviReport;
export var markNavigateForWebpush = function markNavigateForWebpush() {
  return webpushStore.set(NAVIGATE, Date.now());
};
export var shouldReportNavigate = function shouldReportNavigate() {
  var now = Date.now();
  return webpushStore.get(NAVIGATE).then(function (t) {
    if (lastNaviReport && isSameDay(lastNaviReport, now, 8)) return false;
    var shouldReport = !t || !isSameDay(now, t, 8);
    if (shouldReport) {
      lastNaviReport = now;
    }
    return shouldReport;
  });
};
export var shouldReportPermission = function shouldReportPermission() {
  var now = Date.now();
  return webpushStore.get(PERMISSION).then(function (t) {
    return !t || !isSameDay(now, t, 8);
  });
};
export var rememberReportPermission = function rememberReportPermission() {
  return webpushStore.set(PERMISSION, Date.now());
};