import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './RoomLineInfo';
import './GetVideoListRsp';
var GetPullInfoRsp = function GetPullInfoRsp() {
  this.code = 0;
  this.roomLine = new Nimo.RoomLineInfo();
  this.playblackvideodata = new Nimo.GetVideoListRsp();
};
GetPullInfoRsp.prototype._clone = function () {
  return new Nimo.GetPullInfoRsp();
};
GetPullInfoRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetPullInfoRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetPullInfoRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.code);
  os.writeStruct(1, this.roomLine);
  os.writeStruct(2, this.playblackvideodata);
};
GetPullInfoRsp.prototype.readFrom = function (is) {
  this.code = is.readInt32(0, false, this.code);
  this.roomLine = is.readStruct(1, false, this.roomLine);
  this.playblackvideodata = is.readStruct(2, false, this.playblackvideodata);
};
Nimo.GetPullInfoRsp = GetPullInfoRsp;
export default GetPullInfoRsp;