import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var EGiftTabType = {
  kGIFT_TAB_HOT: 1,
  kGIFT_TAB_GOLDBEAN: 2,
  kGIFT_TAB_NEW_HOT: 3,
  kGIFT_WORLD_CUP: 4,
  kGIFT_TAB_BIRTHDAY: 5,
  kGIFT_TAB_MEMORIAL_DAY: 6,
  kGIFT_TAB_LUCKY_GIFT: 7,
  kGIFT_TAB_EVENT: 8
};
Nimo.EGiftTabType = EGiftTabType;
export default EGiftTabType;