import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var VideoTurnCodeRsp = function VideoTurnCodeRsp() {
  this.sTurnCodeKey = "";
  this.iTurnCodeState = 0;
  this.sUrl = "";
};
VideoTurnCodeRsp.prototype._clone = function () {
  return new Nimo.VideoTurnCodeRsp();
};
VideoTurnCodeRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
VideoTurnCodeRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
VideoTurnCodeRsp.prototype.writeTo = function (os) {
  os.writeString(0, this.sTurnCodeKey);
  os.writeInt32(1, this.iTurnCodeState);
  os.writeString(2, this.sUrl);
};
VideoTurnCodeRsp.prototype.readFrom = function (is) {
  this.sTurnCodeKey = is.readString(0, false, this.sTurnCodeKey);
  this.iTurnCodeState = is.readInt32(1, false, this.iTurnCodeState);
  this.sUrl = is.readString(2, false, this.sUrl);
};
Nimo.VideoTurnCodeRsp = VideoTurnCodeRsp;
export default VideoTurnCodeRsp;