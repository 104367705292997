import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var ConfigListView = function ConfigListView() {
  this.configList = new Taf.Map(new Taf.STRING(), new Taf.STRING());
};
ConfigListView.prototype._clone = function () {
  return new NimoBuss.ConfigListView();
};
ConfigListView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ConfigListView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ConfigListView.prototype.writeTo = function (os) {
  os.writeMap(0, this.configList);
};
ConfigListView.prototype.readFrom = function (is) {
  this.configList = is.readMap(0, false, this.configList);
};
NimoBuss.ConfigListView = ConfigListView;
export default ConfigListView;