import { useEffect } from 'react';
import fansGroupSocketModel, { EUriFanJoinClubV2, EUriFanUpgradeClubV2, EUriFansUpgradeAward } from '@client/common/models/socket/fansGroupSocketModel.js';
import fansClubTafSocketModel, { EUriFansLevelUpPopup } from '@client/common/models/socket/fansClubTafSocketModel';
import { eventEmitter, EVENTS } from '@client/common/utils/events';
import { useDispatch, useSelector } from 'react-redux';
import { updateFanGroupInfoAsync } from '@desktop/src/common/models/home/updateFanGroupInfoAsync';
export var useFansGroupModel = function useFansGroupModel() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    onJoinFansGroup = _ref.onJoinFansGroup,
    onFansLevelUpgrade = _ref.onFansLevelUpgrade,
    onFansLevelUpdrageGiftReceived = _ref.onFansLevelUpdrageGiftReceived;
  var dispatch = useDispatch();
  var _ref2 = useSelector(function (state) {
      return state.home.room.roomInfo;
    }) || {},
    auId = _ref2.auId;
  useEffect(function () {
    dispatch(updateFanGroupInfoAsync());
  }, [dispatch]);
  useEffect(function () {
    if (!auId) return function () {};
    var handler = function handler(data) {
      var _ref3 = data || {},
        fanType = _ref3.fanType,
        anchorId = _ref3.anchorId;
      if (auId === anchorId && fanType === 1 || !fanType) {
        eventEmitter.emit(EVENTS.FANS_GROUP_BADGE_LIST_UPDATE, {
          type: 'fansJoinNew'
        });
        dispatch(updateFanGroupInfoAsync());
        if (typeof onJoinFansGroup === 'function') {
          onJoinFansGroup(data);
        }
      }
    };
    fansGroupSocketModel.on(EUriFanJoinClubV2, handler);
    return function () {
      fansGroupSocketModel.off(EUriFanJoinClubV2, handler);
    };
  }, [auId, dispatch, onJoinFansGroup]);
  useEffect(function () {
    if (!auId) return function () {};
    var handler = function handler(data) {
      var _ref4 = data || {},
        anchorId = _ref4.anchorId;
      if (anchorId === auId) {
        eventEmitter.emit(EVENTS.FANS_GROUP_BADGE_LIST_UPDATE, {
          type: 'fansLevelUp',
          data: data
        });
      }
    };
    fansGroupSocketModel.on(EUriFanUpgradeClubV2, handler);
    return function () {
      fansGroupSocketModel.off(EUriFanUpgradeClubV2, handler);
    };
  }, [auId]);
  useEffect(function () {
    if (!auId) return function () {};
    var handler = function handler() {
      var args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var anchorId = args.anchorId;
      if (anchorId === auId) {
        if (typeof onFansLevelUpgrade === 'function') onFansLevelUpgrade(args);
      }
    };
    fansClubTafSocketModel.on(EUriFansLevelUpPopup, handler);
    return function () {
      fansClubTafSocketModel.off(EUriFansLevelUpPopup, handler);
    };
  }, [auId, onFansLevelUpgrade]);
  useEffect(function () {
    if (!auId) return function () {};
    var handler = function handler() {
      var args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var anchorId = args.anchorId;
      if (anchorId === auId) {
        if (typeof onFansLevelUpdrageGiftReceived === 'function') onFansLevelUpdrageGiftReceived(args);
      }
    };
    fansClubTafSocketModel.on(EUriFansUpgradeAward, handler);
    return function () {
      fansClubTafSocketModel.off(EUriFansUpgradeAward, handler);
    };
  }, [auId, onFansLevelUpdrageGiftReceived]);
};