import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var GameAbbr = function GameAbbr() {
  this.value = "";
  this.langId = 0;
};
GameAbbr.prototype._clone = function () {
  return new NimoBuss.GameAbbr();
};
GameAbbr.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GameAbbr.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GameAbbr.prototype.writeTo = function (os) {
  os.writeString(0, this.value);
  os.writeInt32(1, this.langId);
};
GameAbbr.prototype.readFrom = function (is) {
  this.value = is.readString(0, false, this.value);
  this.langId = is.readInt32(1, false, this.langId);
};
NimoBuss.GameAbbr = GameAbbr;
export default GameAbbr;