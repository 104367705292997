import _typeof from "@babel/runtime/helpers/typeof";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _PositionValue, _SizeValue, _SpeedValue, _position, _size, _speed, _reportValueMap;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { local } from '@client/common/utils/storage';
var storeKey = 'text-bulletscreen-settings';
export var FIELD_SWITCH = 'switch';
export var FIELD_PICKME = 'pickme';
export var Value = {
  position: {
    full: '1',
    top: '2',
    bottom: '3'
  },
  size: {
    sm: '1',
    md: '2',
    lg: '3'
  },
  speed: {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5
  }
};
export var PositionValue = (_PositionValue = {}, _defineProperty(_PositionValue, Value.position.full, {
  start: 0,
  end: 1
}), _defineProperty(_PositionValue, Value.position.top, {
  start: 0,
  end: 0.333
}), _defineProperty(_PositionValue, Value.position.bottom, {
  start: 0.667,
  end: 1
}), _PositionValue);
export var SizeValue = (_SizeValue = {}, _defineProperty(_SizeValue, Value.size.sm, 16), _defineProperty(_SizeValue, Value.size.md, 25), _defineProperty(_SizeValue, Value.size.lg, 36), _SizeValue);
export var SpeedValue = (_SpeedValue = {}, _defineProperty(_SpeedValue, Value.speed.xs, 20000), _defineProperty(_SpeedValue, Value.speed.sm, 13333), _defineProperty(_SpeedValue, Value.speed.md, 10000), _defineProperty(_SpeedValue, Value.speed.lg, 8000), _defineProperty(_SpeedValue, Value.speed.xl, 6666), _SpeedValue);
export var reportValueMap = (_reportValueMap = {
  position: (_position = {}, _defineProperty(_position, Value.position.full, "full"), _defineProperty(_position, Value.position.top, "top"), _defineProperty(_position, Value.position.bottom, "bottom"), _position),
  size: (_size = {}, _defineProperty(_size, Value.size.sm, 'min'), _defineProperty(_size, Value.size.md, 'mid'), _defineProperty(_size, Value.size.lg, 'max'), _size),
  speed: (_speed = {}, _defineProperty(_speed, Value.speed.xs, '50%'), _defineProperty(_speed, Value.speed.sm, '75%'), _defineProperty(_speed, Value.speed.md, '100%'), _defineProperty(_speed, Value.speed.lg, '125%'), _defineProperty(_speed, Value.speed.xl, '150%'), _speed)
}, _defineProperty(_reportValueMap, FIELD_PICKME, {
  'true': '1',
  'false': '0'
}), _defineProperty(_reportValueMap, FIELD_SWITCH, {
  'true': '1',
  'false': '0'
}), _reportValueMap);
function getDefaultSettings() {
  return _defineProperty({
    position: Value.position.full,
    size: Value.size.md,
    speed: Value.speed.md,
    opacity: 70
  }, FIELD_SWITCH, undefined);
}
export function getSettings() {
  var rawdata = local.get(storeKey);
  if (!rawdata) return getDefaultSettings();
  var settings;
  try {
    settings = JSON.parse(rawdata);
  } catch (_) {
    // ignore
  }
  settings = settings ? _objectSpread(_objectSpread({}, getDefaultSettings()), settings) : getDefaultSettings();
  return settings;
}
export function setSettings(settings) {
  if (_typeof(settings) === 'object' && settings !== null) {
    local.set(storeKey, JSON.stringify(settings));
  }
}