import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var DOWN_PACKET = function DOWN_PACKET() {
  this.iUri = 0;
  this.sMsg = new Taf.BinBuffer();
};
DOWN_PACKET.prototype._clone = function () {
  return new Nimo.DOWN_PACKET();
};
DOWN_PACKET.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
DOWN_PACKET.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
DOWN_PACKET.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iUri);
  os.writeBytes(1, this.sMsg);
};
DOWN_PACKET.prototype.readFrom = function (is) {
  this.iUri = is.readInt32(0, false, this.iUri);
  this.sMsg = is.readBytes(1, false, this.sMsg);
};
Nimo.DOWN_PACKET = DOWN_PACKET;
export default DOWN_PACKET;