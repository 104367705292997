import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var WorldLangEntity = function WorldLangEntity() {
  this.lcid = 0;
  this.name = "";
  this.code = "";
  this.value = "";
};
WorldLangEntity.prototype._clone = function () {
  return new NimoBuss.WorldLangEntity();
};
WorldLangEntity.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
WorldLangEntity.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
WorldLangEntity.prototype.writeTo = function (os) {
  os.writeInt32(0, this.lcid);
  os.writeString(1, this.name);
  os.writeString(2, this.code);
  os.writeString(3, this.value);
};
WorldLangEntity.prototype.readFrom = function (is) {
  this.lcid = is.readInt32(0, false, this.lcid);
  this.name = is.readString(1, false, this.name);
  this.code = is.readString(2, false, this.code);
  this.value = is.readString(3, false, this.value);
};
NimoBuss.WorldLangEntity = WorldLangEntity;
export default WorldLangEntity;