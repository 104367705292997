import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BaseParam';
var GetFanGroupRelationAndTickeReq = function GetFanGroupRelationAndTickeReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.anchorId = 0;
};
GetFanGroupRelationAndTickeReq.prototype._clone = function () {
  return new NimoBuss.GetFanGroupRelationAndTickeReq();
};
GetFanGroupRelationAndTickeReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetFanGroupRelationAndTickeReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetFanGroupRelationAndTickeReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeInt64(1, this.anchorId);
};
GetFanGroupRelationAndTickeReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.anchorId = is.readInt64(1, false, this.anchorId);
};
NimoBuss.GetFanGroupRelationAndTickeReq = GetFanGroupRelationAndTickeReq;
export default GetFanGroupRelationAndTickeReq;