import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BadgeInfoView';
var GetBadgeInfoListRsp = function GetBadgeInfoListRsp() {
  this.totalCount = 0;
  this.badgeCount = 0;
  this.wearBadgeInfo = new NimoBuss.BadgeInfoView();
  this.badgeList = new Taf.Vector(new NimoBuss.BadgeInfoView());
  this.ticketGiftId = "";
  this.fanGift = "";
  this.currentRoomBadgeStatus = 0;
};
GetBadgeInfoListRsp.prototype._clone = function () {
  return new NimoBuss.GetBadgeInfoListRsp();
};
GetBadgeInfoListRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetBadgeInfoListRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetBadgeInfoListRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.totalCount);
  os.writeInt32(1, this.badgeCount);
  os.writeStruct(2, this.wearBadgeInfo);
  os.writeVector(3, this.badgeList);
  os.writeString(4, this.ticketGiftId);
  os.writeString(5, this.fanGift);
  os.writeInt32(6, this.currentRoomBadgeStatus);
};
GetBadgeInfoListRsp.prototype.readFrom = function (is) {
  this.totalCount = is.readInt32(0, false, this.totalCount);
  this.badgeCount = is.readInt32(1, false, this.badgeCount);
  this.wearBadgeInfo = is.readStruct(2, false, this.wearBadgeInfo);
  this.badgeList = is.readVector(3, false, this.badgeList);
  this.ticketGiftId = is.readString(4, false, this.ticketGiftId);
  this.fanGift = is.readString(5, false, this.fanGift);
  this.currentRoomBadgeStatus = is.readInt32(6, false, this.currentRoomBadgeStatus);
};
NimoBuss.GetBadgeInfoListRsp = GetBadgeInfoListRsp;
export default GetBadgeInfoListRsp;