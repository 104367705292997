import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var FanCard = function FanCard() {
  this.badgeName = "";
  this.level = 0;
  this.badgeIcon = "";
  this.leftBadgeIcon = "";
  this.leftBadgeIconV2 = "";
};
FanCard.prototype._clone = function () {
  return new Nimo.FanCard();
};
FanCard.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FanCard.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FanCard.prototype.writeTo = function (os) {
  os.writeString(0, this.badgeName);
  os.writeInt32(1, this.level);
  os.writeString(2, this.badgeIcon);
  os.writeString(3, this.leftBadgeIcon);
  os.writeString(4, this.leftBadgeIconV2);
};
FanCard.prototype.readFrom = function (is) {
  this.badgeName = is.readString(0, false, this.badgeName);
  this.level = is.readInt32(1, false, this.level);
  this.badgeIcon = is.readString(2, false, this.badgeIcon);
  this.leftBadgeIcon = is.readString(3, false, this.leftBadgeIcon);
  this.leftBadgeIconV2 = is.readString(4, false, this.leftBadgeIconV2);
};
Nimo.FanCard = FanCard;
export default FanCard;