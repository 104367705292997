import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var GiftContributeUserInfo = function GiftContributeUserInfo() {
  this.lUID = 0;
  this.sAvatarUrl = "";
  this.sNickName = "";
};
GiftContributeUserInfo.prototype._clone = function () {
  return new Nimo.GiftContributeUserInfo();
};
GiftContributeUserInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GiftContributeUserInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GiftContributeUserInfo.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUID);
  os.writeString(1, this.sAvatarUrl);
  os.writeString(2, this.sNickName);
};
GiftContributeUserInfo.prototype.readFrom = function (is) {
  this.lUID = is.readInt64(0, false, this.lUID);
  this.sAvatarUrl = is.readString(1, false, this.sAvatarUrl);
  this.sNickName = is.readString(2, false, this.sNickName);
};
Nimo.GiftContributeUserInfo = GiftContributeUserInfo;
export default GiftContributeUserInfo;