import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './LiveRoomInfo';
import './GetVideoListRsp';
var GetLiveRoomInfoRsp = function GetLiveRoomInfoRsp() {
  this.lRoomId = 0;
  this.tLiveRoomInfo = new Nimo.LiveRoomInfo();
  this.tVideoListRsp = new Nimo.GetVideoListRsp();
  this.iCode = 0;
  this.sErrMsg = "";
};
GetLiveRoomInfoRsp.prototype._clone = function () {
  return new Nimo.GetLiveRoomInfoRsp();
};
GetLiveRoomInfoRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetLiveRoomInfoRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetLiveRoomInfoRsp.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
  os.writeStruct(1, this.tLiveRoomInfo);
  os.writeStruct(2, this.tVideoListRsp);
  os.writeInt32(3, this.iCode);
  os.writeString(4, this.sErrMsg);
};
GetLiveRoomInfoRsp.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
  this.tLiveRoomInfo = is.readStruct(1, false, this.tLiveRoomInfo);
  this.tVideoListRsp = is.readStruct(2, false, this.tVideoListRsp);
  this.iCode = is.readInt32(3, false, this.iCode);
  this.sErrMsg = is.readString(4, false, this.sErrMsg);
};
Nimo.GetLiveRoomInfoRsp = GetLiveRoomInfoRsp;
export default GetLiveRoomInfoRsp;