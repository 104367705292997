import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BaseParam';
var LanguageSettingReq = function LanguageSettingReq() {
  this.baseParam = new NimoBuss.BaseParam();
};
LanguageSettingReq.prototype._clone = function () {
  return new NimoBuss.LanguageSettingReq();
};
LanguageSettingReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LanguageSettingReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LanguageSettingReq.prototype.writeTo = function (os) {
  os.writeStruct(1, this.baseParam);
};
LanguageSettingReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(1, false, this.baseParam);
};
NimoBuss.LanguageSettingReq = LanguageSettingReq;
export default LanguageSettingReq;