import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var PageControlView = function PageControlView() {
  this.count = 0;
  this.pageSize = 20;
  this.pageCount = 0;
  this.pageIndex = 1;
  this.pageStep = 4;
};
PageControlView.prototype._clone = function () {
  return new NimoBuss.PageControlView();
};
PageControlView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
PageControlView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
PageControlView.prototype.writeTo = function (os) {
  os.writeInt32(0, this.count);
  os.writeInt32(1, this.pageSize);
  os.writeInt32(2, this.pageCount);
  os.writeInt32(3, this.pageIndex);
  os.writeInt32(4, this.pageStep);
};
PageControlView.prototype.readFrom = function (is) {
  this.count = is.readInt32(0, false, this.count);
  this.pageSize = is.readInt32(1, false, this.pageSize);
  this.pageCount = is.readInt32(2, false, this.pageCount);
  this.pageIndex = is.readInt32(3, false, this.pageIndex);
  this.pageStep = is.readInt32(4, false, this.pageStep);
};
NimoBuss.PageControlView = PageControlView;
export default PageControlView;