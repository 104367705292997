import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './PayChatRichTextItem';
var PayChatRecord = function PayChatRecord() {
  this.lAnchorId = 0;
  this.lUid = 0;
  this.sNickName = "";
  this.sAvatarUrl = "";
  this.iType = 0;
  this.sContent = "";
  this.lSendTime = 0;
  this.iTimeLeftS = 0;
  this.mExtraData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.iSource = 0;
  this.tRichTextItem = new Nimo.PayChatRichTextItem();
  this.iRuleType = 0;
};
PayChatRecord.prototype._clone = function () {
  return new Nimo.PayChatRecord();
};
PayChatRecord.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
PayChatRecord.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
PayChatRecord.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lAnchorId);
  os.writeInt64(1, this.lUid);
  os.writeString(2, this.sNickName);
  os.writeString(3, this.sAvatarUrl);
  os.writeInt32(4, this.iType);
  os.writeString(5, this.sContent);
  os.writeInt64(6, this.lSendTime);
  os.writeInt32(7, this.iTimeLeftS);
  os.writeMap(8, this.mExtraData);
  os.writeInt32(9, this.iSource);
  os.writeStruct(10, this.tRichTextItem);
  os.writeInt32(11, this.iRuleType);
};
PayChatRecord.prototype.readFrom = function (is) {
  this.lAnchorId = is.readInt64(0, false, this.lAnchorId);
  this.lUid = is.readInt64(1, false, this.lUid);
  this.sNickName = is.readString(2, false, this.sNickName);
  this.sAvatarUrl = is.readString(3, false, this.sAvatarUrl);
  this.iType = is.readInt32(4, false, this.iType);
  this.sContent = is.readString(5, false, this.sContent);
  this.lSendTime = is.readInt64(6, false, this.lSendTime);
  this.iTimeLeftS = is.readInt32(7, false, this.iTimeLeftS);
  this.mExtraData = is.readMap(8, false, this.mExtraData);
  this.iSource = is.readInt32(9, false, this.iSource);
  this.tRichTextItem = is.readStruct(10, false, this.tRichTextItem);
  this.iRuleType = is.readInt32(11, false, this.iRuleType);
};
Nimo.PayChatRecord = PayChatRecord;
export default PayChatRecord;