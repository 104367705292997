import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var GuardianAnchorArchivement = function GuardianAnchorArchivement() {
  this.lAnchorUID = 0;
  this.iStatus = 0;
  this.lExpireTime = 0;
  this.iMoreDayToExtend = 0;
};
GuardianAnchorArchivement.prototype._clone = function () {
  return new Nimo.GuardianAnchorArchivement();
};
GuardianAnchorArchivement.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GuardianAnchorArchivement.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GuardianAnchorArchivement.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lAnchorUID);
  os.writeInt32(1, this.iStatus);
  os.writeInt64(2, this.lExpireTime);
  os.writeInt32(3, this.iMoreDayToExtend);
};
GuardianAnchorArchivement.prototype.readFrom = function (is) {
  this.lAnchorUID = is.readInt64(0, false, this.lAnchorUID);
  this.iStatus = is.readInt32(1, false, this.iStatus);
  this.lExpireTime = is.readInt64(2, false, this.lExpireTime);
  this.iMoreDayToExtend = is.readInt32(3, false, this.iMoreDayToExtend);
};
Nimo.GuardianAnchorArchivement = GuardianAnchorArchivement;
export default GuardianAnchorArchivement;