import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _broadcasts;
/* eslint-disable no-param-reassign */
import AuditPunishMuteNotice from '@client/jce/Nimo/AuditPunishMuteNotice';
import AuditPunishMaskNotice from '@client/jce/Nimo/AuditPunishMaskNotice';
import ETopicType from '@client/jce/Nimo/ETopicType';
import EURI from '@client/jce/Nimo/EURI';
import TafMessageSocketModel from '../TafMessageSocketModel';
var EUriAuditPunishMuteNotice = EURI.EUriAuditPunishMuteNotice,
  EUriAuditPunishMaskNotice = EURI.EUriAuditPunishMaskNotice;
export { EUriAuditPunishMuteNotice, EUriAuditPunishMaskNotice };
export var getPunishTopic = function getPunishTopic(roomId) {
  return {
    id: "".concat(ETopicType.kRoomPunish),
    subId: [roomId]
  };
};
export default new TafMessageSocketModel({
  lazyInitSocket: true,
  broadcasts: (_broadcasts = {}, _defineProperty(_broadcasts, EUriAuditPunishMuteNotice, [EUriAuditPunishMuteNotice, AuditPunishMuteNotice]), _defineProperty(_broadcasts, EUriAuditPunishMaskNotice, [EUriAuditPunishMaskNotice, AuditPunishMaskNotice]), _broadcasts)
});