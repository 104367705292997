import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var MatchRealTimeDataConfig = function MatchRealTimeDataConfig() {
  this.componentType = 0;
  this.mobileUrl = "";
  this.pcUrl = "";
};
MatchRealTimeDataConfig.prototype._clone = function () {
  return new NimoBuss.MatchRealTimeDataConfig();
};
MatchRealTimeDataConfig.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MatchRealTimeDataConfig.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MatchRealTimeDataConfig.prototype.writeTo = function (os) {
  os.writeInt32(0, this.componentType);
  os.writeString(1, this.mobileUrl);
  os.writeString(2, this.pcUrl);
};
MatchRealTimeDataConfig.prototype.readFrom = function (is) {
  this.componentType = is.readInt32(0, false, this.componentType);
  this.mobileUrl = is.readString(1, false, this.mobileUrl);
  this.pcUrl = is.readString(2, false, this.pcUrl);
};
NimoBuss.MatchRealTimeDataConfig = MatchRealTimeDataConfig;
export default MatchRealTimeDataConfig;