import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var GetRealtimeReplayReq = function GetRealtimeReplayReq() {
  this.tUser = new Nimo.UserId();
  this.lAnchorUID = 0;
  this.iInterval = 0;
  this.iVideoType = 0;
};
GetRealtimeReplayReq.prototype._clone = function () {
  return new Nimo.GetRealtimeReplayReq();
};
GetRealtimeReplayReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetRealtimeReplayReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetRealtimeReplayReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeInt64(1, this.lAnchorUID);
  os.writeInt32(2, this.iInterval);
  os.writeInt32(3, this.iVideoType);
};
GetRealtimeReplayReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.lAnchorUID = is.readInt64(1, false, this.lAnchorUID);
  this.iInterval = is.readInt32(2, false, this.iInterval);
  this.iVideoType = is.readInt32(3, false, this.iVideoType);
};
Nimo.GetRealtimeReplayReq = GetRealtimeReplayReq;
export default GetRealtimeReplayReq;