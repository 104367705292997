import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './RichText';
var RoomMsgBannerNotice = function RoomMsgBannerNotice() {
  this.iRecordId = 0;
  this.sBannerName = "";
  this.mBannerContent = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.sBannerBgUrlWeb = "";
  this.sBannerVBgUrlApp = "";
  this.sBannerHBgUrlApp = "";
  this.iJumpType = 0;
  this.sJumpUrl = "";
  this.lRoomId = 0;
  this.lAnchorId = 0;
  this.iBusinessType = 0;
  this.iBannerType = 0;
  this.lTriggerRoomId = 0;
  this.iWeight = 0;
  this.iDurationS = 0;
  this.iCategory = 0;
  this.tRichText = new Nimo.RichText();
  this.sNewAppVerticalUrl = "";
  this.mHorizontalPadding = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.mVerticalPadding = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.vExtraData = new Taf.BinBuffer();
  this.sZip = "";
  this.sSvga = "";
  this.sZip2 = "";
};
RoomMsgBannerNotice.prototype._clone = function () {
  return new Nimo.RoomMsgBannerNotice();
};
RoomMsgBannerNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
RoomMsgBannerNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
RoomMsgBannerNotice.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iRecordId);
  os.writeString(1, this.sBannerName);
  os.writeMap(2, this.mBannerContent);
  os.writeString(3, this.sBannerBgUrlWeb);
  os.writeString(4, this.sBannerVBgUrlApp);
  os.writeString(5, this.sBannerHBgUrlApp);
  os.writeInt32(6, this.iJumpType);
  os.writeString(7, this.sJumpUrl);
  os.writeInt64(8, this.lRoomId);
  os.writeInt64(9, this.lAnchorId);
  os.writeInt32(10, this.iBusinessType);
  os.writeInt32(11, this.iBannerType);
  os.writeInt64(12, this.lTriggerRoomId);
  os.writeInt32(13, this.iWeight);
  os.writeInt32(14, this.iDurationS);
  os.writeInt32(15, this.iCategory);
  os.writeStruct(16, this.tRichText);
  os.writeString(17, this.sNewAppVerticalUrl);
  os.writeMap(18, this.mHorizontalPadding);
  os.writeMap(19, this.mVerticalPadding);
  os.writeBytes(20, this.vExtraData);
  os.writeString(21, this.sZip);
  os.writeString(22, this.sSvga);
  os.writeString(23, this.sZip2);
};
RoomMsgBannerNotice.prototype.readFrom = function (is) {
  this.iRecordId = is.readInt32(0, false, this.iRecordId);
  this.sBannerName = is.readString(1, false, this.sBannerName);
  this.mBannerContent = is.readMap(2, false, this.mBannerContent);
  this.sBannerBgUrlWeb = is.readString(3, false, this.sBannerBgUrlWeb);
  this.sBannerVBgUrlApp = is.readString(4, false, this.sBannerVBgUrlApp);
  this.sBannerHBgUrlApp = is.readString(5, false, this.sBannerHBgUrlApp);
  this.iJumpType = is.readInt32(6, false, this.iJumpType);
  this.sJumpUrl = is.readString(7, false, this.sJumpUrl);
  this.lRoomId = is.readInt64(8, false, this.lRoomId);
  this.lAnchorId = is.readInt64(9, false, this.lAnchorId);
  this.iBusinessType = is.readInt32(10, false, this.iBusinessType);
  this.iBannerType = is.readInt32(11, false, this.iBannerType);
  this.lTriggerRoomId = is.readInt64(12, false, this.lTriggerRoomId);
  this.iWeight = is.readInt32(13, false, this.iWeight);
  this.iDurationS = is.readInt32(14, false, this.iDurationS);
  this.iCategory = is.readInt32(15, false, this.iCategory);
  this.tRichText = is.readStruct(16, false, this.tRichText);
  this.sNewAppVerticalUrl = is.readString(17, false, this.sNewAppVerticalUrl);
  this.mHorizontalPadding = is.readMap(18, false, this.mHorizontalPadding);
  this.mVerticalPadding = is.readMap(19, false, this.mVerticalPadding);
  this.vExtraData = is.readBytes(20, false, this.vExtraData);
  this.sZip = is.readString(21, false, this.sZip);
  this.sSvga = is.readString(22, false, this.sSvga);
  this.sZip2 = is.readString(23, false, this.sZip2);
};
Nimo.RoomMsgBannerNotice = RoomMsgBannerNotice;
export default RoomMsgBannerNotice;