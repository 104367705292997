import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var StarLiveRoomView = function StarLiveRoomView() {
  this.id = 0;
  this.countryCode = "";
  this.countryName = "";
  this.anchorUdbid = 0;
  this.backgroundImage = "";
  this.headImage = "";
  this.mediaContent = "";
  this.nickName = "";
  this.notPlayPic = "";
  this.notPlayStatus = 0;
  this.notPlayType = 0;
  this.notPlayVideo = "";
  this.status = 0;
  this.syncClient = "";
};
StarLiveRoomView.prototype._clone = function () {
  return new NimoBuss.StarLiveRoomView();
};
StarLiveRoomView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
StarLiveRoomView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
StarLiveRoomView.prototype.writeTo = function (os) {
  os.writeInt32(0, this.id);
  os.writeString(1, this.countryCode);
  os.writeString(2, this.countryName);
  os.writeInt64(3, this.anchorUdbid);
  os.writeString(4, this.backgroundImage);
  os.writeString(5, this.headImage);
  os.writeString(6, this.mediaContent);
  os.writeString(7, this.nickName);
  os.writeString(8, this.notPlayPic);
  os.writeInt32(9, this.notPlayStatus);
  os.writeInt32(10, this.notPlayType);
  os.writeString(11, this.notPlayVideo);
  os.writeInt32(12, this.status);
  os.writeString(13, this.syncClient);
};
StarLiveRoomView.prototype.readFrom = function (is) {
  this.id = is.readInt32(0, false, this.id);
  this.countryCode = is.readString(1, false, this.countryCode);
  this.countryName = is.readString(2, false, this.countryName);
  this.anchorUdbid = is.readInt64(3, false, this.anchorUdbid);
  this.backgroundImage = is.readString(4, false, this.backgroundImage);
  this.headImage = is.readString(5, false, this.headImage);
  this.mediaContent = is.readString(6, false, this.mediaContent);
  this.nickName = is.readString(7, false, this.nickName);
  this.notPlayPic = is.readString(8, false, this.notPlayPic);
  this.notPlayStatus = is.readInt32(9, false, this.notPlayStatus);
  this.notPlayType = is.readInt32(10, false, this.notPlayType);
  this.notPlayVideo = is.readString(11, false, this.notPlayVideo);
  this.status = is.readInt32(12, false, this.status);
  this.syncClient = is.readString(13, false, this.syncClient);
};
NimoBuss.StarLiveRoomView = StarLiveRoomView;
export default StarLiveRoomView;