import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BaseParam';
var RoomActivityListReq = function RoomActivityListReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.gameType = 0;
  this.roomId = 0;
  this.language = "";
  this.countryCode = "";
  this.lcid = 0;
};
RoomActivityListReq.prototype._clone = function () {
  return new NimoBuss.RoomActivityListReq();
};
RoomActivityListReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
RoomActivityListReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
RoomActivityListReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeInt32(1, this.gameType);
  os.writeInt64(2, this.roomId);
  os.writeString(3, this.language);
  os.writeString(4, this.countryCode);
  os.writeInt32(5, this.lcid);
};
RoomActivityListReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.gameType = is.readInt32(1, false, this.gameType);
  this.roomId = is.readInt64(2, false, this.roomId);
  this.language = is.readString(3, false, this.language);
  this.countryCode = is.readString(4, false, this.countryCode);
  this.lcid = is.readInt32(5, false, this.lcid);
};
NimoBuss.RoomActivityListReq = RoomActivityListReq;
export default RoomActivityListReq;