import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var NickNameFormat = function NickNameFormat() {
  this.iNormalModeColor = 4287270602;
  this.iNightModeColor = 4286347469;
};
NickNameFormat.prototype._clone = function () {
  return new Nimo.NickNameFormat();
};
NickNameFormat.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
NickNameFormat.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
NickNameFormat.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iNormalModeColor);
  os.writeInt32(1, this.iNightModeColor);
};
NickNameFormat.prototype.readFrom = function (is) {
  this.iNormalModeColor = is.readInt32(0, false, this.iNormalModeColor);
  this.iNightModeColor = is.readInt32(1, false, this.iNightModeColor);
};
Nimo.NickNameFormat = NickNameFormat;
export default NickNameFormat;