import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './TeamView';
var WonderfulEventItemView = function WonderfulEventItemView() {
  this.eventCover = "";
  this.eventLogo = "";
  this.eventTitle = "";
  this.scheduleId = 0;
  this.scheduleName = "";
  this.startDate = 0;
  this.scheduleStatus = 0;
  this.lcid = 0;
  this.teamList = new Taf.Vector(new NimoBuss.TeamView());
  this.anchorId = 0;
  this.roomId = 0;
  this.roomSort = 0;
  this.gameType = 0;
  this.gameName = "";
  this.alise = "";
  this.isLottery = 0;
  this.superscriptType = 0;
  this.superscriptText = "";
  this.videoId = "";
  this.endDate = 0;
  this.dotType = 0;
  this.videoIdList = new Taf.Vector(new Taf.INT64());
};
WonderfulEventItemView.prototype._clone = function () {
  return new NimoBuss.WonderfulEventItemView();
};
WonderfulEventItemView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
WonderfulEventItemView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
WonderfulEventItemView.prototype.writeTo = function (os) {
  os.writeString(0, this.eventCover);
  os.writeString(1, this.eventLogo);
  os.writeString(2, this.eventTitle);
  os.writeInt64(3, this.scheduleId);
  os.writeString(4, this.scheduleName);
  os.writeInt64(5, this.startDate);
  os.writeInt32(6, this.scheduleStatus);
  os.writeInt32(7, this.lcid);
  os.writeVector(8, this.teamList);
  os.writeInt64(9, this.anchorId);
  os.writeInt64(10, this.roomId);
  os.writeInt32(11, this.roomSort);
  os.writeInt32(12, this.gameType);
  os.writeString(13, this.gameName);
  os.writeString(14, this.alise);
  os.writeInt32(15, this.isLottery);
  os.writeInt32(16, this.superscriptType);
  os.writeString(17, this.superscriptText);
  os.writeString(18, this.videoId);
  os.writeInt64(19, this.endDate);
  os.writeInt32(20, this.dotType);
  os.writeVector(21, this.videoIdList);
};
WonderfulEventItemView.prototype.readFrom = function (is) {
  this.eventCover = is.readString(0, false, this.eventCover);
  this.eventLogo = is.readString(1, false, this.eventLogo);
  this.eventTitle = is.readString(2, false, this.eventTitle);
  this.scheduleId = is.readInt64(3, false, this.scheduleId);
  this.scheduleName = is.readString(4, false, this.scheduleName);
  this.startDate = is.readInt64(5, false, this.startDate);
  this.scheduleStatus = is.readInt32(6, false, this.scheduleStatus);
  this.lcid = is.readInt32(7, false, this.lcid);
  this.teamList = is.readVector(8, false, this.teamList);
  this.anchorId = is.readInt64(9, false, this.anchorId);
  this.roomId = is.readInt64(10, false, this.roomId);
  this.roomSort = is.readInt32(11, false, this.roomSort);
  this.gameType = is.readInt32(12, false, this.gameType);
  this.gameName = is.readString(13, false, this.gameName);
  this.alise = is.readString(14, false, this.alise);
  this.isLottery = is.readInt32(15, false, this.isLottery);
  this.superscriptType = is.readInt32(16, false, this.superscriptType);
  this.superscriptText = is.readString(17, false, this.superscriptText);
  this.videoId = is.readString(18, false, this.videoId);
  this.endDate = is.readInt64(19, false, this.endDate);
  this.dotType = is.readInt32(20, false, this.dotType);
  this.videoIdList = is.readVector(21, false, this.videoIdList);
};
NimoBuss.WonderfulEventItemView = WonderfulEventItemView;
export default WonderfulEventItemView;