module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="n-as-mrgb-md n-as-w30 n-sk n-sk-t24"></div>\n<div class="n-as-mrgb-xs n-as-mrgh-xs-back n-fx-sc">\n  <div class="n-sk n-sk-btn-lg n-as-mrgh-xs"></div>\n  <div class="n-sk n-sk-btn-lg n-as-mrgh-xs"></div>\n  <div class="n-sk n-sk-btn-lg n-as-mrgh-xs"></div>\n  <div class="n-sk n-sk-btn-lg n-as-mrgh-xs"></div>\n</div>\n<div class="n-as-mrgh-back n-fx-sc">\n  ';
 for(var i = 0; i < 4; i++) { ;
__p += '\n  <div class="n-as-w25 n-as-mrgh bc1 n-as-rnd">\n    <div class="n-as-mrg n-as-mrgb n-as-w50 n-sk n-sk-t18"></div>\n    <div class="n-fx-ac n-as-pad-md">\n      <div class="n-sk n-sk-ava-lg n-as-mrg"></div>\n      <div class="c1 n-as-fs30">VS</div>\n      <div class="n-sk n-sk-ava-lg n-as-mrg"></div>\n    </div>\n  </div>\n  ';
 } ;
__p += '\n</div>\n';

}
return __p
}