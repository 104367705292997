import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var WS_StreamStop = function WS_StreamStop() {
  this.lRoomId = 0;
};
WS_StreamStop.prototype._clone = function () {
  return new Nimo.WS_StreamStop();
};
WS_StreamStop.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
WS_StreamStop.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
WS_StreamStop.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
};
WS_StreamStop.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
};
Nimo.WS_StreamStop = WS_StreamStop;
export default WS_StreamStop;