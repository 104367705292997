import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var WorldBannerEffect = function WorldBannerEffect() {
  this.sResource = "";
  this.lTriggerCondition = 0;
  this.iChannelType = 0;
  this.iJumpType = 0;
  this.sJumpUrl = "";
  this.sBannerEffectID = "";
  this.mExtraData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
};
WorldBannerEffect.prototype._clone = function () {
  return new Nimo.WorldBannerEffect();
};
WorldBannerEffect.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
WorldBannerEffect.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
WorldBannerEffect.prototype.writeTo = function (os) {
  os.writeString(0, this.sResource);
  os.writeInt64(1, this.lTriggerCondition);
  os.writeInt32(2, this.iChannelType);
  os.writeInt32(3, this.iJumpType);
  os.writeString(4, this.sJumpUrl);
  os.writeString(5, this.sBannerEffectID);
  os.writeMap(6, this.mExtraData);
};
WorldBannerEffect.prototype.readFrom = function (is) {
  this.sResource = is.readString(0, false, this.sResource);
  this.lTriggerCondition = is.readInt64(1, false, this.lTriggerCondition);
  this.iChannelType = is.readInt32(2, false, this.iChannelType);
  this.iJumpType = is.readInt32(3, false, this.iJumpType);
  this.sJumpUrl = is.readString(4, false, this.sJumpUrl);
  this.sBannerEffectID = is.readString(5, false, this.sBannerEffectID);
  this.mExtraData = is.readMap(6, false, this.mExtraData);
};
Nimo.WorldBannerEffect = WorldBannerEffect;
export default WorldBannerEffect;