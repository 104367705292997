import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var NoRankUserEnterRoomNotice = function NoRankUserEnterRoomNotice() {
  this.lRoomId = 0;
  this.lUserId = 0;
  this.sNick = "";
  this.sAvatarUrl = "";
  this.sVerticalUrlWeb = "";
  this.sVerticalUrlApp = "";
  this.sAdrMP4Url = "";
  this.sIosMP4Url = "";
  this.sWebMP4Url = "";
  this.iEffectShowTime = 0;
  this.iEffectType = 0;
  this.mText = new Taf.Map(new Taf.STRING(), new Taf.Vector(new Taf.STRING()));
  this.vDecorationUrl = new Taf.Vector(new Taf.STRING());
  this.iLevelType = 0;
  this.sTraceSource = "";
  this.bIsShowContent = 0;
  this.iUserLevel = 0;
  this.sGamePlayUrl = "";
  this.iNobleLevel = 0;
  this.mData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.iCost = 0;
  this.iVisitorNo = 0;
};
NoRankUserEnterRoomNotice.prototype._clone = function () {
  return new Nimo.NoRankUserEnterRoomNotice();
};
NoRankUserEnterRoomNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
NoRankUserEnterRoomNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
NoRankUserEnterRoomNotice.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lRoomId);
  os.writeInt64(1, this.lUserId);
  os.writeString(2, this.sNick);
  os.writeString(3, this.sAvatarUrl);
  os.writeString(4, this.sVerticalUrlWeb);
  os.writeString(5, this.sVerticalUrlApp);
  os.writeString(6, this.sAdrMP4Url);
  os.writeString(7, this.sIosMP4Url);
  os.writeString(8, this.sWebMP4Url);
  os.writeInt32(9, this.iEffectShowTime);
  os.writeInt32(10, this.iEffectType);
  os.writeMap(11, this.mText);
  os.writeVector(13, this.vDecorationUrl);
  os.writeInt32(14, this.iLevelType);
  os.writeString(15, this.sTraceSource);
  os.writeInt32(16, this.bIsShowContent);
  os.writeInt32(17, this.iUserLevel);
  os.writeString(18, this.sGamePlayUrl);
  os.writeInt32(19, this.iNobleLevel);
  os.writeMap(20, this.mData);
  os.writeInt32(21, this.iCost);
  os.writeInt32(22, this.iVisitorNo);
};
NoRankUserEnterRoomNotice.prototype.readFrom = function (is) {
  this.lRoomId = is.readInt64(0, false, this.lRoomId);
  this.lUserId = is.readInt64(1, false, this.lUserId);
  this.sNick = is.readString(2, false, this.sNick);
  this.sAvatarUrl = is.readString(3, false, this.sAvatarUrl);
  this.sVerticalUrlWeb = is.readString(4, false, this.sVerticalUrlWeb);
  this.sVerticalUrlApp = is.readString(5, false, this.sVerticalUrlApp);
  this.sAdrMP4Url = is.readString(6, false, this.sAdrMP4Url);
  this.sIosMP4Url = is.readString(7, false, this.sIosMP4Url);
  this.sWebMP4Url = is.readString(8, false, this.sWebMP4Url);
  this.iEffectShowTime = is.readInt32(9, false, this.iEffectShowTime);
  this.iEffectType = is.readInt32(10, false, this.iEffectType);
  this.mText = is.readMap(11, false, this.mText);
  this.vDecorationUrl = is.readVector(13, false, this.vDecorationUrl);
  this.iLevelType = is.readInt32(14, false, this.iLevelType);
  this.sTraceSource = is.readString(15, false, this.sTraceSource);
  this.bIsShowContent = is.readInt32(16, false, this.bIsShowContent);
  this.iUserLevel = is.readInt32(17, false, this.iUserLevel);
  this.sGamePlayUrl = is.readString(18, false, this.sGamePlayUrl);
  this.iNobleLevel = is.readInt32(19, false, this.iNobleLevel);
  this.mData = is.readMap(20, false, this.mData);
  this.iCost = is.readInt32(21, false, this.iCost);
  this.iVisitorNo = is.readInt32(22, false, this.iVisitorNo);
};
Nimo.NoRankUserEnterRoomNotice = NoRankUserEnterRoomNotice;
export default NoRankUserEnterRoomNotice;