import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var AnchorLevelDetail = function AnchorLevelDetail() {
  this.lUid = 0;
  this.iLevel = 0;
  this.iExp = 0;
};
AnchorLevelDetail.prototype._clone = function () {
  return new Nimo.AnchorLevelDetail();
};
AnchorLevelDetail.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorLevelDetail.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorLevelDetail.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUid);
  os.writeInt32(1, this.iLevel);
  os.writeInt32(2, this.iExp);
};
AnchorLevelDetail.prototype.readFrom = function (is) {
  this.lUid = is.readInt64(0, false, this.lUid);
  this.iLevel = is.readInt32(1, false, this.iLevel);
  this.iExp = is.readInt32(2, false, this.iExp);
};
Nimo.AnchorLevelDetail = AnchorLevelDetail;
export default AnchorLevelDetail;