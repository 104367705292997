import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var EventsTeam = function EventsTeam() {
  this.id = 0;
  this.version = 0;
  this.eventId = 0;
  this.groupId = 0;
  this.groupName = "";
  this.integration = 0;
  this.loseTimes = 0;
  this.status = 0;
  this.teamId = 0;
  this.teamLogo = "";
  this.teamName = "";
  this.tieTimes = 0;
  this.weight = 0;
  this.winTimes = 0;
};
EventsTeam.prototype._clone = function () {
  return new NimoBuss.EventsTeam();
};
EventsTeam.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
EventsTeam.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
EventsTeam.prototype.writeTo = function (os) {
  os.writeInt64(0, this.id);
  os.writeInt32(1, this.version);
  os.writeInt64(2, this.eventId);
  os.writeInt32(3, this.groupId);
  os.writeString(4, this.groupName);
  os.writeInt32(5, this.integration);
  os.writeInt32(6, this.loseTimes);
  os.writeInt32(7, this.status);
  os.writeInt32(8, this.teamId);
  os.writeString(9, this.teamLogo);
  os.writeString(10, this.teamName);
  os.writeInt32(11, this.tieTimes);
  os.writeInt32(12, this.weight);
  os.writeInt32(13, this.winTimes);
};
EventsTeam.prototype.readFrom = function (is) {
  this.id = is.readInt64(0, false, this.id);
  this.version = is.readInt32(1, false, this.version);
  this.eventId = is.readInt64(2, false, this.eventId);
  this.groupId = is.readInt32(3, false, this.groupId);
  this.groupName = is.readString(4, false, this.groupName);
  this.integration = is.readInt32(5, false, this.integration);
  this.loseTimes = is.readInt32(6, false, this.loseTimes);
  this.status = is.readInt32(7, false, this.status);
  this.teamId = is.readInt32(8, false, this.teamId);
  this.teamLogo = is.readString(9, false, this.teamLogo);
  this.teamName = is.readString(10, false, this.teamName);
  this.tieTimes = is.readInt32(11, false, this.tieTimes);
  this.weight = is.readInt32(12, false, this.weight);
  this.winTimes = is.readInt32(13, false, this.winTimes);
};
NimoBuss.EventsTeam = EventsTeam;
export default EventsTeam;