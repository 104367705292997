// import getUrlParams from '@client/common/utils/getUrlParams.js';

// let hyDevice = null;
// const isTest = DOMAINS.isDebug !== 'false';
// const sdk = !!+getUrlParams('_sdk');

// 测试用代码 生产环境 Tree Shaking 会删除
// const getDevicesForTest = () => {
//   if (!sdk) {
//     // 中台 sdk
//     loadSDK(() => {
//       const start = performance.now();
//       hyDevice.getCommmonInfo();
//       const end = performance.now();
//       // eslint-disable-next-line no-console
//       console.warn(
//         `[web devices] 中台 sdk 获取 components 耗时： ${end - start}`
//       );
//       hyDevice.init();
//     });
//   } else {
//     // fingerprintjs2
//     const script = document.createElement('script');
//     script.type = 'text/javascript';
//     script.onload = () => {
//       const start = performance.now();
//       window.Fingerprint2.get(
//         {
//           excludes: {
//             fonts: true,
//             webglVendorAndRenderer: true,
//             webgl: true,
//             fontsFlash: true
//           }
//         },
//         function () {
//           const end = performance.now();
//           // eslint-disable-next-line no-console
//           console.warn(
//             `[web devices] fingerprint2 获取 components 耗时： ${end - start}`
//           );
//         }
//       );
//     };
//     script.src =
//       'https://cdn.jsdelivr.net/npm/fingerprintjs2@2.1.0/dist/fingerprint2.min.js';
//     document.body.appendChild(script);
//   }
// };

var loadCb = function loadCb() {
  try {
    window.initFingerprint(1005);
  } catch (e) {
    console.error('initFingerprint error', e);
  }
  // if (hyDevice) hyDevice.init();
};

var loadSDK = function loadSDK() {
  if (!window.initFingerprint) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = process.env.NIMO_SDK_MAP.hydevice;
    document.body.appendChild(script);
    script.onload = function () {
      loadCb();
    };
  } else {
    loadCb();
  }
  // if (!window.Hydevice) {
  //   const script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.src = process.env.NIMO_SDK_MAP.hydevice;
  //   document.body.appendChild(script);
  //   script.onload = () => {
  //     if (typeof window.HyDeviceSDK === 'function') {
  //         hyDevice = new window.HyDeviceSDK({
  //           url: 'https://udbdf-v2.nimo.tv/device/fingerprint/web_check',
  //           appId: DOMAINS.udb_appid
  //         });
  //         cb();
  //     }
  //   };
  // } else {
  //   cb();
  // }
};

var getDevices = function getDevices() {
  loadSDK();
};
var perform = function perform() {
  // 统一延迟到2.5s后初始化
  setTimeout(function () {
    if (window.requestIdleCallback) {
      requestIdleCallback(function () {
        getDevices();
      });
    } else {
      setTimeout(function () {
        getDevices();
      }, 500);
    }
  }, 2500);
};
if (window.document.readyState === 'complete') {
  perform();
} else {
  window.onload = function () {
    perform();
  };
}