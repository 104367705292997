/**
 * 构造某时区的Date对象，调用getDate()、getHours()等获取当地时间，不要用UTC相关方法
 * @param {number} timezone 8 表 utc+8
 * @param {number|Date} date
 */
export function transformLocalTime(date, timezone) {
  var d = date ? new Date(date) : new Date();
  return new Date(d.getTime() + d.getTimezoneOffset() * 60000 + 3600000 * timezone);
}

/**
 * 判断两个时间在某个时区是否在同一天
 * @param {number|Date} a
 * @param {number|Date} b
 * @param {number} timezone  8 表 utc+8
 */
export function isSameDay(a, b, timezone) {
  if (Math.abs(a - b) >= 86400000) return false;
  var adate = transformLocalTime(a, timezone);
  var bdate = transformLocalTime(b, timezone);
  return adate.getDate() === bdate.getDate();
}