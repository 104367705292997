import EURI from '@client/jce/Nimo/EURI';
export default function filterMsgListByRoomId(msgList, roomId) {
  if (roomId <= 0 || isNaN(roomId)) {
    return msgList;
  }
  var filter = function filter(msg) {
    if (msg) {
      var _msg$data;
      // 根据roomId过滤
      var msgRoomId = msg.__msgtype__ === EURI.EUriJServerPacket ? (_msg$data = msg.data) === null || _msg$data === void 0 ? void 0 : _msg$data.roomId : msg.lRoomId;
      if (msgRoomId > 0) {
        return +msgRoomId === +roomId;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  return msgList.filter(filter);
}