var DOMAIN = 'nimo.tv';
export var cookie = {
  get: function get(name) {
    var arr = document.cookie.match(new RegExp("(^| )".concat(name, "=([^;]*)(;|$)")));
    if (arr != null) {
      return decodeURI(arr[2]);
    }
    return null;
  },
  set: function set(name, value, days, domain) {
    var _days = days !== null && days !== void 0 ? days : 30; // 保存天数 传0则有效期为session级别
    var _domain = domain || DOMAIN;
    var e = 0;
    if (_days) {
      var exp = new Date(); // new Date("December 31, 9998");
      exp.setTime(exp.getTime() + _days * 86400000); // _days * 24 * 60 * 60 * 1000
      e = exp.toGMTString();
    }
    var expire = e ? ";expires=".concat(e) : '';
    var cookieValue = "".concat(name, "=").concat(encodeURI(value), ";domain=.").concat(_domain, ";path=/").concat(expire);
    document.cookie = cookieValue;
  },
  remove: function remove(name, domain) {
    var _domain = domain || DOMAIN;
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = this.get(name);
    if (cval != null) {
      document.cookie = "".concat(name, "=").concat(cval, ";domain=.").concat(_domain, ";path=/;expires=").concat(exp.toGMTString());
    }
  },
  del: function del() {
    cookie.remove.apply(cookie, arguments);
  },
  clear: function clear() {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i += 1) {
      var _cookies$i, _cookie$;
      var _cookie = (_cookies$i = cookies[i]) === null || _cookies$i === void 0 ? void 0 : _cookies$i.split('=');
      var name = (_cookie$ = _cookie[0]) === null || _cookie$ === void 0 ? void 0 : _cookie$.trim();
      this.remove(name);
      this.remove(name, window.location.host);
    }
  }
};
export var local = {
  get: function get(name) {
    try {
      return window.localStorage.getItem(name.toLowerCase()) || null;
    } catch (e) {
      console.log(e);
    }
    return null;
  },
  set: function set(name, value) {
    try {
      window.localStorage.setItem(name.toLowerCase(), value);
    } catch (e) {
      console.log(e);
    }
  },
  remove: function remove(name) {
    try {
      window.localStorage.removeItem(name.toLowerCase());
    } catch (e) {
      console.log(e);
    }
  },
  has: function has(name) {
    try {
      // eslint-disable-next-line no-prototype-builtins
      return window.localStorage.hasOwnProperty(name.toLowerCase());
    } catch (e) {
      console.log(e);
    }
    return false;
  },
  clear: function clear() {
    try {
      window.localStorage.clear();
    } catch (e) {
      console.log(e);
    }
  }
};
export var session = {
  get: function get(name) {
    try {
      return window.sessionStorage.getItem(name.toLowerCase()) || null;
    } catch (e) {
      console.log(e);
    }
    return null;
  },
  set: function set(name, value) {
    try {
      window.sessionStorage.setItem(name.toLowerCase(), value);
    } catch (e) {
      console.log(e);
    }
  },
  remove: function remove(name) {
    try {
      window.sessionStorage.removeItem(name.toLowerCase());
    } catch (e) {
      console.log(e);
    }
  },
  clear: function clear() {
    try {
      window.sessionStorage.clear();
    } catch (e) {
      console.log(e);
    }
  }
};
export default {
  cookie: cookie,
  local: local,
  session: session
};