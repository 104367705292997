import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var FanIntimacyRsp = function FanIntimacyRsp() {
  this.anchorId = 0;
  this.fanId = 0;
  this.intimate = 0;
};
FanIntimacyRsp.prototype._clone = function () {
  return new Nimo.FanIntimacyRsp();
};
FanIntimacyRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FanIntimacyRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FanIntimacyRsp.prototype.writeTo = function (os) {
  os.writeInt64(0, this.anchorId);
  os.writeInt64(1, this.fanId);
  os.writeInt64(2, this.intimate);
};
FanIntimacyRsp.prototype.readFrom = function (is) {
  this.anchorId = is.readInt64(0, false, this.anchorId);
  this.fanId = is.readInt64(1, false, this.fanId);
  this.intimate = is.readInt64(2, false, this.intimate);
};
Nimo.FanIntimacyRsp = FanIntimacyRsp;
export default FanIntimacyRsp;