var _this = this;
import Events from 'events';
import { Queue } from '@client/common/lib/nm-taf';
var EVENTS = {
  SIGNAL_ON: '__NIMO_SIGNAL_ON__'
};
var event = new Events();
var queue = new Queue();
var listenerMap = Object.create(null);
var inited = false;
var signal = null;

// 确保 socket 初始化后再注册广播监听
export var init = function init(signalInstance) {
  inited = true;
  signal = signalInstance;
  queue.runAndClear();
};

// 接收到的数据需要做一些统一的处理，所以wrapper还不能去掉
var createWrapperFunc = function createWrapperFunc(uri, listener) {
  var _func = function _func(data) {
    event.emit(EVENTS.SIGNAL_ON, {
      funcName: uri,
      listener: listener,
      data: data
    });
  };
  // 保存监听function 用于 off
  if (!listenerMap[uri]) listenerMap[uri] = [];
  listenerMap[uri].push({
    listener: listener,
    wrapper: _func
  });
  return _func;
};
var createOnceWrapperFunc = function createOnceWrapperFunc(uri, listener) {
  var _func = function _func(data) {
    event.emit(EVENTS.SIGNAL_ON, {
      funcName: uri,
      listener: listener,
      data: data
    });
    off(uri, _func);
  };
  // 保存监听function
  if (!listenerMap[uri]) listenerMap[uri] = [];
  listenerMap[uri].push({
    listener: listener,
    wrapper: _func
  });
  return _func;
};
var getWrapperFunc = function getWrapperFunc(uri, listener) {
  var arr = listenerMap[uri];
  if (!arr) return null;
  for (var i = 0; i < arr.length; i += 1) {
    var obj = arr[i];
    if (obj.listener === listener) {
      arr.splice(i, 1);
      if (!arr.length) delete listenerMap[uri];
      return obj.wrapper;
    }
  }
  return null;
};
export var onSignal = function onSignal(func) {
  event.on(EVENTS.SIGNAL_ON, func);
};
export var offSignal = function offSignal(func) {
  event.off(EVENTS.SIGNAL_ON, func);
};
export var on = function on(uri, structure, listener) {
  if (inited && signal) {
    signal.registerUri(uri, structure);
    signal.on(uri, createWrapperFunc(uri, listener));
  } else {
    queue.add(on, [uri, structure, listener], _this);
  }
};
export var off = function off(uri, listener) {
  if (inited && signal) {
    if (listener) {
      var wrapperFunc = getWrapperFunc(uri, listener);
      if (wrapperFunc) {
        signal.off(uri, wrapperFunc);
      }
    } else {
      signal.off(uri, null);
    }
  } else {
    queue.add(off, [uri, listener], _this);
  }
};
export var once = function once(uri, structure, listener) {
  if (inited && signal) {
    signal.registerUri(uri, structure);
    signal.on(uri, createOnceWrapperFunc(uri, listener));
  } else {
    queue.add(once, [uri, structure, listener], _this);
  }
};