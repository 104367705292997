import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './MeetingUserInfo';
var MeetingSeat = function MeetingSeat() {
  this.lUID = 0;
  this.iIndex = 0;
  this.iSeatStatus = 0;
  this.iMicStatus = 0;
  this.sNickname = "";
  this.sAvatar = "";
  this.sApplyID = "";
  this.sAvatarBox = "";
  this.tUserInfo = new Nimo.MeetingUserInfo();
};
MeetingSeat.prototype._clone = function () {
  return new Nimo.MeetingSeat();
};
MeetingSeat.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MeetingSeat.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MeetingSeat.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUID);
  os.writeInt32(1, this.iIndex);
  os.writeInt32(2, this.iSeatStatus);
  os.writeInt32(3, this.iMicStatus);
  os.writeString(4, this.sNickname);
  os.writeString(5, this.sAvatar);
  os.writeString(6, this.sApplyID);
  os.writeString(7, this.sAvatarBox);
  os.writeStruct(8, this.tUserInfo);
};
MeetingSeat.prototype.readFrom = function (is) {
  this.lUID = is.readInt64(0, false, this.lUID);
  this.iIndex = is.readInt32(1, false, this.iIndex);
  this.iSeatStatus = is.readInt32(2, false, this.iSeatStatus);
  this.iMicStatus = is.readInt32(3, false, this.iMicStatus);
  this.sNickname = is.readString(4, false, this.sNickname);
  this.sAvatar = is.readString(5, false, this.sAvatar);
  this.sApplyID = is.readString(6, false, this.sApplyID);
  this.sAvatarBox = is.readString(7, false, this.sAvatarBox);
  this.tUserInfo = is.readStruct(8, false, this.tUserInfo);
};
Nimo.MeetingSeat = MeetingSeat;
export default MeetingSeat;