import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var EPktType = {
  kTLoginReq: 100,
  kTLoginRsp: 101,
  kTLogoutReq: 102,
  kTLogoutRsp: 103,
  kTSubReq: 104,
  kTSubRsp: 105,
  kTUnsubReq: 106,
  kTUnsubRsp: 107,
  kTHeartBeatReq: 108,
  kTHeartBeatRsp: 109,
  kTUnbindGuidReq: 201,
  kTUnbindGuidRsp: 202,
  kTUpMsg: 300,
  kTDownMsg: 301
};
Nimo.EPktType = EPktType;
export default EPktType;