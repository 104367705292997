import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './TeamIntroductionView';
import './PageInfo';
var GetTeamIntroductionListRsp = function GetTeamIntroductionListRsp() {
  this.data = new Taf.Vector(new NimoBuss.TeamIntroductionView());
  this.pageInfo = new NimoBuss.PageInfo();
};
GetTeamIntroductionListRsp.prototype._clone = function () {
  return new NimoBuss.GetTeamIntroductionListRsp();
};
GetTeamIntroductionListRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetTeamIntroductionListRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetTeamIntroductionListRsp.prototype.writeTo = function (os) {
  os.writeVector(0, this.data);
  os.writeStruct(1, this.pageInfo);
};
GetTeamIntroductionListRsp.prototype.readFrom = function (is) {
  this.data = is.readVector(0, false, this.data);
  this.pageInfo = is.readStruct(1, false, this.pageInfo);
};
NimoBuss.GetTeamIntroductionListRsp = GetTeamIntroductionListRsp;
export default GetTeamIntroductionListRsp;