import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
/* eslint-disable no-param-reassign */
import { getGuid } from '@client/common/utils/fn';
import runtime from '../runtime';
var inNimoApp = /nimo/.test(navigator.userAgent);

/**
 * 设置用户信息
 * @param {Nimo.UserId} userInstance 用户对象实例
 * @param {object} overridedInfo 覆盖runtime的新用户信息
 * @since 2.0.0 setUserInfo -> setUserId
 */
export function setUserId(userId) {
  var overridedInfo = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _ref = runtime || {},
    rtUser = _ref.user;
  if (!rtUser.sGuid && !inNimoApp) {
    rtUser.sGuid = getGuid();
  }
  var result = _objectSpread(_objectSpread({}, rtUser), overridedInfo || {});
  userId.lUid = result.uid;
  userId.sToken = result.token;
  userId.sUDBVer = result.version;
  userId.iRegOrigin = result.regOrigin;
  userId.sUA = result.sUA;
  userId.sLang = result.lang;
  userId.sCountry = result.sCountry;
  userId.sRegion = result.sRegion;
  userId.sOpSeq = result.sOpSeq;
  userId.iNetType = result.iNetType;

  // 为空则不传
  if (result.sGuid) {
    userId.sGuid = result.sGuid;
  }
}
var userIdAlias = [['nick', 'userName'], ['lcid', 'clang'], ['uid', 'udbUserId'], ['token', 'bizToken']];
export function setUserIdWithAlias(userId, _userInfo) {
  var userInfo;
  if (_userInfo) {
    userInfo = _objectSpread({}, _userInfo);
    var has = Object.prototype.hasOwnProperty;
    userIdAlias.forEach(function (_ref2) {
      var _ref3 = _slicedToArray(_ref2, 2),
        key = _ref3[0],
        alise = _ref3[1];
      if (has.call(userInfo, alise)) {
        userInfo[key] = userInfo[key] || userInfo[alise];
      }
    });
  }
  setUserId(userId, userInfo);
}