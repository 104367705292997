import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { eventEmitter, EVENTS } from '@client/common/utils/events';
import forbidMessageSocketModel from '@client/common/models/socket/forbidMessageSocketModel';
var TextForbidProvider = function TextForbidProvider(_ref) {
  var roomInfo = _ref.roomInfo;
  var roomId = roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.roomId;
  useEffect(function () {
    var handler = function handler(forbidUserMessageNotice) {
      if (roomId && Number(forbidUserMessageNotice.lRoomId) === Number(roomId)) {
        ReactDOM.unstable_batchedUpdates(function () {
          eventEmitter.emit(EVENTS.TEXT_FORBID, forbidUserMessageNotice);
        });
      }
    };
    forbidMessageSocketModel.on('UserForbidMessage', handler);
    return function () {
      forbidMessageSocketModel.off('UserForbidMessage', handler);
    };
  }, [roomId]);
  return null;
};
export default TextForbidProvider;