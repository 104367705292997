import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './TeamView';
var ScheduleView = function ScheduleView() {
  this.scheduleId = 0;
  this.isReservation = 0;
  this.anchorId = 0;
  this.eventLogo = "";
  this.eventName = "";
  this.teamViewList = new Taf.Vector(new NimoBuss.TeamView());
  this.id = 0;
  this.eventId = 0;
  this.roomId = 0;
  this.name = "";
  this.gameType = 0;
  this.scheduleStatus = 0;
  this.teamSupportSwitch = 0;
  this.status = 0;
  this.lcid = 0;
  this.countryCode = "";
  this.videoId = "";
  this.dotType = 0;
  this.startDate = 0;
  this.endDate = 0;
  this.videoIdList = new Taf.Vector(new Taf.INT64());
};
ScheduleView.prototype._clone = function () {
  return new NimoBuss.ScheduleView();
};
ScheduleView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ScheduleView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ScheduleView.prototype.writeTo = function (os) {
  os.writeInt64(0, this.scheduleId);
  os.writeInt32(1, this.isReservation);
  os.writeInt64(2, this.anchorId);
  os.writeString(3, this.eventLogo);
  os.writeString(4, this.eventName);
  os.writeVector(5, this.teamViewList);
  os.writeInt64(6, this.id);
  os.writeInt64(7, this.eventId);
  os.writeInt64(8, this.roomId);
  os.writeString(9, this.name);
  os.writeInt32(10, this.gameType);
  os.writeInt32(11, this.scheduleStatus);
  os.writeInt32(12, this.teamSupportSwitch);
  os.writeInt32(13, this.status);
  os.writeInt32(14, this.lcid);
  os.writeString(15, this.countryCode);
  os.writeString(16, this.videoId);
  os.writeInt32(17, this.dotType);
  os.writeInt64(18, this.startDate);
  os.writeInt64(19, this.endDate);
  os.writeVector(20, this.videoIdList);
};
ScheduleView.prototype.readFrom = function (is) {
  this.scheduleId = is.readInt64(0, false, this.scheduleId);
  this.isReservation = is.readInt32(1, false, this.isReservation);
  this.anchorId = is.readInt64(2, false, this.anchorId);
  this.eventLogo = is.readString(3, false, this.eventLogo);
  this.eventName = is.readString(4, false, this.eventName);
  this.teamViewList = is.readVector(5, false, this.teamViewList);
  this.id = is.readInt64(6, false, this.id);
  this.eventId = is.readInt64(7, false, this.eventId);
  this.roomId = is.readInt64(8, false, this.roomId);
  this.name = is.readString(9, false, this.name);
  this.gameType = is.readInt32(10, false, this.gameType);
  this.scheduleStatus = is.readInt32(11, false, this.scheduleStatus);
  this.teamSupportSwitch = is.readInt32(12, false, this.teamSupportSwitch);
  this.status = is.readInt32(13, false, this.status);
  this.lcid = is.readInt32(14, false, this.lcid);
  this.countryCode = is.readString(15, false, this.countryCode);
  this.videoId = is.readString(16, false, this.videoId);
  this.dotType = is.readInt32(17, false, this.dotType);
  this.startDate = is.readInt64(18, false, this.startDate);
  this.endDate = is.readInt64(19, false, this.endDate);
  this.videoIdList = is.readVector(20, false, this.videoIdList);
};
NimoBuss.ScheduleView = ScheduleView;
export default ScheduleView;