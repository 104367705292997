import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { local as localLocal } from '@client/common/utils/storage';
import { twoEndOrigin, twoEndHost } from '@client/common/services/url';
var modules = {
  local: localLocal
};
var iframeCached;
var iframePromise;
var currentMessageId = 0;
var uuid = '';
var callbacks = {};
var _initResolve;
var getUUID = function getUUID() {
  if (!uuid) {
    uuid = Math.random().toString(32).slice(2, 10);
  }
  return uuid;
};
var callIframe = function callIframe(iframe, methodPath, args) {
  if (!iframe || !iframe.contentWindow) {
    return Promise.reject();
  }
  currentMessageId += 1;
  var p = new Promise(function (resolve, reject) {
    callbacks[currentMessageId] = {
      resolve: resolve,
      reject: reject
    };
  });
  iframe.contentWindow.postMessage({
    type: 'call',
    _id: currentMessageId,
    _uuid: getUUID(),
    methodPath: methodPath,
    args: args
  }, twoEndOrigin);
  return p;
};
function has(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}
var inited = false;
var initMessageHandler = function initMessageHandler() {
  if (!inited) {
    inited = true;
    window.addEventListener('message', function (e) {
      if (e.origin === twoEndOrigin && e.data && e.data._uuid === getUUID()) {
        var _e$data = e.data,
          type = _e$data.type,
          _id = _e$data._id;
        if (type === 'init') {
          if (_initResolve) {
            _initResolve();
          }
        } else if (type === 'callback' && has(callbacks, e.data._id) && callbacks[e.data._id]) {
          var _e$data2 = e.data,
            payload = _e$data2.payload,
            error = _e$data2.error;
          var _callbacks$_id = callbacks[_id],
            resolve = _callbacks$_id.resolve,
            reject = _callbacks$_id.reject;
          if (error) {
            reject(error);
          } else {
            resolve(payload);
          }
          callbacks[_id] = null;
        }
      }
    });
  }
};

/**
 * @param {string[]} methodPath
 * @param {any[]} args
 */
export var executeModule = function executeModule(methodPath, args) {
  if (location.host === twoEndHost) {
    var _args = Array.isArray(args) ? args : [];
    var fn;
    if (Array.isArray(methodPath)) {
      fn = methodPath.reduce(function (prev, key) {
        if (prev && has(prev, key)) {
          return prev[key] || null;
        }
        return null;
      }, modules);
    }
    if (typeof fn === 'function') {
      try {
        return Promise.resolve(fn.apply(void 0, _toConsumableArray(_args)));
      } catch (e) {
        return Promise.reject();
      }
    } else {
      return Promise.reject();
    }
  } else if (iframeCached) {
    return callIframe(iframeCached, methodPath, args);
  } else {
    initMessageHandler();
    return _isInitProxy().then(function (proxyIframe) {
      return callIframe(proxyIframe, methodPath, args);
    });
  }
};

/**
 * @returns {Promise}
 */
function _isInitProxy() {
  if (iframeCached) return Promise.resolve(iframeCached);
  if (iframePromise) return iframePromise;
  iframePromise = new Promise(function (resolve, reject) {
    var iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    var timer;
    _initResolve = function initResolve() {
      iframeCached = iframe;
      iframePromise = null;
      _initResolve = null;
      if (timer) {
        clearTimeout(timer);
      }
      resolve(iframe);
      iframe.onload = null;
    };
    // appendChild、onload和src三个的顺序不能变，否则会导致onload在某些场景提前触发或者触发两次
    document.body.appendChild(iframe);
    iframe.onload = function () {
      timer = setTimeout(function () {
        timer = null;
        _initResolve = null;
        reject();
        iframe.onload = null;
      }, 500);
    };
    iframe.src = "".concat(twoEndOrigin, "/nms/page/proxy-v2.html?_parent=").concat(encodeURIComponent(location.origin), "&_uuid=").concat(getUUID());
  });
  return iframePromise;
}
export var executeLocal = function executeLocal(method) {
  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }
  return executeModule(['local', method], args);
};
export var localSet = function localSet(key, value) {
  return executeLocal('set', key, value).catch(function () {});
};
export var localGet = function localGet(key) {
  return executeLocal('get', key).catch(function () {});
};