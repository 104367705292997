import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var EAppType = {
  ETube: 0,
  EAppidMessageServer: 101,
  EAppidLotteryServer: 102,
  EAppidAtmosphereServer: 103,
  EAppidVipBarServer: 104,
  EAppidRoomMsgBannerServer: 105,
  EAppidMiscServer: 106,
  EAppidGiftRoomServer: 201,
  EAppidTafUserServer: 301,
  EAppidMsgCenterServer: 401,
  EAppidMatchServer: 501,
  EAppidBulletShareLotterySerer: 601,
  EAppidVoteServer: 701,
  EAppidAnchorLevel: 801,
  EAppidAnchorPK: 802,
  EAppidAVPostRsc: 803,
  EAppidAVPostWSOutputKey: 804,
  EAppidAVPostGroupTask: 805,
  EAppidAnchorGuess: 806,
  EAppidAnchorAD: 807,
  EAppidLiveSubtitle: 808,
  EAppidShow: 901,
  EAppidLive: 1001,
  EAppidLiveAtt: 1002,
  EAppidVideoPlayBack: 1100,
  EAppidBoxGift: 1200,
  EAppidCheckin: 1300,
  EAppidLuckyGift: 1400,
  EAppidTaskBox: 1500,
  EAppidDataCancellation: 1600,
  EAppidPickMe: 1700,
  EAppidThirdPartyRepush: 1800,
  EAppidThirdMsgPush: 1900,
  EAppidItemTally: 2000,
  EAppidLiveMeeting: 2100,
  EAppidLiveMeetingCharmValue: 2101,
  EAppidLiveMeetingPairing: 2102,
  EAppidFortuneGift: 2200,
  EAppidSupperTreasure: 2201,
  EAppidGoldBeanLottery: 2300,
  EAppidRoulette: 2301,
  EAppidGamePromote: 2302,
  EAppidBoxLottery: 2303,
  EAppidBaucua: 2304,
  EAppidSlotMachine: 2305,
  EAppidFunnyGame: 2306,
  EAppidSundry: 2400,
  EAppidLinkMic: 2500,
  EAppidLiveShare: 2600,
  EAppidActivityPromote: 2700,
  EAppidInteractiveGift: 2800,
  EAppidWealthFamily: 2900,
  EAppidTurntable: 3000,
  EAppidUserLevel: 3100,
  EAppidUserGrowthTask: 3101,
  EAppidAnchorGrowthTask: 3200,
  EAppidSRE: 3300,
  EAppidStartPopup: 3400,
  EAppidActivityDraw: 3500,
  EAppidBossGame: 3600,
  EAppidFishGame: 3700,
  EAppidHorseRace: 20000,
  EAppidLudo: 20001,
  EAppidActTrea: 20003
};
Nimo.EAppType = EAppType;
export default EAppType;