import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { unescape } from '@client/common/utils/dom';
import { replaceLangDollar } from '@client/common/utils/string';
import getShareText from '@client/common/services/getShareText.js';
import { getThirdPartyReferrerHost } from '@client/common/utils/referrer';
import { session } from '@client/common/utils/storage';
import { isFromCommonShareNow } from './commonShare';
import { parseURL, getLang } from '@client/common/utils/fn';
import { getDefaultImgServerUrl } from '@client/common/utils/getImgServerUrl';
import { normalizeRoomInfo } from './normalizeRoomInfo';
export { normalizeRoomInfo };
export var normalizeRoomInfoWithOrigin = function normalizeRoomInfoWithOrigin(res) {
  return _objectSpread(_objectSpread({}, res), normalizeRoomInfo(res));
};
export var getRoomShareProps = function getRoomShareProps(data, baseLangPkg, abtestParam, shareDynamicInfo, userName, defaultLangKey, titleParam) {
  var nickname = unescape(data.nickname);
  var title = unescape(data.title);
  var gamePrefix = data.game ? "\u3010".concat(data.game, "\u3011") : '';
  var langKey = getLang();
  var ShareParam = {
    ANCHOR_NAME: nickname,
    GAME_TYPE: data.game,
    ROOM_TITLE: title,
    USER_NAME: userName,
    EVENT_NAME: titleParam
  };
  var shareDesc = getShareText({
    shareDynamicInfo: shareDynamicInfo,
    abtestParam: abtestParam,
    defaultLangKey: defaultLangKey,
    lang: langKey
  });
  var descLang = replaceLangDollar(shareDesc, ShareParam);
  return {
    shareTitle: "".concat(gamePrefix).concat(nickname),
    shareImg: data.avatarUrl,
    shareDesc: descLang
  };
};
export var getSelfManagerShareProps = function getSelfManagerShareProps(data, baseLangPkg, abtestParam, shareDynamicInfo) {
  var nickname = unescape(data.nickname);
  var title = unescape(data.title);
  var gamePrefix = data.game ? "\u3010".concat(data.game, "\u3011") : '';
  var langKey = getLang();
  var ShareParam = {
    ANCHOR_NAME: nickname,
    GAME_TYPE: data.game,
    ROOM_TITLE: title
  };
  var shareDesc = getShareText({
    shareDynamicInfo: shareDynamicInfo,
    abtestParam: abtestParam,
    defaultLangKey: 'sharetext_A_selfpromotion_1',
    lang: langKey
  });
  var descLang = replaceLangDollar(shareDesc, ShareParam);
  return {
    shareTitle: "".concat(gamePrefix).concat(nickname),
    shareImg: data.avatarUrl,
    shareDesc: descLang
  };
};
var safeParseJSON = function safeParseJSON(raw) {
  try {
    return JSON.parse(raw);
  } catch (err) {
    // ignore err
  }
  return null;
};
var isNotificationGranted = function isNotificationGranted() {
  try {
    return 'PushManager' in window && 'Notification' in window && Notification.permission === 'granted';
  } catch (error) {
    return false;
  }
};
var getWebpushType = function getWebpushType() {
  if (process.env.platform === 'desktop' && (!document.referrer || /firebase-messaging-sw\.js$/.test(document.referrer)) && isNotificationGranted()) {
    var webpushType = parseURL().params._webpush_t;
    var types = {
      1: 'webpush-fans',
      // 来源粉丝的web推送
      2: 'webpush-rec' // 来源推荐的web推送
    };

    return Object.prototype.hasOwnProperty.call(types, webpushType) ? types[webpushType] : '';
  }
  return '';
};
export var getRoomEnterSource = function getRoomEnterSource() {
  // 1. 分享链接参数判断
  if (isFromCommonShareNow()) {
    return 'share-link';
  }

  // 2. 推送点击
  var webpushType = getWebpushType();
  if (webpushType) {
    return webpushType;
  }

  // 3. 内部跳转来源
  var localRedirectInfoRaw = session.get('roomenterinfo');
  var localRedirectInfo = localRedirectInfoRaw ? safeParseJSON(localRedirectInfoRaw) : null;
  if (localRedirectInfoRaw) {
    session.remove('roomenterinfo');
  }
  if (localRedirectInfo) {
    // 当存在url时需要匹配url
    if (!localRedirectInfo.url || localRedirectInfo.url && localRedirectInfo.url === location.href) {
      return localRedirectInfo.from || 'other_click';
    }
    return 'other_click';
  }

  // 4. 第三方来源
  var thirdPartyRefererHost = getThirdPartyReferrerHost();
  if (thirdPartyRefererHost) {
    return 'third-party';
  }
  return 'other_click';
};
export var getRoomEnterTraceData = function getRoomEnterTraceData() {
  var localDataRaw = session.get('roomenterTraceData');
  var localData = localDataRaw ? safeParseJSON(localDataRaw) : null;
  if (localDataRaw) {
    session.remove('roomenterTraceData');
  }
  if (localData) {
    return localData.traceData || '';
  }
  return '';
};

/**
 * 获取多直播间列表的头图
 * @param {array} normalMultiRoomList 多直播间列表
 * @param {boolean} isPC 区分电脑端
 */
export var getMultiRoomHeadImg = function getMultiRoomHeadImg() {
  var normalMultiRoomList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var isPC = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return function () {
    var _ref = normalMultiRoomList[0] || {},
      appImg = _ref.appImg,
      webImg = _ref.webImg;
    var img = !isPC ? appImg : webImg;
    return getDefaultImgServerUrl(img);
  };
};
export var unescapeRoomInfo = function unescapeRoomInfo(data) {
  var res = _objectSpread({}, data);
  var keys = ['nickname', 'title', 'anchorAnnouncement', 'chatRuleText'];
  keys.forEach(function (key) {
    if (res[key]) {
      res[key] = unescape(res[key]);
    }
  });
  return res;
};