import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _extends from "@babel/runtime/helpers/extends";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function escapeDot(text) {
  return text.replace(/\./g, '\\.');
}
export var mapDomainToCode = {
  'server-avatar.nimostatic.tv': 't',
  'web-ops.nimostatic.tv': 'o',
  'nimo-prod-server-cover.s3.ap-southeast-1.amazonaws.com': 's3-c',
  'server-cover-prod.nimostatic.tv': 'c',
  'server-sharepicture.nimostatic.tv': 's',
  'txvid.vod.nimo.tv': 'vt',
  'wsvid.vod.nimo.tv': 'vw',
  'web-article.nimostatic.tv': 'as',
  'article.nimo.tv': 'an',
  'amzvid.vod.nimo.tv': 'va',
  'web-www.nimostatic.tv': 'w',
  'www.nimo.tv': 'nw',
  'm.nimo.tv': 'nm',
  'msg-pic.nimostatic.tv': 'mp',
  'nimo-expression.nimostatic.tv': 'ne',
  'vppimage.vod.nimo.tv': 'vp',
  'vppimage-vod.nimo.tv': 'vpp'
};

// 在common.php的processImgUrl函数里面有一份临时的白名单，此处的改动要同步过去
if (DOMAINS.isDebug === 'true') {
  _extends(mapDomainToCode, {
    'nimo-test-server-cover.s3.ap-southeast-1.amazonaws.com': 's3-c-test',
    'amzvidtest.vod.nimo.tv': 'va-test',
    'wsvidtest.vod.nimo.tv': 'vw-test',
    'txvidtest.vod.nimo.tv': 'vt-test',
    'server-sharepicture-test.nimostatic.tv': 's-test',
    'server-cover-test.nimostatic.tv': 'c-test',
    'web-ops-test.nimostatic.tv': 'o-test',
    'server-avatar-test.nimostatic.tv': 't-test',
    'article-test.nimo.tv': 'an-test',
    'web-www-test.nimostatic.tv': 'w-test',
    'web-article-test.nimostatic.tv': 'as-test',
    'm-test.nimo.tv': 'nm-test',
    'www-test.nimo.tv': 'nw-test',
    'msg-pic-test.nimostatic.tv': 'mp-test',
    'vppimagetest.vod.nimo.tv': 'vp-test'
  });
}
if (DOMAINS.isLocal) {
  delete mapDomainToCode['m.nimo.tv'];
  delete mapDomainToCode['www.nimo.tv'];
  delete mapDomainToCode['m-test.nimo.tv'];
  delete mapDomainToCode['www-test.nimo.tv'];
}
var transformType = ['jpg', 'png', 'jpeg', 'webp'];
// 1-cover | 2-contain | 3-fill | 4-inside | 5-outside
var fitMap = [1, 2, 3, 4, 5];
// 当 fit = 1/2 时生效，默认 5
// 1-左上 | 2-顶部 | 3-f右上 | 4-左侧 | 5-中央 | 6-右侧 | 7-左下 | 8-底部 | 9-右下
var positionMap = [1, 2, 3, 4, 5, 6, 7, 8, 9];
// 当设置的图片大小大于原图时可设置
// 0-当指定尺寸大于原图时采用原图尺寸 | 1-允许将图片缩放至大于原图的尺寸（默认）
var withoutEnlargementMap = [0, 1];
var __Reg = new RegExp("^(https?:)?//(".concat(Object.keys(mapDomainToCode).map(escapeDot).join('|'), ")/(((\\s|\\S)+).(").concat(transformType.join('|'), "))"));
var _assembleUrl = function _assembleUrl(regMatchRes) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var transform = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var _ref = options || {},
    width = _ref.width,
    height = _ref.height,
    radius = _ref.radius,
    AI = _ref.AI,
    position = _ref.position,
    fit = _ref.fit,
    _ref$withoutEnlargeme = _ref.withoutEnlargement,
    withoutEnlargement = _ref$withoutEnlargeme === void 0 ? 0 : _ref$withoutEnlargeme,
    blur = _ref.blur,
    brightness = _ref.brightness,
    saturate = _ref.saturate,
    _ref$isService = _ref.isService,
    isService = _ref$isService === void 0 ? false : _ref$isService;
  if (!regMatchRes) return null;
  // const [, , domain, imgFullName, imgName, , imgFileName] = regMatchRes;
  var _regMatchRes = _slicedToArray(regMatchRes, 7),
    domain = _regMatchRes[2],
    imgFullName = _regMatchRes[3],
    imgFileName = _regMatchRes[6];
  var processParams = [width ? "w".concat(width) : '', height ? "h".concat(height) : '', radius > 0 ? "r".concat(radius) : '', AI ? 'a1' : '', fitMap.includes(fit) ? "f".concat(fit) : '', positionMap.includes(position) ? "p".concat(position) : '', withoutEnlargementMap.includes(withoutEnlargement) ? "l".concat(withoutEnlargement) : '', blur ? "b".concat(blur) : '', brightness ? "mb".concat(brightness) : '', saturate ? "ms".concat(saturate) : ''];
  var processStr = processParams.reduce(function (pre, cur) {
    if (cur) {
      return "".concat(pre).concat(pre ? '_' : '').concat(cur);
    } else {
      return pre;
    }
  }, '');
  return "".concat(isService ? DOMAINS.imgService : DOMAINS.img, "/").concat(mapDomainToCode[domain], "/").concat(imgFullName.replace(/ /g,
  // 由于srcset以空格作为解析的分隔符，带空格时解析会报错
  '%20'), "/").concat(processStr, "/img.").concat(transform || imgFileName);
};

/**
 * 将图片地址根据参数转换为对应的图片服务地址
 * @param {string} src
 * @param {object} options
 * @param {string|array} transform
 */
export var getDefaultImgServerUrl = function getDefaultImgServerUrl(src) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var transform = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  if (typeof src !== 'string') return src;
  var res = src.match(__Reg);
  if (res) {
    return _assembleUrl(res, options, transform);
  }
  return src;
};

// 根据多个配置返回多个url
export var getMultipleUrls = function getMultipleUrls(src) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var commonConf = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  if (typeof src !== 'string') return src;
  var res = src.match(__Reg);
  if (!Array.isArray(options)) {
    // eslint-disable-next-line no-param-reassign
    options = [options || {}];
  }
  var results = [];
  var _iterator = _createForOfIteratorHelper(options),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _opt = _step.value;
      _opt = _objectSpread(_objectSpread({}, commonConf), _opt);
      results.push(res ? _assembleUrl(res, _opt, _opt.transform) : src);
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  if (results.length > 1) {
    return results;
  } else {
    return results[0];
  }
};

// 获取相同配置不同格式图片
export var getMultipleTranformImgUrls = function getMultipleTranformImgUrls(src) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var transform = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var _opts = [];
  var _iterator2 = _createForOfIteratorHelper(transform),
    _step2;
  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var t = _step2.value;
      _opts.push(_objectSpread(_objectSpread({}, options), {}, {
        transform: t
      }));
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }
  return getMultipleUrls(src, _opts);
};
export var getWebpImgServerUrl = function getWebpImgServerUrl(src, options) {
  return getDefaultImgServerUrl(src, options, 'webp');
};

// 获取绘制海报时的图片服务地址（img-service）
export var getPosterCanvasUrl = function getPosterCanvasUrl(src) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var transform = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  return getDefaultImgServerUrl(src, _objectSpread(_objectSpread({}, options), {}, {
    isService: true
  }), transform);
};
export var getNinePatchJsonUrl = function getNinePatchJsonUrl(src) {
  if (typeof src !== 'string') return '';
  var regMatchRes = src.match(__Reg);
  if (regMatchRes) {
    var _regMatchRes2 = _slicedToArray(regMatchRes, 4),
      domain = _regMatchRes2[2],
      imgFullName = _regMatchRes2[3];
    return "".concat(DOMAINS.img, "/").concat(mapDomainToCode[domain], "/").concat(imgFullName.replace(/ /g,
    // 由于srcset以空格作为解析的分隔符，带空格时解析会报错
    '%20'), "/no_ni/img.json");
  }
  return '';
};