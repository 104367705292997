import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { local, session } from '@client/common/utils/storage';
var NIMO_ROOM_LOGIN_GUIDE_PANEL = 'nimo_room_login_rate_high';
var NIMO_ROOM_PLAYER_RATE_SWITCH = 'nimo_room_player_rate_switch';
var NIMO_ROOM_WATCHING_WITHIN_1_MIN = 'nimo_room_watching_within_1_min';
export var checkCanHighRate = function checkCanHighRate() {
  var result = false;
  var time = local.get(NIMO_ROOM_LOGIN_GUIDE_PANEL);
  var _ref = time ? time.split('-') : [0, 0, 0],
    _ref2 = _slicedToArray(_ref, 3),
    y = _ref2[0],
    m = _ref2[1],
    d = _ref2[2];
  var now = new Date();
  var year = now.getFullYear();
  var month = now.getMonth();
  var date = now.getDate();
  if (!time || time && (year > y || month > m || date > d)) {
    result = true;
  }
  return result;
};
export var updateOverHighRateTime = function updateOverHighRateTime() {
  var now = new Date();
  var year = now.getFullYear();
  var month = now.getMonth();
  var date = now.getDate();
  local.set(NIMO_ROOM_LOGIN_GUIDE_PANEL, "".concat(year, "-").concat(month, "-").concat(date));
};
export var getPlayerRateSwitch = function getPlayerRateSwitch() {
  return local.get(NIMO_ROOM_PLAYER_RATE_SWITCH);
};
export var setPlayerRateSwitch = function setPlayerRateSwitch(rate) {
  local.set(NIMO_ROOM_PLAYER_RATE_SWITCH, rate);
};
export var removePlayerRateSwitch = function removePlayerRateSwitch() {
  local.remove(NIMO_ROOM_PLAYER_RATE_SWITCH);
};
export var getIsWatchingWithin1Min = function getIsWatchingWithin1Min() {
  return session.get(NIMO_ROOM_WATCHING_WITHIN_1_MIN);
};
export var setIsWatchingWithin1Min = function setIsWatchingWithin1Min() {
  session.set(NIMO_ROOM_WATCHING_WITHIN_1_MIN, 1);
};
export var removeIsWatchingWithin1Min = function removeIsWatchingWithin1Min() {
  session.remove(NIMO_ROOM_WATCHING_WITHIN_1_MIN);
};