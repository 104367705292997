function getHiddenProp() {
  var prefixes = ['webkit', 'moz', 'ms', 'o'];
  if ('hidden' in document) return 'hidden';
  for (var i = 0; i < prefixes.length; i++) {
    if (prefixes[i] + 'Hidden' in document) return prefixes[i] + 'Hidden';
  }
  return null;
}
function getVisibilityState() {
  var prefixes = ['webkit', 'moz', 'ms', 'o'];
  if ('visibilityState' in document) return 'visibilityState';
  for (var i = 0; i < prefixes.length; i++) {
    if (prefixes[i] + 'VisibilityState' in document) return prefixes[i] + 'VisibilityState';
  }
  return null;
}
function isHidden() {
  var prop = getHiddenProp();
  if (!prop) return false;
  return document[prop];
}
function getVisibilityChangeEventName() {
  var visProp = getHiddenProp();
  if (visProp) {
    visProp = visProp.replace(/[H|h]idden/, '');
    return "".concat(visProp, "visibilitychange");
  }
  return null;
}
export default {
  getHiddenProp: getHiddenProp,
  getVisibilityState: getVisibilityState,
  isHidden: isHidden,
  getVisibilityChangeEventName: getVisibilityChangeEventName
};