import ua from '@client/common/utils/ua';
import { init as signalSocketInit } from '@client/common/services/socket/signalSocketMiddleware';
import { inIframe } from '@client/common/utils/inIframe';
import getUrlParams from '@client/common/utils/getUrlParams';
var inited = false;
export function initSockitIfNeeded() {
  if (inited) return;
  inited = true;
  if (ua !== null && ua !== void 0 && ua.isInNimoApp || inIframe() && getUrlParams('_uuid')) {
    return;
  }
  signalSocketInit();
}