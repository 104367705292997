var EMediaNetType = {
  // 需要和taf的 EMediaNetType 同步
  ENET_WIFI: 0,
  ENET_MOB: 1,
  ENET_DISCONNECT: 2,
  ENET_2G: 3,
  ENET_3G: 4,
  ENET_4G: 5,
  ENET_UNKNOWN: 127
};
export function getNetType() {
  var _window;
  var _window$navigator = (_window = window) === null || _window === void 0 ? void 0 : _window.navigator,
    onLine = _window$navigator.onLine,
    connection = _window$navigator.connection,
    mozConnection = _window$navigator.mozConnection,
    webkitConnection = _window$navigator.webkitConnection;
  var _connectionAdaptor = connection || mozConnection || webkitConnection;
  if (_connectionAdaptor) {
    if (_connectionAdaptor.effectiveType) {
      if (_connectionAdaptor.effectiveType === '4g') {
        return EMediaNetType.ENET_4G;
      } else if (_connectionAdaptor.effectiveType === '3g') {
        return EMediaNetType.ENET_3G;
      } else if (_connectionAdaptor.effectiveType === '2g' || _connectionAdaptor.effectiveType === 'slow-2g') {
        return EMediaNetType.ENET_2G;
      }
    } else if (_connectionAdaptor.type === 'wifi') {
      return EMediaNetType.ENET_WIFI;
    }
  }
  if (onLine === false) {
    return EMediaNetType.ENET_DISCONNECT;
  }
  return EMediaNetType.ENET_UNKNOWN;
}