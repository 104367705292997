import { unescape } from '@client/common/utils/dom';
export var getDiffTitleAndDesp = function getDiffTitleAndDesp(businessType, roomSort, data) {
  var roomTitleLang = '';
  var roomTitleLangParam = [];
  var roomDescriptionLangParam = [];
  var DiffTitleAndDesp = {};
  var game = data.game;
  var nickname = unescape(data.nickname);

  // 秀场直播间
  if (businessType === 2) {
    roomTitleLang = 'starroom';
    roomTitleLangParam = [nickname, nickname];
    roomDescriptionLangParam = [nickname, nickname];
  }
  // 赛事直播间
  else if (businessType === 1 && roomSort === 1) {
    roomTitleLang = 'matchroom';
    roomTitleLangParam = [nickname, game];
    roomDescriptionLangParam = [nickname, game];
  }
  // 其余直播间
  else {
    roomTitleLang = 'room';
    roomTitleLangParam = [nickname, game, nickname, game];
    roomDescriptionLangParam = [nickname, game];
  }
  DiffTitleAndDesp.roomTitleLang = roomTitleLang;
  DiffTitleAndDesp.roomTitleLangParam = roomTitleLangParam;
  DiffTitleAndDesp.roomDescriptionLangParam = roomDescriptionLangParam;
  return DiffTitleAndDesp;
};