import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var MatchShareConfig = function MatchShareConfig() {
  this.componentType = 0;
  this.shareLotteryId = "";
};
MatchShareConfig.prototype._clone = function () {
  return new NimoBuss.MatchShareConfig();
};
MatchShareConfig.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MatchShareConfig.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MatchShareConfig.prototype.writeTo = function (os) {
  os.writeInt32(0, this.componentType);
  os.writeString(1, this.shareLotteryId);
};
MatchShareConfig.prototype.readFrom = function (is) {
  this.componentType = is.readInt32(0, false, this.componentType);
  this.shareLotteryId = is.readString(1, false, this.shareLotteryId);
};
NimoBuss.MatchShareConfig = MatchShareConfig;
export default MatchShareConfig;