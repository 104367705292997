import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var GetAnchorUnReadPrivilegeRsp = function GetAnchorUnReadPrivilegeRsp() {
  this.iCode = 0;
  this.sErrMsg = "";
  this.vUnreadPrivilegeIds = new Taf.Vector(new Taf.INT32());
};
GetAnchorUnReadPrivilegeRsp.prototype._clone = function () {
  return new Nimo.GetAnchorUnReadPrivilegeRsp();
};
GetAnchorUnReadPrivilegeRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetAnchorUnReadPrivilegeRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetAnchorUnReadPrivilegeRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeString(1, this.sErrMsg);
  os.writeVector(2, this.vUnreadPrivilegeIds);
};
GetAnchorUnReadPrivilegeRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.sErrMsg = is.readString(1, false, this.sErrMsg);
  this.vUnreadPrivilegeIds = is.readVector(2, false, this.vUnreadPrivilegeIds);
};
Nimo.GetAnchorUnReadPrivilegeRsp = GetAnchorUnReadPrivilegeRsp;
export default GetAnchorUnReadPrivilegeRsp;