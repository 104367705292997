module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div id="root" class="nimo-root" >\n    ' +
((__t = ( require('ejs-loader!@desktop/src/common/components/MktHeader/skeleton.ejs')() )) == null ? '' : __t) +
'\n    ' +
((__t = ( require('ejs-loader!../common_index/RoomSkeleton.ejs')() )) == null ? '' : __t) +
'\n</div>';

}
return __p
}