import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './CdnPushInfo';
var GetPushInfoRsp = function GetPushInfoRsp() {
  this.sUrlBase = "";
  this.sCode = "";
  this.iBlacklistInterceptType = 0;
  this.iBlacklistInterceptFinishTime = 0;
  this.vInfos = new Taf.Vector(new Nimo.CdnPushInfo());
  this.iCode = 0;
  this.sErrMsg = "";
};
GetPushInfoRsp.prototype._clone = function () {
  return new Nimo.GetPushInfoRsp();
};
GetPushInfoRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetPushInfoRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetPushInfoRsp.prototype.writeTo = function (os) {
  os.writeString(0, this.sUrlBase);
  os.writeString(1, this.sCode);
  os.writeInt32(2, this.iBlacklistInterceptType);
  os.writeInt32(3, this.iBlacklistInterceptFinishTime);
  os.writeVector(4, this.vInfos);
  os.writeInt32(5, this.iCode);
  os.writeString(6, this.sErrMsg);
};
GetPushInfoRsp.prototype.readFrom = function (is) {
  this.sUrlBase = is.readString(0, false, this.sUrlBase);
  this.sCode = is.readString(1, false, this.sCode);
  this.iBlacklistInterceptType = is.readInt32(2, false, this.iBlacklistInterceptType);
  this.iBlacklistInterceptFinishTime = is.readInt32(3, false, this.iBlacklistInterceptFinishTime);
  this.vInfos = is.readVector(4, false, this.vInfos);
  this.iCode = is.readInt32(5, false, this.iCode);
  this.sErrMsg = is.readString(6, false, this.sErrMsg);
};
Nimo.GetPushInfoRsp = GetPushInfoRsp;
export default GetPushInfoRsp;