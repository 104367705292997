import retry from '@client/common/utils/retry';

/**
 * @template T
 * @param {() => Promise<T>} init
 */
export function createPromiseCache(init) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _ref$retry = _ref.retry,
    _ref$retry2 = _ref$retry === void 0 ? {} : _ref$retry,
    _ref$retry2$delay = _ref$retry2.delay,
    delay = _ref$retry2$delay === void 0 ? 500 : _ref$retry2$delay,
    _ref$retry2$max = _ref$retry2.max,
    max = _ref$retry2$max === void 0 ? 1 : _ref$retry2$max;
  /** @type {T} */
  var cache;

  /** @type { Promise<T>} */
  var pCache;
  var getPromise = function getPromise() {
    if (cache) return Promise.resolve(cache);
    if (pCache) return pCache;
    pCache = init();
    pCache.then(function (res) {
      cache = res;
      pCache = null;
    }, function () {
      pCache = null;
    });
    return pCache;
  };
  if (max && max > 1) {
    getPromise = retry(getPromise, delay, max);
  }

  /**
   * @param {(err: unknown, data: T) => void} callback
   */
  var wrap = function wrap(callback) {
    if (cache) {
      callback(null, cache);
    } else {
      getPromise().then(function (value) {
        callback(null, value);
      }, function (err) {
        callback(err, null);
      });
    }
  };
  return {
    getCache: function getCache() {
      return cache;
    },
    get: getPromise,
    callback: wrap
  };
}