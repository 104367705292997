import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BaseParam';
var GetFanGroupCreateSwitchReqV2 = function GetFanGroupCreateSwitchReqV2() {
  this.baseParam = new NimoBuss.BaseParam();
};
GetFanGroupCreateSwitchReqV2.prototype._clone = function () {
  return new NimoBuss.GetFanGroupCreateSwitchReqV2();
};
GetFanGroupCreateSwitchReqV2.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetFanGroupCreateSwitchReqV2.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetFanGroupCreateSwitchReqV2.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
};
GetFanGroupCreateSwitchReqV2.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
};
NimoBuss.GetFanGroupCreateSwitchReqV2 = GetFanGroupCreateSwitchReqV2;
export default GetFanGroupCreateSwitchReqV2;