import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './LiveInfo';
var LiveTypeChange = function LiveTypeChange() {
  this.tLiveInfo = new Nimo.LiveInfo();
  this.iPrivilege = 0;
  this.sPrivateKey = "";
};
LiveTypeChange.prototype._clone = function () {
  return new Nimo.LiveTypeChange();
};
LiveTypeChange.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LiveTypeChange.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LiveTypeChange.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tLiveInfo);
  os.writeInt32(1, this.iPrivilege);
  os.writeString(2, this.sPrivateKey);
};
LiveTypeChange.prototype.readFrom = function (is) {
  this.tLiveInfo = is.readStruct(0, false, this.tLiveInfo);
  this.iPrivilege = is.readInt32(1, false, this.iPrivilege);
  this.sPrivateKey = is.readString(2, false, this.sPrivateKey);
};
Nimo.LiveTypeChange = LiveTypeChange;
export default LiveTypeChange;