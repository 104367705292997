var IFRAME_LOGIN = 'NiMO_IFRAME_LOGIN';
export var LOGIN = "".concat(IFRAME_LOGIN, "_LOGIN");
var EVENTS = {
  LOGIN: LOGIN
};
export default {
  login: function login(type) {
    import( /* webpackChunkName: "showIframeLoginModal" */'./showIframeLoginModal').then(function (_ref) {
      var showIframeLoginModal = _ref.default;
      showIframeLoginModal({
        type: type,
        events: EVENTS
      });
    });
  }
};