import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BaseParam';
var GetTeamIntroductionListReq = function GetTeamIntroductionListReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.eventId = 0;
  this.offset = 0;
  this.pageSize = 0;
  this.countryCode = "";
  this.lcid = 0;
  this.language = "";
};
GetTeamIntroductionListReq.prototype._clone = function () {
  return new NimoBuss.GetTeamIntroductionListReq();
};
GetTeamIntroductionListReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetTeamIntroductionListReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetTeamIntroductionListReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeInt32(1, this.eventId);
  os.writeInt32(2, this.offset);
  os.writeInt32(3, this.pageSize);
  os.writeString(4, this.countryCode);
  os.writeInt32(5, this.lcid);
  os.writeString(6, this.language);
};
GetTeamIntroductionListReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.eventId = is.readInt32(1, false, this.eventId);
  this.offset = is.readInt32(2, false, this.offset);
  this.pageSize = is.readInt32(3, false, this.pageSize);
  this.countryCode = is.readString(4, false, this.countryCode);
  this.lcid = is.readInt32(5, false, this.lcid);
  this.language = is.readString(6, false, this.language);
};
NimoBuss.GetTeamIntroductionListReq = GetTeamIntroductionListReq;
export default GetTeamIntroductionListReq;