import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './FollowStatusAndCount';
var FollowStatusRsp = function FollowStatusRsp() {
  this.followStatusAndConts = new Taf.Vector(new NimoBuss.FollowStatusAndCount());
};
FollowStatusRsp.prototype._clone = function () {
  return new NimoBuss.FollowStatusRsp();
};
FollowStatusRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FollowStatusRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FollowStatusRsp.prototype.writeTo = function (os) {
  os.writeVector(0, this.followStatusAndConts);
};
FollowStatusRsp.prototype.readFrom = function (is) {
  this.followStatusAndConts = is.readVector(0, false, this.followStatusAndConts);
};
NimoBuss.FollowStatusRsp = FollowStatusRsp;
export default FollowStatusRsp;