import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var ETopicType = {
  kRoom: 1,
  kCountry: 2,
  kPlatform: 3,
  kLang: 4,
  kCountryLang: 5,
  kCountryLangPlat: 6,
  kLangPlat: 7,
  kRoomLang: 8,
  kRoomTypeLang: 9,
  kRoomType: 10,
  kAnchorCountry: 11,
  kAnchorCountryType: 12,
  kRoomPlat: 13,
  kRoomUserLang: 14,
  kRoomCountryLangPlat: 15,
  kAppId: 16,
  kAnchorLang: 17,
  kAnchorLangPlat: 18,
  kAnchorCountryLang: 19,
  kAnchorCountryLangPlat: 20,
  kRoomPunish: 21
};
Nimo.ETopicType = ETopicType;
export default ETopicType;