import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var VoiceEntertainmentLabelLevel2 = function VoiceEntertainmentLabelLevel2() {
  this.labelId = 0;
  this.weight = 0;
  this.relatedEntertainment = 0;
  this.labelName = "";
};
VoiceEntertainmentLabelLevel2.prototype._clone = function () {
  return new NimoBuss.VoiceEntertainmentLabelLevel2();
};
VoiceEntertainmentLabelLevel2.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
VoiceEntertainmentLabelLevel2.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
VoiceEntertainmentLabelLevel2.prototype.writeTo = function (os) {
  os.writeInt32(0, this.labelId);
  os.writeInt32(2, this.weight);
  os.writeInt32(3, this.relatedEntertainment);
  os.writeString(4, this.labelName);
};
VoiceEntertainmentLabelLevel2.prototype.readFrom = function (is) {
  this.labelId = is.readInt32(0, false, this.labelId);
  this.weight = is.readInt32(2, false, this.weight);
  this.relatedEntertainment = is.readInt32(3, false, this.relatedEntertainment);
  this.labelName = is.readString(4, false, this.labelName);
};
NimoBuss.VoiceEntertainmentLabelLevel2 = VoiceEntertainmentLabelLevel2;
export default VoiceEntertainmentLabelLevel2;