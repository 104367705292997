import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
function getPerfBudgets() {
  return {
    TTFB: [100, 300]
  };
}
function getPerfData() {
  var _performance$getEntri, _performance$getEntri2, _performance$getEntri3, _performance$getEntri4;
  var perfTime = window.performance.timing;
  var metric = [];
  metric.TTFB = perfTime.responseStart - perfTime.navigationStart; // Total First byte Time (responseStart - navigationStart)
  metric.TTI = perfTime.domInteractive - perfTime.navigationStart; // DOM Interactive Time (domInteractive - navigationStart)
  metric.DCL = perfTime.domContentLoadedEventEnd - perfTime.navigationStart; // DOM Content Load Time (domContentLoadedEventEnd - navigationStart)
  metric['Page Load'] = perfTime.loadEventEnd - perfTime.loadEventStart; // Onload (loadEventEnd - loadEventStart)
  metric.Latency = perfTime.responseStart - perfTime.fetchStart; // Latency (responseStart - fetchStart)
  metric.DNS = perfTime.domainLookupEnd - perfTime.domainLookupStart; // DNS / Domain Lookup Time (domainLookupEnd - domainLookupStart)
  metric['Server connect Time'] = perfTime.connectEnd - perfTime.connectStart; // Server connect Time (connectEnd - connectStart)
  metric['Server Response Time'] = perfTime.responseStart - perfTime.requestStart; // Server Response Time (responseStart - requestStart)
  metric['Page Load time'] = perfTime.loadEventStart - perfTime.navigationStart; // Page Load time (loadEventStart - navigationStart)
  metric['Transfer/Page Download Time'] = perfTime.responseEnd - perfTime.responseStart; // Transfer/Page Download Time (responseEnd - responseStart)
  metric['DOM Processing to Interactive'] = perfTime.domInteractive - perfTime.domLoading; // DOM Processing to Interactive (domInteractive - domLoading)
  metric['DOM Interactive to Complete'] = perfTime.domComplete - perfTime.domInteractive; // DOM Interactive to Complete (domComplete - domInteractive)
  metric['First Paint Time'] = Math.round(((_performance$getEntri = performance.getEntriesByName('first-paint')) === null || _performance$getEntri === void 0 ? void 0 : (_performance$getEntri2 = _performance$getEntri[0]) === null || _performance$getEntri2 === void 0 ? void 0 : _performance$getEntri2.startTime) || 0);
  metric['First Contentful Paint'] = Math.round(((_performance$getEntri3 = performance.getEntriesByName('first-contentful-paint')) === null || _performance$getEntri3 === void 0 ? void 0 : (_performance$getEntri4 = _performance$getEntri3[0]) === null || _performance$getEntri4 === void 0 ? void 0 : _performance$getEntri4.startTime) || 0);
  return metric;
}
export function printPerfData() {
  var label = 'Page Performance Data';
  var prefData = getPerfData();
  var perfBudgets = getPerfBudgets();
  console.group(label);
  for (var i in prefData) {
    if (prefData.hasOwnProperty(i)) {
      var v = prefData[i];
      var budget = perfBudgets[i];
      var color = 'grey';
      if (budget) {
        var _ref = budget || [],
          _ref2 = _slicedToArray(_ref, 2),
          min = _ref2[0],
          max = _ref2[1];
        if (v <= min) {
          color = 'green';
        } else if (v >= max) {
          color = 'red';
        }
      }
      console.log("%c".concat(i, ": %c").concat(v, "ms"), 'color: grey', "color: ".concat(color));
    }
  }
  console.groupEnd(label);
}