import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var VIPCustomEmojiRecord = function VIPCustomEmojiRecord() {
  this.lAnchorId = 0;
  this.iLevel = 0;
  this.iPosition = 0;
  this.sEmojiUrl = "";
  this.iStatus = 0;
};
VIPCustomEmojiRecord.prototype._clone = function () {
  return new Nimo.VIPCustomEmojiRecord();
};
VIPCustomEmojiRecord.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
VIPCustomEmojiRecord.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
VIPCustomEmojiRecord.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lAnchorId);
  os.writeInt32(1, this.iLevel);
  os.writeInt32(2, this.iPosition);
  os.writeString(3, this.sEmojiUrl);
  os.writeInt32(4, this.iStatus);
};
VIPCustomEmojiRecord.prototype.readFrom = function (is) {
  this.lAnchorId = is.readInt64(0, false, this.lAnchorId);
  this.iLevel = is.readInt32(1, false, this.iLevel);
  this.iPosition = is.readInt32(2, false, this.iPosition);
  this.sEmojiUrl = is.readString(3, false, this.sEmojiUrl);
  this.iStatus = is.readInt32(4, false, this.iStatus);
};
Nimo.VIPCustomEmojiRecord = VIPCustomEmojiRecord;
export default VIPCustomEmojiRecord;