import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var FansEmoticonView = function FansEmoticonView() {
  this.emoticonId = 0;
  this.url = "";
  this.enable = 0;
  this.index = 0;
  this.status = 0;
  this.name = "";
};
FansEmoticonView.prototype._clone = function () {
  return new NimoBuss.FansEmoticonView();
};
FansEmoticonView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FansEmoticonView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FansEmoticonView.prototype.writeTo = function (os) {
  os.writeInt64(0, this.emoticonId);
  os.writeString(1, this.url);
  os.writeInt32(2, this.enable);
  os.writeInt32(3, this.index);
  os.writeInt32(4, this.status);
  os.writeString(5, this.name);
};
FansEmoticonView.prototype.readFrom = function (is) {
  this.emoticonId = is.readInt64(0, false, this.emoticonId);
  this.url = is.readString(1, false, this.url);
  this.enable = is.readInt32(2, false, this.enable);
  this.index = is.readInt32(3, false, this.index);
  this.status = is.readInt32(4, false, this.status);
  this.name = is.readString(5, false, this.name);
};
NimoBuss.FansEmoticonView = FansEmoticonView;
export default FansEmoticonView;