import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './PropsItem';
import './SpecPropItem';
import './QuickPropItem';
import './OverlayEffect';
import './StreamerEffect';
var GetPropsListRsp = function GetPropsListRsp() {
  this.vPropsItemList = new Taf.Vector(new Nimo.PropsItem());
  this.sLang = "";
  this.sClientType = "";
  this.sMd5 = "";
  this.mSpecPropsItemList = new Taf.Map(new Taf.INT32(), new Taf.Vector(new Nimo.SpecPropItem()));
  this.mShowQuickPropList = new Taf.Map(new Taf.INT32(), new Taf.Map(new Taf.STRING(), new Nimo.QuickPropItem()));
  this.vQuickPropTypeList = new Taf.Vector(new Taf.INT32());
  this.iComboCountDown = 0;
  this.mOverlayEffect = new Taf.Map(new Taf.INT32(), new Nimo.OverlayEffect());
  this.mStreamerEffect = new Taf.Map(new Taf.INT32(), new Taf.Vector(new Nimo.StreamerEffect()));
};
GetPropsListRsp.prototype._clone = function () {
  return new Nimo.GetPropsListRsp();
};
GetPropsListRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetPropsListRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetPropsListRsp.prototype.writeTo = function (os) {
  os.writeVector(0, this.vPropsItemList);
  os.writeString(1, this.sLang);
  os.writeString(2, this.sClientType);
  os.writeString(3, this.sMd5);
  os.writeMap(4, this.mSpecPropsItemList);
  os.writeMap(5, this.mShowQuickPropList);
  os.writeVector(6, this.vQuickPropTypeList);
  os.writeInt32(7, this.iComboCountDown);
  os.writeMap(8, this.mOverlayEffect);
  os.writeMap(9, this.mStreamerEffect);
};
GetPropsListRsp.prototype.readFrom = function (is) {
  this.vPropsItemList = is.readVector(0, false, this.vPropsItemList);
  this.sLang = is.readString(1, false, this.sLang);
  this.sClientType = is.readString(2, false, this.sClientType);
  this.sMd5 = is.readString(3, false, this.sMd5);
  this.mSpecPropsItemList = is.readMap(4, false, this.mSpecPropsItemList);
  this.mShowQuickPropList = is.readMap(5, false, this.mShowQuickPropList);
  this.vQuickPropTypeList = is.readVector(6, false, this.vQuickPropTypeList);
  this.iComboCountDown = is.readInt32(7, false, this.iComboCountDown);
  this.mOverlayEffect = is.readMap(8, false, this.mOverlayEffect);
  this.mStreamerEffect = is.readMap(9, false, this.mStreamerEffect);
};
Nimo.GetPropsListRsp = GetPropsListRsp;
export default GetPropsListRsp;