import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './RoomScreenshotsView';
var VisitHistoryInfo = function VisitHistoryInfo() {
  this.DEFAULT_ROOM_NAME = "xxx's Live Channel";
  this.DEFAULT_ROOM_GAME = "unknow";
  this.DEFAULT_LIVE_ANNOUNCEMENT = "Hi! New streamer here. Hope you enjoy my livestreams and feel free to tap that follow button!";
  this.id = 0;
  this.roomTheme = "";
  this.alise = "";
  this.roomType = 0;
  this.roomTypeName = "";
  this.anchorName = "";
  this.anchorId = 0;
  this.onlineStatus = 0;
  this.liveStreamStatus = 0;
  this.lcid = 0;
  this.lcidText = "";
  this.roomScreenshots = new Taf.Vector(new NimoBuss.RoomScreenshotsView());
  this.visitTime = 0;
  this.isFollow = 0;
  this.isLottery = 0;
  this.microPKStatus = 0;
  this.isPlayback = 0;
  this.templateType = 0;
};
VisitHistoryInfo.prototype._clone = function () {
  return new NimoBuss.VisitHistoryInfo();
};
VisitHistoryInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
VisitHistoryInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
VisitHistoryInfo.prototype.writeTo = function (os) {
  os.writeString(0, this.DEFAULT_ROOM_NAME);
  os.writeString(1, this.DEFAULT_ROOM_GAME);
  os.writeString(2, this.DEFAULT_LIVE_ANNOUNCEMENT);
  os.writeInt64(3, this.id);
  os.writeString(4, this.roomTheme);
  os.writeString(5, this.alise);
  os.writeInt64(6, this.roomType);
  os.writeString(7, this.roomTypeName);
  os.writeString(8, this.anchorName);
  os.writeInt64(9, this.anchorId);
  os.writeInt32(10, this.onlineStatus);
  os.writeInt32(11, this.liveStreamStatus);
  os.writeInt32(12, this.lcid);
  os.writeString(13, this.lcidText);
  os.writeVector(14, this.roomScreenshots);
  os.writeInt64(15, this.visitTime);
  os.writeInt32(16, this.isFollow);
  os.writeInt32(17, this.isLottery);
  os.writeInt32(18, this.microPKStatus);
  os.writeInt32(19, this.isPlayback);
  os.writeInt32(20, this.templateType);
};
VisitHistoryInfo.prototype.readFrom = function (is) {
  this.DEFAULT_ROOM_NAME = is.readString(0, false, this.DEFAULT_ROOM_NAME);
  this.DEFAULT_ROOM_GAME = is.readString(1, false, this.DEFAULT_ROOM_GAME);
  this.DEFAULT_LIVE_ANNOUNCEMENT = is.readString(2, false, this.DEFAULT_LIVE_ANNOUNCEMENT);
  this.id = is.readInt64(3, false, this.id);
  this.roomTheme = is.readString(4, false, this.roomTheme);
  this.alise = is.readString(5, false, this.alise);
  this.roomType = is.readInt64(6, false, this.roomType);
  this.roomTypeName = is.readString(7, false, this.roomTypeName);
  this.anchorName = is.readString(8, false, this.anchorName);
  this.anchorId = is.readInt64(9, false, this.anchorId);
  this.onlineStatus = is.readInt32(10, false, this.onlineStatus);
  this.liveStreamStatus = is.readInt32(11, false, this.liveStreamStatus);
  this.lcid = is.readInt32(12, false, this.lcid);
  this.lcidText = is.readString(13, false, this.lcidText);
  this.roomScreenshots = is.readVector(14, false, this.roomScreenshots);
  this.visitTime = is.readInt64(15, false, this.visitTime);
  this.isFollow = is.readInt32(16, false, this.isFollow);
  this.isLottery = is.readInt32(17, false, this.isLottery);
  this.microPKStatus = is.readInt32(18, false, this.microPKStatus);
  this.isPlayback = is.readInt32(19, false, this.isPlayback);
  this.templateType = is.readInt32(20, false, this.templateType);
};
NimoBuss.VisitHistoryInfo = VisitHistoryInfo;
export default VisitHistoryInfo;