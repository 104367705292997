import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './TextFormat';
import './PicFormat';
var RichLabel = function RichLabel() {
  this.iType = 0;
  this.tText = new Nimo.TextFormat();
  this.tPic = new Nimo.PicFormat();
};
RichLabel.prototype._clone = function () {
  return new Nimo.RichLabel();
};
RichLabel.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
RichLabel.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
RichLabel.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iType);
  os.writeStruct(1, this.tText);
  os.writeStruct(2, this.tPic);
};
RichLabel.prototype.readFrom = function (is) {
  this.iType = is.readInt32(0, false, this.iType);
  this.tText = is.readStruct(1, false, this.tText);
  this.tPic = is.readStruct(2, false, this.tPic);
};
Nimo.RichLabel = RichLabel;
export default RichLabel;