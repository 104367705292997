import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var FanGroupLevelParam = function FanGroupLevelParam() {
  this.fanId = 0;
  this.anchorId = 0;
};
FanGroupLevelParam.prototype._clone = function () {
  return new NimoBuss.FanGroupLevelParam();
};
FanGroupLevelParam.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FanGroupLevelParam.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FanGroupLevelParam.prototype.writeTo = function (os) {
  os.writeInt64(0, this.fanId);
  os.writeInt64(1, this.anchorId);
};
FanGroupLevelParam.prototype.readFrom = function (is) {
  this.fanId = is.readInt64(0, false, this.fanId);
  this.anchorId = is.readInt64(1, false, this.anchorId);
};
NimoBuss.FanGroupLevelParam = FanGroupLevelParam;
export default FanGroupLevelParam;