import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var ForbidUserMessageNotice = function ForbidUserMessageNotice() {
  this.lForbidUid = 0;
  this.lRoomId = 0;
  this.lTimeS = 0;
  this.sForbidNick = "";
  this.lOperateUid = 0;
  this.sOperateNick = "";
  this.bPrsentner = true;
};
ForbidUserMessageNotice.prototype._clone = function () {
  return new Nimo.ForbidUserMessageNotice();
};
ForbidUserMessageNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ForbidUserMessageNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ForbidUserMessageNotice.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lForbidUid);
  os.writeInt64(1, this.lRoomId);
  os.writeInt64(2, this.lTimeS);
  os.writeString(3, this.sForbidNick);
  os.writeInt64(4, this.lOperateUid);
  os.writeString(5, this.sOperateNick);
  os.writeBoolean(6, this.bPrsentner);
};
ForbidUserMessageNotice.prototype.readFrom = function (is) {
  this.lForbidUid = is.readInt64(0, false, this.lForbidUid);
  this.lRoomId = is.readInt64(1, false, this.lRoomId);
  this.lTimeS = is.readInt64(2, false, this.lTimeS);
  this.sForbidNick = is.readString(3, false, this.sForbidNick);
  this.lOperateUid = is.readInt64(4, false, this.lOperateUid);
  this.sOperateNick = is.readString(5, false, this.sOperateNick);
  this.bPrsentner = is.readBoolean(6, false, this.bPrsentner);
};
Nimo.ForbidUserMessageNotice = ForbidUserMessageNotice;
export default ForbidUserMessageNotice;