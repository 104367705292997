import "core-js/modules/es6.array.sort.js";
import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var AnchorLabelView = function AnchorLabelView() {
  this.labelId = 0;
  this.sort = 0;
  this.labelName = "";
  this.labelNameLang = "";
  this.type = 0;
  this.anchorLabelType = 0;
  this.timeStamp = 0;
};
AnchorLabelView.prototype._clone = function () {
  return new NimoBuss.AnchorLabelView();
};
AnchorLabelView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorLabelView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorLabelView.prototype.writeTo = function (os) {
  os.writeInt32(0, this.labelId);
  os.writeInt32(1, this.sort);
  os.writeString(2, this.labelName);
  os.writeString(3, this.labelNameLang);
  os.writeInt32(4, this.type);
  os.writeInt32(5, this.anchorLabelType);
  os.writeInt64(6, this.timeStamp);
};
AnchorLabelView.prototype.readFrom = function (is) {
  this.labelId = is.readInt32(0, false, this.labelId);
  this.sort = is.readInt32(1, false, this.sort);
  this.labelName = is.readString(2, false, this.labelName);
  this.labelNameLang = is.readString(3, false, this.labelNameLang);
  this.type = is.readInt32(4, false, this.type);
  this.anchorLabelType = is.readInt32(5, false, this.anchorLabelType);
  this.timeStamp = is.readInt64(6, false, this.timeStamp);
};
NimoBuss.AnchorLabelView = AnchorLabelView;
export default AnchorLabelView;