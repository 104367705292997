import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
var _excluded = ["avatar", "avatarUrl", "guid", "ua", "userid", "userName", "bizToken", "lang", "clang", "country", "udbUserId", "version"];
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import auth from '@client/common/services/auth';
import { runtime } from '@client/common/lib/nm-taf';
var _ref = runtime || {},
  user = _ref.user;

// eslint-disable-next-line import/prefer-default-export
export default function getUserInfo() {
  return new Promise(function (resolve, reject) {
    try {
      if (!user.isReady()) {
        Promise.all([auth.getUserInfo(), auth.checkLogin()]).then(function (_ref2) {
          var _ref3 = _slicedToArray(_ref2, 2),
            originUserInfo = _ref3[0],
            isLogin = _ref3[1];
          var _ref4 = originUserInfo || {},
            avatar = _ref4.avatar,
            avatarUrl = _ref4.avatarUrl,
            guid = _ref4.guid,
            ua = _ref4.ua,
            userid = _ref4.userid,
            userName = _ref4.userName,
            bizToken = _ref4.bizToken,
            lang = _ref4.lang,
            clang = _ref4.clang,
            country = _ref4.country,
            udbUserId = _ref4.udbUserId,
            version = _ref4.version,
            rest = _objectWithoutProperties(_ref4, _excluded);
          var userInfo = _objectSpread(_objectSpread({}, rest), {}, {
            isAnonymous: !isLogin,
            nick: userName,
            lcid: clang,
            uid: udbUserId,
            token: bizToken,
            lang: lang,
            version: version,
            sCountry: country
          });
          if (ua) {
            // mobile 自定义 ua，用于 taf 登录订阅
            userInfo.sUA = ua;
          }
          if (guid) {
            userInfo.sGuid = guid;
          }
          resolve(userInfo);
        });
      } else {
        resolve(user);
      }
    } catch (error) {
      reject(error);
    }
  });
}