import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var ActivitySignNotice = function ActivitySignNotice() {
  this.mContent = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.mLabel = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.udbUserId = 0;
  this.anchorId = 0;
  this.userNickName = "";
};
ActivitySignNotice.prototype._clone = function () {
  return new Nimo.ActivitySignNotice();
};
ActivitySignNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ActivitySignNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ActivitySignNotice.prototype.writeTo = function (os) {
  os.writeMap(0, this.mContent);
  os.writeMap(1, this.mLabel);
  os.writeInt64(2, this.udbUserId);
  os.writeInt64(3, this.anchorId);
  os.writeString(4, this.userNickName);
};
ActivitySignNotice.prototype.readFrom = function (is) {
  this.mContent = is.readMap(0, false, this.mContent);
  this.mLabel = is.readMap(1, false, this.mLabel);
  this.udbUserId = is.readInt64(2, false, this.udbUserId);
  this.anchorId = is.readInt64(3, false, this.anchorId);
  this.userNickName = is.readString(4, false, this.userNickName);
};
Nimo.ActivitySignNotice = ActivitySignNotice;
export default ActivitySignNotice;