/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable global-require */
/* eslint-disable react/no-danger */
import lazy from '@client/common/components/functional/lazyLoadComp';
var StarTeamRoomLazyLoadComponents = function StarTeamRoomLazyLoadComponents() {
  return import( /* webpackChunkName: 'StarTeamRoomLazyLoadComponents' */
  '@desktop/src/modules/home/room/common_index/StarTeamRoomLazyLoadComponents');
};

/**
 * 战队介绍
 */
export var TeamIntroLazyLoad = lazy(function () {
  return StarTeamRoomLazyLoadComponents().then(function (_ref) {
    var TeamIntro = _ref.TeamIntro;
    return {
      default: TeamIntro
    };
  });
}, {
  loading: false,
  async: true
});

/**
 * 战队成员
 */
export var TeamMemberLazyLoad = lazy(function () {
  return StarTeamRoomLazyLoadComponents().then(function (_ref2) {
    var TeamMember = _ref2.TeamMember;
    return {
      default: TeamMember
    };
  });
}, {
  loading: false,
  async: true
});