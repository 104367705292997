import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var GiftChannelType = {
  CHANNEL_TYPE_HOT: 0,
  CHANNEL_TYPE_BACKPACK: 1,
  CHANNEL_TYPE_EMOJIPAY: 2,
  CHANNEL_TYPE_GOLDBEAN: 3,
  CHANNEL_TYPE_PAYCHAT: 4,
  CHANNEL_TYPE_PK_RIDICULE: 5,
  CHANNEL_TYPE_WORLD_CUP: 6,
  CHANNEL_TYPE_PAYVIEW: 7,
  CHANNEL_TYPE_FLY_KISS: 8,
  CHANNEL_TYPE_GUARDIAN: 9,
  CHANNEL_TYPE_GIFT_WALL: 10,
  CHANNEL_TYPE_PAT: 11,
  CHANNEL_TYPE_PK_ACTIVITY: 12,
  CHANNEL_TYPE_CUSTOMIZE: 13
};
Nimo.GiftChannelType = GiftChannelType;
export default GiftChannelType;