import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var GetDecorationInfoListReq = function GetDecorationInfoListReq() {
  this.tUser = new Nimo.UserId();
  this.vAppId = new Taf.Vector(new Taf.INT32());
};
GetDecorationInfoListReq.prototype._clone = function () {
  return new Nimo.GetDecorationInfoListReq();
};
GetDecorationInfoListReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetDecorationInfoListReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetDecorationInfoListReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeVector(1, this.vAppId);
};
GetDecorationInfoListReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.vAppId = is.readVector(1, false, this.vAppId);
};
Nimo.GetDecorationInfoListReq = GetDecorationInfoListReq;
export default GetDecorationInfoListReq;