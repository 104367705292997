import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var GetEmoticonByRoomReq = function GetEmoticonByRoomReq() {
  this.tUser = new Nimo.UserId();
  this.vEmoticonType = new Taf.Vector(new Taf.INT32());
  this.lRoomId = 0;
};
GetEmoticonByRoomReq.prototype._clone = function () {
  return new Nimo.GetEmoticonByRoomReq();
};
GetEmoticonByRoomReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetEmoticonByRoomReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetEmoticonByRoomReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeVector(1, this.vEmoticonType);
  os.writeInt64(2, this.lRoomId);
};
GetEmoticonByRoomReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.vEmoticonType = is.readVector(1, false, this.vEmoticonType);
  this.lRoomId = is.readInt64(2, false, this.lRoomId);
};
Nimo.GetEmoticonByRoomReq = GetEmoticonByRoomReq;
export default GetEmoticonByRoomReq;