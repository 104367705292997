module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="nimo-room-recommend" class="' +
((__t = ( className )) == null ? '' : __t) +
'">\n  ' +
((__t = ( require('ejs-loader!@desktop/src/modules/home/room/components/SectionHeader/skeleton.ejs')() )) == null ? '' : __t) +
'\n  <ul class="n-fx-grid-300">\n    ';
 [1,2,3].forEach(function(k) { ;
__p += '\n    <li>\n      ' +
((__t = ( require('ejs-loader!@desktop/src/common/components/RoomCard/skeleton.ejs')() )) == null ? '' : __t) +
'\n    </li>\n    ';
 }) ;
__p += '\n  </ul>\n</div>\n';

}
return __p
}