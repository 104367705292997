module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="n-as-mrgb-md n-as-w30 n-sk n-sk-t24"></div>\n<div class="n-fx-grid-180">\n  ';
 for(var i = 0; i < 5; i++) { ;
__p += '\n  <div class=" n-as-mrgb-xs">\n    ' +
((__t = (
    require('ejs-loader!@desktop/src/common/components/RoomCard/skeleton.ejs')()
    )) == null ? '' : __t) +
'\n  </div>\n  ';
 } ;
__p += '\n</div>\n';

}
return __p
}