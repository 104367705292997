import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BaseParam';
var LiveChannelWithRegionParam = function LiveChannelWithRegionParam() {
  this.baseParam = new NimoBuss.BaseParam();
  this.gameType = 0;
  this.regionCode = "";
  this.language = "";
  this.gameAbbr = "";
  this.size = 0;
  this.pageNum = 0;
  this.labelId = 0;
  this.innerTest = false;
  this.subGameTypeList = new Taf.Vector(new Taf.INT64());
  this.entertainmentLabel = 0;
  this.useEntertainmentLabel = 0;
};
LiveChannelWithRegionParam.prototype._clone = function () {
  return new NimoBuss.LiveChannelWithRegionParam();
};
LiveChannelWithRegionParam.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LiveChannelWithRegionParam.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LiveChannelWithRegionParam.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeInt64(1, this.gameType);
  os.writeString(2, this.regionCode);
  os.writeString(3, this.language);
  os.writeString(4, this.gameAbbr);
  os.writeInt32(5, this.size);
  os.writeInt32(6, this.pageNum);
  os.writeInt32(7, this.labelId);
  os.writeBoolean(8, this.innerTest);
  os.writeVector(9, this.subGameTypeList);
  os.writeInt32(10, this.entertainmentLabel);
  os.writeInt32(11, this.useEntertainmentLabel);
};
LiveChannelWithRegionParam.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.gameType = is.readInt64(1, false, this.gameType);
  this.regionCode = is.readString(2, false, this.regionCode);
  this.language = is.readString(3, false, this.language);
  this.gameAbbr = is.readString(4, false, this.gameAbbr);
  this.size = is.readInt32(5, false, this.size);
  this.pageNum = is.readInt32(6, false, this.pageNum);
  this.labelId = is.readInt32(7, false, this.labelId);
  this.innerTest = is.readBoolean(8, false, this.innerTest);
  this.subGameTypeList = is.readVector(9, false, this.subGameTypeList);
  this.entertainmentLabel = is.readInt32(10, false, this.entertainmentLabel);
  this.useEntertainmentLabel = is.readInt32(11, false, this.useEntertainmentLabel);
};
NimoBuss.LiveChannelWithRegionParam = LiveChannelWithRegionParam;
export default LiveChannelWithRegionParam;