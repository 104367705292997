import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './AnchorPunishInfo';
var BatchGetAnchorPunishRsp = function BatchGetAnchorPunishRsp() {
  this.iCode = 0;
  this.mPunishInfo = new Taf.Map(new Taf.INT64(), new Nimo.AnchorPunishInfo());
};
BatchGetAnchorPunishRsp.prototype._clone = function () {
  return new Nimo.BatchGetAnchorPunishRsp();
};
BatchGetAnchorPunishRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
BatchGetAnchorPunishRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
BatchGetAnchorPunishRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeMap(1, this.mPunishInfo);
};
BatchGetAnchorPunishRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.mPunishInfo = is.readMap(1, false, this.mPunishInfo);
};
Nimo.BatchGetAnchorPunishRsp = BatchGetAnchorPunishRsp;
export default BatchGetAnchorPunishRsp;