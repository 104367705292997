module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="nimo-room">\n  <div class="nimo-room__head-img" style="height: ' +
((__t = ( typeof headImgHeight !== 'undefined' ? headImgHeight : 0 )) == null ? '' : __t) +
'px;"></div>\n  <div class="n-fx-bn" style="box-sizing: border-box; padding: 8px 8px 0 20px; display: flex; width: 100%; ' +
((__t = ( typeof headImgHeight !== 'undefined' && headImgHeight > 0 ? 'margin-top: -60px' : '' )) == null ? '' : __t) +
'">\n    <div style="flex: 1; margin-right: 8px; padding-bottom: 40px;">\n      ' +
((__t = ( require('ejs-loader!../components/RoomMeta/skeleton.ejs')() )) == null ? '' : __t) +
'\n      <div class="n-as-16x9 n-as-rel n-as-of-hidden" style="height: calc(100vh - 236px);">\n        ' +
((__t = ( require('ejs-loader!./Player/skeleton.ejs')() )) == null ? '' : __t) +
'\n      </div>\n      <div id="nimo-room-gift-shop" class="n-fx0">\n        ' +
((__t = ( require('ejs-loader!../common_index/GiftShop/GiftShopSkeleton.ejs')() )) == null ? '' : __t) +
'\n      </div>\n      <div class="n-as-mrgb-lg">\n        <div class="n-fx-bn">\n          ' +
((__t = (
          require('ejs-loader!@desktop/src/modules/home/room/common_index/LiveRoomRecommendList/skeleton.ejs')({
          className: 'n-fx1 n-as-mrgh-xxs', placeholderList:
          [1,2,3,4,5,6,7,8,9,10,11,12] }) )) == null ? '' : __t) +
'\n        </div>\n      </div>\n    </div>\n    <div style="margin-bottom: 10px; width: 350px; border-radius: 8px; height: calc(100vh - 80px); position: sticky; top: 70px;">\n      ' +
((__t = ( require('ejs-loader!../common_index/ChatRoom/skeleton.ejs')() )) == null ? '' : __t) +
'\n    </div>\n  </div>\n</div>\n';

}
return __p
}