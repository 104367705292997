import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var WS_JServerPacket = function WS_JServerPacket() {
  this.sProtocolName = "";
  this.sData = "";
};
WS_JServerPacket.prototype._clone = function () {
  return new Nimo.WS_JServerPacket();
};
WS_JServerPacket.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
WS_JServerPacket.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
WS_JServerPacket.prototype.writeTo = function (os) {
  os.writeString(0, this.sProtocolName);
  os.writeString(1, this.sData);
};
WS_JServerPacket.prototype.readFrom = function (is) {
  this.sProtocolName = is.readString(0, false, this.sProtocolName);
  this.sData = is.readString(1, false, this.sData);
};
Nimo.WS_JServerPacket = WS_JServerPacket;
export default WS_JServerPacket;