import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var MatchVideoConfig = function MatchVideoConfig() {
  this.componentType = 0;
  this.componentTitle = "";
  this.isDefault = 0;
  this.vids = "";
  this.matchVideoId = 0;
};
MatchVideoConfig.prototype._clone = function () {
  return new NimoBuss.MatchVideoConfig();
};
MatchVideoConfig.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MatchVideoConfig.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MatchVideoConfig.prototype.writeTo = function (os) {
  os.writeInt32(0, this.componentType);
  os.writeString(1, this.componentTitle);
  os.writeInt32(2, this.isDefault);
  os.writeString(3, this.vids);
  os.writeInt64(4, this.matchVideoId);
};
MatchVideoConfig.prototype.readFrom = function (is) {
  this.componentType = is.readInt32(0, false, this.componentType);
  this.componentTitle = is.readString(1, false, this.componentTitle);
  this.isDefault = is.readInt32(2, false, this.isDefault);
  this.vids = is.readString(3, false, this.vids);
  this.matchVideoId = is.readInt64(4, false, this.matchVideoId);
};
NimoBuss.MatchVideoConfig = MatchVideoConfig;
export default MatchVideoConfig;