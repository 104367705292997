import auth from '@client/common/services/auth';
import { createPromiseCache } from '@client/common/utils/createPromiseCache';
var pCache;

/**
 * @returns {Promise<{UA?:string;udbUserId?:string|number;guid?:string;countryCode?:string}>}
 */
export var getBaseParam = function getBaseParam() {
  if (!pCache) {
    pCache = createPromiseCache(function () {
      return auth.getUserInfo().then(function (originUserInfo) {
        var _ref = originUserInfo || {},
          guid = _ref.guid,
          ua = _ref.ua,
          country = _ref.country,
          udbUserId = _ref.udbUserId;
        var userInfo = {};
        if (udbUserId) {
          userInfo.udbUserId = udbUserId;
        }
        if (country) {
          userInfo.countryCode = country;
        }
        if (ua) {
          // mobile 自定义 ua，用于 taf 登录订阅
          userInfo.UA = ua;
        }
        if (guid) {
          userInfo.guid = guid;
        }
        return userInfo;
      });
    });
  }
  return pCache.get().catch(function () {
    return {};
  });
};