import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './EventIntroConfig';
import './TeamIntroConfig';
import './MatchScheduleConfig';
import './MatchProgressConfig';
import './MatchVideoConfig';
import './MatchPKConfig';
var ConfigModel = function ConfigModel() {
  this.enableList = new Taf.Vector(new Taf.STRING());
  this.eventIntroConfig = new NimoBuss.EventIntroConfig();
  this.teamIntroConfig = new NimoBuss.TeamIntroConfig();
  this.scheduleConfig = new NimoBuss.MatchScheduleConfig();
  this.eventProcessConfig = new NimoBuss.MatchProgressConfig();
  this.matchVideoConfig = new NimoBuss.MatchVideoConfig();
  this.teamPKConfig = new NimoBuss.MatchPKConfig();
};
ConfigModel.prototype._clone = function () {
  return new NimoBuss.ConfigModel();
};
ConfigModel.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ConfigModel.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ConfigModel.prototype.writeTo = function (os) {
  os.writeVector(0, this.enableList);
  os.writeStruct(1, this.eventIntroConfig);
  os.writeStruct(2, this.teamIntroConfig);
  os.writeStruct(3, this.scheduleConfig);
  os.writeStruct(4, this.eventProcessConfig);
  os.writeStruct(5, this.matchVideoConfig);
  os.writeStruct(6, this.teamPKConfig);
};
ConfigModel.prototype.readFrom = function (is) {
  this.enableList = is.readVector(0, false, this.enableList);
  this.eventIntroConfig = is.readStruct(1, false, this.eventIntroConfig);
  this.teamIntroConfig = is.readStruct(2, false, this.teamIntroConfig);
  this.scheduleConfig = is.readStruct(3, false, this.scheduleConfig);
  this.eventProcessConfig = is.readStruct(4, false, this.eventProcessConfig);
  this.matchVideoConfig = is.readStruct(5, false, this.matchVideoConfig);
  this.teamPKConfig = is.readStruct(6, false, this.teamPKConfig);
};
NimoBuss.ConfigModel = ConfigModel;
export default ConfigModel;