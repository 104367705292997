import { createBrowserHistory } from 'history';
import ua from '@client/common/utils/ua.js';
// 不能在这个文件注册 history 监听
var history = createBrowserHistory();
export var goBack = function goBack(opt) {
  if (history.length > 1) {
    history.goBack();
  } else if (ua.isInNimoApp) {
    (opt === null || opt === void 0 ? void 0 : opt.closeWebView) && (opt === null || opt === void 0 ? void 0 : opt.closeWebView());
  } else {
    history.replace('/');
  }
};
export var registerLocationBlock = function registerLocationBlock(message) {
  return function (when) {
    if (typeof when !== 'function') return function () {};
    return history.block(function () {
      return when() ? message : true;
    });
  };
};
export default history;