module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="n-as-conf n-fx1">\n  <div class="n-as-mrgv-md">\n    <div class="n-as-mrgb n-sk n-sk-t28 n-as-w20"></div>\n    <div class="n-as-mrgh-xs-back n-fx-sc">\n      <div class="n-as-padh-xs"><div class="n-sk n-sk-btn"></div></div>\n      <div class="n-as-padh-xs"><div class="n-sk n-sk-btn"></div></div>\n      <div class="n-as-padh-xs"><div class="n-sk n-sk-btn"></div></div>\n      <div class="n-as-padh-xs"><div class="n-sk n-sk-btn"></div></div>\n    </div>\n  </div>\n  <ul class="n-fx-grid-200 n-as-mrgh-back">\n    ';
 for(var i = 0; i < 45; i++) { ;
__p += '\n    <li class="n-as-mrgb-md">\n      <a\n        class="n-as-block n-as-pad-xs n-as-padb n-as-rnd"\n        target="_self"\n        rel="noopener noreferrer"\n      >\n        <div class="n-as-1x2">\n          <div class="n-sk n-as-rnd"></div>\n        </div>\n        <div class="n-as-mrgt-xs n-sk n-sk-t16 n-as-w80"></div>\n      </a>\n    </li>\n    ';
 } ;
__p += '\n  </ul>\n</div>\n';

}
return __p
}