var cached;
var showShareDefer = function showShareDefer() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  if (cached) {
    cached.apply(void 0, args);
  } else {
    import( /* webpackChunkName: "functional.showShare" */'./showShare').then(function (_ref) {
      var showShare = _ref.default;
      cached = showShare;
      showShare.apply(void 0, args);
    });
  }
};
export default showShareDefer;