import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var QuickPropItem = function QuickPropItem() {
  this.iPropId = 0;
};
QuickPropItem.prototype._clone = function () {
  return new Nimo.QuickPropItem();
};
QuickPropItem.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
QuickPropItem.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
QuickPropItem.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iPropId);
};
QuickPropItem.prototype.readFrom = function (is) {
  this.iPropId = is.readInt32(0, false, this.iPropId);
};
Nimo.QuickPropItem = QuickPropItem;
export default QuickPropItem;