import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var GiftLuckyBagWinnerNotice = function GiftLuckyBagWinnerNotice() {
  this.lAnchorUid = 0;
  this.lAnchorName = "";
  this.sBagId = "";
};
GiftLuckyBagWinnerNotice.prototype._clone = function () {
  return new Nimo.GiftLuckyBagWinnerNotice();
};
GiftLuckyBagWinnerNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GiftLuckyBagWinnerNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GiftLuckyBagWinnerNotice.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lAnchorUid);
  os.writeString(1, this.lAnchorName);
  os.writeString(2, this.sBagId);
};
GiftLuckyBagWinnerNotice.prototype.readFrom = function (is) {
  this.lAnchorUid = is.readInt64(0, false, this.lAnchorUid);
  this.lAnchorName = is.readString(1, false, this.lAnchorName);
  this.sBagId = is.readString(2, false, this.sBagId);
};
Nimo.GiftLuckyBagWinnerNotice = GiftLuckyBagWinnerNotice;
export default GiftLuckyBagWinnerNotice;