import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var StreamInfo = function StreamInfo() {
  this.iWidth = 0;
  this.iHeight = 0;
  this.iFrameRate = 0;
  this.iBandWidth = 0;
  this.sVideoCode = "";
  this.sAudioCode = "";
};
StreamInfo.prototype._clone = function () {
  return new Nimo.StreamInfo();
};
StreamInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
StreamInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
StreamInfo.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iWidth);
  os.writeInt32(1, this.iHeight);
  os.writeInt32(2, this.iFrameRate);
  os.writeInt32(3, this.iBandWidth);
  os.writeString(4, this.sVideoCode);
  os.writeString(5, this.sAudioCode);
};
StreamInfo.prototype.readFrom = function (is) {
  this.iWidth = is.readInt32(0, false, this.iWidth);
  this.iHeight = is.readInt32(1, false, this.iHeight);
  this.iFrameRate = is.readInt32(2, false, this.iFrameRate);
  this.iBandWidth = is.readInt32(3, false, this.iBandWidth);
  this.sVideoCode = is.readString(4, false, this.sVideoCode);
  this.sAudioCode = is.readString(5, false, this.sAudioCode);
};
Nimo.StreamInfo = StreamInfo;
export default StreamInfo;