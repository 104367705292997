import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["threshold"];
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import LazyLoad from 'vanilla-lazyload';
var lazyload;
var updateLazyload = function updateLazyload() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$threshold = _ref.threshold,
    threshold = _ref$threshold === void 0 ? 600 : _ref$threshold,
    rest = _objectWithoutProperties(_ref, _excluded);
  if (!lazyload) {
    lazyload = new LazyLoad(_objectSpread({
      threshold: threshold,
      elements_selector: "img",
      cancel_on_exit: false
    }, rest));
  } else {
    lazyload.update();
  }
};
export default updateLazyload;
export function destroyLazyLoad() {
  if (lazyload) {
    lazyload.destroy();
    lazyload = null;
  }
}