import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BaseParam';
var GetConfigSwitchListReq = function GetConfigSwitchListReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.countryCode = "";
  this.language = 0;
  this.clientType = 0;
};
GetConfigSwitchListReq.prototype._clone = function () {
  return new NimoBuss.GetConfigSwitchListReq();
};
GetConfigSwitchListReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetConfigSwitchListReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetConfigSwitchListReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeString(1, this.countryCode);
  os.writeInt32(2, this.language);
  os.writeInt32(3, this.clientType);
};
GetConfigSwitchListReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.countryCode = is.readString(1, false, this.countryCode);
  this.language = is.readInt32(2, false, this.language);
  this.clientType = is.readInt32(3, false, this.clientType);
};
NimoBuss.GetConfigSwitchListReq = GetConfigSwitchListReq;
export default GetConfigSwitchListReq;