import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './RoomMsgBannerNotice';
var ExtendRoomMsgBannerNoticeItem = function ExtendRoomMsgBannerNoticeItem() {
  this.tNotice = new Nimo.RoomMsgBannerNotice();
  this.iJumpProb = 0;
};
ExtendRoomMsgBannerNoticeItem.prototype._clone = function () {
  return new Nimo.ExtendRoomMsgBannerNoticeItem();
};
ExtendRoomMsgBannerNoticeItem.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ExtendRoomMsgBannerNoticeItem.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ExtendRoomMsgBannerNoticeItem.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tNotice);
  os.writeInt32(1, this.iJumpProb);
};
ExtendRoomMsgBannerNoticeItem.prototype.readFrom = function (is) {
  this.tNotice = is.readStruct(0, false, this.tNotice);
  this.iJumpProb = is.readInt32(1, false, this.iJumpProb);
};
Nimo.ExtendRoomMsgBannerNoticeItem = ExtendRoomMsgBannerNoticeItem;
export default ExtendRoomMsgBannerNoticeItem;