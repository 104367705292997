import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var MeetingUserInfo = function MeetingUserInfo() {
  this.sNickname = "";
  this.sAvatar = "";
  this.sAvatarBox = "";
  this.iSex = 0;
  this.iRoyalLevel = 0;
  this.lUID = 0;
  this.sDynamicAvatarBox = "";
  this.iIsRoomManager = 0;
  this.iUserLevel = 0;
  this.iClubMemberType = 0;
};
MeetingUserInfo.prototype._clone = function () {
  return new Nimo.MeetingUserInfo();
};
MeetingUserInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MeetingUserInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MeetingUserInfo.prototype.writeTo = function (os) {
  os.writeString(0, this.sNickname);
  os.writeString(1, this.sAvatar);
  os.writeString(2, this.sAvatarBox);
  os.writeInt32(3, this.iSex);
  os.writeInt32(4, this.iRoyalLevel);
  os.writeInt64(5, this.lUID);
  os.writeString(6, this.sDynamicAvatarBox);
  os.writeInt32(7, this.iIsRoomManager);
  os.writeInt32(8, this.iUserLevel);
  os.writeInt32(9, this.iClubMemberType);
};
MeetingUserInfo.prototype.readFrom = function (is) {
  this.sNickname = is.readString(0, false, this.sNickname);
  this.sAvatar = is.readString(1, false, this.sAvatar);
  this.sAvatarBox = is.readString(2, false, this.sAvatarBox);
  this.iSex = is.readInt32(3, false, this.iSex);
  this.iRoyalLevel = is.readInt32(4, false, this.iRoyalLevel);
  this.lUID = is.readInt64(5, false, this.lUID);
  this.sDynamicAvatarBox = is.readString(6, false, this.sDynamicAvatarBox);
  this.iIsRoomManager = is.readInt32(7, false, this.iIsRoomManager);
  this.iUserLevel = is.readInt32(8, false, this.iUserLevel);
  this.iClubMemberType = is.readInt32(9, false, this.iClubMemberType);
};
Nimo.MeetingUserInfo = MeetingUserInfo;
export default MeetingUserInfo;