import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
var registryMap;
export function getRegistry(messenger) {
  if (!registryMap) {
    registryMap = new WeakMap();
  }
  var registry = registryMap.get(messenger);
  if (!registry) {
    var nextTask;
    registry = {
      pending: [],
      registerId: function registerId(id) {
        registry.pending.push({
          type: 'registerId',
          id: id
        });
        registry.update();
      },
      unregisterId: function unregisterId(id) {
        registry.pending.push({
          type: 'unregisterId',
          id: id
        });
        registry.update();
      },
      registerProtocol: function registerProtocol(id) {
        registry.pending.push({
          type: 'registerP',
          id: id
        });
        registry.update();
      },
      unregisterProtocol: function unregisterProtocol(id) {
        registry.pending.push({
          type: 'unregisterP',
          id: id
        });
        registry.update();
      },
      update: function update() {
        if (nextTask) return;
        nextTask = Promise.resolve().then(function () {
          nextTask = null;
          if (registry.pending.length > 0) {
            var toAdd = new Set();
            var toRemove = new Set();
            var toAddMsg = new Set();
            var toRemoveMsg = new Set();
            registry.pending.forEach(function (item) {
              if (item.type === 'registerId') {
                toRemove.delete(item.id);
                toAdd.add(item.id);
              } else if (item.type === 'unregisterId') {
                toAdd.delete(item.id);
                toRemove.add(item.id);
              } else if (item.type === 'registerP') {
                toRemoveMsg.delete(item.id);
                toAddMsg.delete(item.id);
              } else if (item.type === 'unregisterP') {
                toAddMsg.delete(item.id);
                toRemoveMsg.add(item.id);
              }
            });
            if (toAdd.size > 0 || toAddMsg.size > 0) {
              messenger.registerSocketMsg({
                ids: toAdd.size > 0 ? _toConsumableArray(toAdd) : undefined,
                protocols: toAddMsg.size > 0 ? _toConsumableArray(toAddMsg) : undefined
              });
            }
            if (toRemove.size > 0 || toRemoveMsg.size > 0) {
              messenger.unregisterSocketMsg({
                ids: toRemove.size > 0 ? _toConsumableArray(toRemove) : undefined,
                protocols: toRemoveMsg.size > 0 ? _toConsumableArray(toRemoveMsg) : undefined
              });
            }
            registry.pending = [];
          }
        });
      }
    };
    registryMap.set(messenger, registry);
  }
  return registry;
}