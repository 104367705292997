import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var UserHeartBeatRsp = function UserHeartBeatRsp() {
  this.iCode = 0;
  this.iTimestamp = 0;
};
UserHeartBeatRsp.prototype._clone = function () {
  return new Nimo.UserHeartBeatRsp();
};
UserHeartBeatRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
UserHeartBeatRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
UserHeartBeatRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeInt32(1, this.iTimestamp);
};
UserHeartBeatRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.iTimestamp = is.readInt32(1, false, this.iTimestamp);
};
Nimo.UserHeartBeatRsp = UserHeartBeatRsp;
export default UserHeartBeatRsp;