import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './DecorationInfo';
var NewUserEnterRoomNotice = function NewUserEnterRoomNotice() {
  this.lUserId = 0;
  this.sNick = "";
  this.iRank = 0;
  this.iBgColor = 0;
  this.sBgUrl = "";
  this.lRoomId = 0;
  this.bIsManager = true;
  this.sAvatarUrl = "";
  this.sCountry = "";
  this.iRankType = 0;
  this.iEffectShowTime = 0;
  this.sVerticalUrlWeb = "";
  this.sVerticalUrlApp = "";
  this.sHorizontalUrlWeb = "";
  this.sHorizontalUrlApp = "";
  this.sBgUrlWeb = "";
  this.sBgUrlApp = "";
  this.mRankName = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.iEffectType = 0;
  this.sNewUrlWeb = "";
  this.sNewUrlApp = "";
  this.sAdrMP4Url = "";
  this.sIosMP4Url = "";
  this.sWebMP4Url = "";
  this.sTraceSource = "";
  this.bIsShowContent = 0;
  this.iUserLevel = 0;
  this.vDecorationPrefix = new Taf.Vector(new Nimo.DecorationInfo());
  this.sFansOf = "";
  this.iCost = 0;
  this.mSourceLang = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.iVisitorNo = 0;
  this.sBackgroundColor = "";
  this.sBackgroundColorDark = "";
  this.sFontColor = "";
  this.sFontColorDark = "";
  this.sBackgroundColorVoice = "";
  this.sFontColorVoice = "";
};
NewUserEnterRoomNotice.prototype._clone = function () {
  return new Nimo.NewUserEnterRoomNotice();
};
NewUserEnterRoomNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
NewUserEnterRoomNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
NewUserEnterRoomNotice.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUserId);
  os.writeString(1, this.sNick);
  os.writeInt32(2, this.iRank);
  os.writeInt32(3, this.iBgColor);
  os.writeString(4, this.sBgUrl);
  os.writeInt64(5, this.lRoomId);
  os.writeBoolean(6, this.bIsManager);
  os.writeString(7, this.sAvatarUrl);
  os.writeString(8, this.sCountry);
  os.writeInt32(9, this.iRankType);
  os.writeInt32(10, this.iEffectShowTime);
  os.writeString(11, this.sVerticalUrlWeb);
  os.writeString(12, this.sVerticalUrlApp);
  os.writeString(13, this.sHorizontalUrlWeb);
  os.writeString(14, this.sHorizontalUrlApp);
  os.writeString(15, this.sBgUrlWeb);
  os.writeString(16, this.sBgUrlApp);
  os.writeMap(17, this.mRankName);
  os.writeInt32(18, this.iEffectType);
  os.writeString(19, this.sNewUrlWeb);
  os.writeString(20, this.sNewUrlApp);
  os.writeString(21, this.sAdrMP4Url);
  os.writeString(22, this.sIosMP4Url);
  os.writeString(23, this.sWebMP4Url);
  os.writeString(24, this.sTraceSource);
  os.writeInt32(25, this.bIsShowContent);
  os.writeInt32(26, this.iUserLevel);
  os.writeVector(27, this.vDecorationPrefix);
  os.writeString(28, this.sFansOf);
  os.writeInt32(29, this.iCost);
  os.writeMap(30, this.mSourceLang);
  os.writeInt32(31, this.iVisitorNo);
  os.writeString(32, this.sBackgroundColor);
  os.writeString(33, this.sBackgroundColorDark);
  os.writeString(34, this.sFontColor);
  os.writeString(35, this.sFontColorDark);
  os.writeString(36, this.sBackgroundColorVoice);
  os.writeString(37, this.sFontColorVoice);
};
NewUserEnterRoomNotice.prototype.readFrom = function (is) {
  this.lUserId = is.readInt64(0, false, this.lUserId);
  this.sNick = is.readString(1, false, this.sNick);
  this.iRank = is.readInt32(2, false, this.iRank);
  this.iBgColor = is.readInt32(3, false, this.iBgColor);
  this.sBgUrl = is.readString(4, false, this.sBgUrl);
  this.lRoomId = is.readInt64(5, false, this.lRoomId);
  this.bIsManager = is.readBoolean(6, false, this.bIsManager);
  this.sAvatarUrl = is.readString(7, false, this.sAvatarUrl);
  this.sCountry = is.readString(8, false, this.sCountry);
  this.iRankType = is.readInt32(9, false, this.iRankType);
  this.iEffectShowTime = is.readInt32(10, false, this.iEffectShowTime);
  this.sVerticalUrlWeb = is.readString(11, false, this.sVerticalUrlWeb);
  this.sVerticalUrlApp = is.readString(12, false, this.sVerticalUrlApp);
  this.sHorizontalUrlWeb = is.readString(13, false, this.sHorizontalUrlWeb);
  this.sHorizontalUrlApp = is.readString(14, false, this.sHorizontalUrlApp);
  this.sBgUrlWeb = is.readString(15, false, this.sBgUrlWeb);
  this.sBgUrlApp = is.readString(16, false, this.sBgUrlApp);
  this.mRankName = is.readMap(17, false, this.mRankName);
  this.iEffectType = is.readInt32(18, false, this.iEffectType);
  this.sNewUrlWeb = is.readString(19, false, this.sNewUrlWeb);
  this.sNewUrlApp = is.readString(20, false, this.sNewUrlApp);
  this.sAdrMP4Url = is.readString(21, false, this.sAdrMP4Url);
  this.sIosMP4Url = is.readString(22, false, this.sIosMP4Url);
  this.sWebMP4Url = is.readString(23, false, this.sWebMP4Url);
  this.sTraceSource = is.readString(24, false, this.sTraceSource);
  this.bIsShowContent = is.readInt32(25, false, this.bIsShowContent);
  this.iUserLevel = is.readInt32(26, false, this.iUserLevel);
  this.vDecorationPrefix = is.readVector(27, false, this.vDecorationPrefix);
  this.sFansOf = is.readString(28, false, this.sFansOf);
  this.iCost = is.readInt32(29, false, this.iCost);
  this.mSourceLang = is.readMap(30, false, this.mSourceLang);
  this.iVisitorNo = is.readInt32(31, false, this.iVisitorNo);
  this.sBackgroundColor = is.readString(32, false, this.sBackgroundColor);
  this.sBackgroundColorDark = is.readString(33, false, this.sBackgroundColorDark);
  this.sFontColor = is.readString(34, false, this.sFontColor);
  this.sFontColorDark = is.readString(35, false, this.sFontColorDark);
  this.sBackgroundColorVoice = is.readString(36, false, this.sBackgroundColorVoice);
  this.sFontColorVoice = is.readString(37, false, this.sFontColorVoice);
};
Nimo.NewUserEnterRoomNotice = NewUserEnterRoomNotice;
export default NewUserEnterRoomNotice;