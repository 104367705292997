import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _window$nimo_base;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { UPDATE_HOME_LANGPKG, UPDATE_HEADER_NAV_ACTIVE, UPDATE_SIDEBAR_LOCATION, UPDATE_PLAYER, UPDATE_PROFILE_LANGPKG } from './actions';
import roomReducer from './room/reducers';
import clipReducer from './clip/reducers';
import gameReducer from './game/reducers';
var persistConfig = {
  key: 'game',
  storage: storage,
  whitelist: ['game']
};
var initialState = {
  langPkg: {},
  headerNavActiveKey: (_window$nimo_base = window.nimo_base) === null || _window$nimo_base === void 0 ? void 0 : _window$nimo_base.headerNav,
  sidebarLocation: {},
  player: null
};
function commonReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type,
    payload = action.payload;
  switch (type) {
    case UPDATE_HOME_LANGPKG:
      return _objectSpread(_objectSpread({}, state), {}, {
        langPkg: _objectSpread(_objectSpread({}, state.langPkg), payload)
      });
    case UPDATE_HEADER_NAV_ACTIVE:
      return _objectSpread(_objectSpread({}, state), {}, {
        headerNavActiveKey: payload
      });
    case UPDATE_SIDEBAR_LOCATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        sidebarLocation: payload
      });
    case UPDATE_PLAYER:
      return _objectSpread(_objectSpread({}, state), {}, {
        player: payload
      });
    case UPDATE_PROFILE_LANGPKG:
      return {
        langPkg: _objectSpread(_objectSpread({}, state.langPkg), payload)
      };
    default:
      return state;
  }
}
export default persistReducer(persistConfig, combineReducers({
  common: commonReducer,
  room: roomReducer,
  clip: clipReducer,
  game: gameReducer
}));