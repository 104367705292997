import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './AnimationContent';
import './RichText';
var NewRoomAnimationNotice = function NewRoomAnimationNotice() {
  this.tAdrAnimContent = new Nimo.AnimationContent();
  this.tIosAnimContent = new Nimo.AnimationContent();
  this.tWebAnimContent = new Nimo.AnimationContent();
  this.iType = 0;
  this.sData = "";
  this.lRoomId = 0;
  this.sTextBgColor = "";
  this.tRichText = new Nimo.RichText();
  this.mAnimTextData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.mAnimImageData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
};
NewRoomAnimationNotice.prototype._clone = function () {
  return new Nimo.NewRoomAnimationNotice();
};
NewRoomAnimationNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
NewRoomAnimationNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
NewRoomAnimationNotice.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tAdrAnimContent);
  os.writeStruct(1, this.tIosAnimContent);
  os.writeStruct(2, this.tWebAnimContent);
  os.writeInt32(3, this.iType);
  os.writeString(4, this.sData);
  os.writeInt64(5, this.lRoomId);
  os.writeString(6, this.sTextBgColor);
  os.writeStruct(7, this.tRichText);
  os.writeMap(8, this.mAnimTextData);
  os.writeMap(9, this.mAnimImageData);
};
NewRoomAnimationNotice.prototype.readFrom = function (is) {
  this.tAdrAnimContent = is.readStruct(0, false, this.tAdrAnimContent);
  this.tIosAnimContent = is.readStruct(1, false, this.tIosAnimContent);
  this.tWebAnimContent = is.readStruct(2, false, this.tWebAnimContent);
  this.iType = is.readInt32(3, false, this.iType);
  this.sData = is.readString(4, false, this.sData);
  this.lRoomId = is.readInt64(5, false, this.lRoomId);
  this.sTextBgColor = is.readString(6, false, this.sTextBgColor);
  this.tRichText = is.readStruct(7, false, this.tRichText);
  this.mAnimTextData = is.readMap(8, false, this.mAnimTextData);
  this.mAnimImageData = is.readMap(9, false, this.mAnimImageData);
};
Nimo.NewRoomAnimationNotice = NewRoomAnimationNotice;
export default NewRoomAnimationNotice;