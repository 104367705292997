import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var AnchorLevelDetailReq = function AnchorLevelDetailReq() {
  this.user = new Nimo.UserId();
  this.iNeedLevelConfig = 0;
  this.iNeedNextLevelConfig = 0;
};
AnchorLevelDetailReq.prototype._clone = function () {
  return new Nimo.AnchorLevelDetailReq();
};
AnchorLevelDetailReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorLevelDetailReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorLevelDetailReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.user);
  os.writeInt32(1, this.iNeedLevelConfig);
  os.writeInt32(2, this.iNeedNextLevelConfig);
};
AnchorLevelDetailReq.prototype.readFrom = function (is) {
  this.user = is.readStruct(0, false, this.user);
  this.iNeedLevelConfig = is.readInt32(1, false, this.iNeedLevelConfig);
  this.iNeedNextLevelConfig = is.readInt32(2, false, this.iNeedNextLevelConfig);
};
Nimo.AnchorLevelDetailReq = AnchorLevelDetailReq;
export default AnchorLevelDetailReq;