import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './Team';
var MatchPKConfig = function MatchPKConfig() {
  this.componentType = 0;
  this.teamList = new Taf.Vector(new NimoBuss.Team());
  this.scheduleId = 0;
  this.roomList = new Taf.Vector(new Taf.INT64());
};
MatchPKConfig.prototype._clone = function () {
  return new NimoBuss.MatchPKConfig();
};
MatchPKConfig.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MatchPKConfig.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MatchPKConfig.prototype.writeTo = function (os) {
  os.writeInt32(0, this.componentType);
  os.writeVector(1, this.teamList);
  os.writeInt64(2, this.scheduleId);
  os.writeVector(3, this.roomList);
};
MatchPKConfig.prototype.readFrom = function (is) {
  this.componentType = is.readInt32(0, false, this.componentType);
  this.teamList = is.readVector(1, false, this.teamList);
  this.scheduleId = is.readInt64(2, false, this.scheduleId);
  this.roomList = is.readVector(3, false, this.roomList);
};
NimoBuss.MatchPKConfig = MatchPKConfig;
export default MatchPKConfig;