import _typeof from "@babel/runtime/helpers/typeof";
/**
 * 由于需要用 string 做参数识别 key
 * 所以需要传递对象作为参数时，可以有以下两种做法：
 * 提供能唯一识别当前参数的字符串作为第一个参数传入
 * 如需传递 ({id: 'uniqueID', xxx: xxx}) 时，应当传入 ('uniqueID', {id: 'uniqueID', xxx: xxx})
 * 传递的对象中包含唯一属性：id
 */
export default function memo(func) {
  if (typeof func !== 'function') throw Error('first argument must be a function!');
  var _memo = function _memo() {
    var store = func._store;
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    var key = args[0];
    if (_typeof(key) === 'object' && Object.prototype.hasOwnProperty.call(key, 'id')) {
      key = key.id;
    } else if (_typeof(key) === 'object') {
      // 无效 key 不缓存
      return func.apply(this, args);
    }
    if (store && key in store) {
      return store[key];
    } else {
      var _result = func.apply(this, args);
      func._store = store || Object.create(null);
      func._store[key] = _result;
      return _result;
    }
  };
  return _memo;
}

/**
 * 跟 babel 的 decorator 转换方法有关。
 * 当前项目的相关配置是：
 * ["@babel/plugin-proposal-decorators", { "legacy": true }]
 * ["@babel/plugin-proposal-class-properties",{"loose": true}]
 */
export function withMemo(target, key, descriptor) {
  var func = descriptor.value || descriptor.initializer();
  var _func = memo(func);
  if (descriptor.value) {
    descriptor.value = _func; // 装饰在类方法
  } else if (descriptor.initializer) {
    descriptor.initializer = function () {
      return _func;
    }; // 装饰在类属性时
  }

  return descriptor;
}