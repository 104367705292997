module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="n-as-w100 n-fx1 main-container">\n  <div id="container" class="n-as-conf n-as-mrgb-lg">\n    <div class="n-as-w20 n-as-mrgv-md n-sk n-sk-t24"></div>\n    <ul class="n-fx-grid-300">\n      ';
 for(var i = 0; i < 24; i++) { ;
__p += '\n      <li class="n-as-mrgb-md">\n        ' +
((__t = (
        require('ejs-loader!@desktop/src/common/components/RoomCard/skeleton.ejs')()
        )) == null ? '' : __t) +
'\n      </li>\n      ';
 } ;
__p += '\n    </ul>\n  </div>\n</div>\n';

}
return __p
}