import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './ChatMsgEffect';
import './BigAnimEffect';
import './MainAnimEffect';
import './ChatBannerEffect';
import './BulletMsgEffect';
import './WorldBannerEffect';
import './StickerEffect';
var GiftEffect = function GiftEffect() {
  this.vChatMsgEffect = new Taf.Vector(new Nimo.ChatMsgEffect());
  this.vBigAnimEffect = new Taf.Vector(new Nimo.BigAnimEffect());
  this.vMainAnimEffect = new Taf.Vector(new Nimo.MainAnimEffect());
  this.vChatBannerEffect = new Taf.Vector(new Nimo.ChatBannerEffect());
  this.vBulletMsgEffect = new Taf.Vector(new Nimo.BulletMsgEffect());
  this.vWorldBannerEffect = new Taf.Vector(new Nimo.WorldBannerEffect());
  this.vStickerEffect = new Taf.Vector(new Nimo.StickerEffect());
  this.vNewBigAnimEffect = new Taf.Vector(new Nimo.BigAnimEffect());
  this.vNewWorldBannerEffect = new Taf.Vector(new Nimo.WorldBannerEffect());
};
GiftEffect.prototype._clone = function () {
  return new Nimo.GiftEffect();
};
GiftEffect.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GiftEffect.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GiftEffect.prototype.writeTo = function (os) {
  os.writeVector(0, this.vChatMsgEffect);
  os.writeVector(1, this.vBigAnimEffect);
  os.writeVector(2, this.vMainAnimEffect);
  os.writeVector(3, this.vChatBannerEffect);
  os.writeVector(4, this.vBulletMsgEffect);
  os.writeVector(5, this.vWorldBannerEffect);
  os.writeVector(6, this.vStickerEffect);
  os.writeVector(7, this.vNewBigAnimEffect);
  os.writeVector(8, this.vNewWorldBannerEffect);
};
GiftEffect.prototype.readFrom = function (is) {
  this.vChatMsgEffect = is.readVector(0, false, this.vChatMsgEffect);
  this.vBigAnimEffect = is.readVector(1, false, this.vBigAnimEffect);
  this.vMainAnimEffect = is.readVector(2, false, this.vMainAnimEffect);
  this.vChatBannerEffect = is.readVector(3, false, this.vChatBannerEffect);
  this.vBulletMsgEffect = is.readVector(4, false, this.vBulletMsgEffect);
  this.vWorldBannerEffect = is.readVector(5, false, this.vWorldBannerEffect);
  this.vStickerEffect = is.readVector(6, false, this.vStickerEffect);
  this.vNewBigAnimEffect = is.readVector(7, false, this.vNewBigAnimEffect);
  this.vNewWorldBannerEffect = is.readVector(8, false, this.vNewWorldBannerEffect);
};
Nimo.GiftEffect = GiftEffect;
export default GiftEffect;