import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var SetVIPCustomEmojiReq = function SetVIPCustomEmojiReq() {
  this.tUser = new Nimo.UserId();
  this.iLevel = 0;
  this.iPosition = 0;
  this.sEmojiUrl = "";
};
SetVIPCustomEmojiReq.prototype._clone = function () {
  return new Nimo.SetVIPCustomEmojiReq();
};
SetVIPCustomEmojiReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SetVIPCustomEmojiReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SetVIPCustomEmojiReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeInt32(1, this.iLevel);
  os.writeInt32(2, this.iPosition);
  os.writeString(3, this.sEmojiUrl);
};
SetVIPCustomEmojiReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.iLevel = is.readInt32(1, false, this.iLevel);
  this.iPosition = is.readInt32(2, false, this.iPosition);
  this.sEmojiUrl = is.readString(3, false, this.sEmojiUrl);
};
Nimo.SetVIPCustomEmojiReq = SetVIPCustomEmojiReq;
export default SetVIPCustomEmojiReq;