export default (function (imgUrl) {
  return new Promise(function (resolve, reject) {
    var img = document.createElement('img');
    img.setAttribute('fetchpriority', 'high');
    img.onload = function () {
      resolve(imgUrl);
      img = null;
    };
    img.onerror = function (err) {
      reject(err);
      img = null;
    };
    img.src = imgUrl;
  });
});