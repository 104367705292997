import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
export default function (variable) {
  var search = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window.location.search;
  var query = search.substring(1);
  var vars = query.split("&");
  // const isArray = typeof variable === 'array';
  var isArray = Object.prototype.toString.call(variable) === '[object Array]';
  var values = {};
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    var _ref = pair || [],
      _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      val = _ref2[1];
    if (isArray && variable.indexOf(key >= 0)) {
      values[key] = val;
    } else {
      if (key === variable) {
        return val;
      }
    }
  }
  return isArray ? values : false;
}
export var getUrlParmasLast = function getUrlParmasLast(variable) {
  var search = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window.location.search;
  var query = search.substring(1);
  var vars = query.split("&");
  var val = false;
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === variable) {
      val = pair[1];
    }
  }
  return val;
};