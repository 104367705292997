import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './MatchPKConfig';
import './LiveRoomView';
import './TagConfigModel';
var AppMatchConfigModel = function AppMatchConfigModel() {
  this.appBackImg = "";
  this.appHeadImg = "";
  this.appInnerImg = "";
  this.moduleOutShow = 0;
  this.style = 0;
  this.hasPk = 0;
  this.teamPKConfig = new NimoBuss.MatchPKConfig();
  this.roomViewList = new Taf.Vector(new NimoBuss.LiveRoomView());
  this.configModelList = new Taf.Vector(new NimoBuss.TagConfigModel());
  this.enable = 0;
};
AppMatchConfigModel.prototype._clone = function () {
  return new NimoBuss.AppMatchConfigModel();
};
AppMatchConfigModel.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AppMatchConfigModel.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AppMatchConfigModel.prototype.writeTo = function (os) {
  os.writeString(0, this.appBackImg);
  os.writeString(1, this.appHeadImg);
  os.writeString(2, this.appInnerImg);
  os.writeInt32(3, this.moduleOutShow);
  os.writeInt32(4, this.style);
  os.writeInt32(5, this.hasPk);
  os.writeStruct(6, this.teamPKConfig);
  os.writeVector(7, this.roomViewList);
  os.writeVector(8, this.configModelList);
  os.writeInt32(9, this.enable);
};
AppMatchConfigModel.prototype.readFrom = function (is) {
  this.appBackImg = is.readString(0, false, this.appBackImg);
  this.appHeadImg = is.readString(1, false, this.appHeadImg);
  this.appInnerImg = is.readString(2, false, this.appInnerImg);
  this.moduleOutShow = is.readInt32(3, false, this.moduleOutShow);
  this.style = is.readInt32(4, false, this.style);
  this.hasPk = is.readInt32(5, false, this.hasPk);
  this.teamPKConfig = is.readStruct(6, false, this.teamPKConfig);
  this.roomViewList = is.readVector(7, false, this.roomViewList);
  this.configModelList = is.readVector(8, false, this.configModelList);
  this.enable = is.readInt32(9, false, this.enable);
};
NimoBuss.AppMatchConfigModel = AppMatchConfigModel;
export default AppMatchConfigModel;