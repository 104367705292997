import { useIsInSelfRoom, useStreamerChatSettingsSelector } from './streamerChatAtom';
import { useClientChatSettingsSelector } from '../ClientChatSettings/clientChatAtom';

/**
 * @param {Parameters<typeof useStreamerChatSettingsSelector>[0]} streamerKey
 * @param {Parameters<typeof useClientChatSettingsSelector>[0]} [clientKey]
 */
export var useChatSettingsSelector = function useChatSettingsSelector(streamerKey, clientKey) {
  var _useIsInSelfRoom = useIsInSelfRoom(),
    isInSelfRoom = _useIsInSelfRoom.isInSelfRoom;
  var a = useStreamerChatSettingsSelector(streamerKey);
  var b = useClientChatSettingsSelector(clientKey || streamerKey);
  return isInSelfRoom ? a : b;
};