import supportWebPush from './supportWebPush';
export default (function (opts) {
  if (!supportWebPush()) {
    return Promise.resolve({
      notSupported: true
    });
  } else {
    return import('./firebase-messaging').then(function (e) {
      return e.default(opts);
    });
  }
});