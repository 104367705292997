import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BaseParam';
var GetLotterySwitchReq = function GetLotterySwitchReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.countryCode = "";
  this.activityId = 0;
  this.device = 0;
  this.appVersion = "";
  this.type = 0;
};
GetLotterySwitchReq.prototype._clone = function () {
  return new NimoBuss.GetLotterySwitchReq();
};
GetLotterySwitchReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetLotterySwitchReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetLotterySwitchReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeString(1, this.countryCode);
  os.writeInt32(2, this.activityId);
  os.writeInt32(3, this.device);
  os.writeString(4, this.appVersion);
  os.writeInt32(5, this.type);
};
GetLotterySwitchReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.countryCode = is.readString(1, false, this.countryCode);
  this.activityId = is.readInt32(2, false, this.activityId);
  this.device = is.readInt32(3, false, this.device);
  this.appVersion = is.readString(4, false, this.appVersion);
  this.type = is.readInt32(5, false, this.type);
};
NimoBuss.GetLotterySwitchReq = GetLotterySwitchReq;
export default GetLotterySwitchReq;