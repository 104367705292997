import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import sides from '@desktop/src/modules/profile/_common/components/side/side.pure.config';
import { getLang, getClang, getTheme } from '@client/common/utils/fn';
import { UPDATE_PROMPT, UPDATE_COMMON_LANGPKG, UPDATE_LANG, GET_CLANG_LIST, UPDATE_CLANG, UPDATE_USER_INFO, UPDATE_IS_LANDING_PAGE, UPDATE_STREAM_INFO, UPDATE_PLAYER_LOADED_DATA, UPDATE_PLAYER_INTERSECTION_STATE, SHOW_MINI_PLAYER, UPDATE_PLAYER_STATE, UPDATE_SIGNALING_STATE, GET_DECORATION_INFO_LIST, SET_MESSAGE, SET_THEME } from './actions';
var cleanSides = function cleanSides(s) {
  var p = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var _sides = {};
  s.forEach(function (side) {
    if (side.children) {
      _sides = _objectSpread(_objectSpread({}, _sides), cleanSides(side.children, side.key));
    } else if (!side.children && side.prompt) {
      _sides[side.key] = {
        parent: p,
        prompt: false
      };
    }
  });
  return _sides;
};
var updatePrompts = function updatePrompts(prompts, payload) {
  var type = payload.type,
    prompt = payload.prompt;
  var _prompts = _objectSpread(_objectSpread({}, prompts), {}, _defineProperty({}, type, _objectSpread(_objectSpread({}, prompts[type]), {}, {
    prompt: prompt
  })));
  return _prompts;
};
export var PENDING = 'pending'; // pending 状态

var initialState = {
  prompts: cleanSides(sides),
  langPkg: {},
  lang: parseInt(getLang(), 10),
  clangList: [],
  clang: parseInt(getClang() || '1000', 10),
  userInfo: {},
  isLandingPage: false,
  streamInfo: {},
  playerLoadedData: false,
  // 播放器已出第一帧
  playerIntersectionState: {},
  miniPlayerVisible: false,
  // 小窗播放器显示状态
  player: {
    puppetMounted: false,
    // 播放器傀儡是否挂载
    miniVisible: false,
    // 小窗播放器显示状态
    loadedData: false,
    // 播放器已出第一帧
    intersectionState: {} // 播放器可视状态
  },

  signalingState: {
    connected: false,
    launched: false,
    wsConnected: false
  },
  // 虎牙中台信令状态
  decorations: [],
  // 徽章列表
  // IM、官方消息、系统消息
  message: {
    list: [],
    unRead: 0,
    reading: null // 正在查看
  },

  theme: getTheme()
};
function commonReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type,
    payload = action.payload;
  switch (type) {
    case UPDATE_PROMPT:
      return _objectSpread(_objectSpread({}, state), {}, {
        prompts: updatePrompts(state.prompts, payload)
      });
    case UPDATE_COMMON_LANGPKG:
      return _objectSpread(_objectSpread({}, state), {}, {
        langPkg: _objectSpread(_objectSpread({}, state.langPkg), payload)
      });
    case UPDATE_LANG:
      return _objectSpread(_objectSpread({}, state), {}, {
        lang: payload
      });
    case GET_CLANG_LIST:
      return _objectSpread(_objectSpread({}, state), {}, {
        clangList: payload
      });
    case UPDATE_CLANG:
      return _objectSpread(_objectSpread({}, state), {}, {
        clang: payload,
        userInfo: _objectSpread(_objectSpread({}, state.userInfo), {}, {
          clang: payload
        })
      });
    case UPDATE_USER_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        userInfo: payload,
        lang: payload.lang ? parseInt(payload.lang, 10) : state.lang,
        clang: payload.clang ? parseInt(payload.clang, 10) : state.clang
      });
    case UPDATE_IS_LANDING_PAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLandingPage: payload
      });
    case UPDATE_STREAM_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        streamInfo: payload
      });
    case UPDATE_PLAYER_LOADED_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        playerLoadedData: payload
      });
    case UPDATE_PLAYER_INTERSECTION_STATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        playerIntersectionState: payload
      });
    case SHOW_MINI_PLAYER:
      return _objectSpread(_objectSpread({}, state), {}, {
        miniPlayerVisible: payload
      });
    case UPDATE_PLAYER_STATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        player: _objectSpread(_objectSpread({}, state.player), payload)
      });
    case UPDATE_SIGNALING_STATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        signalingState: _objectSpread(_objectSpread({}, state.signalingState), payload)
      });
    case GET_DECORATION_INFO_LIST:
      return _objectSpread(_objectSpread({}, state), {}, {
        decorations: payload
      });
    case SET_MESSAGE:
      return _objectSpread(_objectSpread({}, state), payload);
    case SET_THEME:
      return _objectSpread(_objectSpread({}, state), payload);
    default:
      return state;
  }
}
export default commonReducer;