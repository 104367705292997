import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var EURI = {
  EUriHelloString: 8000,
  EUriStreamNew: 8001,
  EUriStreamStop: 8002,
  EUriRoomAttendeeChange: 8003,
  EUriRoomAttendeeChangeForUid: 8004,
  EUriLiveOver: 8005,
  EUriLiveBeginNotice: 8006,
  EUriLiveEndNotice: 8007,
  EUriLiveBeginNoticeNew: 8008,
  EUriNonPublicLiveBeginNotice: 8009,
  EUriLiveTypeChange: 8010,
  EUriLiveRecmdPVStat: 8011,
  EUriNoticeBan: 8100,
  EUriNoticeBeKick: 8101,
  EUriCancelFreeEnterRoom: 8102,
  EUriPayViewerNotice: 8103,
  EUriMessageNotice: 1400,
  EUriJServerPacket: 1401,
  EUriFavorLive: 1402,
  EUriRoomMessageBanner: 1403,
  EUriShareRoom: 1404,
  EUriSystemMessage: 1405,
  EUriFavorResChg: 1406,
  EUriRoomAnimation: 1407,
  EUriNewRoomAnimation: 1408,
  EUriQuickChat: 1409,
  EUriVipBarListChgNotice: 1410,
  EUriThirdMessageNotice: 1411,
  EUriMarqueeNotice: 1412,
  EUriChatModeChangeNotice: 1413,
  EUriThirdMessageBatchNotice: 1414,
  EUriRoomWelcomeNotice: 1415,
  EUriAnchorMsgBannerNotice: 1416,
  EUriChatBotMessageNotice: 1417,
  EUriSystemChatMessage: 1418,
  EUriPayChatNotice: 1419,
  EUriVoiceRoomEmojiNotice: 1420,
  EUriGetTaskPackNotice: 1421,
  EUriTaskProgressChange: 1422,
  EUriStarLevelUp: 1423,
  EUriSeniorStarTaskChg: 1424,
  EUriSystemChatMessageNew: 1425,
  EUriVoiceRoomRandomEmojiNotice: 1426,
  EUriPayChatRichTextNotice: 1427,
  EUriPayChatRichTextNewNotice: 1428,
  EUriNobleFansRecordNotice: 1429,
  EUriCheckRoomNotice: 1450,
  EUriPaidPotentialListChgNotice: 1451,
  EUriMpsTextDeliver: 1452,
  EUriLiveSubtitleEvent: 1453,
  EUriStarLevelTaskChgNotice: 1454,
  EUriNewUserRewardNotice: 1455,
  EUriPayChatOptionChangeNotice: 1456,
  EUriLotteryMultipleNotice: 1457,
  EUriMysterorStatusNotice: 1458,
  EUriUserForbitMessage: 1500,
  EUriUserLifBanMessage: 1501,
  EUriUserBoxReset: 1510,
  EUriUserSetRoomManager: 1511,
  EUriUserSpecialBox: 1512,
  EUriUserEnterRoom: 1513,
  EUriAnchorKickUser: 1514,
  EUriFansLevelUp: 1515,
  EUriNewUserEnterRoom: 1516,
  EUriVIPLevelChange: 1517,
  EUriNoRankEnterRoom: 1518,
  EUriVoiceRoomBan: 1519,
  EUriEmoticonGroupUnLock: 1520,
  EUriNobleJoinRecommand: 1521,
  EUriNobleChange: 1522,
  EUriAnchorCheckEnterRoom: 1523,
  EUriUserBlockNotify: 1524,
  EUriUserBoxNotify: 1525,
  EUriSendKissToRichMan: 1526,
  EUriNewUserFlagEnterRoom: 1527,
  EUriGuardianArchivement: 1528,
  EUriGuardianListChange: 1529,
  EUriGuardianSelfInfoChange: 1530,
  EUriNewUserEnterRoomVisitor: 1531,
  EUriNoRankEnterRoomVisitor: 1532,
  EUriGuardianLotteryResult: 1533,
  EUriEnterRoomEffectFold: 1534,
  EUriSendHeartStatusNotify: 1535,
  EUriSendHeartAnchorStatusNotify: 1536,
  EUriSendPatRemainNotify: 1537,
  EUriSendPatNotify: 1538,
  EUriUserRoomBackgroundInfoChangeNotify: 1539,
  EUriMatchRoundChange: 1600,
  EUriCampStatChange: 1601,
  EUriSetUserCampSupport: 1602,
  EMsgMarkReadNotify: 1700,
  EMsgSessionNew: 1701,
  EIMMsgSessionNew: 1702,
  EIMMsgGroupMemberChange: 1703,
  EIMMsgPictureMsgIllegal: 1704,
  EIMMsgSessionDelete: 1705,
  EUriBSLotteryStart: 1800,
  EUriBSLotteryPopularity: 1801,
  EUriBSLotteryEnd: 1802,
  EUriBSLotteryDetail: 1803,
  EUriBSLotteryWinner: 1804,
  EUriMulLotBoardcast: 1805,
  EUriBSLotteryStartNew: 1806,
  EUriBSLotteryPopularityNew: 1807,
  EUriBSLotteryEndNew: 1808,
  EUriBSLotteryWinnerNew: 1809,
  EUriBSLotteryStartV2: 1810,
  EUriBSLotteryPopularityV2: 1811,
  EUriBSLotteryEndV2: 1812,
  EUriBSLotteryWinnerV2: 1813,
  EUriBSLotteryStartV3: 1814,
  EUriBSLotteryPopularityV3: 1815,
  EUriBSLotteryEndV3: 1816,
  EUriBSLotteryWinnerV3: 1817,
  EUriNoticeRoomMcEvent: 2001,
  EUriNoticeMcRequest: 2002,
  EUriNoticeMcResponse: 2003,
  EUriNoticeMcReqTimeout: 2004,
  EUriNoticeRoomConfigChange: 2005,
  EUriNoticeWaitUserUpMc: 2006,
  EUriNoticeWaitUserLeave: 2007,
  EUriNoticeUserEvent: 2008,
  EUriAudienceChangeEvent: 2009,
  EUriNoticeCancelInv: 2010,
  EUriNoticePresenterBeInvite: 2011,
  EUriNoticePresenterInviteResult: 2012,
  EUriNoticePresenterLinkChange: 2013,
  EUriNoticePresenterCancelInvite: 2014,
  EUriNoticePresenterLinkNetworkQua: 2015,
  EUriNoticeShowMicBoxChange: 2016,
  EUriVoiceRoomUp: 2017,
  EUriJoinRoomClub: 2018,
  EUriMeetingSeatChange: 2100,
  EUriMeetingInvite: 2101,
  EUriMeetingInviteResponse: 2102,
  EUriMeetingKick: 2103,
  EUriCharmValue: 2104,
  EUriMeetingAnnouncementNotify: 2105,
  EUriMeetingStatusChange: 2106,
  EUriMeetingPairing: 2107,
  EUriMeetingThemeChange: 2108,
  EUriVoiceRoomMemCountChg: 2109,
  EUriRoomLevelUpNotice: 2110,
  EUriLinkMicSessionStatNotify: 2200,
  EUriLinkMicListChangeNotify: 2201,
  EUriLinkMicRandomInviteNotify: 2202,
  EUriLinkMicAudienceAskUpNotice: 2203,
  EUriLinkMicClientDataChange: 2204,
  EUriLinkMicStatusChange: 2205,
  EUriLinkMicAudienceLinkMicResultNotice: 2206,
  EUriLinkMicUserInfoChange: 2207,
  EUriLinkMicVirtualPropChangeNotify: 2208,
  EUriTeamLinkMicAskNotice: 2250,
  EUriTeamLinkMicAnswerNotice: 2251,
  EUriTeamLinkMicLeaderMatchNotice: 2252,
  EUriTeamLinkMicInviteNotice: 2253,
  EUriTeamLinkMicInviteResultNotice: 2254,
  EUriTeamLinkMicSeatNotice: 2255,
  EUriTeamLinkMicQuitNotice: 2256,
  EUriTeamLinkMicDisbandNotice: 2257,
  EUriTeamLinkMicChangeNotice: 2258,
  EUriSundryDiceGame: 2300,
  EUriSundryFingerGuessingGame: 2301,
  EUriSundryBasketballGame: 2302,
  EUriSundryAlertBoxNotify: 2304,
  EUriAirRaidEvent: 2305,
  EUriSundryRandomGames: 2306,
  EUriRouletteBet: 2310,
  EUriClashRoyaleEvent: 2311,
  EUriGiftRoomBroadCastNotice: 9000,
  EUriGiftEffectBroadCastNotice: 9001,
  EUriGiftRoomMgsBroadCastNotice: 9002,
  EUriGiftUpgradeGiftNotice: 9003,
  EUriGiftUpgradeGiftProgressNotice: 9004,
  EUriGiftCustomizeGiftProgressNotice: 9005,
  EUriVoteRoomBroadCastNotice: 9600,
  EUriVoteRoomBroadCastNoticeStart: 9601,
  EUriVoteRoomBroadCastNoticeFinshed: 9602,
  EUriAnchorLevelUp: 9701,
  EUriAnchorLevelUpToAnchor: 9702,
  EUriAnchorPanelInfo: 9703,
  EUriAnchorAssistStrategy: 9704,
  EUriAnchorPKNotifyAskPK: 9731,
  EUriAnchorPKNotifyAnswerPK: 9732,
  EUriAnchorPKNotifySyncGifts: 9733,
  EUriAnchorPKNotifySyncTop3: 9734,
  EUriAnchorPKNotifyPKStart: 9735,
  EUriAnchorPKNotifyPKOverCountDown: 9736,
  EUriAnchorPKNotifyPKOver: 9737,
  EUriAnchorPKNotifyPKShowResultOver: 9738,
  EUriAnchorPKNotifyCancelPK: 9739,
  EUriAnchorPKNotifyPKSituation: 9740,
  EUriAnchorPKNotifyReachHighLight: 9741,
  EUriAnchorPKNotifyHighLightBanner: 9742,
  EUriAnchorPKNotifyTips: 9743,
  EUriAnchorPKNotifyClientDataChange: 9744,
  EUriAnchorPKNotifyRidicule: 9745,
  EUriAnchorPKEraseLoseEffectNotify: 9746,
  EUriAnchorPKEraseNotify: 9747,
  EUriAnchorTeamPKStart: 9750,
  EUriAnchorTeamPKOver: 9751,
  EUriAnchorTeamPKShowResultOver: 9753,
  EUriAnchorTeamPKSyncGifts: 9754,
  EUriAnchorTeamPKSyncTop3: 9755,
  EUriAnchorTeamPKOverCountDown: 9756,
  EUriVideoPlayBlackNotify: 9800,
  EUriVideoPlayBlackNotify2: 9801,
  EUriBGStatusNotice: 9900,
  EUriBGWinnerNotice: 9901,
  EUriCheckinNotice: 10000,
  EUriDailyCheckinNotice: 10001,
  EUriLuckyGiftNotice: 10100,
  EUriLuckyGiftRewardRoom: 10101,
  EUriLuckyAwardNotice: 10102,
  EUriLuckyGiftSuperScoreChangeNotice: 10103,
  EUriLuckyGiftPieceAwardNotice: 10104,
  EUriLuckyGiftPrizeRecordListNotice: 10105,
  EUriTaskBoxOpening: 10200,
  EUriForceUserLogout: 10300,
  EUriPickMeStart: 10400,
  EUriPickMeEnd: 10401,
  EUriPickMeDetails: 10402,
  EUriPickMeWinners: 10403,
  EUriPickMeTask: 10404,
  EUriThirdPartyTokenAuthFailed: 10500,
  EUriThirdPartyRepushSwitchChange: 10501,
  EUriThirdPartyPickMeInvite: 10502,
  EUriThirdPartyPickMeInviteEnd: 10503,
  EUriThirdPartyStreamStatusNotify: 10504,
  EUriThirdPartyStreamStopNotify: 10505,
  EUriThirdPartyActionNotify: 10506,
  EUriItemTallyNotice: 10600,
  EUriItemTallyWishNotice: 10601,
  EUriItemTallyWishNewNotice: 10602,
  EUriItemTallyWishNewSysNotify: 10603,
  EUriItemTallyNewWishNotice: 10604,
  EUriWishPopupNotify: 10605,
  EUriFortuneGiftPrizeNotice: 10700,
  EUriFortuneGiftPrizeRecordNotice: 10701,
  EUriFortuneGiftSuperFortuneChangeNotice: 10702,
  EUriFortuneGiftStreamerEffectNotice: 10703,
  EUriGoldBeanLotteryBuyOrSellNotice: 10800,
  EUriRouletteBetNotice: 10801,
  EUriRouletteStatusUpdateNotice: 10802,
  EUriRouletteRewardNotice: 10803,
  EUriGamePromoteExhaustNotice: 10804,
  EUriGoldBeanLotteryRoundNotice: 10805,
  EUriBaucuaRewardNotice: 10806,
  EUriBaucuaRoundStatusUpdateNotice: 10807,
  EUriBoxLotteryReportNotice: 10808,
  EUriBoxLotteryFinishNotice: 10809,
  EUriSlotMachineTryFinishNotice: 10810,
  EUriBoxLotteryGiftTaskFinishNotice: 10811,
  EUriBoxLotteryClueExchange: 10812,
  EUriBoxLotteryHunterPreparing: 10813,
  EUriBoxLotteryHunterStart: 10814,
  EUriBoxLotteryHunterSettling: 10815,
  EUriBoxLotteryHunterSettled: 10816,
  EUriFunnyGameWinNotice: 10817,
  EUriActivityPromoteInviteNotice: 10900,
  EUriActivityPromoteGroupUpStartNotice: 10901,
  EUriOpenLotteryNotice: 11000,
  EUriInteractiveGiftUpdateNotice: 11100,
  EUriInteractiveGiftWinnerRecordNotice: 11101,
  EUriUserBoxTaskDoubleCoinPrizeNotice: 11200,
  EUriWealthFamilyClumpEndNotice: 11300,
  EUriWatchTogetherStart: 11400,
  EUriPointWallRewardNotice: 11401,
  EUriTurntableStartNotice: 11500,
  EUriTurntableParticipateNotice: 11501,
  EUriTurntableExitNotice: 11502,
  EUriTurntableRoundNotice: 11503,
  EUriTurntableEndNotice: 11504,
  EUriTurntableNotice: 11505,
  EUriTurntableSysMsg: 11506,
  EUriUserLevelExpAddNotice: 11600,
  EUriUserGrowthTask: 11601,
  EUriUserLevelUpdate: 11602,
  EUriWealthSeatUpdateNotice: 11700,
  EUriFamilyClumpUpdateNotice: 11800,
  EUriRoomFamilyClumpListUpdateNotice: 11801,
  EUriRoomFamilyClumpWinnerNotice: 11802,
  EUriSecPackTypeShennongLagSolvedNotice: 11900,
  EUriActivityDrawUpdateNotice: 12000,
  EUriActivityDrawWinnerNotice: 12001,
  EUriSupperTreasureGiftNotice: 12100,
  EUriSupperTreasureFairyGiftNotice: 12101,
  EUriBossGameStatNotice: 12200,
  EUriBossGameRewardNotice: 12201,
  EUriBossGameTaskNotice: 12202,
  EUriBossGameWarriorNotice: 12203,
  EUriFisingKingFishPrizePoolNotice: 12300,
  EUriFishingWinningKingFishNotice: 12301,
  EUriPickUpStatNotice: 12400,
  EUriPickUpPairingNotice: 12401,
  EUriConfessSystemMessageNotice: 12500,
  EUriHorseRaceResultNotice: 12600,
  EUriHorseRacingLargeBetNotice: 12601,
  EUriHorseRacingPopularHorseNotice: 12602,
  EUriHorseRacingLargeBounsNotice: 12603,
  EUriGameHallStatusNotice: 12700,
  EUriGameHallInviteNotice: 12701,
  EUriLudoGameStartNotice: 12800,
  EUriLudoGameEndNotice: 12801,
  EUriLudoGameRoundStartNotice: 12802,
  EUriLudoGameDiceNotice: 12803,
  EUriLudoGameChessGoNotice: 12804,
  EUriLudoGameDataSync: 12805,
  EUriLudoGamePlayerNotice: 12806,
  EUriLudoGameConfNotice: 12807,
  EUriLudoGameHistoryNotice: 12808,
  EUriGiftLuckyBagStatusUpdate: 12901,
  EUriGiftLuckyBagWin: 12902,
  EUriFirstPayGuideSGift: 20001,
  EUriRevenuePanelBean: 20002,
  EUriActivityTaskBean: 20003,
  EUriActivityCommonPanel: 20004,
  EUriRoomRankChange: 20005,
  EUriRoomRankUp: 20006,
  EUriRoomFansRankChange: 20007,
  EUriRoomGiftsChange: 20008,
  EUriResRankUp: 20009,
  EUriLogCollect: 20010,
  EUriActivitySign: 20011,
  EUriMissionOpen: 20012,
  EUriMissionMessage: 20013,
  EUriMissionInfo: 20014,
  EUriRoomGiftRankChange: 20015,
  EUriCurrentLiveRank: 20016,
  EUriLiveHouseShowChange: 20017,
  EUriWeekGiftRank: 20018,
  EUriRobWeekGiftInfo: 20019,
  EUriRegularLottery: 20020,
  EUriActivityUserPanel: 20021,
  EUriActivityRoomWindow: 20022,
  EUriActTreaJackpotChange: 20023,
  EUriActTreaUserKeyChange: 20024,
  EUriActTreaCollectChange: 20025,
  EUriGiftWallGiftUnlock: 20026,
  EUriGiftWallGiftUnlockPublicScreenNotice: 20027,
  EUriGiftWallGiftUnlockPopupNotice: 20028,
  EUriRoomVotePanelChange: 20029,
  EUriPKBonusActivityQualifiedNotice: 20030,
  EUriPKBonusActivityRebirthNotice: 20031,
  EUriAnnualGlobalTaskInfo: 20032,
  EUriBirthdayActivityPanelNotice: 20033,
  EUriActSVipPanelNotice: 20034,
  EUriAdActivityAwardNotice: 20035,
  EUriGiftTeamActivityJoinTeamNotice: 20036,
  EUriGiftTeamActivityAssembleNotice: 20037,
  EUriGambleRadioChange: 40000,
  EUriGuessRadioChange: 40001,
  EUriGambleItemChange: 40002,
  EUriGuessPannel: 40003,
  EUriGamblePannel: 40004,
  EUriGambleBankerChange: 40005,
  EUriGambleStatusChange: 40006,
  EUriGuessStatusChange: 40007,
  EUriGambleResult: 40008,
  EUriGuessResult: 40009,
  EUriAuditPunish: 40010,
  EUriEmperorListUpdate: 40011,
  EUriEmperorSceptreMsg: 40012,
  EUriEmperorScreenMsg: 40013,
  EUriLiveInfoToPC: 40014,
  EUriFollow: 40015,
  EUriReJoinClubTip: 40016,
  EUriFanUpgradeClub: 40017,
  EUriFanJoinClub: 40018,
  EUriSubcriptionNotice: 40019,
  EUriMicroNotifyUser: 40020,
  EUriRoyalWealthNotice: 40021,
  EUriRedHotNotice: 40022,
  EUriFanIntimacy: 40023,
  EUriNewRedHotNotice: 40024,
  EUriIntimacyProgress: 40025,
  EUriGuildRecommendToAnchor: 40026,
  EUriGuildRecommendToUser: 40027,
  EUriGuessRadioChangeWithCurrency: 40028,
  EUriGuessStatusChangeWithCurrency: 40029,
  EUriGuessResultWithCurrency: 40030,
  EUriGuessPanelWithCurrency: 40031,
  EUriOfflineRecommendV2ToUser: 40032,
  EUriVideoCommentReplyNotice: 40033,
  EUriMatchQuizRadioChange: 40034,
  EUriMatchQuizPannel: 40035,
  EUriMatchQuizStatusChange: 40036,
  EuriVoiceRoomAnnounceChange: 40037,
  EUriSubcriptionNoticeV2: 40038,
  EUriFansLevelUpV2: 40039,
  EUriFansLevelUpPopup: 40040,
  EUriFanUpgradeClubV2: 40041,
  EUriFanJoinClubV2: 40042,
  EUriUserClipSwtichChange: 40043,
  EUriUserRecordSwtichChange: 40044,
  EUriUserFollow: 40045,
  EURIGuessBet: 40046,
  EURIGuessAward: 40047,
  EURIGuessGlobalPool: 40048,
  EUriFansUpgradeAward: 40049,
  EUriAuditPunishMuteNotice: 40050,
  EUriAuditPunishMaskNotice: 40051
};
Nimo.EURI = EURI;
export default EURI;