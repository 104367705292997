import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var RoomScreenshotsView = function RoomScreenshotsView() {
  this.screenshotKey = 0;
  this.url = "";
};
RoomScreenshotsView.prototype._clone = function () {
  return new NimoBuss.RoomScreenshotsView();
};
RoomScreenshotsView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
RoomScreenshotsView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
RoomScreenshotsView.prototype.writeTo = function (os) {
  os.writeInt32(0, this.screenshotKey);
  os.writeString(1, this.url);
};
RoomScreenshotsView.prototype.readFrom = function (is) {
  this.screenshotKey = is.readInt32(0, false, this.screenshotKey);
  this.url = is.readString(1, false, this.url);
};
NimoBuss.RoomScreenshotsView = RoomScreenshotsView;
export default RoomScreenshotsView;