import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var GiftEffectID = function GiftEffectID() {
  this.vBigAnimEffectIDs = new Taf.Vector(new Taf.STRING());
  this.vWorldBannerEffectIDs = new Taf.Vector(new Taf.STRING());
};
GiftEffectID.prototype._clone = function () {
  return new Nimo.GiftEffectID();
};
GiftEffectID.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GiftEffectID.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GiftEffectID.prototype.writeTo = function (os) {
  os.writeVector(0, this.vBigAnimEffectIDs);
  os.writeVector(1, this.vWorldBannerEffectIDs);
};
GiftEffectID.prototype.readFrom = function (is) {
  this.vBigAnimEffectIDs = is.readVector(0, false, this.vBigAnimEffectIDs);
  this.vWorldBannerEffectIDs = is.readVector(1, false, this.vWorldBannerEffectIDs);
};
Nimo.GiftEffectID = GiftEffectID;
export default GiftEffectID;