import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './ContentFormat';
var SystemMessageNotice = function SystemMessageNotice() {
  this.mSystemMessage = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.tFormat = new Nimo.ContentFormat();
  this.lRoomId = 0;
};
SystemMessageNotice.prototype._clone = function () {
  return new Nimo.SystemMessageNotice();
};
SystemMessageNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SystemMessageNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SystemMessageNotice.prototype.writeTo = function (os) {
  os.writeMap(0, this.mSystemMessage);
  os.writeStruct(1, this.tFormat);
  os.writeInt64(2, this.lRoomId);
};
SystemMessageNotice.prototype.readFrom = function (is) {
  this.mSystemMessage = is.readMap(0, false, this.mSystemMessage);
  this.tFormat = is.readStruct(1, false, this.tFormat);
  this.lRoomId = is.readInt64(2, false, this.lRoomId);
};
Nimo.SystemMessageNotice = SystemMessageNotice;
export default SystemMessageNotice;