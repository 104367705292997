import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var FansLevelUpPopup = function FansLevelUpPopup() {
  this.udbUserId = 0;
  this.roomId = 0;
  this.anchorId = 0;
  this.anchorName = "";
  this.title = new Taf.Map(new Taf.INT32(), new Taf.STRING());
  this.content = new Taf.Map(new Taf.INT32(), new Taf.STRING());
  this.badgeName = new Taf.Map(new Taf.INT32(), new Taf.STRING());
  this.badgeIcon = "";
  this.days = 0;
};
FansLevelUpPopup.prototype._clone = function () {
  return new NimoBuss.FansLevelUpPopup();
};
FansLevelUpPopup.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FansLevelUpPopup.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FansLevelUpPopup.prototype.writeTo = function (os) {
  os.writeInt64(0, this.udbUserId);
  os.writeInt64(1, this.roomId);
  os.writeInt64(2, this.anchorId);
  os.writeString(3, this.anchorName);
  os.writeMap(4, this.title);
  os.writeMap(5, this.content);
  os.writeMap(6, this.badgeName);
  os.writeString(7, this.badgeIcon);
  os.writeInt32(8, this.days);
};
FansLevelUpPopup.prototype.readFrom = function (is) {
  this.udbUserId = is.readInt64(0, false, this.udbUserId);
  this.roomId = is.readInt64(1, false, this.roomId);
  this.anchorId = is.readInt64(2, false, this.anchorId);
  this.anchorName = is.readString(3, false, this.anchorName);
  this.title = is.readMap(4, false, this.title);
  this.content = is.readMap(5, false, this.content);
  this.badgeName = is.readMap(6, false, this.badgeName);
  this.badgeIcon = is.readString(7, false, this.badgeIcon);
  this.days = is.readInt32(8, false, this.days);
};
NimoBuss.FansLevelUpPopup = FansLevelUpPopup;
export default FansLevelUpPopup;