import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import Nimo from '../nimo';
import '../Nimo/RoomLineInfo';
import '../Nimo/UserTitleData';
import './RoomScreenshotsView';
var LiveChannelDynamicVo = function LiveChannelDynamicVo() {
  this.channelId = 0;
  this.viewerNum = 0;
  this.fanCount = 0;
  this.isFollow = true;
  this.liveStreamStatus = 0;
  this.isLottery = 0;
  this.microPKStatus = 0;
  this.isGuessing = 0;
  this.roomLineInfo = new Nimo.RoomLineInfo();
  this.mStreamPkg = "";
  this.isPlayback = 0;
  this.startLiveTime = 0;
  this.endLiveTime = 0;
  this.userTitleData = new Nimo.UserTitleData();
  this.roomScreenshots = new Taf.Vector(new NimoBuss.RoomScreenshotsView());
};
LiveChannelDynamicVo.prototype._clone = function () {
  return new NimoBuss.LiveChannelDynamicVo();
};
LiveChannelDynamicVo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
LiveChannelDynamicVo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
LiveChannelDynamicVo.prototype.writeTo = function (os) {
  os.writeInt64(1, this.channelId);
  os.writeInt64(2, this.viewerNum);
  os.writeInt64(3, this.fanCount);
  os.writeBoolean(4, this.isFollow);
  os.writeInt32(5, this.liveStreamStatus);
  os.writeInt32(6, this.isLottery);
  os.writeInt32(7, this.microPKStatus);
  os.writeInt32(8, this.isGuessing);
  os.writeStruct(9, this.roomLineInfo);
  os.writeString(10, this.mStreamPkg);
  os.writeInt32(11, this.isPlayback);
  os.writeInt32(12, this.startLiveTime);
  os.writeInt32(13, this.endLiveTime);
  os.writeStruct(14, this.userTitleData);
  os.writeVector(15, this.roomScreenshots);
};
LiveChannelDynamicVo.prototype.readFrom = function (is) {
  this.channelId = is.readInt64(1, false, this.channelId);
  this.viewerNum = is.readInt64(2, false, this.viewerNum);
  this.fanCount = is.readInt64(3, false, this.fanCount);
  this.isFollow = is.readBoolean(4, false, this.isFollow);
  this.liveStreamStatus = is.readInt32(5, false, this.liveStreamStatus);
  this.isLottery = is.readInt32(6, false, this.isLottery);
  this.microPKStatus = is.readInt32(7, false, this.microPKStatus);
  this.isGuessing = is.readInt32(8, false, this.isGuessing);
  this.roomLineInfo = is.readStruct(9, false, this.roomLineInfo);
  this.mStreamPkg = is.readString(10, false, this.mStreamPkg);
  this.isPlayback = is.readInt32(11, false, this.isPlayback);
  this.startLiveTime = is.readInt32(12, false, this.startLiveTime);
  this.endLiveTime = is.readInt32(13, false, this.endLiveTime);
  this.userTitleData = is.readStruct(14, false, this.userTitleData);
  this.roomScreenshots = is.readVector(15, false, this.roomScreenshots);
};
NimoBuss.LiveChannelDynamicVo = LiveChannelDynamicVo;
export default LiveChannelDynamicVo;