import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var BulletMsgEffect = function BulletMsgEffect() {
  this.sResource = "";
  this.lTriggerCondition = 0;
};
BulletMsgEffect.prototype._clone = function () {
  return new Nimo.BulletMsgEffect();
};
BulletMsgEffect.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
BulletMsgEffect.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
BulletMsgEffect.prototype.writeTo = function (os) {
  os.writeString(0, this.sResource);
  os.writeInt64(1, this.lTriggerCondition);
};
BulletMsgEffect.prototype.readFrom = function (is) {
  this.sResource = is.readString(0, false, this.sResource);
  this.lTriggerCondition = is.readInt64(1, false, this.lTriggerCondition);
};
Nimo.BulletMsgEffect = BulletMsgEffect;
export default BulletMsgEffect;