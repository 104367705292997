import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './AnchorLevelConfig';
var AnchorLevelConfigRsp = function AnchorLevelConfigRsp() {
  this.iCode = 0;
  this.vConfig = new Taf.Vector(new Nimo.AnchorLevelConfig());
};
AnchorLevelConfigRsp.prototype._clone = function () {
  return new Nimo.AnchorLevelConfigRsp();
};
AnchorLevelConfigRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorLevelConfigRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorLevelConfigRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeVector(1, this.vConfig);
};
AnchorLevelConfigRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.vConfig = is.readVector(1, false, this.vConfig);
};
Nimo.AnchorLevelConfigRsp = AnchorLevelConfigRsp;
export default AnchorLevelConfigRsp;