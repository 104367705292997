import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _broadcasts;
import EURI from '@client/jce/Nimo/EURI';
import NonPublicLiveBeginNotice from '@client/jce/Nimo/NonPublicLiveBeginNotice';
import LiveTypeChange from '@client/jce/Nimo/LiveTypeChange';
import TafMessageSocketModel from '../TafMessageSocketModel';
var EUriNonPublicLiveBeginNotice = EURI.EUriNonPublicLiveBeginNotice,
  EUriLiveTypeChange = EURI.EUriLiveTypeChange;
export { EUriNonPublicLiveBeginNotice, EUriLiveTypeChange };
export default new TafMessageSocketModel({
  broadcasts: (_broadcasts = {}, _defineProperty(_broadcasts, EUriNonPublicLiveBeginNotice, [EUriNonPublicLiveBeginNotice, NonPublicLiveBeginNotice]), _defineProperty(_broadcasts, EUriLiveTypeChange, [EUriLiveTypeChange, LiveTypeChange]), _broadcasts)
});