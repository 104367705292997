import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './GiftItem';
import './QuickPropItem';
import './StreamerEffect';
import './GiftTab';
import './GiftContributeUserInfo';
var GetGiftListByRoomRsp = function GetGiftListByRoomRsp() {
  this.iCode = 0;
  this.vGiftItem = new Taf.Vector(new Nimo.GiftItem());
  this.sGiftItemListMD5 = "";
  this.vReplaceItem = new Taf.Vector(new Nimo.GiftItem());
  this.tQuickGiftItem = new Nimo.QuickPropItem();
  this.mStreamerEffect = new Taf.Map(new Taf.INT32(), new Taf.Vector(new Nimo.StreamerEffect()));
  this.iComboCountDown = 0;
  this.lRoomId = 0;
  this.vGiftTab = new Taf.Vector(new Nimo.GiftTab());
  this.mGiftContributeInfos = new Taf.Map(new Taf.INT32(), new Nimo.GiftContributeUserInfo());
  this.mSceneLimitedGiftData = new Taf.Map(new Taf.STRING(), new Taf.STRING());
};
GetGiftListByRoomRsp.prototype._clone = function () {
  return new Nimo.GetGiftListByRoomRsp();
};
GetGiftListByRoomRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetGiftListByRoomRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetGiftListByRoomRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeVector(1, this.vGiftItem);
  os.writeString(2, this.sGiftItemListMD5);
  os.writeVector(3, this.vReplaceItem);
  os.writeStruct(4, this.tQuickGiftItem);
  os.writeMap(5, this.mStreamerEffect);
  os.writeInt32(6, this.iComboCountDown);
  os.writeInt64(7, this.lRoomId);
  os.writeVector(8, this.vGiftTab);
  os.writeMap(9, this.mGiftContributeInfos);
  os.writeMap(10, this.mSceneLimitedGiftData);
};
GetGiftListByRoomRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.vGiftItem = is.readVector(1, false, this.vGiftItem);
  this.sGiftItemListMD5 = is.readString(2, false, this.sGiftItemListMD5);
  this.vReplaceItem = is.readVector(3, false, this.vReplaceItem);
  this.tQuickGiftItem = is.readStruct(4, false, this.tQuickGiftItem);
  this.mStreamerEffect = is.readMap(5, false, this.mStreamerEffect);
  this.iComboCountDown = is.readInt32(6, false, this.iComboCountDown);
  this.lRoomId = is.readInt64(7, false, this.lRoomId);
  this.vGiftTab = is.readVector(8, false, this.vGiftTab);
  this.mGiftContributeInfos = is.readMap(9, false, this.mGiftContributeInfos);
  this.mSceneLimitedGiftData = is.readMap(10, false, this.mSceneLimitedGiftData);
};
Nimo.GetGiftListByRoomRsp = GetGiftListByRoomRsp;
export default GetGiftListByRoomRsp;