import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var AnchorLevelUp = function AnchorLevelUp() {
  this.lUid = 0;
  this.iFromLevel = 0;
  this.iToLevel = 0;
  this.iCurrentExp = 0;
};
AnchorLevelUp.prototype._clone = function () {
  return new Nimo.AnchorLevelUp();
};
AnchorLevelUp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorLevelUp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorLevelUp.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUid);
  os.writeInt32(1, this.iFromLevel);
  os.writeInt32(2, this.iToLevel);
  os.writeInt32(3, this.iCurrentExp);
};
AnchorLevelUp.prototype.readFrom = function (is) {
  this.lUid = is.readInt64(0, false, this.lUid);
  this.iFromLevel = is.readInt32(1, false, this.iFromLevel);
  this.iToLevel = is.readInt32(2, false, this.iToLevel);
  this.iCurrentExp = is.readInt32(3, false, this.iCurrentExp);
};
Nimo.AnchorLevelUp = AnchorLevelUp;
export default AnchorLevelUp;