import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var AuditPunishMaskNotice = function AuditPunishMaskNotice() {
  this.anchorId = 0;
  this.roomId = 0;
  this.maskSeconds = 0;
  this.scale = 0;
};
AuditPunishMaskNotice.prototype._clone = function () {
  return new Nimo.AuditPunishMaskNotice();
};
AuditPunishMaskNotice.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AuditPunishMaskNotice.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AuditPunishMaskNotice.prototype.writeTo = function (os) {
  os.writeInt64(0, this.anchorId);
  os.writeInt64(1, this.roomId);
  os.writeInt32(2, this.maskSeconds);
  os.writeDouble(3, this.scale);
};
AuditPunishMaskNotice.prototype.readFrom = function (is) {
  this.anchorId = is.readInt64(0, false, this.anchorId);
  this.roomId = is.readInt64(1, false, this.roomId);
  this.maskSeconds = is.readInt32(2, false, this.maskSeconds);
  this.scale = is.readDouble(3, false, this.scale);
};
Nimo.AuditPunishMaskNotice = AuditPunishMaskNotice;
export default AuditPunishMaskNotice;