import MsgSysType from '@client/jce/Nimo/MsgSysType';
import EURI from '@client/jce/Nimo/EURI';
import ERoomAnimationType from '@client/jce/Nimo/ERoomAnimationType';
import ERoomMsgBannerType from '@client/jce/Nimo/ERoomMsgBannerType';
export { default as MessageProvider } from './MessageProvider';
export { default as TextMessageConsumer, MessageType } from './TextMessageConsumer';
export { default as GiftMessageConsumer, GiftEffectType } from './GiftMessageConsumer';
var EUriMessageNotice = EURI.EUriMessageNotice,
  EUriGiftRoomBroadCastNotice = EURI.EUriGiftRoomBroadCastNotice,
  EUriGiftEffectBroadCastNotice = EURI.EUriGiftEffectBroadCastNotice,
  EUriGiftRoomMgsBroadCastNotice = EURI.EUriGiftRoomMgsBroadCastNotice,
  EUriBSLotteryEndV3 = EURI.EUriBSLotteryEndV3,
  EUriBSLotteryWinnerV3 = EURI.EUriBSLotteryWinnerV3,
  EUriUserEnterRoom = EURI.EUriNewUserEnterRoom,
  EUriRoomMessageBanner = EURI.EUriRoomMessageBanner,
  EUriNoRankEnterRoom = EURI.EUriNoRankEnterRoom,
  EUriRoomAnimation = EURI.EUriRoomAnimation,
  EUriNewRoomAnimation = EURI.EUriNewRoomAnimation,
  EUriThirdMessageNotice = EURI.EUriThirdMessageNotice,
  EUriMarqueeNotice = EURI.EUriMarqueeNotice,
  EUriFortuneGiftStreamerEffectNotice = EURI.EUriFortuneGiftStreamerEffectNotice,
  EUriPayChatRichTextNewNotice = EURI.EUriPayChatRichTextNewNotice,
  EUriAnchorCheckEnterRoom = EURI.EUriAnchorCheckEnterRoom;
var EANIM_TYPE_ROYAL_UPGRADE = ERoomAnimationType.EANIM_TYPE_ROYAL_UPGRADE,
  EANIM_TYPE_SUBSCRIBE = ERoomAnimationType.EANIM_TYPE_SUBSCRIBE,
  EANIM_TYPE_ROYAL_RENEWAL = ERoomAnimationType.EANIM_TYPE_ROYAL_RENEWAL,
  EANIM_TYPE_ACTIVITY_BOMB = ERoomAnimationType.EANIM_TYPE_ACTIVITY_BOMB,
  EANIM_TYPE_COMMON = ERoomAnimationType.EANIM_TYPE_COMMON,
  EANIM_TYPE_FANGROUP_UPGRADE = ERoomAnimationType.EANIM_TYPE_FANGROUP_UPGRADE;
var kROOM_BANNER_ROYAL_UPGRADE = ERoomMsgBannerType.kROOM_BANNER_ROYAL_UPGRADE,
  kROOM_BANNER_ROYAL_RENEWAL = ERoomMsgBannerType.kROOM_BANNER_ROYAL_RENEWAL;
export { MsgSysType, EUriMessageNotice, EUriGiftRoomBroadCastNotice, EUriGiftEffectBroadCastNotice, EUriGiftRoomMgsBroadCastNotice, EUriBSLotteryEndV3, EUriBSLotteryWinnerV3, EUriUserEnterRoom, EUriRoomMessageBanner, EUriNoRankEnterRoom, EUriRoomAnimation, EUriNewRoomAnimation, EANIM_TYPE_ROYAL_UPGRADE, EANIM_TYPE_SUBSCRIBE, EANIM_TYPE_ROYAL_RENEWAL, EANIM_TYPE_ACTIVITY_BOMB, EANIM_TYPE_COMMON, EANIM_TYPE_FANGROUP_UPGRADE, kROOM_BANNER_ROYAL_UPGRADE, kROOM_BANNER_ROYAL_RENEWAL, ERoomAnimationType, EUriThirdMessageNotice, EUriMarqueeNotice, EUriFortuneGiftStreamerEffectNotice,
// EUriPayChatNotice,
EUriPayChatRichTextNewNotice, EUriAnchorCheckEnterRoom };