import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var ProgressTab = function ProgressTab() {
  this.id = 0;
  this.progressId = 0;
  this.title = "";
  this.titleLang = "";
  this.type = 0;
  this.img = "";
  this.num = 0;
};
ProgressTab.prototype._clone = function () {
  return new NimoBuss.ProgressTab();
};
ProgressTab.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ProgressTab.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ProgressTab.prototype.writeTo = function (os) {
  os.writeInt32(0, this.id);
  os.writeInt32(1, this.progressId);
  os.writeString(2, this.title);
  os.writeString(3, this.titleLang);
  os.writeInt32(4, this.type);
  os.writeString(5, this.img);
  os.writeInt32(6, this.num);
};
ProgressTab.prototype.readFrom = function (is) {
  this.id = is.readInt32(0, false, this.id);
  this.progressId = is.readInt32(1, false, this.progressId);
  this.title = is.readString(2, false, this.title);
  this.titleLang = is.readString(3, false, this.titleLang);
  this.type = is.readInt32(4, false, this.type);
  this.img = is.readString(5, false, this.img);
  this.num = is.readInt32(6, false, this.num);
};
NimoBuss.ProgressTab = ProgressTab;
export default ProgressTab;