/* eslint-disable */
var a = {
  generate32: function generate32() {
    var a = new Date(1582, 10, 15, 0, 0, 0, 0),
      b = new Date(),
      c = b.getTime() - a.getTime(),
      d = "",
      e = this.generateBits(c, 0, 31),
      f = this.generateBits(c, 32, 47),
      g = this.generateBits(c, 48, 59) + "1",
      h = this.generateBits(this.rand(4095), 0, 7),
      i = this.generateBits(this.rand(4095), 0, 7),
      j = this.generateBits(this.rand(8191), 0, 7) + this.generateBits(this.rand(8191), 8, 15) + this.generateBits(this.rand(8191), 0, 7) + this.generateBits(this.rand(8191), 8, 15) + this.generateBits(this.rand(8191), 0, 15);
    return e + d + f + d + g + d + h + i + d + j;
  },
  generateBits: function generateBits(a, b, c) {
    var d = this.returnBase(a, 16),
      e = new Array(),
      f = "",
      g = 0;
    for (g = 0; g < d.length; g++) e.push(d.substring(g, g + 1));
    for (g = Math.floor(b / 4); g <= Math.floor(c / 4); g++) f += e[g] && "" != e[g] ? e[g] : "0";
    return f;
  },
  returnBase: function returnBase(a, b) {
    return Math.abs(a).toString(b).toUpperCase();
  },
  rand: function rand(a) {
    return Math.floor(Math.random() * a);
  }
};
export default (function () {
  return a.generate32();
});