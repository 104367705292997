import payChatWupModel from '@client/common/models/wup/payChatWupModel';
import { setPayChatList } from '@desktop/src/common/models/home/actions';
export var updatePayChatList = function updatePayChatList(auId, dispatch) {
  payChatWupModel.getPayChatList(auId).then(function (res) {
    var vPayChatOption = res.vPayChatOption,
      vPayChatRecord = res.vPayChatRecord,
      iRecordSizeLimit = res.iRecordSizeLimit,
      tPayChatOption4Guardian = res.tPayChatOption4Guardian,
      iGuardianRemainCount = res.iGuardianRemainCount,
      vSpecialPayChatOption = res.vSpecialPayChatOption,
      iCode = res.iCode;
    dispatch(setPayChatList({
      vPayChatOption: vPayChatOption,
      vPayChatRecord: vPayChatRecord,
      iRecordSizeLimit: iRecordSizeLimit,
      tPayChatOption4Guardian: tPayChatOption4Guardian,
      iGuardianRemainCount: iGuardianRemainCount,
      vSpecialPayChatOption: vSpecialPayChatOption,
      iCode: iCode
    }));
  }).catch(function () {});
};