import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var GiftEffectType = {
  EFFECT_TYPE_SENDER: 111,
  EFFECT_TYPE_MESSAGE: 112,
  EFFECT_TYPE_CHAT_BANNER: 113,
  EFFECT_TYPE_ROOM_BANNER: 114,
  EFFECT_TYPE_BULLET: 115,
  EFFECT_TYPE_WORLD_BANNER: 116,
  EFFECT_TYPE_ANIM: 117,
  EFFECT_TYPE_BIG_ANIM: 118,
  EFFECT_TYPE_STICKER: 119
};
Nimo.GiftEffectType = GiftEffectType;
export default GiftEffectType;