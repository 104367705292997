import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import Nimo from '../nimo';
import '../Nimo/UserId';
var BaseParam = function BaseParam() {
  this.userId = new Nimo.UserId();
  this.ip = "";
  this.reqSerialNum = "";
  this.requestSource = "";
  this.reqInterface = "";
  this.appVersion = "";
  this.deviceType = 0;
  this.packageName = "";
  this.deviceInfo = "";
  this.imei = "";
  this.mac = "";
  this.versionCode = 0;
  this.apiVersionCode = "";
  this.deviceId = "";
  this.newUser = 0;
  this.region = "";
  this.language = "";
};
BaseParam.prototype._clone = function () {
  return new NimoBuss.BaseParam();
};
BaseParam.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
BaseParam.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
BaseParam.prototype.writeTo = function (os) {
  os.writeStruct(0, this.userId);
  os.writeString(1, this.ip);
  os.writeString(2, this.reqSerialNum);
  os.writeString(3, this.requestSource);
  os.writeString(4, this.reqInterface);
  os.writeString(5, this.appVersion);
  os.writeInt32(6, this.deviceType);
  os.writeString(7, this.packageName);
  os.writeString(8, this.deviceInfo);
  os.writeString(9, this.imei);
  os.writeString(10, this.mac);
  os.writeInt32(11, this.versionCode);
  os.writeString(12, this.apiVersionCode);
  os.writeString(13, this.deviceId);
  os.writeInt32(14, this.newUser);
  os.writeString(15, this.region);
  os.writeString(16, this.language);
};
BaseParam.prototype.readFrom = function (is) {
  this.userId = is.readStruct(0, false, this.userId);
  this.ip = is.readString(1, false, this.ip);
  this.reqSerialNum = is.readString(2, false, this.reqSerialNum);
  this.requestSource = is.readString(3, false, this.requestSource);
  this.reqInterface = is.readString(4, false, this.reqInterface);
  this.appVersion = is.readString(5, false, this.appVersion);
  this.deviceType = is.readInt32(6, false, this.deviceType);
  this.packageName = is.readString(7, false, this.packageName);
  this.deviceInfo = is.readString(8, false, this.deviceInfo);
  this.imei = is.readString(9, false, this.imei);
  this.mac = is.readString(10, false, this.mac);
  this.versionCode = is.readInt32(11, false, this.versionCode);
  this.apiVersionCode = is.readString(12, false, this.apiVersionCode);
  this.deviceId = is.readString(13, false, this.deviceId);
  this.newUser = is.readInt32(14, false, this.newUser);
  this.region = is.readString(15, false, this.region);
  this.language = is.readString(16, false, this.language);
};
NimoBuss.BaseParam = BaseParam;
export default BaseParam;