import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var ChatMsgEffect = function ChatMsgEffect() {
  this.sResource = "";
};
ChatMsgEffect.prototype._clone = function () {
  return new Nimo.ChatMsgEffect();
};
ChatMsgEffect.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
ChatMsgEffect.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
ChatMsgEffect.prototype.writeTo = function (os) {
  os.writeString(0, this.sResource);
};
ChatMsgEffect.prototype.readFrom = function (is) {
  this.sResource = is.readString(0, false, this.sResource);
};
Nimo.ChatMsgEffect = ChatMsgEffect;
export default ChatMsgEffect;