import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BaseParam';
var GetliveRoomPageReq = function GetliveRoomPageReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.regionCode = "";
  this.language = "";
  this.lcid = 0;
  this.size = 0;
  this.pageNum = 0;
};
GetliveRoomPageReq.prototype._clone = function () {
  return new NimoBuss.GetliveRoomPageReq();
};
GetliveRoomPageReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetliveRoomPageReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetliveRoomPageReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeString(1, this.regionCode);
  os.writeString(2, this.language);
  os.writeInt32(3, this.lcid);
  os.writeInt32(4, this.size);
  os.writeInt32(5, this.pageNum);
};
GetliveRoomPageReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.regionCode = is.readString(1, false, this.regionCode);
  this.language = is.readString(2, false, this.language);
  this.lcid = is.readInt32(3, false, this.lcid);
  this.size = is.readInt32(4, false, this.size);
  this.pageNum = is.readInt32(5, false, this.pageNum);
};
NimoBuss.GetliveRoomPageReq = GetliveRoomPageReq;
export default GetliveRoomPageReq;