import _typeof from "@babel/runtime/helpers/typeof";
import { runtime } from '@client/common/lib/nm-taf';
import getUserInfo from '@client/common/services/socket/getUserInfo';
export default function withUser(target, name, descriptor) {
  var fn = descriptor.value;
  if (typeof fn !== 'function') {
    throw new TypeError("@withUser decorator can only be applied to methods not: ".concat(_typeof(fn)));
  }

  // eslint-disable-next-line no-param-reassign
  descriptor.value = function () {
    var _this = this;
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    if (!runtime.user.isReady()) {
      return getUserInfo().then(function (userInfo) {
        runtime.user.init(userInfo);
      }).then(function () {
        return fn.apply(_this, args);
      });
    } else {
      return fn.apply(this, args);
    }
  };
  return descriptor;
}