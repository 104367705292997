import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BaseParam';
var GetEventListReq = function GetEventListReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.offset = 0;
  this.pageSize = 0;
  this.udbUserId = 0;
  this.language = "";
  this.lcid = 0;
};
GetEventListReq.prototype._clone = function () {
  return new NimoBuss.GetEventListReq();
};
GetEventListReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetEventListReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetEventListReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeInt32(1, this.offset);
  os.writeInt32(2, this.pageSize);
  os.writeInt64(3, this.udbUserId);
  os.writeString(4, this.language);
  os.writeInt32(5, this.lcid);
};
GetEventListReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.offset = is.readInt32(1, false, this.offset);
  this.pageSize = is.readInt32(2, false, this.pageSize);
  this.udbUserId = is.readInt64(3, false, this.udbUserId);
  this.language = is.readString(4, false, this.language);
  this.lcid = is.readInt32(5, false, this.lcid);
};
NimoBuss.GetEventListReq = GetEventListReq;
export default GetEventListReq;