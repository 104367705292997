import { isInNimoApp, jumpToPlayerPage } from '@client/mobile/src/common/services/jsBridge';
import history from '@client/common/services/react-history';
import { dotTypePathMap, getVideoPath } from '@client/common/services/videoDotTypePath';
var isApp = isInNimoApp();
export { dotTypePathMap };
export function getVideoUrl(dataSource) {
  var dotType = dataSource.dotType,
    resourceId = dataSource.resourceId;
  var path = getVideoPath(dotType);
  var mainSiteDomain = process.env.platform === 'desktop' ? DOMAINS.maindomain : DOMAINS.wapdomain;
  return "//".concat(mainSiteDomain, "/").concat(path, "/").concat(resourceId);
}
export function enterVideo(dataSource) {
  if (isApp) {
    var _ref = dataSource || {},
      title = _ref.title,
      videoUrl = _ref.videoUrl,
      businessType = _ref.businessType,
      resourceId = _ref.resourceId,
      cdnFlag = _ref.cdnFlag,
      videoResolution = _ref.videoResolution,
      roomId = _ref.roomId,
      anchorId = _ref.anchorId,
      from = _ref.from;
    jumpToPlayerPage({
      title: title,
      videoUrl: videoUrl,
      businessType: businessType,
      resourceId: resourceId,
      cdnFlag: cdnFlag,
      videoResolution: videoResolution,
      roomId: roomId,
      anchorId: anchorId,
      from: from
    });
  } else if ((dataSource === null || dataSource === void 0 ? void 0 : dataSource.from) === 'wap') {
    // 赛事页不为单页，从赛事页跳视频页用此跳转
    setTimeout(function () {
      window.location.href = getVideoUrl(dataSource);
    }, 200);
  } else {
    var _resourceId = dataSource.resourceId,
      dotType = dataSource.dotType;
    var path = getVideoPath(dotType);
    history.push("/".concat(path, "/").concat(_resourceId));
  }
}