import history from '@client/common/services/react-history';
import { parseURL } from '@client/common/utils/fn.js';
import removeUrlParams from '@client/common/utils/removeUrlParams';
import { setRoomEnterInfo } from '@client/common/services/setRoomEnterInfo';
window.isHomeSPA = true;
var toRemoveParams = ['_roomEnterFrom', '_roomEnterTraceData', '_pushtype', '_openGiftId'];

/**
 * act.nimo.tv活动页点击头像跳转到直播间要展示“通过xxx活动进入直播间”的消息
 * 后端是根据OnUserEvent传的sTraceSource判断的
 */
var urlParams = parseURL().params;
if (toRemoveParams.some(function (key) {
  return urlParams[key];
})) {
  var _ref = urlParams || {},
    _roomEnterFrom = _ref._roomEnterFrom,
    _roomEnterTraceData = _ref._roomEnterTraceData;
  if (_roomEnterFrom || _roomEnterTraceData) {
    setRoomEnterInfo({
      from: _roomEnterFrom,
      traceData: _roomEnterTraceData
    });
  }
  history.replace(removeUrlParams(toRemoveParams).replace(window.location.origin, ''));
}
window.__SPA_QUERY__ = urlParams;