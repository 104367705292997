import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var MsgCommDecoIcon = function MsgCommDecoIcon() {
  this.sUrl = "";
};
MsgCommDecoIcon.prototype._clone = function () {
  return new Nimo.MsgCommDecoIcon();
};
MsgCommDecoIcon.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
MsgCommDecoIcon.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
MsgCommDecoIcon.prototype.writeTo = function (os) {
  os.writeString(0, this.sUrl);
};
MsgCommDecoIcon.prototype.readFrom = function (is) {
  this.sUrl = is.readString(0, false, this.sUrl);
};
Nimo.MsgCommDecoIcon = MsgCommDecoIcon;
export default MsgCommDecoIcon;