import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var TransDownFanJoinClubPacketRspV2 = function TransDownFanJoinClubPacketRspV2() {
  this.badgeName = "";
  this.level = 0;
  this.fanId = 0;
  this.anchorId = 0;
  this.badgeId = 0;
  this.fanType = 0;
  this.avatarUrl = "";
  this.isQualified = 0;
  this.discount = 0;
  this.leftBadgeIcon = "";
  this.badgeIcon = "";
  this.leftBadgeIconV2 = "";
};
TransDownFanJoinClubPacketRspV2.prototype._clone = function () {
  return new NimoBuss.TransDownFanJoinClubPacketRspV2();
};
TransDownFanJoinClubPacketRspV2.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
TransDownFanJoinClubPacketRspV2.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
TransDownFanJoinClubPacketRspV2.prototype.writeTo = function (os) {
  os.writeString(0, this.badgeName);
  os.writeInt32(1, this.level);
  os.writeInt64(2, this.fanId);
  os.writeInt64(3, this.anchorId);
  os.writeInt64(4, this.badgeId);
  os.writeInt32(5, this.fanType);
  os.writeString(6, this.avatarUrl);
  os.writeInt32(7, this.isQualified);
  os.writeInt32(8, this.discount);
  os.writeString(9, this.leftBadgeIcon);
  os.writeString(10, this.badgeIcon);
  os.writeString(11, this.leftBadgeIconV2);
};
TransDownFanJoinClubPacketRspV2.prototype.readFrom = function (is) {
  this.badgeName = is.readString(0, false, this.badgeName);
  this.level = is.readInt32(1, false, this.level);
  this.fanId = is.readInt64(2, false, this.fanId);
  this.anchorId = is.readInt64(3, false, this.anchorId);
  this.badgeId = is.readInt64(4, false, this.badgeId);
  this.fanType = is.readInt32(5, false, this.fanType);
  this.avatarUrl = is.readString(6, false, this.avatarUrl);
  this.isQualified = is.readInt32(7, false, this.isQualified);
  this.discount = is.readInt32(8, false, this.discount);
  this.leftBadgeIcon = is.readString(9, false, this.leftBadgeIcon);
  this.badgeIcon = is.readString(10, false, this.badgeIcon);
  this.leftBadgeIconV2 = is.readString(11, false, this.leftBadgeIconV2);
};
NimoBuss.TransDownFanJoinClubPacketRspV2 = TransDownFanJoinClubPacketRspV2;
export default TransDownFanJoinClubPacketRspV2;