import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './PropsResource';
import './GiftEffectInfo';
var PropsItem = function PropsItem() {
  this.iPropsId = 0;
  this.sPropsName = "";
  this.sPropsDesc = "";
  this.fCostGold = 0;
  this.fCostSilver = 0;
  this.fExchangeMoney = 0;
  this.vSelectNum = new Taf.Vector(new Taf.INT32());
  this.iWeights = 0;
  this.iClass = 0;
  this.iMaxNum = 0;
  this.tPhoneResource = new Nimo.PropsResource();
  this.tWebResource = new Nimo.PropsResource();
  this.vEffectInfo = new Taf.Vector(new Nimo.GiftEffectInfo());
  this.vRoomsId = new Taf.Vector(new Taf.INT64());
  this.mStatus = new Taf.Map(new Taf.INT32(), new Taf.INT32());
  this.mNameLangs = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.mDescLangs = new Taf.Map(new Taf.STRING(), new Taf.STRING());
  this.iGiftType = 0;
  this.iChannelType = 0;
  this.vChannelData = new Taf.Vector(new Taf.STRING());
  this.iBackpackValidity = 0;
  this.sMarkeIcon = "";
  this.iMarkerStatus = 0;
  this.iPlay = 0;
  this.iPlayBoxId = 0;
  this.iSubChannelType = 0;
  this.vSubChannelData = new Taf.Vector(new Taf.STRING());
};
PropsItem.prototype._clone = function () {
  return new Nimo.PropsItem();
};
PropsItem.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
PropsItem.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
PropsItem.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iPropsId);
  os.writeString(1, this.sPropsName);
  os.writeString(2, this.sPropsDesc);
  os.writeFloat(3, this.fCostGold);
  os.writeFloat(4, this.fCostSilver);
  os.writeFloat(5, this.fExchangeMoney);
  os.writeVector(6, this.vSelectNum);
  os.writeInt32(7, this.iWeights);
  os.writeInt32(8, this.iClass);
  os.writeInt32(9, this.iMaxNum);
  os.writeStruct(10, this.tPhoneResource);
  os.writeStruct(11, this.tWebResource);
  os.writeVector(12, this.vEffectInfo);
  os.writeVector(13, this.vRoomsId);
  os.writeMap(14, this.mStatus);
  os.writeMap(15, this.mNameLangs);
  os.writeMap(16, this.mDescLangs);
  os.writeInt32(17, this.iGiftType);
  os.writeInt32(18, this.iChannelType);
  os.writeVector(19, this.vChannelData);
  os.writeInt32(20, this.iBackpackValidity);
  os.writeString(21, this.sMarkeIcon);
  os.writeInt32(22, this.iMarkerStatus);
  os.writeInt32(23, this.iPlay);
  os.writeInt32(24, this.iPlayBoxId);
  os.writeInt32(25, this.iSubChannelType);
  os.writeVector(26, this.vSubChannelData);
};
PropsItem.prototype.readFrom = function (is) {
  this.iPropsId = is.readInt32(0, false, this.iPropsId);
  this.sPropsName = is.readString(1, false, this.sPropsName);
  this.sPropsDesc = is.readString(2, false, this.sPropsDesc);
  this.fCostGold = is.readFloat(3, false, this.fCostGold);
  this.fCostSilver = is.readFloat(4, false, this.fCostSilver);
  this.fExchangeMoney = is.readFloat(5, false, this.fExchangeMoney);
  this.vSelectNum = is.readVector(6, false, this.vSelectNum);
  this.iWeights = is.readInt32(7, false, this.iWeights);
  this.iClass = is.readInt32(8, false, this.iClass);
  this.iMaxNum = is.readInt32(9, false, this.iMaxNum);
  this.tPhoneResource = is.readStruct(10, false, this.tPhoneResource);
  this.tWebResource = is.readStruct(11, false, this.tWebResource);
  this.vEffectInfo = is.readVector(12, false, this.vEffectInfo);
  this.vRoomsId = is.readVector(13, false, this.vRoomsId);
  this.mStatus = is.readMap(14, false, this.mStatus);
  this.mNameLangs = is.readMap(15, false, this.mNameLangs);
  this.mDescLangs = is.readMap(16, false, this.mDescLangs);
  this.iGiftType = is.readInt32(17, false, this.iGiftType);
  this.iChannelType = is.readInt32(18, false, this.iChannelType);
  this.vChannelData = is.readVector(19, false, this.vChannelData);
  this.iBackpackValidity = is.readInt32(20, false, this.iBackpackValidity);
  this.sMarkeIcon = is.readString(21, false, this.sMarkeIcon);
  this.iMarkerStatus = is.readInt32(22, false, this.iMarkerStatus);
  this.iPlay = is.readInt32(23, false, this.iPlay);
  this.iPlayBoxId = is.readInt32(24, false, this.iPlayBoxId);
  this.iSubChannelType = is.readInt32(25, false, this.iSubChannelType);
  this.vSubChannelData = is.readVector(26, false, this.vSubChannelData);
};
Nimo.PropsItem = PropsItem;
export default PropsItem;