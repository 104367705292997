import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import socket from '@client/common/lib/nm-socket/signal';
import { logger } from '@client/common/lib/nm-socket/utils';
import { mark } from '@client/common/lib/nm-socket/helpers';
import { flatData } from '@client/common/lib/nm-taf';
import { API_SERVER_NAME } from '@nimo-fed/metric-report';
import { JSError } from '@nimo-fed/http';
import { eventEmitter as commonEventEmitter, EVENTS } from '@client/common/utils/events';
import { MODULE_NIMO_UI } from './const';
import getUserInfo from './getUserInfo';
import DataCollector from '@client/common/services/DataCollector';
export { channel } from '@client/common/lib/nm-socket/signal';
var defaults = {
  wsServer: DOMAINS.isDebug === 'true' ? 'testws.va.huya.com' : 'ws.va.huya.com' // 只是数据上报使用
};

/**
 * 接口信息采集器，可用于接口错误采集、接口性能数据采集
 * 暂时没有需要上报的
 */
var collector = new DataCollector();

// 信令 sdk 执行封包操作，这里只是维持一样的参数传递
function handleBeforeSend(params) {
  var _params = _slicedToArray(params, 3),
    funcName = _params[0],
    data = _params[1],
    structure = _params[2];
  logger.info('signal socket send', funcName, data);
  return Promise.resolve({
    type: funcName,
    data: data,
    requestData: [MODULE_NIMO_UI, funcName, data, structure]
  }); // 保持和我们自己的 sdk 一样的参数
}

function handleReceive(_ref) {
  var funcName = _ref.funcName,
    listener = _ref.listener,
    data = _ref.data;
  // 所有的 on 都先经过这个 wrapper function 可以做一些统一的业务处理
  logger.info('signal received', funcName, data);
  listener(mark(funcName, flatData(data)));
  return Promise.resolve();
}

/**
 * 处理解包时错误
 * @param {*} reason
 */
function handleResponseError(reason) {
  if (reason !== undefined) {
    // 这里的报错有点神奇，先把error message报上去看一下是不是faltData方法有问题。再看是不是要改一下补充别的数据
    collector.reportError(new JSError(reason, {
      server: API_SERVER_NAME.SIGNAL
    }));
  }
}

/**
 * 接收原始广播数据，并分发（用于 iframe 内嵌页面等共享广播数据）
 */
function handleReceiveRawMessage(rawMessage) {
  var _ref2 = rawMessage || {},
    uri = _ref2.uri,
    data = _ref2.data;
  // 将原始数据广播给订阅者（目前有iframe）
  commonEventEmitter.emit(EVENTS.TAF_MESSAGE_RECEIVE, {
    uri: uri,
    data: data
  });
}
var inited = false;
// 进入直播间时，更新 roomId 运行时，并开启通道，则会收到广播消息
// roomId 运行时更新，触发开启/切换通道，所以可以扩展到非直播间页面订阅通道
// 开启通道必须要有 roomId
export var init = function init() {
  if (inited) return socket;
  inited = true;
  // 这里是 socket 发送及接收信息的处理链。不是业务方注册的方法的链路，所以不需要把promise状态往下传
  // 只是方便对 socket 的使用流程做一些通用的前置或后置处理
  // 处理 send
  socket.interceptors.request.unshift(handleBeforeSend, undefined); // 发送前处理 预处理错误

  socket.interceptors.response.push(handleReceive, undefined); // 接收sdk解包后的数据 只有成功解包了才会触发
  socket.interceptors.response.push(undefined, handleResponseError); // 处理解包错误

  getUserInfo().then(function (userInfo) {
    socket.init(_objectSpread(_objectSpread({}, defaults), {}, {
      user: userInfo,
      onSocketOpen: function onSocketOpen(socketInstance) {
        socketInstance.onUriNotice(handleReceiveRawMessage);
      },
      onSocketClose: function onSocketClose(socketInstance) {
        socketInstance.offUriNotice(handleReceiveRawMessage);
      }
    }));
  });
  return socket;
};
export default socket;