import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var AvatarBox = function AvatarBox() {
  this.sAvatarBoxUrl = "";
  this.sDynamicAvatarBoxUrl = "";
  this.iScene = 0;
};
AvatarBox.prototype._clone = function () {
  return new Nimo.AvatarBox();
};
AvatarBox.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AvatarBox.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AvatarBox.prototype.writeTo = function (os) {
  os.writeString(0, this.sAvatarBoxUrl);
  os.writeString(1, this.sDynamicAvatarBoxUrl);
  os.writeInt32(2, this.iScene);
};
AvatarBox.prototype.readFrom = function (is) {
  this.sAvatarBoxUrl = is.readString(0, false, this.sAvatarBoxUrl);
  this.sDynamicAvatarBoxUrl = is.readString(1, false, this.sDynamicAvatarBoxUrl);
  this.iScene = is.readInt32(2, false, this.iScene);
};
Nimo.AvatarBox = AvatarBox;
export default AvatarBox;