import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './StreamerEffect';
import './FortuneGiftStreamerContent';
var FortuneGiftStreamerEffectPacket = function FortuneGiftStreamerEffectPacket() {
  this.iItemType = 0;
  this.iItemCount = 0;
  this.lSenderUid = 0;
  this.sSenderNick = "";
  this.sSenderAvatarUrl = "";
  this.mStreamerEffect = new Taf.Map(new Taf.INT32(), new Nimo.StreamerEffect());
  this.iPrizeRatio = 0;
  this.lPrizeCount = 0;
  this.iSuperPrize = 0;
  this.tWebEffectContent = new Nimo.FortuneGiftStreamerContent();
  this.tIosEffectContent = new Nimo.FortuneGiftStreamerContent();
  this.tAdrEffectContent = new Nimo.FortuneGiftStreamerContent();
  this.iEffectLevel = 0;
};
FortuneGiftStreamerEffectPacket.prototype._clone = function () {
  return new Nimo.FortuneGiftStreamerEffectPacket();
};
FortuneGiftStreamerEffectPacket.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
FortuneGiftStreamerEffectPacket.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
FortuneGiftStreamerEffectPacket.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iItemType);
  os.writeInt32(1, this.iItemCount);
  os.writeInt64(2, this.lSenderUid);
  os.writeString(3, this.sSenderNick);
  os.writeString(4, this.sSenderAvatarUrl);
  os.writeMap(5, this.mStreamerEffect);
  os.writeInt32(6, this.iPrizeRatio);
  os.writeInt64(7, this.lPrizeCount);
  os.writeInt32(8, this.iSuperPrize);
  os.writeStruct(9, this.tWebEffectContent);
  os.writeStruct(10, this.tIosEffectContent);
  os.writeStruct(11, this.tAdrEffectContent);
  os.writeInt32(12, this.iEffectLevel);
};
FortuneGiftStreamerEffectPacket.prototype.readFrom = function (is) {
  this.iItemType = is.readInt32(0, false, this.iItemType);
  this.iItemCount = is.readInt32(1, false, this.iItemCount);
  this.lSenderUid = is.readInt64(2, false, this.lSenderUid);
  this.sSenderNick = is.readString(3, false, this.sSenderNick);
  this.sSenderAvatarUrl = is.readString(4, false, this.sSenderAvatarUrl);
  this.mStreamerEffect = is.readMap(5, false, this.mStreamerEffect);
  this.iPrizeRatio = is.readInt32(6, false, this.iPrizeRatio);
  this.lPrizeCount = is.readInt64(7, false, this.lPrizeCount);
  this.iSuperPrize = is.readInt32(8, false, this.iSuperPrize);
  this.tWebEffectContent = is.readStruct(9, false, this.tWebEffectContent);
  this.tIosEffectContent = is.readStruct(10, false, this.tIosEffectContent);
  this.tAdrEffectContent = is.readStruct(11, false, this.tAdrEffectContent);
  this.iEffectLevel = is.readInt32(12, false, this.iEffectLevel);
};
Nimo.FortuneGiftStreamerEffectPacket = FortuneGiftStreamerEffectPacket;
export default FortuneGiftStreamerEffectPacket;