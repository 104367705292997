/* eslint-disable no-param-reassign */
import Taf, { runtime, unpackMap, flatData } from '@client/common/lib/nm-taf';
import { Logger } from '@nimo-fed/util';
import DOWN_PACKET from '@client/jce/Nimo/DOWN_PACKET';
import EPktType from '@client/jce/Nimo/EPktType';
export var logger = new Logger({
  prefix: 'nm-socket'
});
function writeBinBuffer(uri, data) {
  var bin = new Taf.BinBuffer();
  bin.writeInt32(uri);
  bin.writeInt32(0);
  bin.writeInt32(0);
  bin.writeInt32(data.len);
  bin.writeBytes(data);
  return bin.getBuffer();
}
function readBinBuffer(data) {
  var buffer = new Taf.BinBuffer(data);
  var uri = buffer.readInt32();
  buffer.readInt32(); // appid, 不能删除，否则 DOWN_PACKET 出错
  buffer.readInt32(); // reserved, 不能删除，否则 DOWN_PACKET 出错
  var bodyLen = buffer.readInt32();
  var body = buffer.readBytes(bodyLen);
  return {
    uri: uri,
    body: body
  };
}

/**
 * 对回包消息进行解包
 *
 * @param {Blob} dataStream 加密数据包
 * @returns {Promise}
 */
export function readAsArrayBuffer(dataStream) {
  return blobToArrayBuffer(dataStream).then(function (arrayBuffer) {
    var buffer = new Taf.BinBuffer(arrayBuffer);
    return buffer;
  });
}
export var readInputStream = function readInputStream(buffer, Structure, key) {
  if (Structure) {
    var result = unpackCommon(buffer, Structure, key);
    result = flatData(result);
    logger.success('[readInputStream]', key, result);
    return Promise.resolve(result);
  } else {
    logger.warning("[readInputStream]No unpacking structure is specified for ".concat(key), key, Structure);
    return Promise.reject(new Error("[readInputStream] No unpacking structure is specified for ".concat(key)));
  }
};

/**
 * 封包函数
 *
 * @export
 * @param {*} uri 封包类型
 * @param {*} data 数据包
 * @returns
 */
export function packSocket(uri, data) {
  var withoutPackUri = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var dataStream = new Taf.JceOutputStream();
  data.writeTo(dataStream);
  var buffer = dataStream.getBinBuffer();
  logger.info('[packSocket]', uri, data);
  if (withoutPackUri && !uri) {
    return buffer;
  }
  return writeBinBuffer(uri, buffer);
}

/**
 * 普通解包方法
 *
 * @param {*} buffer 需要解包的数据
 * @param {string} Structure 包的结构体
 * @return {*}
 */
export function unpackCommon(buffer, Structure, iCmdType) {
  var inputStream = new Taf.JceInputStream(buffer);
  var result = new Structure();
  result.readFrom(inputStream);
  result.funcName = iCmdType;

  // logger.log('%c<unpack _common>', 'color:green;', buffer, Structure, iCmdType, result);

  return result;
}

/**
 * 对回包消息进行解包
 *
 * @param {*} data 加密数据包
 * @returns {Promise}
 */
export function unpackSocket(data) {
  return blobToArrayBuffer(data).then(function (arrayBuffer) {
    var kTDownMsg = EPktType.kTDownMsg;
    // eslint-disable-next-line prefer-const
    var _readBinBuffer = readBinBuffer(arrayBuffer),
      uri = _readBinBuffer.uri,
      body = _readBinBuffer.body;
    var key = uri; // 单播
    if (key === kTDownMsg) {
      // 广播
      var downPack = unpackCommon(body, DOWN_PACKET);
      key = "".concat(uri, "_").concat(downPack.iUri);
      body = downPack.sMsg;
    }
    return {
      key: key,
      body: body
    };
  });
}

/**
 * 解包 app 发布的广播数据包
 * @param {blob} data 数据包
 * @param {string} key 协议号
 */
export function unpackAppSocket(data, key) {
  var Structure = unpackMap.get(key);
  if (!Structure) {
    return Promise.reject(new Error("[unpackAppSocket] No unpacking structure is specified for ".concat(key)));
  }
  return blobToArrayBuffer(data).then(function (arrayBuffer) {
    var buffer = new Taf.BinBuffer(arrayBuffer);
    var result = unpackCommon(buffer, Structure, key);
    result = flatData(result);
    logger.success('[unpackAppSocket]', key, result);
    return result;
  });
}

/**
 * 设置用户信息（websocket）
 *
 * @param {Nimo.TubeId} tubeId 用户对象实例
 */
export function setTubeId(tubeId) {
  var _ref = runtime || {},
    rtUser = _ref.user;
  tubeId.iIdType = rtUser.uid === 0 ? 2 : 1; // 类型：2 匿名登录，1 用户登录
  tubeId.lUserid = rtUser.uid;
  tubeId.sToken = rtUser.token;
  tubeId.sUDBVer = rtUser.version;
  tubeId.iRegOrigin = rtUser.regOrigin;
  tubeId.sUA = rtUser.sUA;
  tubeId.sCountry = rtUser.sCountry;
  tubeId.sRegion = rtUser.sRegion;
  tubeId.sOpSeq = rtUser.sOpSeq;
  tubeId.sLang = rtUser.lang;

  // 为空则不传
  if (rtUser.sGuid) {
    tubeId.sGuid = rtUser.sGuid;
  }
}

/**
 * @param {Blob} blob
 * @returns
 */
function blobToArrayBuffer(blob) {
  if (blob && typeof blob.arrayBuffer === 'function') {
    return blob.arrayBuffer();
  } else {
    return blobReadAsArrayBuffer(blob);
  }
}

/**
 * @param {Blob} blob
 * @returns {Promise<ArrayBuffer>}
 */
function blobReadAsArrayBuffer(blob) {
  return new Promise(function (resolve, reject) {
    if (typeof FileReader !== 'function') {
      reject(new Error('FileReader Not support'));
      return;
    }
    try {
      var reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function () {
        reject(new Error('FileReader reading error'));
      };
      reader.readAsArrayBuffer(blob);
    } catch (error) {
      logger.error('[readAsArrayBuffer]', error);
      reject(error);
    }
  });
}