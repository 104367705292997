import nimoReport from '@desktop/src/common/services/report';

/**
 * 直播间_活动位_曝光
 * @param {String} position 活动位具体位置 S、A、B
 * @param {String} activityID 活动ID
 */
export var reportLiveroomActivityseatShow = function reportLiveroomActivityseatShow(position, activityID, activityType) {
  var reportObj = nimoReport.formatReportObj("liveroom_activityseat_".concat(position), "\u76F4\u64AD\u95F4_\u6D3B\u52A8\u4F4D".concat(position, "_\u66DD\u5149"), {
    activityID_: activityID,
    activityType: activityType
  });
  nimoReport.report(reportObj);
};

/**
 * 直播间_活动位_点击
 * @param {String} position  活动位具体位置 S、A、B
 * @param {String} activityID 活动ID
 */
export var reportLiveroomActivityseatClick = function reportLiveroomActivityseatClick(position, activityID, activityType) {
  var reportObj = nimoReport.formatReportObj("liveroom_activityseat_".concat(position, "_click"), "\u76F4\u64AD\u95F4_\u6D3B\u52A8\u4F4D".concat(position, "_\u70B9\u51FB"), {
    activityID_: activityID,
    activityType: activityType
  });
  nimoReport.report(reportObj);
};

/**
 * 直播间_活动位S_关闭
 */
export var reportLiveroomActivityseatCloseBtnClick = function reportLiveroomActivityseatCloseBtnClick() {
  var reportObj = nimoReport.formatReportObj("liveroom_activityseat_S_close", "\u76F4\u64AD\u95F4_\u6D3B\u52A8\u4F4DS_\u5173\u95ED");
  nimoReport.report(reportObj);
};