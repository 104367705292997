import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var GuardianInfo = function GuardianInfo() {
  this.lUID = 0;
  this.lAnchorUID = 0;
  this.iStatus = 0;
  this.iBizStatus = 0;
  this.lExpireTime = 0;
  this.iTotalDay = 0;
  this.lNowPoint = 0;
  this.iLevel = 0;
  this.iIsMaxLevel = 0;
  this.lNextLevelStartPoint = 0;
  this.iNextLevel = 0;
  this.lNowLevelStartPoint = 0;
  this.lInitTime = 0;
  this.iIsMemorialDay = 0;
};
GuardianInfo.prototype._clone = function () {
  return new Nimo.GuardianInfo();
};
GuardianInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GuardianInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GuardianInfo.prototype.writeTo = function (os) {
  os.writeInt64(0, this.lUID);
  os.writeInt64(1, this.lAnchorUID);
  os.writeInt32(2, this.iStatus);
  os.writeInt32(3, this.iBizStatus);
  os.writeInt64(4, this.lExpireTime);
  os.writeInt32(5, this.iTotalDay);
  os.writeInt64(6, this.lNowPoint);
  os.writeInt32(7, this.iLevel);
  os.writeInt32(8, this.iIsMaxLevel);
  os.writeInt64(9, this.lNextLevelStartPoint);
  os.writeInt32(10, this.iNextLevel);
  os.writeInt64(11, this.lNowLevelStartPoint);
  os.writeInt64(12, this.lInitTime);
  os.writeInt32(13, this.iIsMemorialDay);
};
GuardianInfo.prototype.readFrom = function (is) {
  this.lUID = is.readInt64(0, false, this.lUID);
  this.lAnchorUID = is.readInt64(1, false, this.lAnchorUID);
  this.iStatus = is.readInt32(2, false, this.iStatus);
  this.iBizStatus = is.readInt32(3, false, this.iBizStatus);
  this.lExpireTime = is.readInt64(4, false, this.lExpireTime);
  this.iTotalDay = is.readInt32(5, false, this.iTotalDay);
  this.lNowPoint = is.readInt64(6, false, this.lNowPoint);
  this.iLevel = is.readInt32(7, false, this.iLevel);
  this.iIsMaxLevel = is.readInt32(8, false, this.iIsMaxLevel);
  this.lNextLevelStartPoint = is.readInt64(9, false, this.lNextLevelStartPoint);
  this.iNextLevel = is.readInt32(10, false, this.iNextLevel);
  this.lNowLevelStartPoint = is.readInt64(11, false, this.lNowLevelStartPoint);
  this.lInitTime = is.readInt64(12, false, this.lInitTime);
  this.iIsMemorialDay = is.readInt32(13, false, this.iIsMemorialDay);
};
Nimo.GuardianInfo = GuardianInfo;
export default GuardianInfo;