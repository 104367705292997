import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
/* eslint-disable import/prefer-default-export */
import { genUnpackMapKey, decodeUnpackMapKey } from '@client/common/lib/nm-taf';
import EAppType from '@client/jce/Nimo/EAppType';
import EPktType from '@client/jce/Nimo/EPktType';

/**
 * 生成 tube key，用于解包
 *
 * @deprecated
 * @export
 * @param {*} uri
 * @returns
 */
export function genTubeKey(uri) {
  return genUnpackMapKey(EAppType.ETube, uri);
}

/**
 * 生成 DownMsg key，用于解包
 *
 * @export
 * @param {*} uri
 * @returns
 */
export function genDownMsgKey(uri) {
  return genUnpackMapKey(EPktType.kTDownMsg, uri);
}

/**
 * 从 DownMsg key 解析出 uri，用于标记消息类型
 *
 * @export
 * @param {string} key
 * @returns
 */
export function decodeUriFromDownMsgKey(key) {
  var _ref = decodeUnpackMapKey(key) || [],
    _ref2 = _slicedToArray(_ref, 2),
    first = _ref2[0],
    _ref2$ = _ref2[1],
    uri = _ref2$ === void 0 ? '' : _ref2$;
  if (first && !uri) return Number(first);
  return Number(uri);
}

/**
 * 标记消息类型
 * @param {string} uri 二级协议名称
 * @param {*} data
 */
export function mark(uri, data) {
  return _objectSpread({
    __msgtype__: uri
  }, data);
}

/**
 * 将 base64 字符串转化为 blob 对象
 */
export function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {
    type: mime
  });
}

/**
 * 将 blob 对象转换为 base64 字符串
 */
// export function blobToDataURL(blob, callback) {
//   var a = new FileReader();
//   a.onload = function(e) {
//     callback(e.target.result);
//   };
//   a.readAsDataURL(blob);
// }

/**
 * 将 base64 字符串在家转换为字符串
 */
export function dataURLtoStr(dataurl) {
  var arr = dataurl.split(',');
  var bstr = decodeURIComponent(escape(atob(arr[1])));
  return bstr;
}
export function dataURLtoFile(dataUrl, fileName) {
  var blob = dataURLtoBlob(dataUrl);
  blob.lastModifiedDate = new Date();
  blob.name = fileName;
  return blob;
}