import "core-js/modules/es6.array.sort.js";
import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var SubGameItem = function SubGameItem() {
  this.gameId = 0;
  this.sort = 0;
  this.gameName = "";
};
SubGameItem.prototype._clone = function () {
  return new NimoBuss.SubGameItem();
};
SubGameItem.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SubGameItem.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SubGameItem.prototype.writeTo = function (os) {
  os.writeInt64(0, this.gameId);
  os.writeInt32(1, this.sort);
  os.writeString(2, this.gameName);
};
SubGameItem.prototype.readFrom = function (is) {
  this.gameId = is.readInt64(0, false, this.gameId);
  this.sort = is.readInt32(1, false, this.sort);
  this.gameName = is.readString(2, false, this.gameName);
};
NimoBuss.SubGameItem = SubGameItem;
export default SubGameItem;