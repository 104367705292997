import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
var InterceptReasonView = function InterceptReasonView() {
  this.result = true;
  this.blacklistInterceptType = 0;
  this.blacklistInterceptFinishTime = 0;
};
InterceptReasonView.prototype._clone = function () {
  return new NimoBuss.InterceptReasonView();
};
InterceptReasonView.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
InterceptReasonView.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
InterceptReasonView.prototype.writeTo = function (os) {
  os.writeBoolean(0, this.result);
  os.writeInt32(1, this.blacklistInterceptType);
  os.writeInt64(2, this.blacklistInterceptFinishTime);
};
InterceptReasonView.prototype.readFrom = function (is) {
  this.result = is.readBoolean(0, false, this.result);
  this.blacklistInterceptType = is.readInt32(1, false, this.blacklistInterceptType);
  this.blacklistInterceptFinishTime = is.readInt64(2, false, this.blacklistInterceptFinishTime);
};
NimoBuss.InterceptReasonView = InterceptReasonView;
export default InterceptReasonView;