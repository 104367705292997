import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './PropsItem';
var SpecPropItem = function SpecPropItem() {
  this.iType = 0;
  this.vDataByType = new Taf.Vector(new Taf.STRING());
  this.tSpecPropItem = new Nimo.PropsItem();
  this.iConfigId = 0;
};
SpecPropItem.prototype._clone = function () {
  return new Nimo.SpecPropItem();
};
SpecPropItem.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
SpecPropItem.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
SpecPropItem.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iType);
  os.writeVector(1, this.vDataByType);
  os.writeStruct(2, this.tSpecPropItem);
  os.writeInt32(3, this.iConfigId);
};
SpecPropItem.prototype.readFrom = function (is) {
  this.iType = is.readInt32(0, false, this.iType);
  this.vDataByType = is.readVector(1, false, this.vDataByType);
  this.tSpecPropItem = is.readStruct(2, false, this.tSpecPropItem);
  this.iConfigId = is.readInt32(3, false, this.iConfigId);
};
Nimo.SpecPropItem = SpecPropItem;
export default SpecPropItem;