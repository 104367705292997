import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './EmoticonGroup';
var GetEmoticonByGroupIdRsp = function GetEmoticonByGroupIdRsp() {
  this.iCode = 0;
  this.tGroup = new Nimo.EmoticonGroup();
};
GetEmoticonByGroupIdRsp.prototype._clone = function () {
  return new Nimo.GetEmoticonByGroupIdRsp();
};
GetEmoticonByGroupIdRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetEmoticonByGroupIdRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetEmoticonByGroupIdRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeStruct(1, this.tGroup);
};
GetEmoticonByGroupIdRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.tGroup = is.readStruct(1, false, this.tGroup);
};
Nimo.GetEmoticonByGroupIdRsp = GetEmoticonByGroupIdRsp;
export default GetEmoticonByGroupIdRsp;