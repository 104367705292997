import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './EmoticonGroup';
var GetEmoticonByRoomRsp = function GetEmoticonByRoomRsp() {
  this.iCode = 0;
  this.vEmoticonGroup = new Taf.Vector(new Nimo.EmoticonGroup());
};
GetEmoticonByRoomRsp.prototype._clone = function () {
  return new Nimo.GetEmoticonByRoomRsp();
};
GetEmoticonByRoomRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetEmoticonByRoomRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetEmoticonByRoomRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeVector(1, this.vEmoticonGroup);
};
GetEmoticonByRoomRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.vEmoticonGroup = is.readVector(1, false, this.vEmoticonGroup);
};
Nimo.GetEmoticonByRoomRsp = GetEmoticonByRoomRsp;
export default GetEmoticonByRoomRsp;