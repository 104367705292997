import { local } from '@client/common/utils/storage';
export var storeKey = 'gift-record-height';
var defaultHeight = '20%';
export var min = 0.15;
export var max = 0.5;
export function getInitalHeight() {
  var input = local.get(storeKey);
  if (typeof input === 'string') {
    var maybeNumber = Number(input.slice(0, -1));
    if (!isNaN(maybeNumber) && maybeNumber >= min * 100 && maybeNumber <= max * 100) {
      return input;
    }
  }
  return defaultHeight;
}