import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './UserId';
var GetEmoticonByGroupIdReq = function GetEmoticonByGroupIdReq() {
  this.tUser = new Nimo.UserId();
  this.iGroupId = 0;
  this.lRoomId = 0;
};
GetEmoticonByGroupIdReq.prototype._clone = function () {
  return new Nimo.GetEmoticonByGroupIdReq();
};
GetEmoticonByGroupIdReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
GetEmoticonByGroupIdReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
GetEmoticonByGroupIdReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.tUser);
  os.writeInt32(1, this.iGroupId);
  os.writeInt64(2, this.lRoomId);
};
GetEmoticonByGroupIdReq.prototype.readFrom = function (is) {
  this.tUser = is.readStruct(0, false, this.tUser);
  this.iGroupId = is.readInt32(1, false, this.iGroupId);
  this.lRoomId = is.readInt64(2, false, this.lRoomId);
};
Nimo.GetEmoticonByGroupIdReq = GetEmoticonByGroupIdReq;
export default GetEmoticonByGroupIdReq;