import _typeof from "@babel/runtime/helpers/typeof";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { local } from '@client/common/utils/storage';
export var getDefaultSettings = function getDefaultSettings() {
  return {
    showEnterRoomMsg: true,
    showCoinGiftMsg: true,
    showFollowAnchorMsg: true,
    showGiftAnimation: true,
    isConcise: false,
    showEnterRoomBanner: true,
    enterRoomBannerSound: false,
    chat_pickme_switch: true,
    chat_enter_effect_switch: true
  };
};
var _cacheDefault;
export var getSetting = function getSetting(settings, key) {
  if (!settings) {
    if (!_cacheDefault) _cacheDefault = getDefaultSettings();
    return _cacheDefault[key];
  }
  return settings[key];
};
function getStoreKey(auId) {
  return "nimo-chatroom-msg-config-".concat(auId);
}
export function getMessageSettings(auId) {
  if (!auId) return null;
  var rawdata = local.get(getStoreKey(auId));
  if (!rawdata) return getDefaultSettings();
  var settings;
  try {
    settings = JSON.parse(rawdata);
  } catch (e) {
    // ignore error
  }
  settings = settings ? _objectSpread(_objectSpread({}, getDefaultSettings()), settings) : getDefaultSettings();
  return settings;
}
export function setMessageSettings(auId, settings) {
  if (_typeof(settings) === 'object' && settings !== null && auId) {
    local.set(getStoreKey(auId), JSON.stringify(settings));
  }
}