import { set as _set, get as _get, del as _del, Store } from 'idb-keyval';
var store;
var getStore = function getStore() {
  if (!store) {
    store = new Store('nimo', 'webpush');
    // catch 空间不足的情况
    store._dbp.catch(function (e) {
      var name = e === null || e === void 0 ? void 0 : e.name;
      if (name === 'QuotaExceededError' || name === 'UnknownError') {
        // ignore QuotaExceededError UnknownError for sentry report
      } else {
        throw e;
      }
    });
  }
  return store;
};
export default {
  getStore: getStore,
  set: function set(k, v) {
    return _set(k, v, getStore());
  },
  get: function get(k) {
    return _get(k, getStore());
  },
  del: function del(k) {
    return _del(k, getStore());
  }
};