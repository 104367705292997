import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var ELIVETYPE = {
  E_LIVE_PUBLIC: 0,
  E_LIVE_PRIVATE: 1,
  E_LIVE_PASSWD: 2,
  E_LIVE_PAYROOM: 3,
  E_LIVE_WEB_WATCH_ONLY: 4
};
Nimo.ELIVETYPE = ELIVETYPE;
export default ELIVETYPE;