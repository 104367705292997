import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
/* eslint-disable no-param-reassign */
// import { cookie } from '@client/common/utils/storage';
import { getLang, getCountry } from '@client/common/utils/fn';
import { getNetType } from '@client/common/utils/connection';
import { Runtime } from '@nimo-fed/taf';
import { getUserAgent } from './helpers/ua';
var runtime = new Runtime();
var lang = getLang();
var sCountry = getCountry();
var sUA = getUserAgent('web');
var iNetType = getNetType();
var defaultRuntimeUser = {
  isAnonymous: true,
  nick: '',
  lcid: '',
  uid: 0,
  sGuid: '',
  token: '',
  sUA: sUA,
  lang: lang,
  version: '',
  regOrigin: 99,
  // NOTE: 使用默认值，暂时没用
  sCountry: sCountry,
  sRegion: '',
  // NOTE: 使用默认值，暂时没用
  sOpSeq: '',
  // NOTE: 使用默认值，暂时没用
  iNetType: iNetType // 用户网络类型
};

var defaultRuntimeLiveRoom = {
  roomId: 0,
  lessonId: 0,
  auId: 0,
  nickname: '',
  topicId: 0,
  iLessonStatus: -1,
  iTerminalType: 6
};
var defaultRuntimeChannel = {
  status: false,
  // 只要 init 过 即为 true
  roomId: 0
};

/**
 * 用户运行时
 * TODO: 暂不支持 m 站页面使用
 */
var user = new Runtime(defaultRuntimeUser);
user.clear = function clearRuntimeUser() {
  user.reset(defaultRuntimeUser);
};

/**
 * 直播间运行时
 */
var liveRoom = new Runtime(defaultRuntimeLiveRoom);
liveRoom.clear = function clearRuntimeLiveRoom() {
  liveRoom.reset(defaultRuntimeLiveRoom);
};

/**
 * 通道运行时
 */
var channel = new Runtime(defaultRuntimeChannel);
channel.open = function openRuntimeChannel(data) {
  return channel.init(_objectSpread({
    status: true
  }, data));
};
channel.close = function closeRuntimeChannel() {
  channel.reset();
};
channel.clear = function clearRuntimeLiveRoom() {
  channel.reset(defaultRuntimeChannel);
};
channel.isOpened = function isRuntimeChannelOpened(type) {
  return channel.status;
};
runtime.init({
  user: user,
  liveRoom: liveRoom,
  channel: channel
});
export { defaultRuntimeUser, defaultRuntimeLiveRoom, defaultRuntimeChannel };
export default runtime;