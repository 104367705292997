import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var ERoomMsgBannerType = {
  kROOM_BANNER_COMMON: 0,
  kROOM_BANNER_ROYAL_UPGRADE: 1,
  kROOM_BANNER_ROYAL_RENEWAL: 2,
  kROOM_BANNER_WITH_AVATAR: 3,
  kROOM_BANNER_WEEK_STAR: 4,
  kROOM_BANNER_INTERACTIVE: 5,
  kROOM_BANNER_WORLD: 6
};
Nimo.ERoomMsgBannerType = ERoomMsgBannerType;
export default ERoomMsgBannerType;