import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
import './AnchorLevelDetail';
import './AnchorLevelConfig';
var AnchorLevelDetailRsp = function AnchorLevelDetailRsp() {
  this.iCode = 0;
  this.detail = new Nimo.AnchorLevelDetail();
  this.vConfig = new Taf.Vector(new Nimo.AnchorLevelConfig());
};
AnchorLevelDetailRsp.prototype._clone = function () {
  return new Nimo.AnchorLevelDetailRsp();
};
AnchorLevelDetailRsp.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
AnchorLevelDetailRsp.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
AnchorLevelDetailRsp.prototype.writeTo = function (os) {
  os.writeInt32(0, this.iCode);
  os.writeStruct(1, this.detail);
  os.writeVector(2, this.vConfig);
};
AnchorLevelDetailRsp.prototype.readFrom = function (is) {
  this.iCode = is.readInt32(0, false, this.iCode);
  this.detail = is.readStruct(1, false, this.detail);
  this.vConfig = is.readVector(2, false, this.vConfig);
};
Nimo.AnchorLevelDetailRsp = AnchorLevelDetailRsp;
export default AnchorLevelDetailRsp;