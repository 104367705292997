import Nimo from "../nimo";
import { Taf } from "@nimo-fed/taf";
var EmoticonInfo = function EmoticonInfo() {
  this.sName = "";
  this.sUrl = "";
  this.iPosition = 0;
  this.bCanUse = false;
  this.iLevel = 0;
  this.iStatus = 0;
  this.iEmoticonId = 0;
  this.lLeftTime = 0;
  this.sDesc = "";
  this.iSort = 0;
  this.vRoomId = new Taf.Vector(new Taf.INT64());
  this.iType = 0;
  this.sCornerIcon = "";
};
EmoticonInfo.prototype._clone = function () {
  return new Nimo.EmoticonInfo();
};
EmoticonInfo.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
EmoticonInfo.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
EmoticonInfo.prototype.writeTo = function (os) {
  os.writeString(0, this.sName);
  os.writeString(1, this.sUrl);
  os.writeInt32(2, this.iPosition);
  os.writeBoolean(3, this.bCanUse);
  os.writeInt32(4, this.iLevel);
  os.writeInt32(5, this.iStatus);
  os.writeInt32(6, this.iEmoticonId);
  os.writeInt64(7, this.lLeftTime);
  os.writeString(8, this.sDesc);
  os.writeInt32(9, this.iSort);
  os.writeVector(10, this.vRoomId);
  os.writeInt32(11, this.iType);
  os.writeString(12, this.sCornerIcon);
};
EmoticonInfo.prototype.readFrom = function (is) {
  this.sName = is.readString(0, false, this.sName);
  this.sUrl = is.readString(1, false, this.sUrl);
  this.iPosition = is.readInt32(2, false, this.iPosition);
  this.bCanUse = is.readBoolean(3, false, this.bCanUse);
  this.iLevel = is.readInt32(4, false, this.iLevel);
  this.iStatus = is.readInt32(5, false, this.iStatus);
  this.iEmoticonId = is.readInt32(6, false, this.iEmoticonId);
  this.lLeftTime = is.readInt64(7, false, this.lLeftTime);
  this.sDesc = is.readString(8, false, this.sDesc);
  this.iSort = is.readInt32(9, false, this.iSort);
  this.vRoomId = is.readVector(10, false, this.vRoomId);
  this.iType = is.readInt32(11, false, this.iType);
  this.sCornerIcon = is.readString(12, false, this.sCornerIcon);
};
Nimo.EmoticonInfo = EmoticonInfo;
export default EmoticonInfo;