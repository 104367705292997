import NimoBuss from "../nimobuss";
import { Taf } from "@nimo-fed/taf";
import './BaseParam';
var NextScheduleReq = function NextScheduleReq() {
  this.baseParam = new NimoBuss.BaseParam();
  this.currentRoomId = 0;
  this.eventId = 0;
  this.language = "";
  this.lcid = "";
  this.requestSource = "";
};
NextScheduleReq.prototype._clone = function () {
  return new NimoBuss.NextScheduleReq();
};
NextScheduleReq.prototype._write = function (os, tag, value) {
  os.writeStruct(tag, value);
};
NextScheduleReq.prototype._read = function (is, tag, def) {
  return is.readStruct(tag, true, def);
};
NextScheduleReq.prototype.writeTo = function (os) {
  os.writeStruct(0, this.baseParam);
  os.writeInt64(1, this.currentRoomId);
  os.writeInt64(2, this.eventId);
  os.writeString(3, this.language);
  os.writeString(4, this.lcid);
  os.writeString(5, this.requestSource);
};
NextScheduleReq.prototype.readFrom = function (is) {
  this.baseParam = is.readStruct(0, false, this.baseParam);
  this.currentRoomId = is.readInt64(1, false, this.currentRoomId);
  this.eventId = is.readInt64(2, false, this.eventId);
  this.language = is.readString(3, false, this.language);
  this.lcid = is.readString(4, false, this.lcid);
  this.requestSource = is.readString(5, false, this.requestSource);
};
NimoBuss.NextScheduleReq = NextScheduleReq;
export default NextScheduleReq;